import { Resources } from '../Models';

export const prioritizeDocuments = (fetchedDocuments: Resources[], keywords: string[], subject?: string) => {
  if (subject && subject === 'Reading') {
    let filteredDocuments = fetchedDocuments.filter((document, index) => {
      if (keywords.includes('blends') && document.title.includes('Blends')) {
        fetchedDocuments.splice(index, 1);
        return true;
      }
      if (document.title.includes('Letter ') && !keywords.includes('blends')) {
        fetchedDocuments.splice(index, 1);
        return true;
      }
    });
    return { randomizedDocuments: randomizeDocuments(filteredDocuments), splicedDocuments: fetchedDocuments };
  } else if (subject && subject === 'Math') {
    let filteredDocuments = fetchedDocuments.filter((document, index) => {
      if (document.title.includes('Multiplication')) {
        fetchedDocuments.splice(index, 1);
        return true;
      }
    });
    return { randomizedDocuments: randomizeDocuments(filteredDocuments), splicedDocuments: fetchedDocuments };
  } else if (subject && subject === 'Writing') {
    let filteredDocuments = fetchedDocuments.filter((document, index) => {
      if (document.title === ('Common and Proper Nouns' || 'Possessive Nouns' || 'Prepositions')) {
        fetchedDocuments.splice(index, 1);
        return true;
      }
    });
    return { randomizedDocuments: randomizeDocuments(filteredDocuments), splicedDocuments: fetchedDocuments };
  } else if (!subject) {
    let filteredDocuments = fetchedDocuments.filter((document, index) => {
      if (document.grade.includes('Kindergarten')) {
        fetchedDocuments.splice(index, 1);
        return true;
      }
    });
    return { randomizedDocuments: randomizeDocuments(filteredDocuments), splicedDocuments: fetchedDocuments };
  }
  return { randomizedDocuments: [], splicedDocuments: fetchedDocuments };
};

const randomizeDocuments = (filteredDocuments: Resources[]) => {
  let randomDocuments = [];
  while (randomDocuments.length < 3 && filteredDocuments.length > 0) {
    let randomIndex = Math.floor(Math.random() * filteredDocuments.length);
    randomDocuments.push(filteredDocuments[randomIndex]);
    filteredDocuments.splice(randomIndex, 1);
  }
  return randomDocuments;
};
