import React from 'react';
import { Link } from 'react-router-dom';
import { SubjectCardInfo } from '../../Models';
import Picture from '../Partials/Picture';

const Subjects = () => {
  return (
    <section className="subjects" id="subjects">
      <h4 className="subjects__header">Every Subject | Grade 1 - 12</h4>
      <div className="subjects__icon-cards-wrapper">
        {SubjectCardInfo.map((subject, index) => {
          return (
            <Link to={subject.link} key={index} className="subjects__link">
              <div className="subjects__icon-card">
                <Picture webp={subject.webpIcon} src={subject.icon} alt="subject" picClass="subjects__icon" />
                <p className="subjects__label">{subject.subject}</p>
              </div>
            </Link>
          );
        })}
      </div>
    </section>
  );
};

export default Subjects;
