import React, { useState } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useCookies } from 'react-cookie';
import { decrypt } from '../../Utils';

type PaymentProps = {
  priceId: string;
};
const Payment: React.FC<PaymentProps> = ({ priceId }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [cookies] = useCookies(['email']);
  const [error, setError] = useState(null);

  // Handle any errors returned from Checkout
  const handleResult = (result: any) => {
    alert(result);
    if (result.error) {
      // !setErrorState
      setError(result.error.message);
    }
  };

  const handleSubscribe = async (event: any, priceId: string) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    const email = decrypt(cookies.email! as string);
    stripe
      .redirectToCheckout({
        lineItems: [{ price: priceId, quantity: 1 }],
        mode: 'subscription',
        customerEmail: email,
        successUrl: `${process.env.REACT_APP_DOMAIN_NAME}/subscription?session_id={CHECKOUT_SESSION_ID}&price_id=${priceId}`,
        cancelUrl: `${process.env.REACT_APP_DOMAIN_NAME}/worksheets-for-kids`,
      })
      .then(handleResult); //!not hitting this??
  };

  return (
    <button
      className="premium__select-btn"
      type="submit"
      disabled={!stripe}
      onClick={(e) => handleSubscribe(e, priceId)}
    >
      Upgrade
    </button>
  );
};

export default Payment;
