import { decrypt } from './Encryption';

export const GetDecryptedEmail = (email: string | undefined) => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('id')) {
    return decrypt(urlParams.get('id')! as string);
  } else if (email) {
    return decrypt(email! as string);
  } else {
    return '';
  }
};
