import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { DownloadedDocument } from '../Models';
import { decrypt, GetBaseURL } from '../Utils';

type UserType = {
  full_name: string;
  email: string;
  phone?: string;
  street_address?: string;
  city?: string;
  postcode?: string;
};

type UserDataType = {
  _id: string;
  user_data: UserType;
  password: string;
  tutorInterest: string;
  userRole: string;
  assessments: DownloadedDocument[];
  worksheets: DownloadedDocument[];
  products: string[];
  receipts: string[];
  customer_id: string;
  message?: string;
  subscription_active: boolean;
  shouldUpdate: boolean;
  session_id: string;
  subscription_id: string;
};
export interface UserContextProps {
  userData: UserDataType;
  setUserData: (userData: any) => void;
}

const UserContext = React.createContext({} as UserContextProps);

const UserContextProvider = (props: any) => {
  const [userData, setUserData] = useState<UserDataType>({
    _id: '',
    user_data: { full_name: '', email: '', phone: '', street_address: '', city: '', postcode: '' },
    password: '',
    tutorInterest: 'Maybe Later',
    userRole: 'Incomplete',
    assessments: [],
    worksheets: [],
    products: [],
    receipts: [],
    customer_id: '',
    message: '',
    subscription_active: false,
    shouldUpdate: true,
    session_id: '',
    subscription_id: '',
  });

  const [cookies, setCookie, removeCookie] = useCookies(['email']);

  const fetchUser = async (email: string) => {
    const request = await fetch(`${GetBaseURL()}/api/users/fetchUser?key=${process.env.REACT_APP_API_KEY}`, {
      method: 'POST',
      body: JSON.stringify({ email }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (request.status === 200) {
      return request.json();
    } else {
      return null;
    }
  };

  const setUserInContext = async () => {
    if (cookies.email) {
      const fetchedUser = await fetchUser(decrypt(cookies.email! as string));
      if (fetchedUser && fetchedUser.user_data) {
        setUserData((prev: UserDataType) => ({
          ...prev,
          ...fetchedUser,
          user_data: { ...prev.user_data, ...fetchedUser.user_data },
        }));
      } else {
        setUserData({
          _id: '',
          user_data: { full_name: '', email: '', phone: '', street_address: '', city: '', postcode: '' },
          password: '',
          tutorInterest: 'Maybe Later',
          userRole: 'Incomplete',
          assessments: [],
          worksheets: [],
          products: [],
          receipts: [],
          customer_id: '',
          subscription_active: false,
          shouldUpdate: true,
          session_id: '',
          subscription_id: '',
        });
      }
    }
  };

  const updateUserWithProduct = async () => {
    await setUserInContext();
    const urlParams = new URLSearchParams(window.location.search);
    const priceId = urlParams.get('price_id');
    if (userData.session_id !== '' && userData._id !== '' && userData.subscription_id === '') {
      const request = await fetch(`${GetBaseURL()}/api/payments/updateUser?key=${process.env.REACT_APP_API_KEY}`, {
        body: JSON.stringify({ session_id: userData.session_id, user_id: userData._id }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      });
      const response = await request.json();
      if (request.status !== 400) {
        fetch(`${process.env.REACT_APP_STUDY_PORTAL_URL}/api/users?key=${process.env.REACT_APP_API_KEY}`, {
          method: 'PATCH',
          body: JSON.stringify({ email: userData.user_data.email, customer_id: response.customer_id }),
          headers: { 'Content-Type': 'application/json' },
        });
        setUserData((prev: any) => ({
          ...prev,
          customer_id: response.customer_id,
          subscription_id: response.subscription_id,
        }));
      }
    }
    if (userData._id !== '') {
      UpdateBilling();
    }
    if (priceId && userData._id !== '') {
      const request = await fetch(
        `${GetBaseURL()}/api/payments/confirm/${priceId}/${userData._id}?key=${process.env.REACT_APP_API_KEY}`,
        { method: 'GET', headers: { 'Content-Type': 'application/json' } },
      );
      const response = await request.json();

      if (request.status === 200) {
        setUserData((prev: any) => ({
          ...prev,
          products: prev.products ? [...prev.products, response.productID] : [response.productID],
          message: 'Thank you for your purchase! Enjoy unlimited access to all worksheets!',
          subscription_active: true,
          shouldUpdate: false,
        }));
      } else if (request.status === 500) {
        setUserData((prev: any) => ({
          ...prev,
          message: 'Thank you for your purchase! Enjoy unlimited access to all worksheets!',
          subscription_active: true,
          shouldUpdate: false,
        }));
      } else {
        setUserData((prev: any) => ({
          ...prev,
          message:
            'Something went wrong. Please contact our Account Manager, Ruth, at ruth@nofusstutors.com for assistance regarding this transaction.',
          subscription_active: false,
          shouldUpdate: false,
        }));
      }
    }
  };

  const UpdateBilling = async () => {
    fetch(
      `${GetBaseURL()}/api/payments/billingChanges/${
        userData.customer_id && userData.customer_id !== '' ? userData.customer_id : 'undefined'
      }/${userData.subscription_id && userData.subscription_id !== '' ? userData.subscription_id : 'undefined'}/${
        userData._id
      }?key=${process.env.REACT_APP_API_KEY}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      },
    );
  };

  useEffect(() => {
    if (userData.shouldUpdate) {
      updateUserWithProduct();
    }
  }, [userData._id, userData.shouldUpdate, userData.session_id]);

  const providerValue: UserContextProps = {
    userData,
    setUserData,
  };

  return <UserContext.Provider value={providerValue}>{props.children}</UserContext.Provider>;
};

export { UserContext, UserContextProvider };
