import React from 'react';
import AWSImage from './AWSImage';

type ReviewProps = {
  reviewContent: {
    review: string;
    personReviewing: string;
  };
};

const GoogleReview: React.FC<ReviewProps> = ({ reviewContent }) => {
  return (
    <section className="google-review">
      <div className="google-review__review-wrapper">
        <i className="fa fa-quote-left google-review__quote-icon"></i>
        <p className="google-review__quote">{reviewContent.review}</p>
        <i className="fa fa-quote-right google-review__quote-icon google-review__quote-icon--right"></i>
      </div>
      <div className="google-review__review-info-wrapper">
        <AWSImage
          pngFile={'google_icon.png'}
          documentPrefix={'icons'}
          picClass={'google-review__icon'}
          imageAlt={'Google Icon'}
          hasWebp
        />
        <div className="google-review__info">
          <h5 className="google-review__info-header">Google Review</h5>
          <p className="google-review__info-reviewer">By {reviewContent.personReviewing}</p>
          <div className="google-review__rating-wrapper">
            <p className="google-review__rating">5.0</p>
            <AWSImage
              pngFile={'five-stars.png'}
              documentPrefix={'icons'}
              picClass={'google-review__rating-stars'}
              imageAlt={'five star rating'}
              hasWebp
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default GoogleReview;
