import React, { useEffect } from 'react';
import { DateOptions } from '../../Models';
import { useDisplayData } from '../../Hooks/useDisplayData';
import AWSImage from './AWSImage';

type BannerProps = {
  lineOne: string;
  imageName: string;
  alt: string;
  lineTwo?: string;
  author?: string;
  date?: string;
  main?: boolean;
};

const Banner: React.FC<BannerProps> = ({ lineOne, lineTwo, author, date, main, imageName, alt }) => {
  const { displayData } = useDisplayData();

  useEffect(() => {
    //! scroll to top of page
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className={imageName.toLowerCase().includes('mobile') ? 'main main--mobile' : main ? 'main' : 'banner'}>
      {!imageName.toLowerCase().includes('mobile') && (
        <AWSImage
          pngFile={`${imageName}.jpg`}
          documentPrefix={'banner'}
          picClass={displayData.showModal ? 'fade-banner' : 'regular-banner'}
          imageAlt={alt}
          hasWebp
        />
      )}

      <div className={main ? 'main-banner__content' : 'banner__content'}>
        <h1
          className={
            displayData.showModal ? 'banner__content--heading hide heading-opacity' : 'banner__content--heading'
          }
        >
          {lineOne}
          {lineTwo ? (
            <>
              <br />
              {lineTwo}
            </>
          ) : null}
        </h1>
        {author ? (
          <div className="author-date">
            <span>{author}</span>
            {date ? (
              <>
                <i className="fas fa-circle"></i>
                <span>{new Date(date).toLocaleString('en-US', DateOptions)}</span>
              </>
            ) : null}
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default Banner;
