import homeBannerImg from '../assets/banners/min/banner-image.png';
import homeBannerWebp from '../assets/banners/webp/banner-image.webp';
import mathBannerImg from '../assets/banners/min/mathPageBanner.jpg';
import mathBannerWebp from '../assets/banners/webp/mathPageBanner.webp';
import englishBannerImg from '../assets/banners/min/englishBanner.jpg';
import englishBannerWebp from '../assets/banners/webp/englishBanner.webp';
import readingBannerImg from '../assets/banners/min/banner-reading.jpg';
import readingBannerWebp from '../assets/banners/webp/banner-reading.webp';
import writingBannerImg from '../assets/banners/min/writingBanner.jpg';
import writingBannerWebp from '../assets/banners/webp/writingBanner.webp';
import scienceBannerImg from '../assets/banners/min/scienceBanner.jpg';
import scienceBannerWebp from '../assets/banners/webp/scienceBanner.webp';
import languageBannerImg from '../assets/banners/min/languageBanner.jpg';
import languageBannerWebp from '../assets/banners/webp/languageBanner.webp';
import testPrepBannerImg from '../assets/banners/min/testPrepBanner.png';
import testPrepBannerWebp from '../assets/banners/webp/testPrepBanner.webp';
import bCBanner from '../assets/location-pages/banner-img-bc.jpg';
import bCBannerwebp from '../assets/location-pages/banner-img-bc.webp';
import ontarioBanner from '../assets/location-pages/banner-img-ontario.jpg';
import ontarioBannerwebp from '../assets/location-pages/banner-img-ontario.webp';
import canadaBanner from '../assets/location-pages/banner-img-canada.jpg';
import canadaBannerwebp from '../assets/location-pages/banner-img-canada.webp';
import californiaBanner from '../assets/location-pages/banner-img-cali.jpg';
import californiaBannerwebp from '../assets/location-pages/banner-img-cali.webp';
import nyBanner from '../assets/location-pages/banner-img-ny.jpg';
import nyBannerwebp from '../assets/location-pages/banner-img-ny.webp';
import usaBanner from '../assets/location-pages/banner-img-usa.jpg';
import usaBannerwebp from '../assets/location-pages/banner-img-usa.webp';
import floridaBanner from '../assets/location-pages/banner-img-florida.jpg';
import floridaBannerwebp from '../assets/location-pages/banner-img-florida.webp';

export interface HeroProps {
  content: {
    header: string;
    subheader?: string;
    className: string;
    src: any;
    webp: any;
  };
}

export const HomePageContent = {
  header: 'Catch Your Child Up and Get Them Ahead',
  subheader: 'We catch students up fast.',
  className: 'hero hero__home-webp hero__home',
  src: homeBannerImg,
  webp: homeBannerWebp,
};

export const MathHeaderContent = {
  header: 'Get an expert online math tutor',
  className: 'hero hero__math-webp hero__math',
  src: mathBannerImg,
  webp: mathBannerWebp,
};

export const EnglishHeaderContent = {
  header: 'Get an expert online english tutor',
  className: 'hero hero__english-webp hero__english',
  src: englishBannerImg,
  webp: englishBannerWebp,
};

export const ReadingHeaderContent = {
  header: 'Boost your child\'s reading level in 12 weeks!',
  className: 'hero hero__reading-webp hero__reading',
  src: readingBannerImg,
  webp: readingBannerWebp,
};

export const WritingHeaderContent = {
  header: 'Get an expert online writing tutor',
  className: 'hero hero__writing-webp hero__writing',
  src: writingBannerImg,
  webp: writingBannerWebp,
};

export const ScienceHeaderContent = {
  header: 'Get an expert online science tutor',
  className: 'hero hero__science-webp hero__science',
  src: scienceBannerImg,
  webp: scienceBannerWebp,
};

export const LanguagesHeaderContent = {
  header: 'Get an expert online language tutor',
  className: 'hero hero__languages-webp hero__languages',
  src: languageBannerImg,
  webp: languageBannerWebp,
};

export const TestPrepHeaderContent = {
  header: 'Get an expert online test prep tutor',
  className: 'hero hero__test-prep-webp hero__test-prep',
  src: testPrepBannerImg,
  webp: testPrepBannerWebp,
};

export const CanadaPageHeaderContent = {
  header: 'Online Tutors Canada',
  className: 'hero hero__online-tutor-canada-webp hero__online-tutor-canada',
  src: canadaBanner,
  webp: canadaBannerwebp,
};

export const OntarioPageHeaderContent = {
  header: 'Online Tutors Ontario',
  className: 'hero hero__online-tutor-ontario-webp hero__online-tutor-ontario',
  src: ontarioBanner,
  webp: ontarioBannerwebp,
};

export const TorontoPageHeaderContent = {
  header: 'Toronto Tutoring',
  className: 'hero hero__online-tutor-ontario-webp hero__online-tutor-ontario',
  src: ontarioBanner,
  webp: ontarioBannerwebp,
};

export const TorontoMathPageHeaderContent = {
  header: 'Math Tutors in Toronto',
  className: 'hero hero__online-tutor-ontario-webp hero__online-tutor-ontario',
  src: ontarioBanner,
  webp: ontarioBannerwebp,
};

export const BCPageHeaderContent = {
  header: 'Online Tutors British Columbia',
  className: 'hero hero__online-tutor-bc-webp hero__online-tutor-bc',
  src: bCBanner,
  webp: bCBannerwebp,
};

export const USAPageHeaderContent = {
  header: 'Online Tutors United States of America',
  className: 'hero hero__online-tutor-usa-webp hero__online-tutor-usa',
  src: usaBanner,
  webp: usaBannerwebp,
};

export const CaliPageHeaderContent = {
  header: 'Online Tutors California',
  className: 'hero hero__online-tutor-cali-webp hero__online-tutor-cali',
  src: californiaBanner,
  webp: californiaBannerwebp,
};

export const FloridaPageHeaderContent = {
  header: 'Online Tutors Florida',
  className: 'hero hero__online-tutor-florida-webp hero__online-tutor-florida',
  src: floridaBanner,
  webp: floridaBannerwebp,
};

export const NYPageHeaderContent = {
  header: 'Online Tutors New York',
  className: 'hero hero__online-tutor-ny-webp hero__online-tutor-ny',
  src: nyBanner,
  webp: nyBannerwebp,
};
