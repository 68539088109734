import React from 'react';
import MetaData from '../../Components/MetaData';
import Hero from '../../Components/Partials/Hero';
import LanguagesOffered from '../../Components/LanguagePage/LanguageOffered';
import TutoringPrograms from '../../Components/Partials/TutoringPrograms';
import OurPricing from '../../Components/Partials/OurPricing';
import GoogleReview from '../../Components/Partials/GoogleReview';
import Tutors from '../../Components/Partials/TutorCards/Tutors';
import ConsultationWidget from '../../Components/Partials/ConsultationWidget';
import ShareIcons from '../../Components/Blog/ShareIcons';
import SideContent from '../../Components/Partials/SideContent';
import {
  LanguagesHeaderContent,
  LanguagesTutoringProgramsInfo,
  LanguagesTutoringProgramsHeader,
  MathPageReview,
  StandardSubjectQuickLinks,
} from '../../Models';
import { generateID } from '../../Utils';

const Language = () => {
  return (
    <>
      <MetaData
        pageTitle="No Fuss Tutors | Online Languages Tutors"
        postTitle="Online Languages Tutors"
        description="Your Destination For Top Online Languages Tutors. Our mission is to make quality languages tutors affordable for everyone!"
        image={'https://nft-development.netlify.app/assets/webp/logo.png'}
        url={'https://nofusstutors.com/online-languages-tutors'}
        keywords={[
          'online languages tutor canada',
          'online languages tutor new york',
          'online languages tutor florida',
        ]}
      />
      <div className="languages-page">
        <Hero content={LanguagesHeaderContent} />
        <LanguagesOffered />
        <TutoringPrograms content={LanguagesTutoringProgramsInfo} headerContent={LanguagesTutoringProgramsHeader} />
        <OurPricing />
        <GoogleReview reviewContent={MathPageReview} />
        <Tutors />
        <ConsultationWidget source="form" />
        {
          <React.Fragment key={generateID()}>
            <aside className={'blog-page__side-content--mobile'}>
              <div className="blog-page__side-links">
                {StandardSubjectQuickLinks && (
                  <SideContent
                    links={[...StandardSubjectQuickLinks.pages]}
                    worksheetsLinks={[...StandardSubjectQuickLinks.worksheets]}
                  />
                )}
              </div>
              <div className="blog-page__share-icons-wrapper">
                <ShareIcons
                  url="https://nofusstutors.com/online-language-tutor"
                  facebookQuote="No Fuss Tutoring has a dedicated network of tutors to help your kids improve their grades!"
                />
              </div>
            </aside>
          </React.Fragment>
        }
      </div>
    </>
  );
};

export default Language;
