import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useCookies } from 'react-cookie';
//********** STRIPE IMPORTS ********** */
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Payment from '../Components/Partials/Payment';
import SignInModal from '../Components/SignInModal';
import BackToPage from '../Components/Partials/BackToPage';
import { useDisplayData } from '../Hooks/useDisplayData';
import { useUserData } from '../Hooks/useUserData';
import { useResourceFilters } from '../Hooks/useResourceFilter';
import { PremiumCards, FAQ } from '../Models';
import { generatedLocation, generatedRoute } from '../Utils';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY!);

const Premium: React.FC = () => {
  const { displayData, hideModal, startBodyScroll } = useDisplayData();
  const { userData } = useUserData();
  const { resourceData } = useResourceFilters();
  const [cookies] = useCookies(['email']);
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  useEffect(() => {
    if (cookies.email) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  return isLoggedIn && !userData.subscription_active ? (
    <div className="premium">
      <div
        className={displayData.showModal ? 'overlay' : 'transparent'}
        onClick={() => {
          hideModal();
          startBodyScroll();
        }}
      ></div>
      <div className="premium__hero">
        <p className="premium__hero-sub-heading">Premium Membership</p>
        {/* <h1 className="premium__hero-heading">.</h1> */}
        {/* <div className="premium__credit-wrapper">
          <p className="premium__hero-sub-heading">Worksheet Limit: 1 per day</p>
          <p className="premium__hero-sub-heading">Assessment Limit: 1 per day</p>
        </div> */}
      </div>

      {/* <BackToPage
        route={`/${generatedRoute({ ...resourceData.filters })}`}
        location={generatedLocation(generatedRoute({ ...resourceData.filters }))}
      /> */}

      <div className="premium__cards-wrapper">
        {PremiumCards.map((card, index) => {
          return (
            <div className="premium__card" key={index}>
              <h3 className="premium__card-heading" style={{ fontSize: '2.1rem' }}>
                {card.subType}
              </h3>
              <h4 className="premium__card-price">
                US {card.price}
                <span className="premium__card-price-month">/month</span>
              </h4>
              <h5 className="premium__original-price">{card.originalPrice}/month</h5>
              <ul className="premium__benefits-list">
                <li>50 Daily Messages with our AI Tutor</li>
                <li>Unlimited access to all resources</li>
                {/* <li>Personalized learning recommendations</li> */}
                <li>Ad-free experience for focused learning</li>
                {/* <li>Access to premium-only content</li> */}
                <li>Priority customer support</li>
              </ul>
              <Elements stripe={stripePromise}>
                <Payment
                  priceId={
                    card.subType === 'Monthly Price'
                      ? `${process.env.REACT_APP_MONTHLY_SUBSCRIPTION_PRICE}`
                      : `${process.env.REACT_APP_YEARLY_SUBSCRIPTION_PRICE}`
                  }
                />
              </Elements>
            </div>
          );
        })}
      </div>

      {/* <div className="premium__faq">
        <h5 className="premium__faq-heading">Frequently Asked Questions</h5>
        <div className="premium__faq-answers-container">
          {FAQ.map((question, index) => {
            return (
              <React.Fragment key={index}>
                <div className="premium__faq-qa-wrapper">
                  <h5 className="premium__faq-question">{question.question}</h5>
                  <p className="premium__faq-answer">{question.answer}</p>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div> */}
      {displayData.showModal && <SignInModal HideModal={hideModal} />}
    </div>
  ) : (
    <Redirect to="/worksheets-for-kids" />
  );
};

export default Premium;
