// ! subject page imports
import MathPage from '../Pages/Subjects/Math';
import EnglishPage from '../Pages/Subjects/English';
import ReadingPage from '../Pages/Subjects/Reading';
import WritingPage from '../Pages/Subjects/Writing';
import SciencePage from '../Pages/Subjects/Science';
import LanguagePage from '../Pages/Subjects/Language';
import TestPrepPage from '../Pages/Subjects/TestPrep';
import NYPage from '../Components/LocationPages/NYLocation';
import FloridaPage from '../Components/LocationPages/FloridaLocation';
import CaliPage from '../Components/LocationPages/CaliLocation';
import OntarioPage from '../Components/LocationPages/OntarioLocation';
import BCPage from '../Components/LocationPages/BCLocation';
import CanadaPage from '../Components/LocationPages/CanadaLocation';
import USAPage from '../Components/LocationPages/USALocation';
import TorontoLocation from '../Components/LocationPages/OntarioCities/TorontoLocation';
import TorontoMath from '../Components/LocationPages/TorontoSubjects/TorontoMath';


import Home from '../Pages/Home';

import {
  WhenDoesAChildLearnToReadContent,
  AfraidToReadContent,
  WhyDoesMyChildAskSoManyQuestionsContent,
  MyChildCantFocusContent,
  My9YearOldCantReadContent,
  HelpWithSpellingContent,
  ParentTeacherConferenceContent,
  PlayingVideoGamesContent,
  WhenYourChildWontDoHomeWorkContent,
  FallingBehindContent,
  DistributivePropertyContent,
  MultiplicationChart,
  whatAreBlendsPost,
  sightWordsDoneRightPost,
  spellingActivitiesPost,
  WhatIsTextStructureContent,
  BenefitsOfColoringPagesForKidsContent,
} from '../Models/pages/Blog';

// ! blog post routes
export const BlogPostRoutes = [
  {
    route: '/blog-posts/why-does-my-child-ask-so-many-questions',
    content: WhyDoesMyChildAskSoManyQuestionsContent,
  },
  {
    route: '/blog-posts/my-kid-wont-do-homework',
    content: WhenYourChildWontDoHomeWorkContent,
  },
  {
    route: '/blog-posts/my-kid-is-falling-behind-in-math',
    content: FallingBehindContent,
  },
  {
    route: '/blog-posts/my-9-year-old-cant-read',
    content: My9YearOldCantReadContent,
  },
  {
    route: '/blog-posts/my-child-is-playing-video-games',
    content: PlayingVideoGamesContent,
  },
  {
    route: '/blog-posts/what-should-i-ask-in-a-parent-teacher-conference',
    content: ParentTeacherConferenceContent,
  },
  {
    route: '/blog-posts/my-child-cant-focus-on-school-work',
    content: MyChildCantFocusContent,
  },
  {
    route: '/blog-posts/how-to-help-my-child-with-spelling',
    content: HelpWithSpellingContent,
  },
  {
    route: '/blog-posts/my-kid-is-afraid-to-read-in-class',
    content: AfraidToReadContent,
  },
  {
    route: '/blog-posts/distributive-property-of-multiplication',
    content: DistributivePropertyContent,
  },
  {
    route: '/blog-posts/multiplication-chart',
    content: MultiplicationChart,
  },
  {
    route: '/blog-posts/what-are-blends',
    content: whatAreBlendsPost,
  },
  {
    route: '/blog-posts/sight-words-done-right',
    content: sightWordsDoneRightPost,
  },
  {
    route: '/blog-posts/why-are-spelling-activities-important',
    content: spellingActivitiesPost,
  },
  {
    route: '/blog-posts/what-is-text-structure',
    content: WhatIsTextStructureContent,
  },
  {
    route: '/blog-posts/when-does-a-child-learn-to-read',
    content: WhenDoesAChildLearnToReadContent,
  },
  {
    route: '/blog-posts/coloring-pages-for-kids',
    content: BenefitsOfColoringPagesForKidsContent,
  },
];

// ! subject pages route
export const SubjectPagesRoutes = [
  {
    component: MathPage,
    route: '/online-math-tutor',
  },
  {
    component: EnglishPage,
    route: '/online-english-tutor',
  },
  {
    component: ReadingPage,
    route: '/online-reading-tutor',
  },
  {
    component: WritingPage,
    route: '/online-writing-tutor',
  },
  {
    component: SciencePage,
    route: '/online-science-tutor',
  },
  {
    component: LanguagePage,
    route: '/online-language-tutor',
  },
  {
    component: TestPrepPage,
    route: '/online-test-prep-tutor',
  },
  {
    component: Home,
    route: '/online-geometry-tutor',
  },
  {
    component: Home,
    route: '/online-algebra-tutor',
  },
  {
    component: Home,
    route: '/online-calculus-tutor',
  },
  {
    component: Home,
    route: '/online-biology-tutor',
  },
  {
    component: Home,
    route: '/online-chemistry-tutor',
  },
  {
    component: Home,
    route: '/online-physics-tutor',
  },
  {
    component: Home,
    route: '/online-english-literature-tutor',
  },
  {
    component: Home,
    route: '/online-history-tutor',
  },
  {
    component: Home,
    route: '/online-spanish-tutor',
  },
  {
    component: Home,
    route: '/online-french-tutor',
  },
  {
    component: Home,
    route: '/online-esl-tutor',
  },
  {
    component: Home,
    route: '/online-mandarin-tutor',
  },
  {
    component: Home,
    route: '/online-german-tutor',
  },
  {
    component: Home,
    route: '/online-italian-tutor',
  },
  {
    component: Home,
    route: '/online-russian-tutor',
  },
  {
    component: Home,
    route: '/online-arabic-tutor',
  },
  {
    component: Home,
    route: '/online-korean-tutor',
  },
  {
    component: Home,
    route: '/online-hindi-tutor',
  },
  {
    component: Home,
    route: '/online-sat-tutor',
  },
  {
    component: Home,
    route: '/online-act-tutor',
  },
  {
    component: Home,
    route: '/online-private-school-tutor',
  },

  {
    component: Home,
    route: '/online-high-school-tutor',
  },
  {
    component: Home,
    route: '/online-elementary-tutor',
  },


  
];

// ! location page routes
export const LocationPageRoutes = [
  {
    component: CanadaPage,
    route: '/online-tutor-canada',
  },
  {
    component: USAPage,
    route: '/online-tutor-usa',
  },
  {
    component: NYPage,
    route: '/online-tutor-new-york',
  },
  {
    component: FloridaPage,
    route: '/online-tutor-florida',
  },
  {
    component: CaliPage,
    route: '/online-tutor-california',
  },
  {
    component: OntarioPage,
    route: '/online-tutor-ontario',
  },
  {
    component: TorontoLocation,
    route: '/toronto-tutoring',
  },
  {
    component: TorontoMath,
    route: '/math-tutor-toronto',
  },
  {
    component: BCPage,
    route: '/online-tutor-british-columbia',
  },
  {
    component: Home,
    route: '/online-tutor-quebec',
  },
  {
    component: Home,
    route: '/online-tutor-nova-scotia',
  },
  {
    component: Home,
    route: '/online-tutor-alberta',
  },
  {
    component: Home,
    route: '/online-tutor-manitoba',
  },
  {
    component: Home,
    route: '/online-tutor-saskatchewan',
  },
  {
    component: Home,
    route: '/online-tutor-texas',
  },
  {
    component: Home,
    route: '/online-tutor-pennsylvania',
  },
  {
    component: Home,
    route: '/online-tutor-illinois',
  },
  {
    component: Home,
    route: '/online-tutor-ohio',
  },
  {
    component: Home,
    route: '/online-tutor-georgia',
  },
  {
    component: Home,
    route: '/online-tutor-north-carolina',
  },
  {
    component: Home,
    route: '/online-tutor-michigan',
  },
  {
    component: Home,
    route: '/online-tutor-new-jersey',
  },
  {
    component: Home,
    route: '/online-tutor-virginia',
  },
  {
    component: Home,
    route: '/online-tutor-washington',
  },
  {
    component: Home,
    route: '/online-tutor-arizona',
  },
  {
    component: Home,
    route: '/online-tutor-massachusetts',
  },
  {
    component: Home,
    route: '/online-tutor-tennessee',
  },
  {
    component: Home,
    route: '/online-tutor-indiana',
  },
  {
    component: Home,
    route: '/online-tutor-missouri',
  },
  {
    component: Home,
    route: '/online-tutor-maryland',
  },
  {
    component: Home,
    route: '/online-tutor-wisconsin',
  },
  {
    component: Home,
    route: '/online-tutor-colorado',
  },
  {
    component: Home,
    route: '/online-tutor-minnesota',
  },
  
];
