import React from 'react';
import { PerfectScoreSteps } from '../../Models';
import AWSImage from '../Partials/AWSImage';

const PerfectScore = () => {
  return (
    <section className="perfect-score">
      <h2 className="perfect-score__heading">
        The road to the <span className="perfect-score__orange-text">perfect</span> score
      </h2>
      <p className="perfect-score__sub-heading">
        The tried and true steps to getting a great SAT/ACT test score with No Fuss Tutors
      </p>
      <div className="perfect-score__wrapper">
        <AWSImage
          pngFile={'test-prep-outline.png'}
          picClass={'perfect-score__img'}
          imageAlt={'path to perfect test score'}
          documentPrefix={'homepage/test-prep'}
          hasWebp
        />

        {/* {PerfectScoreSteps.map((item, index) => {
          return (
            <div className={item.stepClassName} key={index}>
              <h3 className="perfect-score__step-heading">{item.stepHeader}</h3>
              <p className="perfect-score__step-summary">{item.stepSummary}</p>
              <ul className="perfect-score__cycle">
                {item.step4List?.map((item, index) => {
                  return (
                    <li className="perfect-score__cycle-list-item" key={index}>
                      {item}
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })} */}
      </div>
    </section>
  );
};

export default PerfectScore;
