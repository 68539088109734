import React from 'react';
import AWSImage from '../Partials/AWSImage';

const LevelsOfMath = () => {
  return (
    <section className="levels-of-math">
      <h2 className="levels-of-math__header">Levels of Math</h2>
      <h5 className="levels-of-math__sub-header">
        Math skills grow in stages. Each one like a building block on the other. These levels of growth have their own
        benchmarks and specific grade levels in our school systems assigned to them. We put together a simplified path
        to help our students navigate toward becoming a math whiz:
      </h5>

      <AWSImage
        pngFile={'mathLevels.png'}
        documentPrefix={'homepage/math'}
        imageAlt="levels of math"
        picClass="levels-of-math__img-wrapper"
        hasWebp
      />
    </section>
  );
};

export default LevelsOfMath;
