import React, { useState } from 'react';
import { ErrorProps } from '../../Models';
import { generateID, GetBaseURL, GetStudyPortalURL, GradeToNumberOrString } from '../../Utils';
import ErrorHandler from '../Partials/ErrorHandler';
import QuestionCard from './QuestionCard';
// import Whiteboard from './Whiteboard';

export type Question = {
  _id?: string;
  subject: string;
  question: string;
  question_type: 'MC' | 'MC ma' | 'input';
  answers: { answer: string; correct: boolean }[];
  assessing: string;
  wordsNumbers?: 'Numbers' | 'Words';
  difficulty: number;
  topic: string;
  subtopic: string;
  specific_subtopic: string;
  grade: string;
  images: string[];
};

const GameSettings: React.FC = () => {
  const [subject, setSubject] = useState('Math');
  const [grade, setGrade] = useState(8);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [error, setError] = useState<ErrorProps>({ type: 'error', isActive: false, message: '' });

  const [startGame, setStartGame] = useState(false);

  const resetError = () => {
    setError((prev: ErrorProps) => ({ ...prev, isActive: false }));
  };

  const shuffleArray = (arr: any[]) => {
    for (let i = arr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    return arr;
  };

  return (
    <>
      {error.isActive && <ErrorHandler type={error.type} message={error.message} handler={resetError} />}
      {!startGame && (
        <div className="games__settings--container">
          <form>
            <div className="games__settings--slider-wrapper">
              <span className="self-checkout-widget__slider-value">
                {grade < 1 ? GradeToNumberOrString(grade) : `Grade ${grade}`}
              </span>
              <input
                type="range"
                min="0"
                max="8"
                defaultValue="8"
                className="self-checkout-widget__slider"
                onInput={(e) => {
                  setGrade(Number(e.currentTarget.value));
                }}
              />
            </div>

            <div className="games__settings--buttons-wrapper">
              <div
                className={`games__settings--button ${subject === 'English' && 'games__settings--button--selected'}`}
                id="form-button"
                onClick={() => {
                  setSubject('English');
                }}
              >
                English
              </div>
              <div
                className={`games__settings--button ${subject === 'Math' && 'games__settings--button--selected'}`}
                id="form-button"
                onClick={() => {
                  setSubject('Math');
                }}
              >
                Math
              </div>
            </div>
            <div className="games__settings--buttons-wrapper">
              <div
                className={`games__settings--button--start ${
                  subject === 'Math' && 'games__settings--button--selected'
                } ${subject === 'English' && 'games__settings--button--selected'}`}
                onClick={async () => {
                  const request = await fetch(
                    `${GetStudyPortalURL()}/api/questions/fetchRelevant?key=${process.env.REACT_APP_API_KEY}`,
                    {
                      method: 'POST',
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify({ grade: GradeToNumberOrString(grade), subject }),
                    },
                  );
                  const response = await request.json();
                  if (request.status === 200) {
                    if (response.questions.length < 10) {
                      setError({
                        isActive: true,
                        type: 'error',
                        message: 'We currently do not have enough questions for this grade level and subject.',
                      });
                    } else {
                      setQuestions(shuffleArray(response.questions).slice(0, 10));
                      setStartGame(true);
                    }
                  } else {
                    setError({ isActive: true, type: 'error', message: response.message });
                  }
                }}
              >
                Start Quiz
              </div>
            </div>
          </form>
        </div>
      )}

      {startGame && (
        <>
          <div className="question__title">
            {grade < 1 ? GradeToNumberOrString(grade) : `Grade ${grade}`} {subject} Quiz
          </div>
          <QuestionCard
            question={questions[currentQuestion].question}
            answers={questions[currentQuestion].answers}
            images={questions[currentQuestion].images}
            currentQuestion={currentQuestion}
            setCurrentQuestion={setCurrentQuestion}
          />
        </>
      )}
    </>
  );
};

export default GameSettings;
