import React from 'react';
import { Link } from 'react-router-dom';
import { EnglishTutoringInfo } from '../../Models';
import AWSImage from '../Partials/AWSImage';

const TutoringInfo = () => {
  return (
    <section className="english-page__selling-points">
      {[EnglishTutoringInfo[0]].map((item, index) => {
        return (
          <div key={index}>
            <div className="english-page__selling-point-wrapper">
              <div className={'english-page__content'}>
                <div className="english-page__selling-point-content">
                  <h4 className="english-page__selling-point-number">{item.number}</h4>
                  <h4 className="english-page__selling-point-header">{item.header}</h4>
                  <p className="english-page__selling-point-info">{item.info}</p>
                  <p className="english-page__selling-point-info">{item.findOutMore}</p>
                  <div className="english-page__selling-points--links">
                    <Link to="/online-reading-tutor">
                      <button className={item.readingBtnClass}>{item.readingBtn}</button>
                    </Link>
                    <Link to="/online-writing-tutor">
                      <button className={item.writingBtnClass}>{item.writingBtn}</button>
                    </Link>
                  </div>
                </div>
                <AWSImage
                  pngFile={item.img}
                  imageAlt={item.alt}
                  picClass={item.imgClass}
                  documentPrefix="homepage/english"
                  hasWebp
                />
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default TutoringInfo;
