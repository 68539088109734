import React, { useRef, useState } from 'react';
import { generateID } from '../../Utils';
import { ReactSketchCanvas, ReactSketchCanvasRef } from 'react-sketch-canvas';

interface QuestionCardProps {
  question: string;
  answers: { answer: string; correct: boolean }[];
  images: string[];
  currentQuestion: number;
  setCurrentQuestion: React.Dispatch<React.SetStateAction<number>>;
}

type Results = {
  display: boolean;
  data: { question: string; answers: { answer: string; correct: boolean; selected: boolean }[] }[];
};

const QuestionCard: React.FC<QuestionCardProps> = ({
  question,
  answers,
  images,
  currentQuestion,
  setCurrentQuestion,
}) => {
  const [answered, setAnswered] = useState(false);
  const [incorrectIndex, setIncorrectIndex] = useState(-1);
  const [score, setScore] = useState(0);
  const [results, setResults] = useState<Results>({ display: false, data: [] });
  const [selectedColor, setSelectedColor] = useState('black');

  const canvasRef = useRef<ReactSketchCanvasRef>(null);

  const clearCanvas = () => {
    if (canvasRef.current) {
      canvasRef.current.clearCanvas();
    }
  };

  const selectColor = (color: string) => {
    setSelectedColor(color);
  };

  const undo = () => {
    if (canvasRef.current) {
      canvasRef.current.undo();
    }
  };

  const redo = () => {
    if (canvasRef.current) {
      canvasRef.current.redo();
    }
  };

  const colors = [
    '#000000', // Black
    '#2980b9', // Blue
    '#f1c40f', // Yellow
    '#e67e22', // Orange
    '#2ecc71', // Emerald
    '#8e44ad', // Dark Purple
    '#16a085', // Dark Green
    '#d35400', // Rust
  ];

  return results.display ? (
    <div className="question">
      <div className="question__score">Final Score: {score}/10</div>
      {results.data.map((data, index) => (
        <React.Fragment key={generateID()}>
          <div className="question__results--question">
            {index + 1}: {data.question}
          </div>
          {data.answers.map((answer) => (
            <React.Fragment key={generateID()}>
              <div
                className={`question__answer question__results--answer${
                  answer.correct ? ' question__answer--correct' : answer.selected ? ' question__answer--incorrect' : ''
                }`}
              >
                {answer.answer}
              </div>
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
    </div>
  ) : (
    <div>
      <div className="question">
        <div className="question__score">Score: {score}/10</div>
        <div className="question__question">{question}</div>
        {images.length > 0 && images[0] !== '' && (
          <div className="question__images">
            {images.map((imageString) => (
              <React.Fragment key={generateID()}>
                {imageString !== 'No image' ? (
                  <img src={`https://moonrock-images.s3.us-east-2.amazonaws.com/${imageString}`} />
                ) : null}
              </React.Fragment>
            ))}
          </div>
        )}
        <div className="question__mc">
          {answers.map((answer, index) => (
            <React.Fragment key={generateID()}>
              <div
                className={`question__answer${
                  answered
                    ? answer.correct
                      ? ' question__answer--correct'
                      : incorrectIndex === index
                      ? ' question__answer--incorrect'
                      : ' question__answer--other'
                    : ''
                }`}
                onClick={() => {
                  if (!answer.correct) {
                    setIncorrectIndex(index);
                  } else {
                    setScore((prev: number) => prev + 1);
                  }
                  setResults((prev: Results) => ({
                    ...prev,
                    data: [
                      ...prev.data,
                      {
                        question,
                        answers: answers.map((answer) => ({
                          answer: answer.answer,
                          correct: answer.correct,
                          selected: answers[index].answer === answer.answer,
                        })),
                      },
                    ],
                  }));
                  setAnswered(true);
                }}
              >
                {answer.answer}
              </div>
            </React.Fragment>
          ))}
        </div>
        {answered && (
          <div
            className="question__next"
            onClick={() => {
              if (currentQuestion < 9) {
                setCurrentQuestion((prev: number) => prev + 1);
                setAnswered(false);
                setIncorrectIndex(-1);
              } else {
                setResults((prev: Results) => ({ ...prev, display: true }));
              }
            }}
          >
            {currentQuestion < 9 ? 'Next Question' : 'See Results'}
          </div>
        )}
      </div>

      <div className="question-canvas">
        <div className="question__color-palette">
          {colors.map((color) => (
            <div
              key={color}
              className={`question__color-palette__color${
                selectedColor === color ? ' question__color-paletteselected' : ''
              }`}
              style={{ backgroundColor: color }}
              onClick={() => selectColor(color)}
            />
          ))}
        </div>
        <div className="question__canvas-controls">
          <button className="question__canvas-button" onClick={undo}>
            Undo
          </button>
          <button className="question__canvas-button" onClick={redo}>
            Redo
          </button>
          <button className="question__canvas-button" onClick={clearCanvas}>
            Clear Canvas
          </button>
        </div>

        <div className="question__whiteboard">
          <ReactSketchCanvas ref={canvasRef} width="100%" height="500px" strokeWidth={3} strokeColor={selectedColor} />
        </div>
      </div>
    </div>
  );
};

export default QuestionCard;
