import React from 'react';
import { ScienceProgramsContent02 } from '../../Models';
import AWSImage from '../Partials/AWSImage';

const SubjectPhotos = () => {
  return (
    <div className="science-subjects__img-wrapper">
      {ScienceProgramsContent02.map((item: any, index: any) => {
        return (
          <React.Fragment key={index}>
            <h3 className="science-subjects__number">{item.number}</h3>
            <h4 className="science-subjects__header">{item.header}</h4>
            <div className="science-subjects__img-container">
              <AWSImage
                pngFile={item.img02}
                picClass={item.img02Class}
                imageAlt={item.img02Alt}
                documentPrefix={item.awsPrefix}
                hasWebp
              />
              <AWSImage
                pngFile={item.img03}
                picClass={item.img03Class}
                imageAlt={item.img03Alt}
                documentPrefix={item.awsPrefix}
                hasWebp
              />
              <AWSImage
                pngFile={item.img04}
                picClass={item.img04Class}
                imageAlt={item.img04Alt}
                documentPrefix={item.awsPrefix}
                hasWebp
              />
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default SubjectPhotos;
