import React, { useEffect, useState, useRef } from 'react';
import Hero from '../../../Components/Partials/Hero';
import MetaData from '../../../Components/MetaData';
import { TorontoPageContent, TorontoPageHeaderContent, TorontoPageQuickLinks } from '../../../Models';
import { generateID } from '../../../Utils';
import ShareIcons from '../../Blog/ShareIcons';
import SideContent from '../../Partials/SideContent';
import ConsultationWidget from '../../Partials/ConsultationWidget';
import { Link } from 'react-router-dom';
import OnlineReview from '../../Home/OnlineReview';
import Tutors from '../../Partials/TutorCards/Tutors';
import Subject from '../../SciencePage/Subject';

const TorontoLocation: React.FC = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth < 1200 ? true : false);
  const [isLinkVisibleFromTop, setIsLinkVisibleFromTop] = useState(true);
  const [isLinkVisibleFromBottom, setIsLinkVisibleFromBottom] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const scrollHeightRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    //! scroll to top of page
    window.scrollTo(0, 0);
  }, []);

  window.addEventListener('scroll', () => {
    let lastKnownScrollPosition = window.scrollY;

    if (scrollHeightRef.current && lastKnownScrollPosition < 615) {
      setIsLinkVisibleFromTop(true);
    } else {
      setIsLinkVisibleFromTop(false);
    }
    if (scrollHeightRef.current && lastKnownScrollPosition > scrollHeightRef.current.scrollHeight - 815) {
      setIsLinkVisibleFromBottom(true);
    } else {
      setIsLinkVisibleFromBottom(false);
    }
  });

  window.addEventListener('resize', () => {
    setWidth(window.innerWidth);
    if (isMobileScreen && window.innerWidth > 1199) {
      setIsMobileScreen(false);
    }
    if (!isMobileScreen && window.innerWidth < 1200) {
      setIsMobileScreen(true);
    }
  });

  return (
    <>
      <MetaData
        pageTitle="Tutors in Toronto"
        postTitle="Tutors in Toronto"
        description="Your Destination For Top Tutors in Toronto. Get a free consultation today!"
        image={'https://nft-development.netlify.app/assets/webp/logo.png'}
        url={'https://nofusstutors.com/online-tutor-toronto'}
        keywords={['Toronto Tutors', 'Online Tutor Toronto', 'Virtual Toronto Tutor']}
      />
      <div className="toronto-location-page" ref={scrollHeightRef}>
        <Hero content={TorontoPageHeaderContent} />
        <div className="toronto-location-page">
          <ConsultationWidget source="form" />

          <div className="city-online-review-widget">
            <OnlineReview />
          </div>

          <section className="location-subjects-links">
            <div className="container">
              <h2 className="subjects-title">Subjects We Tutor in Toronto</h2>
              <div className="subjects-grid">
                <Link to="/math-tutor-toronto" className="subject-link">
                  Math Tutoring
                </Link>
                <Link to="/english-tutor-toronto" className="subject-link">
                  English Tutoring
                </Link>
                <Link to="/writing-tutor-toronto" className="subject-link">
                  Writing Tutoring
                </Link>
                <Link to="/reading-tutor-toronto" className="subject-link">
                  Reading Tutoring
                </Link>
                <Link to="/science-tutor-toronto" className="subject-link">
                  Science Tutoring
                </Link>
                <Link to="/french-tutor-toronto" className="subject-link">
                  French Tutoring
                </Link>
                <Link to="/chemistry-tutor-toronto" className="subject-link">
                  Chemistry Tutoring
                </Link>
                <Link to="/physics-tutor-toronto" className="subject-link">
                  Physics Tutoring
                </Link>
                <Link to="/biology-tutor-toronto" className="subject-link">
                  Biology Tutoring
                </Link>
                <Link to="/calculus-tutor-toronto" className="subject-link">
                  Calculus Tutoring
                </Link>
                <Link to="/advanced-functions-tutor-toronto" className="subject-link">
                  Advanced Functions
                </Link>
                <Link to="/esl-tutor-toronto" className="subject-link">
                  ESL Tutoring
                </Link>
                <Link to="/history-tutor-toronto" className="subject-link">
                  History Tutoring
                </Link>
                <Link to="/coding-tutor-toronto" className="subject-link">
                  Coding Tutoring
                </Link>
                <Link to="/data-management-tutor-toronto" className="subject-link">
                  Data Management
                </Link>
              </div>
            </div>
          </section>

          <Tutors />

          {TorontoPageContent.map((item, index) => (
            <section className="city-location-page__section" key={index}>
              <header className="city-location-page__header">
                <h2 className="city-location-page__heading">{item.heading || 'Default Heading'}</h2>
              </header>
              <article className="city-location-page__article">
                <p className="city-location-page__content">{item.content || 'Default content for this section.'}</p>
              </article>
            </section>
          ))}
        </div>
        <ConsultationWidget source="form" />
      </div>
    </>
  );
};

export default TorontoLocation;
