import React from 'react';
import Hero from '../../Components/Partials/Hero';
import MetaData from '../../Components/MetaData';
import SciencePrograms from '../../Components/SciencePage/SciencePrograms';
import Subjects from '../../Components/SciencePage/Subject';
import SubjectPhotos from '../../Components/SciencePage/SubjectPhotos';
import Careers from '../../Components/SciencePage/Careers';
import OurPricing from '../../Components/Partials/OurPricing';
import GoogleReview from '../../Components/Partials/GoogleReview';
import Tutors from '../../Components/Partials/TutorCards/Tutors';
import ConsultationWidget from '../../Components/Partials/ConsultationWidget';
import ShareIcons from '../../Components/Blog/ShareIcons';
import SideContent from '../../Components/Partials/SideContent';
import { MathPageReview, ScienceHeaderContent, ScienceProgramsContent, StandardSubjectQuickLinks } from '../../Models';
import { generateID } from '../../Utils';

const Science = () => {
  const RELATED_BLOGS: { pageName: string; pageLink: string }[] = [
    {
      pageLink: '/blog-posts/my-child-cant-focus-on-school-work',
      pageName: 'Blog | Struggling to Focus',
    },
    {
      pageLink: '/blog-posts/my-kid-wont-do-homework',
      pageName: 'Blog | Homework Troubles',
    },
  ];

  const RELATED_WORKSHEETS: { worksheetsName: string; worksheetsLink: string }[] = [
    {
      worksheetsLink: '/science-worksheets',
      worksheetsName: 'Science',
    },
  ];

  return (
    <div className="science-page">
      <MetaData
        pageTitle="No Fuss Tutors | Online Science Tutors"
        postTitle="Online Science Tutors"
        description="Your Destination For Top Online Science Tutors. Our mission is to make quality science tutors affordable for everyone!"
        image={'https://nft-development.netlify.app/assets/webp/logo.png'}
        url={'https://nofusstutors.com/onling-science-tutor'}
        keywords={[
          'online science tutoring canada',
          'online science tutoring new york',
          'online science tutoring florida',
        ]}
      />
      <Hero content={ScienceHeaderContent} />
      <SciencePrograms content={ScienceProgramsContent} />
      <div className="science-page__subjects-flex-wrapper">
        <Subjects />
        <SubjectPhotos />
      </div>
      <Careers />
      <OurPricing />
      <GoogleReview reviewContent={MathPageReview} />
      <Tutors />
      <ConsultationWidget source="form" />
      {
        <React.Fragment key={generateID()}>
          <aside className={'blog-page__side-content--mobile'}>
            <div className="blog-page__side-links">
              {StandardSubjectQuickLinks && (
                <SideContent
                  links={[...RELATED_BLOGS, ...StandardSubjectQuickLinks.pages]}
                  worksheetsLinks={[...RELATED_WORKSHEETS, ...StandardSubjectQuickLinks.worksheets]}
                />
              )}
            </div>
            <div className="blog-page__share-icons-wrapper">
              <ShareIcons
                url="https://nofusstutors.com/online-science-tutor"
                facebookQuote="No Fuss Tutoring has a dedicated network of tutors to help your kids improve their grades!"
              />
            </div>
          </aside>
        </React.Fragment>
      }
    </div>
  );
};

export default Science;
