import React from 'react';
import { WorksheetsTextSegment } from '../../Models';
import { generateID } from '../../Utils';

type WorksheetTextHeadingProps = {
  segment: WorksheetsTextSegment;
};

const WorksheetTextHeading: React.FC<WorksheetTextHeadingProps> = ({ segment }) => {
  return (
    <React.Fragment key={generateID()}>
      <h3 className="single-worksheet__textBlock-heading">
        {segment.type === 'header' && typeof segment.content === 'string' && segment.content}
      </h3>
    </React.Fragment>
  );
};

export default WorksheetTextHeading;
