import React from 'react';
import Hero from '../Components/Partials/Hero';
import Programs from '../Components/Partials/ProgramCards/Programs';
import Journey from '../Components/Home/Journey';
import AdvancedLearning from '../Components/Home/AdvancedLearning';
import Subject from '../Components/Home/Subjects';
import MetaData from '../Components/MetaData';
import OnlineReview from '../Components/Home/OnlineReview';
import SignInModal from '../Components/SignInModal';
import ConsultationWidget from '../Components/Partials/ConsultationWidget';
import { useDisplayData } from '../Hooks/useDisplayData';
import { useScript } from '../Hooks/useScript';
import { HomePageContent, nftAdvantages } from '../Models';
import SimpleCheckout from '../Components/Partials/SimpleCheckout';
import SelfCheckoutWidget from '../Components/Partials/SelfCheckoutWidget';
import FAQModal from '../Components/FAQModal';

const LandingPage1: React.FC = () => {
  const { displayData, hideModal, startBodyScroll } = useDisplayData();
  return (
    <>
      <div
        className={displayData.showModal ? 'main-overlay' : 'transparent'}
        onClick={() => {
          hideModal();
          startBodyScroll();
        }}
      ></div>
      <MetaData
        pageTitle="No Fuss Tutors | Online Tutoring"
        postTitle="No Fuss Tutors | Affordable Online Tutoring"
        description="Your Destination For Top Online Tutors. Our mission is to make quality tutors affordable for everyone!"
        image={'https://nft-development.netlify.app/assets/webp/logo.png'}
        url={'https://nofusstutors.com/'}
        keywords={['online tutoring canada', 'online tutoring new york', 'online tutoring florida']}
      />
      {/* <SelfCheckoutWidget source="form" /> */}
      <div className="simple-checkout__call-banner">
        Prefer to talk to a human? Call or text{' '}
        <a href="tel:+19179053402" style={{ color: 'white' }}>
          (917) 905-3402
        </a>
      </div>
      <SimpleCheckout />
      <OnlineReview />
      {displayData.showFAQModal && <FAQModal />}
    </>
  );
};

export default LandingPage1;
