import React from 'react';

import { useDisplayData } from '../Hooks/useDisplayData';

const FAQModal: React.FC = () => {
  const { displayData, hideModal, startBodyScroll } = useDisplayData();
  return (
    <div className="modal hide-scrollbar">
      <div
        className="modal__type-close"
        onClick={() => {
          hideModal();
          startBodyScroll();
        }}
      >
        <span aria-label="close-window">
          <i className="fas fa-times"></i>
        </span>
      </div>
      <div className="modal__text">{displayData.FAQInfo}</div>
    </div>
  );
};

export default FAQModal;
