import React, { useState } from 'react';
import { pdfFiles } from '../Models';

export interface PDFContextProps {
  pdfData: pdfFiles[];
  setPdfData: (newData: any) => void;
}

const PDFContext = React.createContext({} as PDFContextProps);

const PDFContextProvider = (props: any) => {
  const [pdfData, setPdfData] = useState<pdfFiles[]>([]);

  const providerValue: PDFContextProps = {
    pdfData,
    setPdfData,
  };

  return <PDFContext.Provider value={providerValue}>{props.children}</PDFContext.Provider>;
};

export { PDFContext, PDFContextProvider };
