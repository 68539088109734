import React from 'react';
import { Link } from 'react-router-dom';

type NavLinkProps = {
  href: string;
  title: string;
  text: string;
  nested?: boolean;
  footer?: boolean;
  linkClass?: string;
  responsive?: boolean;
  closeNav?: () => void;
  toggleDropdown?: () => void;
};

type linkTypes = {
  Blog: string;
  Math: string;
  English: string;
  Reading: string;
  Writing: string;
  Science: string;
  Language: string;
  TestPrep: string;
  Elementary: string;
  HighSchool: string;
  Worksheets: string;
  Locations: string;
  Canada: string;
  USA: string;
  Ontario: string;
  BritishColumbia: string;
  Quebec: string;
  NovaScotia: string;
  Alberta: string;
  Manitoba: string;
  Saskatchewan: string;
  California: string;
  Florida: string;
  NY: string;
  Texas: string;
  Pennsylvania: string;
  Illinois: string;
  Ohio: string;
  Georgia: string;
  NorthCarolina: string;
  Michigan: string;
  NewJersey: string;
  Virginia: string;
  Washington: string;
  Arizona: string;
  Massachusetts: string;
  Tennessee: string;
  Indiana: string;
  Missouri: string;
  Maryland: string;
  Wisconsin: string;
  Colorado: string;
  Minnesota: string;
  Algebra: string;
  Geometry: string;
  Calculus: string;
  Biology: string;
  Chemistry: string;
  Physics: string;
  EnglishLiterature: string;
  History: string;
  Spanish: string;
  French: string;
  ESL: string;
  Mandarin: string;
  German: string;
  Italian: string;
  Russian: string;
  Arabic: string;
  Korean: string;
  Hindi: string;

  SAT: string;
  ACT: string;
  PrivateSchool: string;
};

let links: linkTypes = {
  Blog: '/blog',
  Math: '/online-math-tutor',
  English: '/online-english-tutor',
  Reading: '/online-reading-tutor',
  Writing: '/online-writing-tutor',
  Science: '/online-science-tutor',
  Language: '/online-language-tutor',
  TestPrep: '/online-test-prep-tutor',
  Elementary: '/online-elementary-tutor',
  HighSchool: '/online-high-school-tutor',
  Worksheets: '/worksheets-for-kids',
  Locations: '/#locations',
  Canada: '/online-tutor-canada',
  USA: '/online-tutor-usa',
  Ontario: '/online-tutor-ontario',
  BritishColumbia: '/online-tutor-british-columbia',
  Quebec: '/online-tutor-quebec',
  NovaScotia: '/online-tutor-nova-scotia',
  Alberta: '/online-tutor-alberta',
  Manitoba: '/online-tutor-manitoba',
  Saskatchewan: '/online-tutor-saskatchewan',
  California: '/online-tutor-california',
  Florida: '/online-tutor-florida',
  NY: '/online-tutor-new-york',
  Texas: '/online-tutor-texas',
  Pennsylvania: '/online-tutor-pennsylvania',
  Illinois: '/online-tutor-illinois',
  Ohio: '/online-tutor-ohio',
  Georgia: '/online-tutor-georgia',
  NorthCarolina: '/online-tutor-north-carolina',
  Michigan: '/online-tutor-michigan',
  NewJersey: '/online-tutor-new-jersey',
  Virginia: '/online-tutor-virginia',
  Washington: '/online-tutor-washington',
  Arizona: '/online-tutor-arizona',
  Massachusetts: '/online-tutor-massachusetts',
  Tennessee: '/online-tutor-tennessee',
  Indiana: '/online-tutor-indiana',
  Missouri: '/online-tutor-missouri',
  Maryland: '/online-tutor-maryland',
  Wisconsin: '/online-tutor-wisconsin',
  Colorado: '/online-tutor-colorado',
  Minnesota: '/online-tutor-minnesota',
  Algebra: '/online-algebra-tutor',
  Geometry: '/online-geometry-tutor',
  Calculus: '/online-calculus-tutor',
  Biology: '/online-biology-tutor',
  Chemistry: '/online-chemistry-tutor',
  Physics: '/online-physics-tutor',
  EnglishLiterature: '/online-english-literature-tutor',
  History: '/online-history-tutor',
  Spanish: '/online-spanish-tutor',
  French: '/online-french-tutor',
  ESL: '/online-esl-tutor',
  Mandarin: '/online-mandarin-tutor',
  German: '/online-german-tutor',
  Italian: '/online-italian-tutor',
  Russian: '/online-russian-tutor',
  Arabic: '/online-arabic-tutor',
  Korean: '/online-korean-tutor',
  Hindi: '/online-hindi-tutor',
  SAT: '/online-sat-tutor',
  ACT: '/online-act-tutor',
  PrivateSchool: '/online-private-school-tutor',
};

let linkTo = (key: keyof linkTypes) => {
  return links[key];
};

const NavLink: React.FC<NavLinkProps> = ({
  href,
  title,
  text,
  nested,
  footer,
  responsive,
  toggleDropdown,
  closeNav,
}) => {
  // ! render different className based on props
  const getClassName = (footer: boolean, responsive: boolean) => {
    let classForLink;
    if (responsive) {
      classForLink = 'responsive-nav-link';
    } else if (footer) {
      classForLink = 'footer__nav-item';
    } else {
      classForLink = 'nav-link';
    }
    return classForLink;
  };

  const clickLink = () => {
    if (closeNav) {
      closeNav();
    }
  };

  return (
    <Link
      to={linkTo(text! as keyof linkTypes)}
      className={nested ? `${getClassName(footer!, responsive!)} nested` : getClassName(footer!, responsive!)}
      href={href}
      aria-label={title}
      title={title}
      onClick={() => clickLink()}
    >
      {text}
    </Link>
  );
};

export default NavLink;
