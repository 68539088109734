import React from 'react';
import { Link } from 'react-router-dom';

type SideBarProps = {
  links: {
    pageName: string,
    pageLink: string
  }[],
  worksheetsLinks: {
    worksheetsName: string,
    worksheetsLink: string
  }[]
}

const SideContent: React.FC<SideBarProps> = ({ links, worksheetsLinks }) => {
  return (
    <aside className="internal-links">
      <div className="internal-links__worksheets-wrapper">
        <div className="internal-links__header-wrapper">
          {/* <i className="fas fa-pencil-alt"></i> */}
          <h5 className="internal-links__header">Worksheets</h5>
        </div>
        <div className="internal-links__link-wrapper">
          {
            worksheetsLinks.map((link, index) => {
              return (
                <Link to={link.worksheetsLink} key={index}>
                  <p className="internal-links__name">{link.worksheetsName}</p>
                </Link>
              )
            })
          }
        </div>
      </div>
      <div className="internal-links__pages-wrapper">
        <div className="internal-links__header-wrapper">
          {/* <i className="fas fa-file-alt"></i> */}
          <h5 className="internal-links__header">Pages</h5>
        </div>
        <div className="internal-links__link-wrapper">
          {
            links.map((link, index) => {
              return (
                <Link to={link.pageLink} key={index}>
                  <p className="internal-links__name">{link.pageName}</p>
                </Link>
              )
            })
          }
        </div>
      </div>
    </aside>
  )
}

export default SideContent
