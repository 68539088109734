import React from 'react';
import { Link } from 'react-router-dom';
import DownloadIcon from '../assets/icon-download.png';
import DownloadIconWebp from '../assets/icon-download.webp';
import ModernArrowRight from '../assets/modern-arrow-right.svg';
import Picture from './Partials/Picture';
import AWSImage from './Partials/AWSImage';
import { useDisplayData } from '../Hooks/useDisplayData';
import { FormatGrades, SingleResourceLink } from '../Utils';

export type CardProps = {
  link?: string;
  src?: any;
  webp?: any;
  alt: string;
  type: 'worksheets' | 'assessments';
  title: string;
  subject: string[];
  grade: string[];
  shareUrl?: string;
  facebookQuote?: string;
  description?: string;
  onClick: () => void;
  _id?: string;
  source?: string;
  seeMore?: boolean;
};

const Card: React.FC<CardProps> = ({
  link,
  src,
  webp,
  alt,
  type,
  title,
  subject,
  grade,
  shareUrl,
  facebookQuote,
  description,
  onClick,
  _id,
  source,
  seeMore,
}) => {
  const { displayData, hideModal } = useDisplayData();
  return source === 'downloads-page' ? (
    <Link to={`/worksheets/${SingleResourceLink(title)}`} target="_blank" rel="noopener noreferrer">
      <article
        data-cy="card__article--card"
        className={window.location.href.includes('blog-posts') ? 'card card--worksheets-widget' : 'card'}
      >
        <div className="card__image">
          <AWSImage
            pngFile={src}
            imageAlt={alt}
            picClass={displayData.showModal ? 'hide-image' : ''}
            documentPrefix="worksheets"
          />
        </div>
        <div className="card__info">
          <div className="card__content-wrapper">
            <h2
              className={
                subject[0] === 'Math'
                  ? 'card__subject card__subject--math'
                  : subject[0] === 'Reading'
                  ? 'card__subject card__subject--reading'
                  : subject[0] === 'Writing'
                  ? 'card__subject card__subject--writing'
                  : 'card__subject card__subject--science'
              }
            >
              {subject}
            </h2>
            <h2 className="card__title">{title}</h2>
            <div className="card__info-download-wrapper">
              <p className="card__grade">Grade: {FormatGrades(grade)}</p>
              <div className="card__download-icon-wrapper">
                <Picture webp={DownloadIconWebp} src={DownloadIcon} alt="Download Icon" picClass={'icon-image'} />
              </div>
            </div>
          </div>
        </div>
      </article>
    </Link>
  ) : seeMore ? (
    <article data-cy="card__article--card" className="card">
      <div className="card__content-wrapper__see-more">
        <h2 className="card__title">{title}</h2>
        <img src={ModernArrowRight} alt="Modern Arrow Right" className="see-more-arrow" />
      </div>
    </article>
  ) : (
    <article
      data-cy="card__article--card"
      className={displayData.showModal ? 'card hide' : 'card'}
      onClick={displayData.showModal ? hideModal : onClick}
    >
      <div className="card__image">
        <AWSImage
          pngFile={src}
          imageAlt={alt}
          picClass={displayData.showModal ? 'hide-image' : ''}
          documentPrefix="worksheets"
        />
      </div>
      <div className={displayData.showModal ? 'hide-image card__info' : 'card__info'}>
        <div className="card__content-wrapper">
          <h2
            className={
              subject[0] === 'Math'
                ? 'card__subject card__subject--math'
                : subject[0] === 'Reading'
                ? 'card__subject card__subject--reading'
                : subject[0] === 'Writing'
                ? 'card__subject card__subject--writing'
                : 'card__subject card__subject--science'
            }
          >
            {subject}
          </h2>
          <h2 className="card__title">{title}</h2>
          <div className="card__info-download-wrapper">
            <p className="card__grade">Grade: {FormatGrades(grade)}</p>
            <div className="card__download-icon-wrapper">
              <Picture webp={DownloadIconWebp} src={DownloadIcon} alt="Download Icon" picClass={'icon-image'} />
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Card;
