import React, { useRef, useState } from 'react';
import { FAQ, ProgramGrades, validDates, validTimes } from '../../Models';
import { ErrorProps } from '../../Models';
import { generateID, GetBaseURL, SendEmail } from '../../Utils';
import ErrorHandler from './ErrorHandler';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';

import Programs from './ProgramCards/Programs';
import { Redirect } from 'react-router';
import { useDisplayData } from '../../Hooks/useDisplayData';
import { FAQInfo } from '../../Models/ProgramInfo';

const promise = loadStripe(
  process.env.NODE_ENV === 'production'
    ? `${process.env.REACT_APP_STRIPE_KEY}`
    : `${process.env.REACT_APP_STRIPE_TEST_KEY}`,
);

type ClientDetails = {
  name: string;
  email: string;
  phone: string;
  childName: string;
  childGrade: string;
  timeZone: string;
  startDate: string;
  day1: string;
  time1: string;
  day2: string;
  time2: string;
  day3: string;
  time3: string;
  extraNotes: string;
  learningDifficultyNotes: string;
  childWantsToGetBetter: string;
  childPersonality: string[];
  childHobbies: string;
};

const SimpleCheckout: React.FC = () => {
  const { displayData, showFAQModal, stopBodyScroll, hideModal, startBodyScroll } = useDisplayData();
  const [section, setSection] = useState(1);
  const [slideInFromBack, setslideInFromBack] = useState(false);
  const [slideInFromFront, setslideInFromFront] = useState(false);
  const [error, setError] = useState<ErrorProps>({
    isActive: false,
    message: '',
    type: 'error',
  });
  const [program, setProgram] = useState({
    name: '',
    minTimesPerWeek: 0,
    subject: '',
    ageRange: '',
    price: 0,
    cardText: '',
    bgColor: '',
    lessonLength: '',
    priceLow: 0,
    priceMid: 0,
    priceHigh: 0,
  });
  const [timesPerWeek, setTimesPerWeek] = useState(0);
  const [lessonLength, setLessonLength] = useState('1 hr');
  const [day, setDay] = useState('Monday');
  const [time, setTime] = useState('9:00am');
  const [showFAQAnswers, setShowFAQAnswers] = useState<string[]>([]);
  const [clientDetails, setClientDetails] = useState<ClientDetails>({
    name: '',
    email: '',
    phone: '',
    childName: '',
    childGrade: '',
    timeZone: 'Eastern',
    startDate: '',
    day1: '',
    time1: '7:00 pm',
    day2: '',
    time2: '7:00 pm',
    day3: '',
    time3: '7:00 pm',
    extraNotes: '',
    learningDifficultyNotes: '',
    childWantsToGetBetter: '',
    childPersonality: [],
    childHobbies: '',
  });
  const [showOtherTimeZones, setShowOtherTimezones] = useState(false);
  const [showAppointmentTimes, setShowAppointmentTimes] = useState(false);
  const [redirectToSuccessPage, setRedirectToSuccessPage] = useState(false);
  const resetError = () => {
    setError({
      isActive: false,
      message: '',
      type: 'error',
    });
  };
  const [learningDifficulties, setLearningDifficulties] = useState({
    dyslexia: false,
    ADHD: false,
    autism: false,
    other: false,
  });
  const [days, setDays] = useState<string[]>([]);
  const [sliceValue, setSliceValue] = useState(7);
  const [useCode, setUseCode] = useState('');
  const nameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const childNameRef = useRef<HTMLInputElement>(null);

  const previousSection = () => {
    resetError();
    if (!(slideInFromFront && slideInFromBack)) {
      setslideInFromBack(true);
      setSection(section - 1);
      setTimeout(() => {
        setslideInFromBack(false);
      }, 500);
    }
    window.scrollTo(0, 0);
  };

  const nextSection = (skip?: number) => {
    resetError();
    if (section === 5) {
      sendClientData();
    }
    if (!(slideInFromFront && slideInFromBack)) {
      setslideInFromFront(true);
      setSection(skip ? section + skip + 1 : section + 1);
      setTimeout(() => {
        setslideInFromFront(false);
      }, 500);
    }
    window.scrollTo(0, 0);
  };

  const dayClickHandler = (day: string) => {
    if (!days.includes(day) && days.length < 3) {
      const tempDays = days;
      tempDays.push(day);

      tempDays.sort((a: string, b: string) => dayNumber(a)! - dayNumber(b)!);

      setClientDetails((prev: any) => ({
        ...prev,
        day1: tempDays[0],
        day2: tempDays[1] ? tempDays[1] : '',
        day3: tempDays[2] ? tempDays[2] : '',
        time1: '7:00 pm',
        time2: '7:00 pm',
        time3: '7:00 pm',
      }));
      resetError();
    } else {
      if (!days.includes(day) && days.length === 3) {
        setError({
          type: 'info',
          isActive: true,
          message: 'This program is 3 days a week. Please UNSELECT a day to choose another one.',
        });
      } else {
        resetError();
        setDays((prev: string[]) => prev.filter((prevDay: string) => prevDay !== day));

        if (clientDetails.day1 === day) {
          setClientDetails((prev: any) => ({
            ...prev,
            day1: '',
            time1: '7:00 pm',
            time2: '7:00 pm',
            time3: '7:00 pm',
          }));
        } else if (clientDetails.day2 === day) {
          setClientDetails((prev: any) => ({
            ...prev,
            day2: '',
            time1: '7:00 pm',
            time2: '7:00 pm',
            time3: '7:00 pm',
          }));
        } else if (clientDetails.day3 === day) {
          setClientDetails((prev: any) => ({
            ...prev,
            day3: '',
            time1: '7:00 pm',
            time2: '7:00 pm',
            time3: '7:00 pm',
          }));
        }
      }
    }
  };

  const sendEmail = async (e: any) => {
    e.preventDefault();
    if (
      nameRef.current &&
      emailRef.current &&
      phoneRef.current &&
      nameRef.current.value !== '' &&
      emailRef.current.value !== '' &&
      phoneRef.current.value !== ''
    ) {
      const name = nameRef.current.value;
      const email = emailRef.current.value;
      const phone = phoneRef.current.value;
      setClientDetails((prev: any) => ({
        ...prev,
        name,
        email,
        phone,
      }));
      nextSection();

      await SendEmail(
        `A lead generated from Simple Checkout:\nName: ${name}\nEmail: ${email}\nPhone: ${phone}`,
        'matt@nofusstutors.com',
        'Lead - Simple Checkout',
      );
      await SendEmail(
        `A lead generated from Simple Checkout:\nName: ${name}\nEmail: ${email}\nPhone: ${phone}`,
        'victoria@nofusstutors.com',
        'Lead - Simple Checkout',
      );
      await SendEmail(
        `A lead generated from Simple Checkout:\nName: ${name}\nEmail: ${email}\nPhone: ${phone}`,
        'suhail@nofusstutors.com',
        'Lead - Simple Checkout',
      );
    } else {
      setError({ type: 'error', isActive: true, message: 'Please fill out all the fields.' });
    }
  };

  const sendClientData = async () => {
    await SendEmail(formEmail('unpaid'), 'may@nofusstutors.com', 'Full Lead Info - Simple Checkout -- Pending Payment');
    await SendEmail(
      formEmail('unpaid'),
      'matt@nofusstutors.com',
      'Full Lead Info - Simple Checkout -- Pending Payment',
    );
    await SendEmail(
      formEmail('unpaid'),
      'victoria@nofusstutors.com',
      'Full Lead Info - Simple Checkout -- Pending Payment',
    );
    await SendEmail(
      formEmail('unpaid'),
      'suhail@nofusstutors.com',
      'Full Lead Info - Simple Checkout -- Pending Payment',
    );
  };

  const processPayment = async () => {
    if (useCode !== '') {
      const useRequest = await fetch(`${GetBaseURL()}/api/discountCodes/useCode`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ code: useCode }),
      });
      const useResponse = await useRequest.json();

      console.log(useResponse.message);
    }

    const codeRequest = await fetch(`${GetBaseURL()}/api/discountCodes/createCode`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    });
    const codeResponse = await codeRequest.json();

    if (codeRequest.status === 200) {
      await SendEmail(
        `Thanks for booking! Our team will confirm your tutor within 3 business days. Please text or call 305-590-5573 with any questions or concerns.\n\nYour start date: ${new Date(
          clientDetails.startDate,
        ).toLocaleString('en-US', {
          weekday: 'long',
          month: 'short',
          year: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
        })}\n\nYour chosen lesson days and times: ${clientDetails.day1} ${clientDetails.time1}, ${clientDetails.day2} ${
          clientDetails.time2
        }, ${clientDetails.day3} ${
          clientDetails.time3
        }\n\nIf you want to enroll for another program, save this unique discount code and use it at checkout:\n\n${
          codeResponse.code.code
        }`,
        clientDetails.email,
        `Successful Booking -- ${program.name}`,
      );
    }

    await SendEmail(
      formEmail('paid'),
      'may@nofusstutors.com',
      'Full Lead Info - Simple Checkout -- Successful Payment',
    );
    await SendEmail(
      formEmail('paid'),
      'matt@nofusstutors.com',
      'Full Lead Info - Simple Checkout -- Successful Payment',
    );
    await SendEmail(
      formEmail('paid'),
      'victoria@nofusstutors.com',
      'Full Lead Info - Simple Checkout -- Successful Payment',
    );
    await SendEmail(
      formEmail('paid'),
      'suhail@nofusstutors.com',
      'Full Lead Info - Simple Checkout -- Successful Payment',
    );

    setRedirectToSuccessPage(true);
  };

  const formEmail = (type: string) => {
    let emailText = `\nName: ${clientDetails.name}\nEmail: ${clientDetails.email}\nPhone: ${
      clientDetails.phone
    }\nProgram: ${program.name}\nChild's name: ${clientDetails.childName}\nChild's grade: ${
      clientDetails.childGrade
    }\nLesson Length: ${program.lessonLength}\nGoals for child: ${
      clientDetails.extraNotes !== '' ? clientDetails.extraNotes : 'None'
    }\nChild's motivation: ${
      clientDetails.childWantsToGetBetter !== '' ? clientDetails.childWantsToGetBetter : 'No answer provided'
    }\nPersonalities child gets along with: ${
      clientDetails.childPersonality.length === 0
        ? 'Child gets along with everyone'
        : clientDetails.childPersonality.join(', ')
    }\nChild hobbies/interests: ${
      clientDetails.childHobbies !== '' ? clientDetails.childHobbies : 'No answer provided'
    }\nLearning Difficulties: ${
      Object.values(learningDifficulties).includes(true)
        ? Object.entries(learningDifficulties)
            .filter((ld) => ld[1])
            .map((ld) => ld[0])
            .join(', ')
        : 'None'
    }\nLearning Difficulty Notes: ${
      clientDetails.learningDifficultyNotes !== '' ? clientDetails.learningDifficultyNotes : 'None'
    }\nPreferred start date: ${clientDetails.startDate} ${clientDetails.timeZone}\nDays and times: ${
      clientDetails.day1
    } ${clientDetails.time1}, ${clientDetails.day2} ${clientDetails.time2}, ${clientDetails.day3} ${
      clientDetails.time3
    }`;
    if (type === 'unpaid') {
      emailText =
        'A lead has filled out all the information on the Simple Checkout form. If they complete the checkout, you will receive another email with the transaction details.' +
        emailText;
    } else if (type === 'paid') {
      emailText = 'A client has successfully paid ${program.price} for the program: ${program.name}.' + emailText;
    }
    return emailText;
  };

  const dayNumber = (day: string) =>
    day === 'Monday'
      ? 0
      : day === 'Tuesday'
      ? 1
      : day === 'Wednesday'
      ? 2
      : day === 'Thursday'
      ? 3
      : day === 'Friday'
      ? 4
      : day === 'Saturday'
      ? 5
      : day === 'Sunday'
      ? 6
      : null;

  const CONTENT = (
    <>
      {section === 1 && (
        <div
          id="form"
          className={
            slideInFromBack ? 'simple-checkout__start-container-backslide' : 'simple-checkout__start-container'
          }
          style={{ flexDirection: 'column' }}
        >
          <h2
            className="form-header"
            id="form"
            style={{
              fontSize: '3rem',
              margin: '0 auto 4rem',
              textAlign: 'center',
              textTransform: 'none',
            }}
          >
            Interested In Our 12-Week Immersive Tutoring Programs? Get In Touch Today!
          </h2>
          <div className="simple-checkout__right-side">
            <h4 className="simple-checkout__flavor-text1">
              12 week program updated{' '}
              <span className="simple-checkout__date">
                {new Date(Date.now()).toLocaleString('en-US', {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                })}
              </span>
            </h4>
            <div className="simple-checkout__start-button" style={{ width: '32.5rem' }} onClick={() => nextSection()}>
              <i className="fas fa-arrow-right" style={{ fontSize: '25px' }}></i>
              <span className="simple-checkout__start-button--text">Click Here To Begin</span>
            </div>
            <h4 className="simple-checkout__flavor-text2">Tap And Help Your Child Get Above Grade Level!</h4>
          </div>
        </div>
      )}

      {section === 2 && (
        <div
          className={
            slideInFromBack
              ? 'simple-checkout__slider-container-backslide'
              : slideInFromFront
              ? 'simple-checkout__slider-container-frontslide'
              : 'simple-checkout__slider-container'
          }
        >
          {error.isActive && <ErrorHandler type={error.type} message={error.message} handler={resetError} />}

          <span className="simple-checkout__slider-value">
            Where Would You Like Us To Send Your Program Invitation?
          </span>
          <form onSubmit={sendEmail} className="simple-checkout__form">
            <input
              className="simple-checkout__form-input"
              type="text"
              placeholder="Enter Your Full Name..."
              ref={nameRef}
            />
            <input
              className="simple-checkout__form-input"
              type="text"
              placeholder="Enter Your Email Address..."
              ref={emailRef}
            />
            <input
              className="simple-checkout__form-input"
              type="phone"
              placeholder="Enter Your Phone Number..."
              ref={phoneRef}
            />
            <input
              className="simple-checkout__form-submit"
              type="submit"
              value="Send My Program Invitation Now!"
            ></input>
            <span className="simple-checkout__form-span">
              <i className="fa fa-lock simple-checkout__icon-right" aria-hidden="true"></i>We will never sell, rent, or
              share your personal information.
            </span>
          </form>
        </div>
      )}

      {section === 3 && (
        <div
          className={
            slideInFromBack
              ? 'simple-checkout__slider-container-backslide'
              : slideInFromFront
              ? 'simple-checkout__slider-container-frontslide'
              : 'simple-checkout__slider-container'
          }
        >
          <button className="simple-checkout__button-back" onClick={() => previousSection()}>
            Back
          </button>
          <h2>Choose your program</h2>
          {
            <Programs
              onClick={(
                name,
                minTimesPerWeek,
                subject,
                ageRange,
                price,
                cardText,
                bgColor,
                lessonLength,
                priceLow,
                priceMid,
                priceHigh,
              ) => {
                setProgram({
                  name,
                  minTimesPerWeek,
                  subject,
                  ageRange,
                  price,
                  cardText,
                  bgColor,
                  lessonLength,
                  priceLow,
                  priceMid,
                  priceHigh,
                });
                setTimesPerWeek(minTimesPerWeek);
                nextSection();
              }}
            ></Programs>
          }
          {FAQInfo.firstSet.map((faq) => (
            <React.Fragment key={generateID()}>
              <div
                className="simple-checkout__faq"
                onClick={() =>
                  setShowFAQAnswers((prev: any) =>
                    prev.includes(faq.identifier)
                      ? prev.filter((val: string) => val !== faq.identifier)
                      : [...prev, faq.identifier],
                  )
                }
              >
                FAQ: {faq.question}
                {showFAQAnswers.includes(faq.identifier) && (
                  <div className="simple-checkout__faq--answer">
                    <br />
                    A: {faq.answer}
                  </div>
                )}
              </div>
            </React.Fragment>
          ))}
        </div>
      )}
      {section === 4 && (
        <div
          className={
            slideInFromBack
              ? 'simple-checkout__slider-container-backslide'
              : slideInFromFront
              ? 'simple-checkout__slider-container-frontslide'
              : 'simple-checkout__slider-container'
          }
        >
          <button className="simple-checkout__button-back" onClick={() => previousSection()}>
            Back
          </button>
          <div className="simple-checkout__section-container">
            <h2 className="simple-checkout__heading">You've selected:</h2>
            <div className="programs__program-card" style={{ pointerEvents: 'none' }}>
              <div
                style={{
                  backgroundImage: program.bgColor,
                  paddingTop: program.subject === 'Any' ? '10px' : '20px',
                  lineHeight: program.subject === 'Any' ? '1.2' : '1.7',
                }}
                className="program-img"
              >
                {`${program.cardText}${program.subject === 'Any' ? ' Subject' : ''}`}
                <br></br>
                <span className="program-img--text">1-on-1</span>
              </div>

              <div className="programs__program-header-info-wrapper">
                <div className="programs__info-wrapper">
                  <h4 className="programs__program-card-name">{program.name}</h4>
                  <h5 className="programs__program-occupation">{`3 x per week`}</h5>
                  <p className="programs__education">
                    <i className="fas fa-graduation-cap programs__education-bio-icon"></i>
                    {`Ages ${program.ageRange}`}
                  </p>
                </div>
                <div className="programs__price-info-wrapper">
                  <div className="programs__price-number-wrapper">
                    <p className="programs__price-number">${Math.round(100 * program.price) / 100} </p>
                  </div>
                  <div className="programs__price-row-end">
                    <p className="programs__price-payment-schedule">12 lessons per month</p>
                  </div>
                </div>
              </div>
              <div className="programs__each-lesson">
                Each 1-on-1 lesson comes to:{''}
                <span className="programs__price-each">${Math.round((100 * program.price) / 12) / 100}</span>
              </div>
            </div>
            <div className="programs__lesson-container-2">
              <div className="programs__lesson-heading">Lesson Length</div>
              <div className="programs__lesson-length-wrapper">
                <div
                  className={
                    program.lessonLength === '30 min' ? 'programs__lesson-length--selected' : 'programs__lesson-length'
                  }
                  onClick={() =>
                    setProgram((prev: any) => ({ ...prev, lessonLength: '30 min', price: program.priceLow }))
                  }
                >
                  30 min
                </div>
                <div
                  className={
                    program.lessonLength === '45 min' ? 'programs__lesson-length--selected' : 'programs__lesson-length'
                  }
                  onClick={() =>
                    setProgram((prev: any) => ({ ...prev, lessonLength: '45 min', price: program.priceMid }))
                  }
                >
                  45 min
                </div>
                <div
                  className={
                    program.lessonLength === '1 hr' ? 'programs__lesson-length--selected' : 'programs__lesson-length'
                  }
                  onClick={() =>
                    setProgram((prev: any) => ({ ...prev, lessonLength: '1 hr', price: program.priceHigh }))
                  }
                >
                  1 hr
                </div>
              </div>
            </div>
            <div
              id="faq-child"
              className="simple-checkout__faq-2"
              onClick={() => {
                setShowFAQAnswers((prev: any) =>
                  prev.includes(FAQInfo.secondSet[0].identifier)
                    ? prev.filter((val: string) => val !== FAQInfo.secondSet[0].identifier)
                    : [...prev, FAQInfo.secondSet[0].identifier],
                );
              }}
            >
              FAQ: {FAQInfo.secondSet[0].question}
              {showFAQAnswers.includes(FAQInfo.secondSet[0].identifier) && (
                <div className="simple-checkout__faq--answer">
                  <br />
                  A: {FAQInfo.secondSet[0].answer}
                </div>
              )}
            </div>

            {error.isActive && <ErrorHandler message={error.message} type={error.type} handler={resetError} />}
            <div className="simple-checkout__form-input--row">
              <h2 className="simple-checkout__form-input--heading">Child's name:</h2>
              <input
                className="simple-checkout__form-input--child-name"
                placeholder="Child's Name"
                defaultValue={clientDetails.childName}
                onChange={(e) => {
                  setClientDetails((prev: any) => ({ ...prev, childName: e.target.value }));
                  resetError();
                }}
              />
              {error.isActive && <i className="fas fa-exclamation-triangle"></i>}
            </div>

            <div className="simple-checkout__form-input--row-2">
              <h2 className="simple-checkout__form-input--heading">Child's grade:</h2>
              <div className="simple-checkout__grade-select--arrow">
                <select
                  className="simple-checkout__grade"
                  defaultValue=""
                  onChange={(e) => {
                    setClientDetails((prev: any) => ({ ...prev, childGrade: e.target.value }));
                    resetError();
                  }}
                >
                  <option value=""></option>
                  {ProgramGrades.map((grade) => (
                    <option value={grade}>{grade}</option>
                  ))}
                </select>
              </div>
              {error.isActive && <i className="fas fa-exclamation-triangle"></i>}
            </div>

            <h2 className="simple-checkout__heading-2">Your goals for your child in the program?</h2>
            <div className="simple-checkout__sub-heading">
              (While we obviously can not offer guarantees as every student is unique, we will do our best to meet them)
            </div>
            <textarea
              className="simple-checkout__extra-notes"
              placeholder="Type here..."
              onChange={(e) => setClientDetails((prev: any) => ({ ...prev, extraNotes: e.target.value }))}
            />

            <h2 className="simple-checkout__heading-2">
              Does your child have any of the following learning difficulties?
            </h2>
            <div className="simple-checkout__sub-heading">
              (We will choose a tutor who specializes in working with learning difficulties)
            </div>

            <div className="simple-checkout__ld--container">
              <div className="simple-checkout__ld--row">
                <div
                  className={
                    learningDifficulties.dyslexia ? 'simple-checkout__ld--label selected' : 'simple-checkout__ld--label'
                  }
                  onClick={() => setLearningDifficulties((prev: any) => ({ ...prev, dyslexia: !prev.dyslexia }))}
                >
                  Dyslexia
                </div>
                <div
                  className={
                    learningDifficulties.ADHD ? 'simple-checkout__ld--label selected' : 'simple-checkout__ld--label'
                  }
                  onClick={() => setLearningDifficulties((prev: any) => ({ ...prev, ADHD: !prev.ADHD }))}
                >
                  ADHD
                </div>
                <div
                  className={
                    learningDifficulties.autism ? 'simple-checkout__ld--label selected' : 'simple-checkout__ld--label'
                  }
                  onClick={() => setLearningDifficulties((prev: any) => ({ ...prev, autism: !prev.autism }))}
                >
                  Autism
                </div>
                <div
                  className={
                    learningDifficulties.other ? 'simple-checkout__ld--label selected' : 'simple-checkout__ld--label'
                  }
                  onClick={() => setLearningDifficulties((prev: any) => ({ ...prev, other: !prev.other }))}
                >
                  Other
                </div>
                <div
                  className={
                    !Object.values(learningDifficulties).includes(true)
                      ? 'simple-checkout__ld--label selected'
                      : 'simple-checkout__ld--label'
                  }
                  onClick={() => setLearningDifficulties({ dyslexia: false, ADHD: false, autism: false, other: false })}
                >
                  No
                </div>
              </div>
            </div>

            {Object.values(learningDifficulties).includes(true) && (
              <>
                <textarea
                  placeholder="Please share details so we can choose the right fit for you."
                  className="simple-checkout__extra-notes"
                  onChange={(e) =>
                    setClientDetails((prev: any) => ({ ...prev, learningDifficultyNotes: e.target.value }))
                  }
                />
                <div className="simple-checkout__sub-heading">
                  <i className="fa fa-lock simple-checkout__icon-right" aria-hidden="true"></i>We treat this information
                  as confidential, please only provide information that you are comfortable with sharing. We will share
                  this information with your selected tutor.
                </div>
              </>
            )}

            <h2 className="simple-checkout__heading-2">
              Is your child motivated to improve{program.subject !== 'Any' ? ` in ${program.subject}?` : '?'}
            </h2>

            <div className="simple-checkout__ld--container">
              <div className="simple-checkout__ld--row">
                <div
                  className={
                    clientDetails.childWantsToGetBetter === "No, they don't care like I want them to."
                      ? 'simple-checkout__motivated selected-motivated'
                      : 'simple-checkout__motivated'
                  }
                  onClick={() =>
                    setClientDetails((prev: any) => ({
                      ...prev,
                      childWantsToGetBetter: "No, they don't care like I want them to.",
                    }))
                  }
                >
                  No, they don't care.
                </div>
                <div
                  className={
                    clientDetails.childWantsToGetBetter === 'Somewhere in between.'
                      ? 'simple-checkout__motivated selected-motivated'
                      : 'simple-checkout__motivated'
                  }
                  onClick={() =>
                    setClientDetails((prev: any) => ({ ...prev, childWantsToGetBetter: 'Somewhere in between.' }))
                  }
                >
                  Somewhere in between
                </div>
                <div
                  className={
                    clientDetails.childWantsToGetBetter === 'Yes, they want to improve.'
                      ? 'simple-checkout__motivated selected-motivated'
                      : 'simple-checkout__motivated'
                  }
                  onClick={() =>
                    setClientDetails((prev: any) => ({ ...prev, childWantsToGetBetter: 'Yes, they want to improve.' }))
                  }
                >
                  Yes, they want to improve.
                </div>
              </div>
            </div>

            <h2 className="simple-checkout__heading-2">
              What kind of personality does your child get along best with?
            </h2>
            <div className="simple-checkout__sub-heading">(Select all that apply)</div>

            <div className="simple-checkout__ld--container">
              <div className="simple-checkout__ld--row">
                <div
                  className={
                    clientDetails.childPersonality.includes('Stern')
                      ? 'simple-checkout__ld--label selected'
                      : 'simple-checkout__ld--label'
                  }
                  onClick={() =>
                    setClientDetails((prev: any) => ({
                      ...prev,
                      childPersonality: prev.childPersonality.includes('Stern')
                        ? prev.childPersonality.filter((personality: string) => personality !== 'Stern')
                        : [...prev.childPersonality, 'Stern'],
                    }))
                  }
                >
                  Stern
                </div>
                <div
                  className={
                    clientDetails.childPersonality.includes('In-between')
                      ? 'simple-checkout__ld--label selected'
                      : 'simple-checkout__ld--label'
                  }
                  onClick={() =>
                    setClientDetails((prev: any) => ({
                      ...prev,
                      childPersonality: prev.childPersonality.includes('In-between')
                        ? prev.childPersonality.filter((personality: string) => personality !== 'In-between')
                        : [...prev.childPersonality, 'In-between'],
                    }))
                  }
                >
                  In-between
                </div>
                <div
                  className={
                    clientDetails.childPersonality.includes('Bubbly')
                      ? 'simple-checkout__ld--label selected'
                      : 'simple-checkout__ld--label'
                  }
                  onClick={() =>
                    setClientDetails((prev: any) => ({
                      ...prev,
                      childPersonality: prev.childPersonality.includes('Bubbly')
                        ? prev.childPersonality.filter((personality: string) => personality !== 'Bubbly')
                        : [...prev.childPersonality, 'Bubbly'],
                    }))
                  }
                >
                  Bubbly
                </div>
              </div>
              <div className="simple-checkout__ld--row simple-checkout__gets-along-mobile">
                <div
                  className={
                    clientDetails.childPersonality.length === 0
                      ? 'simple-checkout__gets-along selected--gets-along'
                      : 'simple-checkout__gets-along'
                  }
                  onClick={() =>
                    setClientDetails((prev: any) => ({
                      ...prev,
                      childPersonality: [],
                    }))
                  }
                >
                  Doesn't matter, my child gets along with everyone
                </div>
              </div>
            </div>
            <div
              className="simple-checkout__faq-3"
              onClick={() => {
                setShowFAQAnswers((prev: any) =>
                  prev.includes(FAQInfo.thirdSet[0].identifier)
                    ? prev.filter((val: string) => val !== FAQInfo.thirdSet[0].identifier)
                    : [...prev, FAQInfo.thirdSet[0].identifier],
                );
              }}
            >
              FAQ: {FAQInfo.thirdSet[0].question}
              {showFAQAnswers.includes(FAQInfo.thirdSet[0].identifier) && (
                <div className="simple-checkout__faq--answer">
                  <br />
                  A: {FAQInfo.thirdSet[0].answer}
                </div>
              )}
            </div>
            <h2 className="simple-checkout__heading-2">Tell us about your child's hobbies and interests:</h2>

            <textarea
              className="simple-checkout__extra-notes-2"
              placeholder="Type here..."
              onChange={(e) => setClientDetails((prev: any) => ({ ...prev, childHobbies: e.target.value }))}
            />

            <div>
              <button
                className="simple-checkout__button"
                onClick={() => {
                  if (clientDetails.childName !== '' && clientDetails.childGrade !== '') {
                    nextSection();
                  } else {
                    setError({ type: 'error', isActive: true, message: "Please enter your child's name and grade." });
                    document.getElementById('faq-child')!.scrollIntoView();
                  }
                }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      )}
      {section === 5 && (
        <div
          className={
            slideInFromBack
              ? 'simple-checkout__true-end-container-backslide'
              : slideInFromFront
              ? 'simple-checkout__true-end-container-frontslide'
              : 'simple-checkout__true-end-container'
          }
        >
          <button className="simple-checkout__button-back" onClick={() => previousSection()}>
            Back
          </button>

          <h2 className="simple-checkout__heading">Time Zone</h2>
          <div className="simple-checkout__datetimezone-wrapper">
            <span
              className={
                clientDetails.timeZone === 'Eastern'
                  ? 'simple-checkout__datetimezone selected-dtz'
                  : 'simple-checkout__datetimezone'
              }
              onClick={() => {
                setClientDetails((prev: any) => ({ ...prev, timeZone: 'Eastern' }));
              }}
            >
              Eastern
            </span>
            <span
              className={
                clientDetails.timeZone === 'Central'
                  ? 'simple-checkout__datetimezone selected-dtz'
                  : 'simple-checkout__datetimezone'
              }
              onClick={() => {
                setClientDetails((prev: any) => ({ ...prev, timeZone: 'Central' }));
              }}
            >
              Central
            </span>
            <span
              className={
                clientDetails.timeZone === 'Mountain'
                  ? 'simple-checkout__datetimezone selected-dtz'
                  : 'simple-checkout__datetimezone'
              }
              onClick={() => {
                setClientDetails((prev: any) => ({ ...prev, timeZone: 'Mountain' }));
              }}
            >
              Mountain
            </span>
            <span
              className={
                clientDetails.timeZone === 'Pacific'
                  ? 'simple-checkout__datetimezone selected-dtz'
                  : 'simple-checkout__datetimezone'
              }
              onClick={() => {
                setClientDetails((prev: any) => ({ ...prev, timeZone: 'Pacific' }));
              }}
            >
              Pacific
            </span>
            <span
              className={
                clientDetails.timeZone !== 'Eastern' &&
                clientDetails.timeZone !== 'Central' &&
                clientDetails.timeZone !== 'Mountain' &&
                clientDetails.timeZone !== 'Pacific'
                  ? 'simple-checkout__datetimezone selected-dtz'
                  : 'simple-checkout__datetimezone'
              }
              onClick={(e) => {
                e.stopPropagation();
                setShowOtherTimezones(true);
              }}
            >
              {clientDetails.timeZone !== 'Eastern' &&
              clientDetails.timeZone !== 'Central' &&
              clientDetails.timeZone !== 'Mountain' &&
              clientDetails.timeZone !== 'Pacific'
                ? clientDetails.timeZone
                : 'Other'}
            </span>
            {showOtherTimeZones && (
              <div className="simple-checkout__other-datetimezone">
                <span
                  className="simple-checkout__datetimezone--other"
                  onClick={() => setClientDetails((prev: any) => ({ ...prev, timeZone: 'Newfoundland' }))}
                >
                  Newfoundland
                </span>
                <span
                  className="simple-checkout__datetimezone--other"
                  onClick={() => setClientDetails((prev: any) => ({ ...prev, timeZone: 'Atlantic' }))}
                >
                  Atlantic
                </span>
                <span
                  className="simple-checkout__datetimezone--other"
                  onClick={() => setClientDetails((prev: any) => ({ ...prev, timeZone: 'Alaska' }))}
                >
                  Alaska
                </span>
                <span
                  className="simple-checkout__datetimezone--other"
                  onClick={() => setClientDetails((prev: any) => ({ ...prev, timeZone: 'Hawaii-Aleutian' }))}
                >
                  Hawaii-Aleutian
                </span>
              </div>
            )}
          </div>
          {error.isActive && <ErrorHandler message={error.message} type={error.type} handler={resetError} />}

          <h2 className="simple-checkout__heading">Pick 3 days of the week</h2>
          <div className="simple-checkout__datetimezone-wrapper-2">
            <span
              className={
                days.includes('Monday') ? 'simple-checkout__datetimezone selected-dtz' : 'simple-checkout__datetimezone'
              }
              onClick={() => dayClickHandler('Monday')}
            >
              Monday
            </span>
            <span
              className={
                days.includes('Tuesday')
                  ? 'simple-checkout__datetimezone selected-dtz'
                  : 'simple-checkout__datetimezone'
              }
              onClick={() => dayClickHandler('Tuesday')}
            >
              Tuesday
            </span>
            <span
              className={
                days.includes('Wednesday')
                  ? 'simple-checkout__datetimezone selected-dtz'
                  : 'simple-checkout__datetimezone'
              }
              onClick={() => dayClickHandler('Wednesday')}
            >
              Wednesday
            </span>
            <span
              className={
                days.includes('Thursday')
                  ? 'simple-checkout__datetimezone selected-dtz'
                  : 'simple-checkout__datetimezone'
              }
              onClick={() => dayClickHandler('Thursday')}
            >
              Thursday
            </span>
            <span
              className={
                days.includes('Friday') ? 'simple-checkout__datetimezone selected-dtz' : 'simple-checkout__datetimezone'
              }
              onClick={() => dayClickHandler('Friday')}
            >
              Friday
            </span>
            <span
              className={
                days.includes('Saturday')
                  ? 'simple-checkout__datetimezone selected-dtz'
                  : 'simple-checkout__datetimezone'
              }
              onClick={() => dayClickHandler('Saturday')}
            >
              Saturday
            </span>
            <span
              className={
                days.includes('Sunday') ? 'simple-checkout__datetimezone selected-dtz' : 'simple-checkout__datetimezone'
              }
              onClick={() => dayClickHandler('Sunday')}
            >
              Sunday
            </span>
          </div>
          <div
            className="simple-checkout__faq-2"
            onClick={() => {
              setShowFAQAnswers((prev: any) =>
                prev.includes(FAQInfo.secondSet[1].identifier)
                  ? prev.filter((val: string) => val !== FAQInfo.secondSet[1].identifier)
                  : [...prev, FAQInfo.secondSet[1].identifier],
              );
            }}
          >
            FAQ: {FAQInfo.secondSet[1].question}
            {showFAQAnswers.includes(FAQInfo.secondSet[1].identifier) && (
              <div className="simple-checkout__faq--answer">
                <br />
                A: {FAQInfo.secondSet[1].answer}
              </div>
            )}
          </div>
          <div className="simple-checkout__time-select-wrapper">
            {days.map((day, index) => (
              <React.Fragment key={generateID()}>
                <div className="simple-checkout__time-select">
                  <span className="simple-checkout__day">{day} Time:</span>
                  <div className="simple-checkout__time-select--arrow">
                    <select
                      className="simple-checkout__time"
                      value={
                        index === 0
                          ? clientDetails.time1
                          : index === 1
                          ? clientDetails.time2
                          : index === 2
                          ? clientDetails.time3
                          : ''
                      }
                      onChange={(e) => {
                        const newClientDetails = clientDetails;
                        newClientDetails[`day${index + 1}` as 'day1' | 'day2' | 'day3'] = day;
                        newClientDetails[`time${index + 1}` as 'time1' | 'time2' | 'time3'] = e.target.value;

                        setClientDetails((prev: any) => ({ ...prev, ...newClientDetails }));
                      }}
                    >
                      {validTimes.map((time) => (
                        <option value={time}>{time}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
          {clientDetails.day1 !== '' &&
            clientDetails.day2 !== '' &&
            clientDetails.day3 !== '' &&
            validTimes.includes(clientDetails.time1) &&
            validTimes.includes(clientDetails.time2) &&
            validTimes.includes(clientDetails.time3) && (
              <div className="simple-checkout__appointment-dates">
                <h2 className="simple-checkout__heading">Choose Your Start Date</h2>
                {validDates.slice(0, sliceValue).map((date) => {
                  return date.getDay() - 1 === dayNumber(clientDetails.day1) ||
                    (date.getDay() === 0 && dayNumber(clientDetails.day1) === 6) ? (
                    <React.Fragment key={generateID()}>
                      <div
                        className={
                          clientDetails.startDate === date.toDateString() + ` ${clientDetails.time1}`
                            ? 'simple-checkout__appointment-date selected-date'
                            : 'simple-checkout__appointment-date'
                        }
                        onClick={() => {
                          console.log(date.toDateString() + ` ${clientDetails.time1}`);
                          setClientDetails((prev: any) => ({
                            ...prev,
                            startDate: date.toDateString() + ` ${clientDetails.time1}`,
                          }));
                        }}
                      >
                        {date.toDateString().split(' ').slice(0, 3).join(' ') + ` ${clientDetails.time1}`}
                      </div>
                    </React.Fragment>
                  ) : date.getDay() - 1 === dayNumber(clientDetails.day2) ||
                    (date.getDay() === 0 && dayNumber(clientDetails.day2) === 6) ? (
                    <React.Fragment key={generateID()}>
                      <div
                        className={
                          clientDetails.startDate === date.toDateString() + ` ${clientDetails.time2}`
                            ? 'simple-checkout__appointment-date selected-date'
                            : 'simple-checkout__appointment-date'
                        }
                        onClick={() => {
                          console.log(date.toDateString() + ` ${clientDetails.time2}`);
                          setClientDetails((prev: any) => ({
                            ...prev,
                            startDate: date.toDateString() + ` ${clientDetails.time2}`,
                          }));
                        }}
                      >
                        {date.toDateString().split(' ').slice(0, 3).join(' ') + ` ${clientDetails.time2}`}
                      </div>
                    </React.Fragment>
                  ) : date.getDay() - 1 === dayNumber(clientDetails.day3) ||
                    (date.getDay() === 0 && dayNumber(clientDetails.day3) === 6) ? (
                    <React.Fragment key={generateID()}>
                      <div
                        className={
                          clientDetails.startDate === date.toDateString() + ` ${clientDetails.time3}`
                            ? 'simple-checkout__appointment-date selected-date'
                            : 'simple-checkout__appointment-date'
                        }
                        onClick={() => {
                          console.log(date.toDateString() + ` ${clientDetails.time3}`);
                          setClientDetails((prev: any) => ({
                            ...prev,
                            startDate: date.toDateString() + ` ${clientDetails.time3}`,
                          }));
                        }}
                      >
                        {date.toDateString().split(' ').slice(0, 3).join(' ') + ` ${clientDetails.time3}`}
                      </div>
                    </React.Fragment>
                  ) : null;
                })}
                {sliceValue < 28 && (
                  <div
                    className="simple-checkout__appointment-dates--show-more"
                    onClick={() => setSliceValue(sliceValue + 7)}
                  >
                    Show More
                  </div>
                )}
              </div>
            )}

          <button
            className="simple-checkout__button"
            onClick={() =>
              clientDetails.day1 !== '' &&
              clientDetails.day2 !== '' &&
              clientDetails.day3 !== '' &&
              validTimes.includes(clientDetails.time1) &&
              validTimes.includes(clientDetails.time2) &&
              validTimes.includes(clientDetails.time3) &&
              clientDetails.startDate !== ''
                ? nextSection()
                : setError({
                    type: 'error',
                    isActive: true,
                    message: 'Please select three days and times as well as a starting date.',
                  })
            }
          >
            Enroll
          </button>
        </div>
      )}

      {section === 6 && (
        <div
          className={
            slideInFromBack
              ? 'simple-checkout__true-end-container-backslide'
              : slideInFromFront
              ? 'simple-checkout__true-end-container-frontslide'
              : 'simple-checkout__true-end-container'
          }
        >
          <button className="simple-checkout__button-back" onClick={() => previousSection()}>
            Back
          </button>
          <h2 className="simple-checkout__heading">Review Information</h2>
          <div className="programs__program-card" style={{ pointerEvents: 'none' }}>
            <div
              style={{
                backgroundImage: program.bgColor,
                paddingTop: program.subject === 'Any' ? '10px' : '20px',
                lineHeight: program.subject === 'Any' ? '1.2' : '1.7',
              }}
              className="program-img"
            >
              {`${program.cardText}${program.subject === 'Any' ? ' Subject' : ''}`}
              <br></br>
              <span className="program-img--text">1-on-1</span>
            </div>

            <div className="programs__program-header-info-wrapper">
              <div className="programs__info-wrapper">
                <h4 className="programs__program-card-name">{program.name}</h4>
                <h5 className="programs__program-occupation">{`3 x per week`}</h5>
                <p className="programs__education">
                  <i className="fas fa-graduation-cap programs__education-bio-icon"></i>
                  {`Ages ${program.ageRange}`}
                </p>
              </div>
              <div className="programs__price-info-wrapper">
                <div className="programs__price-number-wrapper">
                  <p className="programs__price-number">${Math.round(100 * program.price) / 100} </p>
                </div>
                <div className="programs__price-row-end">
                  <p className="programs__price-payment-schedule">12 lessons per month</p>
                </div>
              </div>
            </div>
            <div className="programs__each-lesson">
              Each 1-on-1 lesson comes to:{''}
              <span className="programs__price-each">${Math.round((100 * program.price) / 12) / 100}</span>
            </div>
          </div>
          <div className="programs__lesson-container-2">
            <div className="programs__lesson-heading">Lesson Length</div>
            <div className="programs__lesson-length-wrapper">
              <div
                className={
                  program.lessonLength === '30 min' ? 'programs__lesson-length--selected' : 'programs__lesson-length'
                }
                onClick={() =>
                  setProgram((prev: any) => ({ ...prev, lessonLength: '30 min', price: program.priceLow }))
                }
              >
                30 min
              </div>
              <div
                className={
                  program.lessonLength === '45 min' ? 'programs__lesson-length--selected' : 'programs__lesson-length'
                }
                onClick={() =>
                  setProgram((prev: any) => ({ ...prev, lessonLength: '45 min', price: program.priceMid }))
                }
              >
                45 min
              </div>
              <div
                className={
                  program.lessonLength === '1 hr' ? 'programs__lesson-length--selected' : 'programs__lesson-length'
                }
                onClick={() => setProgram((prev: any) => ({ ...prev, lessonLength: '1 hr', price: program.priceHigh }))}
              >
                1 hr
              </div>
            </div>
          </div>
          <div className="simple-checkout__review-item-container">
            <span className="simple-checkout__review-identifier">Start date: </span>
            <span className="simple-checkout__review-item">
              {new Date(clientDetails.startDate).toLocaleString('en-US', {
                weekday: 'long',
                month: 'short',
                year: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: '2-digit',
              })}
            </span>
          </div>
          <div className="simple-checkout__review-item-container">
            <span className="simple-checkout__review-identifier">Days and times: </span>
            <span className="simple-checkout__review-item">{`${clientDetails.day1} ${clientDetails.time1}, ${clientDetails.day2} ${clientDetails.time2}, ${clientDetails.day3} ${clientDetails.time3}`}</span>
          </div>

          {error.isActive && <ErrorHandler type={error.type} message={error.message} />}
          <div className="simple-checkout__discount-wrapper">
            <h3 className="simple-checkout__sub-heading-discount">Have a discount code? Enter it here:</h3>
            <input
              className="simple-checkout__form-input"
              type="text"
              maxLength={12}
              onChange={async (e) => {
                if (e.target.value.length === 12) {
                  const request = await fetch(`${GetBaseURL()}/api/discountCodes/validateCode`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ code: e.target.value }),
                  });
                  const response = await request.json();
                  if (request.status === 500) {
                    setError({ type: 'warning', isActive: true, message: response.message });
                    setUseCode('');
                  } else if (request.status === 200) {
                    setError({ type: 'success', isActive: true, message: response.message });
                    setUseCode(e.target.value);
                  } else {
                    setError({ type: 'error', isActive: true, message: response.message });
                    setUseCode('');
                  }
                } else {
                  setError({ type: 'error', isActive: false, message: '' });
                  setUseCode('');
                }
              }}
            />

            {useCode !== '' && (
              <div className="simple-checkout__review-item-container" style={{ display: 'flex', flexDirection: 'row' }}>
                <h4 className="simple-checkout__sub-heading" style={{ textDecoration: 'line-through' }}>
                  ${program.price}
                </h4>
                <h4 className="simple-checkout__sub-heading">${Math.round(program.price * 0.9 * 100) / 100}</h4>
              </div>
            )}
          </div>
          <h3 className="simple-checkout__sub-heading-discount">Pay for your first 12 lessons:</h3>
          <span className="simple-checkout__review-item">
            (If you like the progress you see, you can continue with another 12 lessons next month)
          </span>
          <br></br>
          <Elements stripe={promise}>
            <CheckoutForm
              price={useCode ? Math.round(program.price * 0.9 * 100) / 100 : program.price}
              onSuccess={() => processPayment()}
            />
          </Elements>
        </div>
      )}
    </>
  );
  return redirectToSuccessPage ? (
    <Redirect to="/success" />
  ) : (
    <section
      onClick={() => setShowOtherTimezones(false)}
      id="form"
      className="simple-checkout"
      style={displayData.showModal ? { pointerEvents: 'none', margin: '0% -150% 0%' } : { margin: '0% -150% 0%' }}
    >
      <div
        className={displayData.showModal ? 'main-overlay' : 'transparent'}
        onClick={() => {
          hideModal();
          startBodyScroll();
        }}
      ></div>
      {CONTENT}
    </section>
  );
};

export default SimpleCheckout;
