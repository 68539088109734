import React from 'react';

type HatsProps = {
  type: string;
};

const Hats: React.FC<HatsProps> = ({ type }) => {
  switch (type) {
    case 'hat1':
      return (
        <img src="https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/hat1.svg" width="130" height="70" />
      );
    case 'hat2':
      return (
        <img src="https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/hat2.svg" width="130" height="60" />
      );
    case 'hat3':
      return (
        <img src="https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/hat3.svg" width="130" height="70" />
      );
    case 'hat4':
      return (
        <img src="https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/hat4.svg" width="130" height="70" />
      );
    case 'hat5':
      return (
        <img src="https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/hat5.svg" width="130" height="70" />
      );
    case 'hat6':
      return (
        <img src="https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/hat6.svg" width="130" height="70" />
      );
    case 'hat7':
      return (
        <img src="https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/hat7.svg" width="130" height="80" />
      );
    case 'hat8':
      return (
        <img src="https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/hat8.svg" width="130" height="70" />
      );
    case 'hat9':
      return (
        <img src="https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/hat9.svg" width="130" height="70" />
      );
    case 'hat10':
      return (
        <img
          src="https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/hat10.svg"
          width="130"
          height="70"
        />
      );
    default:
      return null;
  }
};

export default Hats;
