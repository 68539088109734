import React from 'react';

type EyesProps = {
  type: string;
};

const Eyes: React.FC<EyesProps> = ({ type }) => {
  switch (type) {
    case 'eyes1':
      return (
        <img
          src="https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/eyes1.svg"
          height="40"
          width="100"
        />
      );
    case 'eyes2':
      return (
        <img
          src="https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/eyes2.svg"
          height="40"
          width="100"
        />
      );
    case 'eyes3':
      return (
        <img
          src="https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/eyes3.svg"
          height="40"
          width="100"
        />
      );
    case 'eyes4':
      return (
        <img
          src="https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/eyes4.svg"
          height="40"
          width="100"
        />
      );
    case 'eyes5':
      return (
        <img
          src="https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/eyes5.svg"
          height="40"
          width="100"
        />
      );
    case 'eyes6':
      return (
        <img
          src="https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/eyes6.svg"
          height="40"
          width="100"
        />
      );
    case 'eyes7':
      return (
        <img
          src="https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/eyes7.svg"
          height="40"
          width="100"
        />
      );
    case 'eyes8':
      return (
        <img
          src="https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/eyes8.svg"
          height="40"
          width="100"
        />
      );
    case 'eyes9':
      return (
        <img
          src="https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/eyes9.svg"
          height="40"
          width="100"
        />
      );
    case 'eyes10':
      return (
        <img
          src="https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/eyes10.svg"
          height="40"
          width="100"
        />
      );
    default:
      return null;
  }
};

export default Eyes;
