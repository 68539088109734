import React from 'react';
import { ScienceSubjectsContent } from '../../Models/pages/SciencePage';
import AWSImage from '../Partials/AWSImage';

const Subject = () => {
  return (
    <div className="science-subjects">
      <div className="science-subjects__subjects-wrapper">
        {ScienceSubjectsContent.map((item, index) => {
          return (
            <div className="science-subjects__subject-card" key={index}>
              <AWSImage
                pngFile={item.icon}
                picClass={item.picClass}
                imageAlt={item.alt}
                documentPrefix={item.awsPrefix}
                hasWebp
              />

              <h4 className="science-subjects__subject-card-heading">{item.subjectHeading}</h4>
              <p className="science-subjects__subject-card-content">{item.subjectContent}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Subject;
