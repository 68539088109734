import React from 'react';
import { Switch, Route } from 'react-router-dom';
import BlogPost from '../../Components/Blog/BlogPost';
import { BlogPostRoutes } from '../../Models';

const BlogPosts: React.FC = () => {
  return (
    <div>
      <Switch>
        {BlogPostRoutes.map((post, index) => {
          return (
            <Route
              path={post.route}
              render={() => {
                return <BlogPost content={post.content} />;
              }}
              key={index}
            />
          );
        })}
      </Switch>
    </div>
  );
};

export default BlogPosts;
