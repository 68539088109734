export const TutorCardInfo = [
  {
    name: 'Ellison',
    occupation: 'Math Tutor',
    education: "Holds a Bachelor's in Medical Physics.",
    bio: 'I find joy in guiding a struggling student to reach their full potential.',
    img: 'ellison.jpg',
  },

  {
    name: 'Natalie',
    occupation: 'Teacher',
    education: "Holds a Bachelor's of Education.",
    bio: "I've been teaching for 13 years and have loved every second of it!",
    img: 'natalie.jpg',
  },

  {
    name: 'Rachel',
    occupation: 'Writing Tutor',
    education: 'Majoring in English Literature.',
    bio: 'I like to build habits in my students, ensuring they stay on top of their work!',
    img: 'rachel.jpeg',
  },
];
