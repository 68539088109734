import React, { useState, useEffect } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useUserData } from '../Hooks/useUserData';
import { useDisplayData } from '../Hooks/useDisplayData';

interface MatchParams {
  email: string;
}

interface ComponentProps extends RouteComponentProps<MatchParams> {}

const ReadingRedirect: React.FC<ComponentProps> = ({ match }) => {
  const [redirect, setRedirect] = useState(false);
  const { redirectReload } = useDisplayData();

  const [cookies, setCookie] = useCookies(['email']);

  useEffect(() => {
    setCookie('email', match.params.email, { path: '/' });
    redirectReload(true);
    setRedirect(true);
  }, [match]);

  return redirect ? (
    <Redirect to="/worksheets-for-kids" />
  ) : (
    <div>
      <div>Redirecting...</div>
    </div>
  );
};

export default ReadingRedirect;
