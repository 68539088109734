import React from 'react';
interface ErrorProps {
  message: string;
  type: 'error' | 'warning' | 'success' | 'info';
  handler?: () => void;
  icon?: string;
  id?: string;
}

const ErrorHandler: React.FC<ErrorProps> = ({ message, type, handler, id }) => {
  const findIcon = () => {
    let icon: string = '';
    switch (type) {
      case 'error':
        icon = 'fas fa-times-circle';
        break;

      case 'warning':
        icon = 'fas fa-exclamation-triangle';
        break;

      case 'success':
        icon = 'fas fa-check-circle';
        break;

      case 'info':
        icon = 'fas fa-info-circle';
        break;
    }
    return icon;
  };

  const classVar = 'default-margin-top-bottom isa_' + type;
  return (
    <div className={classVar} onClick={handler} data-cy="error_handler" id={id}>
      <i className={findIcon()}></i>
      {message}
    </div>
  );
};

export default ErrorHandler;
