import React, { useState, useRef, useEffect } from 'react';
import { useScrollListener } from './Resources/RenderAllDocuments';
import Confetti from 'react-confetti';
import { RouteComponentProps, Link } from 'react-router-dom';
import ModernArrowRight from '../assets/modern-arrow-right.svg';
import MetaData from './MetaData';
import Modal from './Modal';
import TabFilter from './Partials/TabFilter';
import WorksheetText from '../Components/WorksheetPartials/WorksheetText';
import ResourceRow from './Resources/ResourceRow';
import RenderAllDocuments from './Resources/RenderAllDocuments';
import { useResourceFilters } from '../Hooks/useResourceFilter';
import { useDisplayData } from '../Hooks/useDisplayData';
import { useTextBlocks } from '../Hooks/useTextBlocks';
import { FaArrowUp } from 'react-icons/fa';
import { grades, Resources, subjectListArray, subjectOptions, subcategoryArray, GradeOptions } from '../Models';
import {
  URLGeneratorByFilter,
  ParseURLFilters,
  MetadataTitlefromFilters,
  MetadataDescriptionfromFilters,
  MetadataKeywordsFromFilters,
  generateID,
} from '../Utils';
import { useUserData } from '../Hooks/useUserData';
import SearchInput from './Partials/SearchInput';
import GameSettings from './Games/GameSettings';

interface MatchParams {
  filterURL: string;
}

interface ComponentProps extends RouteComponentProps<MatchParams> {}

const ResourceComponent: React.FC<ComponentProps> = ({ match }) => {
  const { userData } = useUserData();
  const { displayData, hideModal, startBodyScroll, stopBodyScroll, setLoginType, showModal, showGameModal } =
    useDisplayData();
  const { gatherTextBlock } = useTextBlocks();
  const {
    resourceData,
    SetFilterOptions,
    clearFilters,
    ReturnRowIdentifier,
    AllDocsForFilters,
    FetchRelevantSubcategories,
    FetchRelevantSubjectBySubcategory,
    UpdateFilterOptions,
  } = useResourceFilters();
  const [rowValue, setRowValue] = useState<string>('subject');
  const [hasValidRows, setHasValidRows] = useState(true);
  const [title, setTitle] = useState<string | null>(null);
  const [allResourcesForFilters, setAllResourcesForFilters] = useState<Resources[]>([]);
  const [confetti, setConfetti] = useState({ show: false, x: 0, y: 0 });
  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth < 768 ? true : false);
  const [selectors, setSelectors] = useState({ grade: true, subject: false, subcategory: false });
  const [selectArray, setSelectArray] = useState<string[]>([]);
  const [searchText, setSearchText] = useState('');
  const scrollHeightRef2 = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [showPopup, setShowPopup] = useState(false);

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    if (selectors.grade) {
      setSelectArray(GradeOptions);
    } else if (selectors.subject) {
      setSelectArray(subjectOptions);
    } else if (selectors.subcategory) {
      setSelectArray(FetchRelevantSubcategories(resourceData.filters.subject));
    }
  }, [selectors]);

  useEffect(() => {
    let filterString = match.params.filterURL;
    if (filterString !== 'worksheets-for-kids') {
      let newFilter = ParseURLFilters(filterString);
      if (newFilter.subcategory !== undefined && newFilter.subject === undefined) {
        newFilter['subject'] = FetchRelevantSubjectBySubcategory(newFilter.subcategory);
      }
      let willRefresh = false;
      newFilter.docType !== resourceData.filters.docType ? (willRefresh = true) : (willRefresh = willRefresh);
      newFilter.grade !== resourceData.filters.grade ? (willRefresh = true) : (willRefresh = willRefresh);
      newFilter.subject !== resourceData.filters.subject ? (willRefresh = true) : (willRefresh = willRefresh);
      newFilter.subcategory !== resourceData.filters.subcategory ? (willRefresh = true) : (willRefresh = willRefresh);
      willRefresh && SetFilterOptions(newFilter);
    } else {
      SetFilterOptions({ docType: 'Worksheets', grade: undefined, subject: undefined, subcategory: undefined });
    }
  }, [match, resourceData.documents]);

  useEffect(() => {
    let newRowValue = ReturnRowIdentifier();

    //! Obtains every single doc related to the current filters
    if (AllDocsForFilters(resourceData.filters, searchText).length > 0) {
      setHasValidRows(true);
    } else {
      setHasValidRows(false);
    }

    setAllResourcesForFilters(AllDocsForFilters(resourceData.filters, searchText));
    setRowValue(newRowValue);
  }, [resourceData.filters, match, resourceData.documents, searchText]);

  useEffect(() => {
    if (rowValue === 'none') {
      let generatedURL = URLGeneratorByFilter({
        ...resourceData.filters,
      });
      let adjustedURLString = generatedURL.split('-').join(' ');
      let capitalizedString = adjustedURLString.split(' ').map((singleWord) => {
        return `${singleWord.charAt(0).toUpperCase()}${singleWord.length > 1 ? singleWord.slice(1) : ''}`;
      });
      setTitle(capitalizedString.join(' '));
    }
  }, [resourceData.filters, rowValue, resourceData.documents]);

  //!Mobile screen listener
  window.addEventListener('resize', () => {
    if (isMobileScreen && window.innerWidth > 767) {
      setIsMobileScreen(false);
    }
    if (!isMobileScreen && window.innerWidth < 768) {
      setIsMobileScreen(true);
    }
  });

  const SEOTitles =
    match.params.filterURL === 'worksheets-for-kindergarten'
      ? 'Worksheets for Kindergarten | Beautifully Designed Modern Worksheets'
      : match.params.filterURL === 'worksheets-for-kids'
      ? 'Worksheets for Kids | Beautifully Designed Modern Worksheets'
      : false;

  const SEODescriptions =
    match.params.filterURL === 'worksheets-for-kindergarten'
      ? 'With our Worksheets for Kindergarten, give your child a boost from a young age, positioning them to be a top student. Visit to download our Kindergarten Worksheets!'
      : match.params.filterURL === 'worksheets-for-kids'
      ? 'With our Worksheets give your child a boost, positioning them to be a top student. Visit to download our Worksheets!'
      : false;

  const confettiAnimation = (e: any) => {
    e.preventDefault();
    if (confetti.show) {
      return;
    } else {
      setConfetti({ show: true, x: e.pageX, y: e.pageY });
      setTimeout(() => setConfetti({ show: false, x: 0, y: 0 }), 5000);
    }
  };
  useEffect(() => {
    function handleScroll() {
      const scrollPosition = scrollHeightRef2.current?.getBoundingClientRect().top ?? 0;
      setScrollPosition(scrollPosition);
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <MetaData
        pageTitle={SEOTitles || `${MetadataTitlefromFilters(resourceData.filters)}`}
        postTitle={
          resourceData.pageTitle ? resourceData.pageTitle : title !== null ? title : 'No Fuss Tutors Worksheets'
        }
        description={SEODescriptions || MetadataDescriptionfromFilters(resourceData.filters)}
        image="https://nft-development.netlify.app/assets/webp/logo.png"
        url={`${process.env.REACT_APP_DOMAIN_NAME}/${URLGeneratorByFilter(resourceData.filters)}`}
        keywords={MetadataKeywordsFromFilters(resourceData.filters)}
        canonical={
          match.params.filterURL === 'worksheets-for-kindergarten' || match.params.filterURL === 'worksheets-for-kids'
            ? undefined
            : resourceData.pageTitle.toLowerCase().includes('kindergarten')
            ? `${process.env.REACT_APP_DOMAIN_NAME}/worksheets-for-kindergarten`
            : `${process.env.REACT_APP_DOMAIN_NAME}/worksheets-for-kids`
        }
      />

      {userData._id && userData._id !== '' && !userData.subscription_active && (
        <div className="banner__upgrade-banner">
          <div className="banner__upgrade-banner--left">Upgrade for Unlimited Worksheets!</div>

          {userData._id && userData._id !== '' ? (
            <Link to="/premium" target="_blank">
              <div className="banner__upgrade-banner--right">Upgrade</div>
            </Link>
          ) : (
            <a
              onClick={(e) => {
                e.preventDefault();
                setLoginType({ login: false, signup: true });
                showModal();
              }}
            >
              <div className="banner__upgrade-banner--right">Upgrade</div>
            </a>
          )}
        </div>
      )}

      <div className="modal-container">
        {/* //! when modal is visible, show black overlay to dull background */}
        <div
          className={displayData.showModal ? 'overlay' : 'transparent'}
          onClick={() => {
            hideModal();
            startBodyScroll();
          }}
        ></div>
        <section className="games__container">
          <div className="games__container-title">Games &amp; Tools:</div>
          <div className="games__card--wrapper">
            <div
              className="games__card games__card--bg--quiz"
              onClick={() => {
                if (!userData._id || userData._id === '') {
                  setLoginType({ login: true, signup: false }, true);
                  showModal();
                } else {
                  showGameModal(true);
                  stopBodyScroll();
                }
              }}
            >
              <div className="games__card--title-quiz">Classic Quiz</div>
            </div>
            <div className="games__card--player" style={{ marginLeft: '40px' }}>
              <div>Quiz</div>
            </div>
          </div>

          <div className="games__card--wrapper">
            <Link to="/ai-tutor" target="_blank">
              <div className="games__card games__card--bg--ai-tutor">
                {/* <div className="games__card--blue-font">AI</div> */}
                <div className="games__card--racing-font">AI ROBOT TUTOR</div>
              </div>
            </Link>
            <div className="games__card--player">
              <div>AI Robot Tutor</div>
            </div>
          </div>

          <div className="games__card--wrapper">
            {/* <Link to="/art-off" target="_blank"> */}
            <div className="games__card games__card--bg--art" onClick={openPopup}>
              <img
                src="https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/ArtOff.png"
                alt="Art Off"
                className="games__art-image"
              />
            </div>
            {/* </Link> */}
            <div className="games__card--player">
              <div>Multi Player</div> <i className="fa fa-users user-icon games__card--player-icon"></i>{' '}
            </div>
          </div>

          {showPopup && (
            <div className="games__popup">
              <div className="games__popup-content">
                <h2>Coming Soon!</h2>
                <p>This game is currently in development and will be available soon.</p>
                <button className="games__popup-close" onClick={closePopup}>
                  Close
                </button>
              </div>
            </div>
          )}

          <div className="games__card--wrapper">
            <a href="https://moonrockracer.netlify.app" target="_blank">
              <div className="games__card games__card--bg--racing">
                <div className="games__card--racing-font">ASTEROID RACER</div>
              </div>
            </a>
            <div className="games__card--player">
              <div>Single Player</div> <i className="fa fa-user user-icon games__card--player-icon"></i>
            </div>
          </div>

          <div className="games__card--wrapper">
            <a href="https://spacejunkgame.netlify.app" target="_blank">
              <div className="games__card games__card--bg--spacejunk">
                <img
                  src="https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/SpaceJunkLogo.png"
                  alt="SpaceJunk Game"
                  className="games__spacejunk-image"
                />
              </div>
            </a>
            <div className="games__card--player">
              <div>Single Player</div> <i className="fa fa-user user-icon games__card--player-icon"></i>
            </div>
          </div>

          <div className="games__card--wrapper">
            <Link to="/memory-game" target="_blank">
              <div className="games__card games__card--bg--memory" onClick={() => {}}>
                <img
                  src="https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/MemoryGame1.png"
                  alt="Memory Game"
                  className="games__memory-image"
                />
              </div>
            </Link>
            <div className="games__card--player">
              <div>Single Player</div> <i className="fa fa-user user-icon games__card--player-icon"></i>
            </div>
          </div>
          <div className="games__card--wrapper">
            <Link to="/guess-the-word" target="_blank">
              <div className="games__card games__card--bg" onClick={() => {}}>
                <img
                  src="https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/GuessTheWord.png"
                  alt="Art Off"
                  className="games__guess-image"
                />
              </div>
            </Link>
            <div className="games__card--player">
              <div>Single Player</div> <i className="fa fa-user user-icon games__card--player-icon"></i>
            </div>
          </div>
          <div className="games__card--wrapper">
            <Link to="/balloons" target="_blank">
              <div className="games__card games__card--bg--balloons">
                <img
                  src="https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/bVsd.png"
                  alt="Art Off"
                  className="games__bvsd-image"
                />
              </div>
            </Link>
            <div className="games__card--player">
              <div>Single Player</div> <i className="fa fa-user user-icon games__card--player-icon"></i>
            </div>
          </div>
        </section>
        <div className="downloads">
          <section className="cards-container">
            <div className="tab-filter__doc-type">
              {[
                { label: 'Worksheets', value: 'Worksheets' },
                { label: 'Assessments', value: 'Assessments' },
              ].map((docType, index) => {
                return (
                  <React.Fragment key={index}>
                    <TabFilter name="docType" filterOption={docType} />
                  </React.Fragment>
                );
              })}
            </div>

            <div className="cards-container__desktop-flex-wrapper">
              <div className="tab-fixed tab-fixed--visible">
                <div
                  className={
                    resourceData.filters.grade !== undefined ? 'tab-filter tab-filter--active-filter' : 'tab-filter'
                  }
                >
                  <div className="tab-filter__subject">
                    <h5 className="tab-filter__heading">By Subject</h5>
                    {isMobileScreen ? (
                      <div
                        className="worksheets__filter-select--mobile"
                        style={displayData.showModal ? { zIndex: -1 } : undefined}
                      >
                        {subjectOptions.slice(0).map((RowIdentifier) => {
                          return (
                            <React.Fragment key={generateID()}>
                              <Link
                                to={`${URLGeneratorByFilter({
                                  ...resourceData.filters,
                                  subject: resourceData.filters.subject === RowIdentifier ? undefined : RowIdentifier,
                                })}`}
                              >
                                <div
                                  className={`cards-container__daughter-section-tags${
                                    resourceData.filters.subject === RowIdentifier
                                      ? ' tab-filter__filter-option--active'
                                      : ''
                                  }`}
                                  onClick={() =>
                                    SetFilterOptions({
                                      docType: resourceData.filters.docType,
                                      subject:
                                        resourceData.filters.subject === RowIdentifier ? undefined : RowIdentifier,
                                      grade: resourceData.filters.grade,
                                    })
                                  }
                                >
                                  {RowIdentifier.replace('Preschool', 'Pre-K')
                                    .replace('Kindergarten', 'KG')
                                    .replace('Grade', 'Gr')}
                                </div>
                              </Link>
                            </React.Fragment>
                          );
                        })}
                      </div>
                    ) : (
                      <div className="tab-filter__flex-wrapper">
                        {subjectListArray.map((singleSubject, index) => {
                          return (
                            <React.Fragment key={index}>
                              <TabFilter name="subject" filterOption={singleSubject} children={'subcategory'} />
                            </React.Fragment>
                          );
                        })}
                      </div>
                    )}
                  </div>

                  <div className="tab-filter__grade">
                    <h5 className="tab-filter__heading">By Grade</h5>
                    {isMobileScreen ? (
                      <div
                        className="worksheets__filter-select--mobile"
                        style={displayData.showModal ? { zIndex: -1 } : undefined}
                      >
                        {grades.slice(0).map((RowIdentifier) => {
                          return (
                            <React.Fragment key={generateID()}>
                              <Link
                                to={`${URLGeneratorByFilter({
                                  ...resourceData.filters,
                                  grade:
                                    resourceData.filters.grade === RowIdentifier.value
                                      ? undefined
                                      : RowIdentifier.value,
                                })}`}
                              >
                                <div
                                  className={`cards-container__daughter-section-tags${
                                    resourceData.filters.grade === RowIdentifier.value
                                      ? ' tab-filter__filter-option--active'
                                      : ''
                                  }`}
                                  onClick={() =>
                                    SetFilterOptions({
                                      docType: resourceData.filters.docType,
                                      grade:
                                        resourceData.filters.grade === RowIdentifier.value
                                          ? undefined
                                          : RowIdentifier.value,
                                      subject: resourceData.filters.subject,
                                    })
                                  }
                                >
                                  {RowIdentifier.value
                                    .replace('Preschool', 'Pre-K')
                                    .replace('Kindergarten', 'KG')
                                    .replace('Grade', 'Gr')}
                                </div>
                              </Link>
                            </React.Fragment>
                          );
                        })}
                      </div>
                    ) : (
                      <div className="tab-filter__flex-wrapper">
                        {grades.map((singleGrade, index) => {
                          return (
                            <React.Fragment key={index}>
                              <TabFilter name="grade" filterOption={singleGrade} />
                            </React.Fragment>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* //! Add in subcategory filter IF both subject and grade are selected */}
              {/* {isMobileScreen && resourceData.filters.subject !== undefined && (
                <div className="tab-filter__grade">
                  <h5 className="tab-filter__heading">By Subcategory</h5>

                  <div
                    className="worksheets__filter-select--mobile"
                    style={displayData.showModal ? { zIndex: -1 } : undefined}
                  >
                    {subcategoryArray.slice(0).map((RowIdentifier) => {
                      return (
                        <React.Fragment key={generateID()}>
                          <Link
                            to={`${URLGeneratorByFilter({
                              ...resourceData.filters,
                              subcategory: RowIdentifier,
                            })}`}
                          >
                            <div className="cards-container__daughter-section-tags">
                              {RowIdentifier.replace('Preschool', 'Pre-K')
                                .replace('Kindergarten', 'KG')
                                .replace('Grade', 'Gr')}
                            </div>
                          </Link>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              )} */}

              {/* //! <---------- document cards ----------> */}
              <div className="cards-container__tag-cards-wrapper">
                <div className="cards-container__search-wrapper">
                  <div>
                    <SearchInput setSearchText={setSearchText} />
                  </div>
                  {searchText !== '' && (
                    <div>
                      Searching for <strong>"{searchText}"</strong> in{' '}
                      <strong>
                        {resourceData.pageTitle === 'Worksheets For Kids' || resourceData.pageTitle === 'Assessments'
                          ? `All ${resourceData.filters.docType}`
                          : resourceData.pageTitle}
                      </strong>
                    </div>
                  )}
                </div>
                {!isMobileScreen && (
                  <div className={`cards-container__tag-wrapper ${isMobileScreen && 'scroll'}`}>
                    {resourceData.filters.grade !== undefined && (
                      <>
                        <div
                          className={
                            resourceData.filters.grade !== undefined
                              ? 'cards-container__tags'
                              : 'cards-container__tags-hidden'
                          }
                          id="grade-tag"
                        >
                          <p>{resourceData.filters.grade}</p>
                          <Link
                            to={`${
                              resourceData.filters.subcategory === undefined &&
                              resourceData.filters.subject === undefined
                                ? `${
                                    resourceData.filters.docType === 'Assessments'
                                      ? '/assessments'
                                      : '/worksheets-for-kids'
                                  }`
                                : `/${URLGeneratorByFilter({ ...resourceData.filters, grade: undefined })}`
                            }`}
                          >
                            <button
                              className="cards-container__close-btn"
                              onClick={() => {
                                clearFilters('grade');
                                setSelectors({ grade: true, subject: false, subcategory: false });
                              }}
                              name="grade"
                            >
                              <i className="fa fa-close"></i>
                            </button>
                          </Link>
                        </div>
                        {/* {!(selectors.grade || isMobileScreen) && (
                          <div
                            className="cards-container__grade-selector"
                            onClick={() => setSelectors({ grade: true, subject: false, subcategory: false })}
                          >
                            Switch Grade
                          </div>
                        )} */}
                      </>
                    )}
                    {resourceData.filters.grade === undefined && (
                      <div className="cards-container__grade-wrapper">
                        <div className="cards-container__empty-grade-tag">Grade</div>
                        {/* {!(selectors.grade || isMobileScreen) && (
                          <div
                            className="cards-container__grade-selector"
                            onClick={() => setSelectors({ grade: true, subject: false, subcategory: false })}
                          >
                            Choose Grade
                          </div>
                        )} */}
                      </div>
                    )}
                    {resourceData.filters.subject !== undefined && (
                      <>
                        <div
                          className={
                            resourceData.filters.subject !== undefined
                              ? 'cards-container__tags'
                              : 'cards-container__tags-hidden'
                          }
                          id="subject-tag"
                        >
                          <p>{resourceData.filters.subject}</p>
                          <Link
                            to={`${
                              resourceData.filters.subcategory === undefined && resourceData.filters.grade === undefined
                                ? `${
                                    resourceData.filters.docType === 'Assessments'
                                      ? '/assessments'
                                      : '/worksheets-for-kids'
                                  }`
                                : `/${URLGeneratorByFilter({ ...resourceData.filters, subject: undefined })}`
                            }`}
                          >
                            <button
                              className="cards-container__close-btn"
                              onClick={() => {
                                clearFilters('subject');
                                setSelectors({ grade: false, subject: true, subcategory: false });
                              }}
                              name="subject"
                            >
                              <i className="fa fa-close"></i>
                            </button>
                          </Link>
                        </div>
                        {/* {!(selectors.subject || isMobileScreen) && (
                          <div
                            className="cards-container__subject-selector"
                            onClick={() => setSelectors({ grade: false, subject: true, subcategory: false })}
                          >
                            Switch Subject
                          </div>
                        )} */}
                      </>
                    )}
                    {resourceData.filters.subject === undefined && (
                      <div className="cards-container__subject-wrapper">
                        <div className="cards-container__empty-subject-tag" id="empty-subject-tag">
                          Subject
                        </div>
                        {/* {!(selectors.subject || isMobileScreen) && (
                          <div
                            className="cards-container__subject-selector"
                            onClick={() => setSelectors({ grade: false, subject: true, subcategory: false })}
                          >
                            Choose Subject
                          </div>
                        )} */}
                      </div>
                    )}
                    {/* {resourceData.filters.subcategory === undefined && (
                      <img
                        src={ModernArrowRight}
                        alt="Modern Arrow Right"
                        className="cards-container__subcategory-arrow"
                        id="subcategory-arrow"
                      />
                    )} */}
                    {resourceData.filters.subcategory === undefined && (
                      <div className="cards-container__subcategory-wrapper">
                        <div className="cards-container__empty-subcategory-tag" id="empty-subcategory-tag">
                          Subcategory
                        </div>
                        {/* {resourceData.filters.subject && !(selectors.subcategory || isMobileScreen) && (
                          <div
                            className="cards-container__subcategory-selector"
                            onClick={() => setSelectors({ grade: false, subject: false, subcategory: true })}
                          >
                            Choose Subcategory
                          </div>
                        )} */}
                      </div>
                    )}
                    {/* {resourceData.filters.subcategory !== undefined && (
                      <img
                        src={ModernArrowRight}
                        alt="Modern Arrow Right"
                        className="cards-container__subcategory-arrow"
                        id="subcategory-arrow"
                      />
                    )} */}
                    {resourceData.filters.subcategory !== undefined && (
                      <>
                        <div
                          id="subcategory-tag"
                          className={
                            resourceData.filters.subcategory !== undefined
                              ? 'cards-container__tags'
                              : 'cards-container__tags-hidden'
                          }
                        >
                          <p>{resourceData.filters.subcategory}</p>
                          <Link
                            to={`${
                              resourceData.filters.subject === undefined && resourceData.filters.grade === undefined
                                ? `${
                                    resourceData.filters.docType === 'Assessments'
                                      ? '/assessments'
                                      : '/worksheets-for-kids'
                                  }`
                                : `/${URLGeneratorByFilter({ ...resourceData.filters, subcategory: undefined })}`
                            }`}
                          >
                            <button
                              className="cards-container__close-btn__subcategory"
                              onClick={() => {
                                clearFilters('subcategory');
                                setSelectors({ grade: false, subject: false, subcategory: true });
                                // setForceSelectSubcategory(true);
                              }}
                              name="subcategory"
                            >
                              <i className="fa fa-close"></i>
                            </button>
                          </Link>
                        </div>
                        {/* {resourceData.filters.subject && !(selectors.subcategory || isMobileScreen) && (
                          <div
                            className="cards-container__subcategory-selector"
                            onClick={() => setSelectors({ grade: false, subject: false, subcategory: true })}
                          >
                            Switch Subcategory
                          </div>
                        )} */}
                      </>
                    )}
                  </div>
                )}
                {/* {!isMobileScreen && (
                  <div
                    className={
                      selectors.subcategory
                        ? 'cards-container__daughter-section-tag-wrapper--right'
                        : selectors.subject
                        ? 'cards-container__daughter-section-tag-wrapper--middle'
                        : 'cards-container__daughter-section-tag-wrapper--left'
                    }
                    style={displayData.showModal ? { zIndex: -1 } : undefined}
                  >
                    {selectArray.map((identifier) => {
                      return (
                        <React.Fragment key={generateID()}>
                          <Link
                            to={`${URLGeneratorByFilter({
                              ...resourceData.filters,
                              [Object.entries(selectors).filter((selector) => selector[1])[0][0]]: identifier,
                              [Object.entries(selectors).filter((selector) => selector[1])[0][0] === 'subject'
                                ? 'subcategory'
                                : 'undefined']: undefined,
                            })}`}
                          >
                            <div
                              className="cards-container__daughter-section-tags"
                              onClick={() => {
                                if (Object.entries(selectors).filter((selector) => selector[1])[0][0] === 'subject') {
                                  UpdateFilterOptions({ entryKey: 'subcategory', entryValue: 'undefined' }, true);
                                }
                              }}
                            >
                              {identifier
                                .replace('Preschool', 'Pre-K')
                                .replace('Kindergarten', 'KG')
                                .replace('Grade', 'Gr')}
                            </div>
                          </Link>
                        </React.Fragment>
                      );
                    })}
                  </div>
                )} */}
                {rowValue !== 'none' && hasValidRows && (
                  <React.Fragment key={generateID()}>
                    <RenderAllDocuments
                      documents={allResourcesForFilters}
                      title={resourceData.pageTitle}
                      source="worksheets-page"
                    />
                  </React.Fragment>
                )}

                {!resourceData.hasFetched && <h3 className="resource-row-heading">Loading...</h3>}

                {hasValidRows === false &&
                  resourceData.hasFetched &&
                  (resourceData.filters.docType === 'Worksheets' ? (
                    <>
                      <h3 className="resource-row-heading">
                        No {searchText !== '' ? `\"${searchText}\" ` : ''}
                        {resourceData.filters.grade}{' '}
                        {resourceData.filters.subcategory
                          ? resourceData.filters.subcategory
                          : resourceData.filters.subject
                          ? resourceData.filters.subject
                          : ''}{' '}
                        worksheets available
                      </h3>
                      <h3 className="resource-row-subheading">
                        If you'd like {searchText !== '' ? `\"${searchText}\" ` : ''} {resourceData.filters.grade}{' '}
                        {resourceData.filters.subcategory
                          ? resourceData.filters.subcategory
                          : resourceData.filters.subject
                          ? resourceData.filters.subject
                          : ''}{' '}
                        worksheets,{' '}
                        <span className="resource-row-subheading-link" onClick={(e: any) => confettiAnimation(e)}>
                          request us to make them!
                        </span>
                        {confetti.show && (
                          <Confetti
                            width={1000}
                            height={500}
                            confettiSource={{ x: 100, y: 100, w: 100, h: 100 }}
                            numberOfPieces={50}
                            recycle={false}
                            style={{ top: confetti.y - 225, left: confetti.x - 140, zIndex: 0 }}
                          ></Confetti>
                        )}
                      </h3>

                      <div className="resource-row-border"></div>
                      <h3 className="resource-row-subheading-2">
                        We do have the following{' '}
                        {resourceData.filters.subcategory
                          ? resourceData.filters.subcategory
                          : resourceData.filters.subject
                          ? resourceData.filters.subject
                          : ''}{' '}
                        worksheets available:
                      </h3>
                      {grades
                        .map((grade) => grade.value)
                        .map((RowIdentifier, index) => {
                          return (
                            <React.Fragment key={generateID()}>
                              <ResourceRow
                                identifying_filter="grade"
                                filter_value={RowIdentifier}
                                current_filters={{
                                  docType: resourceData.filters.docType,
                                  grade: RowIdentifier,
                                  subject: resourceData.filters.subject,
                                  subcategory: resourceData.filters.subcategory,
                                }}
                              />
                            </React.Fragment>
                          );
                        })}
                    </>
                  ) : (
                    <>
                      <h1>No Assessments Available.</h1>
                      <h3>Check out our worksheets instead!</h3>
                    </>
                  ))}
                {rowValue === 'none' && title !== null && hasValidRows && (
                  <React.Fragment key={generateID()}>
                    <RenderAllDocuments
                      title={resourceData.pageTitle}
                      documents={allResourcesForFilters}
                      source="worksheets-page"
                    />
                  </React.Fragment>
                )}

                {/* {isMobileScreen && (
                  <div
                    className={
                      resourceData.filters.grade !== undefined ? 'tab-filter tab-filter--active-filter' : 'tab-filter'
                    }
                  >
                    <div className="tab-filter__flex-wrapper">
                      {subjectListArray.map((singleSubject, index) => {
                        return (
                          <React.Fragment key={index}>
                            <TabFilter name="subject" filterOption={singleSubject} children={'subcategory'} />
                          </React.Fragment>
                        );
                      })}
                    </div>

                    <div className="tab-filter__flex-wrapper">
                      {grades.map((singleGrade, index) => {
                        return (
                          <React.Fragment key={index}>
                            <TabFilter name="grade" filterOption={singleGrade} />
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                )} */}
              </div>
            </div>
          </section>
          {displayData.showModal && (
            <Modal
              type={displayData.activeCard.type}
              title={displayData.activeCard.title}
              src={displayData.activeCard.src}
              webp={displayData.activeCard.webp}
              alt={displayData.activeCard.alt}
              shareUrl={displayData.activeCard.shareUrl}
              facebookQuote={displayData.activeCard.facebookQuote}
              description={displayData.activeCard.description}
              subject={displayData.activeCard.subject}
              grade={displayData.activeCard.grade}
              pdfDoc={displayData.activeCard.pdfDoc}
              link={displayData.activeCard.link}
              _id={displayData.activeCard._id}
            />
          )}
        </div>
      </div>
      {gatherTextBlock(match.url) && (
        <React.Fragment key={generateID()}>
          <section className="single-worksheet__wrapper">
            <WorksheetText textData={gatherTextBlock(match.url)} />
          </section>
        </React.Fragment>
      )}
    </>
  );
};

export default ResourceComponent;
