import React, { useRef, useState } from 'react';
import { useDisplayData } from '../Hooks/useDisplayData';
import Navigation from './Partials/Navigation';
import AWSImage from './Partials/AWSImage';
import { FooterNavOptions1, FooterNavOptions2, FooterNavOptions3, AllPhoneNumbers, ErrorProps } from '../Models';
import { SubscribeEmailList, ValidateEmail } from '../Utils';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  const { displayData } = useDisplayData();
  const [isDisabled, setIsDisabled] = useState(false);
  const [error, setError] = useState<ErrorProps>({
    isActive: false,
    message: '',
    type: 'error',
  });

  const emailRef = useRef<HTMLInputElement>(null);

  const EmailSubscriptionHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsDisabled(true);

    if (emailRef.current?.value && ValidateEmail(emailRef.current?.value as string)) {
      let subscribe = await SubscribeEmailList(emailRef.current.value);
      if (subscribe) {
        setError({
          isActive: true,
          message: 'Email added to mailing list!',
          type: 'success',
        });
      } else {
        setError({
          isActive: true,
          message: 'Failed to send email. Please try again.',
          type: 'error',
        });
      }
    } else {
      setError({
        isActive: true,
        message: 'Please enter a valid email address.',
        type: 'error',
      });
    }
    setIsDisabled(false);
  };

  const resetError = () => {
    setError({
      isActive: false,
      message: '',
      type: 'error',
    });
  };

  return (
    <footer className={displayData.showModal ? 'header-overlay' : 'footer'}>
      <Link to="/ai-tutor" target="_blank" className="footer-chat-bot">
        <i className="fas fa-comment-dots"></i>
      </Link>

      <div className="footer__wrapper">
        {/* // ! left side of footer */}
        <section className="footer__left-side">
          <div className={displayData.showModal ? 'logo hide-image' : 'footer__logo'}>
            <AWSImage pngFile={'NFT-logo-white.png'} documentPrefix={'homepage'} imageAlt={'No Fuss Tutors logo'} />
          </div>
          <div className="footer__newsletter">
            <p className="footer__newsletter-message">
              <strong>Subscribe</strong> to our newsletter to get exclusive offers &amp; the inside scoop.
            </p>
            <form onSubmit={EmailSubscriptionHandler} className="footer__newsletter-form">
              {error.isActive ? (
                <div className="error">
                  <span className={error.type === 'error' ? 'error-span' : 'success-span'} onClick={resetError}>
                    {error.message}
                  </span>
                </div>
              ) : null}
              <div
                className={
                  displayData.showModal ? 'subscribe-input-container hide-shadow' : 'footer__subscribe-input-container'
                }
              >
                <input
                  ref={emailRef}
                  name="email"
                  aria-label="email"
                  type="email"
                  placeholder="Your email"
                  data-cy="subscribe_email"
                  className="footer__email-input"
                />
                <button
                  disabled={isDisabled}
                  className={displayData.showModal ? 'subscribe hide-image' : 'footer__subscribe-button'}
                  aria-label="subscribe to email newsletter"
                  type="submit"
                  data-cy="subscribe"
                >
                  <i className="fa fa-arrow-right"></i>
                </button>
              </div>
            </form>
          </div>
          <p className="footer__copyright">Copyright © 2023 No Fuss Tutors</p>
        </section>

        {/* //! middle footer section */}
        <section className="footer__nav-container">
          <div className="footer__nav-links-wrapper">
            <h4 className={displayData.showModal ? 'hide-image' : 'footer__nav-header'}>Navigation</h4>
            <Navigation options={FooterNavOptions2} navListClass="footer__nav-list" />
            {/* // ! used this map function to return a tags because we can't use Link from react router dom to quick scroll to sections on the page */}
            <div className="footer__nav-list">
              {FooterNavOptions1.map((navOption, index) => {
                return (
                  <a href={navOption.link} className="footer__nav-item" key={index}>
                    {navOption.label}
                  </a>
                );
              })}
            </div>
          </div>
          <div className="footer__policies-wrapper">
            <h4 className={displayData.showModal ? 'hide-image' : 'footer__nav-header'}>Policies</h4>
            <Navigation options={FooterNavOptions3} navListClass="footer__nav-list" />
          </div>
        </section>

        {/* //! right footer section */}
        <section className="footer__social-phones-wrapper">
          <div className="footer__phone-wrapper">
            <h4 className={displayData.showModal ? 'hide-image' : 'footer__nav-header'}>Get In Touch</h4>
            {AllPhoneNumbers.map((number, index) => {
              return (
                <a href={number.link} key={index} className="footer__nav-item">
                  {number.label}
                </a>
              );
            })}
          </div>
          <div className={displayData.showModal ? 'social-link-container hide-image' : 'footer__social-link-container'}>
            <a
              href="https://www.facebook.com/No-Fuss-Tutors-459431424590486/"
              className="footer__social-icon-link"
              aria-label="No Fuss Tutors Facebook"
            >
              <div className="footer__social-icon-holder">
                <i className="fab fa-facebook-f social-icon"></i>
              </div>
            </a>
            <a
              href="https://www.instagram.com/nofusstutors/"
              className="footer__social-icon-link"
              aria-label="No Fuss Tutors Instagram"
            >
              <div className="footer__social-icon-holder">
                <i className="fab fa-instagram social-icon"></i>
              </div>
            </a>
          </div>
        </section>
      </div>
    </footer>
  );
};

export default Footer;
