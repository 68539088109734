import React from 'react';
import AWSImage from '../Partials/AWSImage';

const SellingPoint = () => {
  return (
    <section className="selling-point">
      <div className="selling-point__content-wrapper">
        <h2 className="selling-point__heading">Why Us</h2>
        <h3 className="selling-point__sub-heading">Get resits far beyond English Class with a writing tutor</h3>
        <p className="selling-point__content">
          Whether you’re looking to improve your child’s school grades, preparation for standardized testing, or to help
          their overall development of lifelong communication skills, an online writing tutor is there to help achieve
          your desired goals.
        </p>
      </div>
      <AWSImage
        pngFile={'writing1.jpg'}
        documentPrefix={'homepage/writing'}
        picClass={'selling-point__img'}
        imageAlt={'tutor on web call'}
        hasWebp
      />
    </section>
  );
};

export default SellingPoint;
