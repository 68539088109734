import { DownloadedDocument } from '../Models';

export const isDownloadable = (userAssessments: DownloadedDocument[], userWorksheets: DownloadedDocument[]) => {
  //sort downloads by date (newest to oldest)
  // const sortedAssessments = userAssessments.sort((first: DownloadedDocument, second: DownloadedDocument) => {
  //   return new Date(first.date_downloaded).getTime() - new Date(second.date_downloaded).getTime();
  // });
  // const sortedWorksheets = userWorksheets.sort((first: DownloadedDocument, second: DownloadedDocument) => {
  //   return new Date(first.date_downloaded).getTime() - new Date(second.date_downloaded).getTime();
  // });

  const today = new Date(Date.now());

  if (
    userAssessments.length + userWorksheets.length < 1 ||
    ((userWorksheets.length > 0
      ? today.setHours(0, 0, 0, 0).valueOf() -
          new Date(userWorksheets[userWorksheets.length - 1].date_downloaded).setHours(0, 0, 0, 0).valueOf() >
        0
      : true) &&
      (userAssessments.length > 0
        ? today.setHours(0, 0, 0, 0).valueOf() -
            new Date(userAssessments[userAssessments.length - 1].date_downloaded).setHours(0, 0, 0, 0).valueOf() >
          0
        : true))
  ) {
    return true;
  } else {
    return false;
  }
};
