import React from 'react';
import TutorCard from './TutorCard';
import { TutorCardInfo } from '../../../Models/TutorInfo';

const Tutors: React.FC = () => {
  return (
    <section className="tutors">
      <h3 className="tutors__heading">Tutors</h3>
      <h4 className="tutors__sub-heading">Meet some of our amazing tutors</h4>
      <h5 className="tutors__heading-info">
        With over 500 tutors hand-picked to be on our staff, we are sure we have the right one for you!
      </h5>
      <div className="tutors__tutor-cards">
        {TutorCardInfo.map((info, index) => {
          return (
            <React.Fragment key={index}>
              <TutorCard info={info} />
            </React.Fragment>
          );
        })}
      </div>
    </section>
  );
};

export default Tutors;
