import React, { useContext, useEffect } from "react";
import { AppContext } from "../GuessTheWord";

function Letter({ letterPos, attemptVal }) {
  const { board, setDisabledLetters, currAttempt, correctWord } =
    useContext(AppContext);
    

    const isAlmost = (letter, correctWord, letterPos) => {
      const upperLetter = letter.toUpperCase();
      const upperCorrectWord = correctWord.toUpperCase();
      if (upperCorrectWord[letterPos] === upperLetter) return false;
      return upperCorrectWord.includes(upperLetter);
    };
    
    
  // Check if board is defined and the nested arrays are not undefined
  const letter = board && board[attemptVal] ? board[attemptVal][letterPos] : "";
  const correct = correctWord.toUpperCase()[letterPos] === letter;
  const almost = !correct && letter !== "" && isAlmost(letter, correctWord, letterPos);

  const letterState =
    currAttempt.attempt > attemptVal &&
    (correct ? "correct" : almost ? "almost" : "error");

  useEffect(() => {
    if (letter !== "" && !correct && !almost) {
      console.log(letter);
      setDisabledLetters((prev) => [...prev, letter]);
    }

    // Cleanup function
    return () => {
      setDisabledLetters([]);
    };
  }, [currAttempt.attempt]);

  return (
    <div className="letter" id={letterState}>
      {letter}
    </div>
  );
}

export default Letter;
