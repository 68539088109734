import React, { useState, useContext, useEffect, useRef } from 'react';
import { SocketContext } from './../../Context/WebsocketContext';

type User = {
  id: string;
  username: string;
  characterDetails: {
    color: string;
    hat: string;
    eyes: string;
  };
};

type ChatProps = {
  roomId: string;
  currentUserId: string | null;
  turnUserId: string | null;
  users: User[];
};

type Message = {
  sender?: string;
  senderId?: string;
  content: string;
  isCorrectGuess?: boolean;
};

const Chat: React.FC<ChatProps> = ({ roomId, currentUserId, turnUserId, users }) => {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState<Message[]>([]);
  const socket = useContext(SocketContext);
  const chatRef = useRef<HTMLDivElement>(null);
  const [hasGuessedCorrectly, setHasGuessedCorrectly] = useState(false);
  const [usersWhoGuessedCorrectly, setUsersWhoGuessedCorrectly] = useState<string[]>([]);

  const sendMessage = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (socket && message) {
      // Ensure that there is a message before sending it
      const newMessage: Message = {
        content: message,
      };

      socket.emit('send-message', roomId, newMessage);
      setMessage('');
    }
  };

  useEffect(() => {
    if (users && users.length > 0 && usersWhoGuessedCorrectly.length === users.length - 1) {
      console.log('Changing turn from correct guesses...'); // Add this line

      if (socket) {
        socket.emit('change-turn', roomId);
      }
      setUsersWhoGuessedCorrectly([]);
    }
  }, [usersWhoGuessedCorrectly, users]);

  useEffect(() => {
    if (socket) {
      socket.on('receive-message', (message: Message) => {
        setMessages((prevMessages) => [...prevMessages, message]);

        if (message.isCorrectGuess && message.senderId) {
          setUsersWhoGuessedCorrectly((prevUsers) => {
            if (message.senderId && !prevUsers.includes(message.senderId)) {
              return [...prevUsers, message.senderId];
            }
            return prevUsers;
          });
        }

        if (message.isCorrectGuess && message.senderId === currentUserId) {
          setHasGuessedCorrectly(true);
        }
      });
    }
  }, [socket, roomId]);

  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    setHasGuessedCorrectly(false);
  }, [turnUserId]);

  return (
    <div className="ArtOff__chat--container">
      <div className="ArtOff__chat--messages" ref={chatRef}>
        {messages.map((message, index) => (
          <div
            className={`ArtOff__chat--content ${
              message.isCorrectGuess ? 'ArtOff__chat--message--correct ArtOff__chat--message' : 'ArtOff__chat--message'
            }`}
            key={index}
          >
            <span className="ArtOff__chat--sender">{message.sender} </span>
            <span className={`ArtOff__chat--content ${message.isCorrectGuess ? 'ArtOff__chat--correctGuess' : ''}`}>
              {message.content}
            </span>
          </div>
        ))}
      </div>

      {currentUserId !== turnUserId && (
        <>
          <form className="ArtOff__chat--form" onSubmit={sendMessage}>
            <input
              type="text"
              placeholder="Type your message..."
              value={message}
              onChange={(event) => setMessage(event.target.value)}
              disabled={currentUserId === turnUserId || hasGuessedCorrectly}
            />
            <button type="submit" disabled={currentUserId === turnUserId || hasGuessedCorrectly}>
              Send
            </button>
          </form>
        </>
      )}
    </div>
  );
};

export default Chat;
