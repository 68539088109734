import TEXT_BLOCKS from '../Models/TextBlocks.json';

export const useTextBlocks = () => {
  const TextBlocks: { url: string; text: any }[] = [...TEXT_BLOCKS];

  const gatherTextBlock = (url: string) => {
    const singleBlock = TextBlocks.find((singleBlock: any) => {
      return url === singleBlock.url;
    });

    return singleBlock ? singleBlock.text : [];
  };

  return { gatherTextBlock };
};
