import React from 'react';

type AWSImageProps = {
  pngFile: string;
  imageAlt: string;
  documentPrefix: string;
  picClass?: string;
  hasWebp?: boolean;
  forceLoad?: boolean;
};

const AWSImage: React.FC<AWSImageProps> = ({ pngFile, imageAlt, documentPrefix, picClass, hasWebp, forceLoad }) => {
  let file = encodeURIComponent(pngFile); //If the file has special characters that get mutated for URL strings this will do the conversion for us

  let splitFile = file.split('.');

  return (
    <div
      className={picClass}
      onContextMenu={(e) => {
        e.preventDefault();
      }}
    >
      {hasWebp ? (
        <>
          <picture>
            <source
              srcSet={new URL(`/${documentPrefix}/${splitFile[0]}.webp`, process.env.REACT_APP_AWS_BUCKET_URL).toJSON()}
              type="image/webp"
            />
            <source
              srcSet={new URL(`/${documentPrefix}/${file}`, process.env.REACT_APP_AWS_BUCKET_URL).toJSON()}
              type={`image/${file.split('.')[file.split('.').length - 1]}`}
            />
          </picture>
          <img
            src={new URL(`/${documentPrefix}/${file}`, process.env.REACT_APP_AWS_BUCKET_URL).toJSON()}
            alt={imageAlt}
            loading="lazy"
          />
        </>
      ) : (
        <img
          src={new URL(`/${documentPrefix}/${file}`, process.env.REACT_APP_AWS_BUCKET_URL).toJSON()}
          alt={imageAlt}
          loading={forceLoad ? 'eager' : 'lazy'}
        />
      )}
    </div>
  );
};

export default AWSImage;
