import React from 'react';
import MetaData from '../../Components/MetaData';
import Banner from '../../Components/Blog/Banner';
import BlogCard from '../../Components/Blog/BlogCard';
import { BlogCardContent } from '../../Models';
import ConsultationWidget from '../../Components/Partials/ConsultationWidget';

const BlogHome: React.FC = () => {
  return (
    <div className="blog-page">
      <MetaData
        pageTitle="No Fuss Tutors | Blog"
        postTitle="No Fuss Tutors - Blog"
        description="Find the latest No Fuss Tutors news and updates on our blog. Browse articles and topics to learn more about how you can help improve your child's education!"
        image={'https://nft-development.netlify.app/assets/webp/logo.png'}
        url={'https://nofusstutors.com/online-languages-tutors'}
        keywords={['online tutor canada', 'online tutor new york', 'online tutor florida']}
      />
      <Banner
        lineOne={'No Fuss Tutors'}
        lineTwo={'Blog'}
        imageName="blog-banner"
        image={'blog-banner.png'}
        alt="No Fuss Tutors banner"
        awsPrefix={'banner'}
      />
      <section className="blog-page__cards-wrapper">
        {BlogCardContent.slice(0)
          .reverse()
          .map((item, index) => {
            return (
              <BlogCard
                key={index}
                link={item.link}
                src={item.src}
                alt={item.alt}
                title={item.title}
                date={item.date}
              />
            );
          })}
      </section>
      <ConsultationWidget source="form" />
    </div>
  );
};

export default BlogHome;
