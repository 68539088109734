import React from 'react';
import { ScienceCareersContent } from '../../Models';
import AWSImage from '../Partials/AWSImage';

const Careers = () => {
  return (
    <section className="careers">
      <h1 className="careers__header">You and your child deserve the best</h1>
      <div className="careers__card-wrapper">
        {ScienceCareersContent.map((item: any, index: any) => {
          return (
            <div className="careers__card" key={index}>
              <AWSImage
                pngFile={item.img}
                picClass={item.picClass}
                imageAlt={item.imgAlt}
                documentPrefix={item.awsPrefix}
                hasWebp
              />
              {/* <Picture webp={item.imgWebp} src={item.img} alt={item.imgAlt} picClass="careers__card-img" /> */}
              <div className="careers__card-content-wrapper">
                <h3 className="careers__card-heading">{item.careerHeading}</h3>
                <p className="careers__card-content">{item.careerContent}</p>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Careers;
