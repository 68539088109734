import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../GuessTheWord";
import Confetti from "react-dom-confetti";

function GameOver() {
  const {
    boardDefault,
    setBoard,
    currAttempt,
    setCurrAttempt,
    gameOver,
    onSelectLetter,
    correctWord,
    onDelete,
    setDisabledLetters,
    setGameOver,
    handleGameOver,
  } = useContext(AppContext);

  const [playConfetti, setPlayConfetti] = useState(false);

  useEffect(() => {
    if (gameOver.guessedWord) {
      setPlayConfetti(true);
    }
  }, [gameOver.guessedWord]);

  const handlePlayAgain = () => {
    // Call handleGameOver instead of setGameOver
    handleGameOver({ gameOver: false, guessedWord: false });
    setPlayConfetti(false);
  };

  const confettiConfig = {
    angle: 90,
    spread: 360,
    startVelocity: 45,
    elementCount: 50,
    dragFriction: 0.1,
    duration: 3000,
    stagger: 0,
    width: "10px",
    height: "10px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  };

  return (
    <div className="gameOver">
      <h3>
        {gameOver.guessedWord
          ? "You Correctly Guessed the Word!"
          : "You Didn't Get It This Time."}
      </h3>
      <button className="play-again-button gameOver-refresh" onClick={handlePlayAgain}>
        Play Again
      </button>
      <h1>Correct Word: {correctWord}</h1>
      {gameOver.guessedWord && (
        <h3>You guessed the word in {currAttempt.attempt} attempts</h3>
      )}
      <Confetti active={playConfetti} config={confettiConfig} />
    </div>
  );
}

export default GameOver;
