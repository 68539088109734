import React from 'react';
import { AdvancedLearningInfo } from '../../Models';
import Picture from '../Partials/Picture';
import src from '../../assets/home/mins/ipad-tutoring.png';
import webp from '../../assets/home/webp/ipad-tutoring.webp';

const AdvancedLearning = () => {
  return (
    <section className="advancedLearning">
      <div className="advancedLearning__content-wrapper">
        <div className="advancedLearning__desktop-left-side">
          <h4 className="advancedLearning__header">Advanced e-learning with lesson space</h4>
          <p className="advancedLearning__sub-header">
            Our online whiteboard provides the closest experience there is to an in-person tutoring lesson.
          </p>
          <div className="advancedLearning__benefits">
            {AdvancedLearningInfo.map((item, index) => {
              return (
                <div className="advancedLearning__benefit" key={index}>
                  <i className="fa fa-check advancedLearning__benefit-icon"></i>
                  <p className="advancedLearning__benefit-text">{item}</p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="advancedLearning__img-wrapper">
          <Picture src={src} webp={webp} picClass="advancedLearning__img" alt="ipad tutoring" />
        </div>
      </div>
    </section>
  );
};

export default AdvancedLearning;
