import { useContext } from 'react';
import { ResourceContext } from '../Context/ResourceFilterContext';
import { ResourceContextValues, UpdatedFilterOptions, grades, subjectListArray, Resources, docTypes } from '../Models';

export const useResourceFilters = () => {
  const { resourceData, setResourceData } = useContext(ResourceContext);

  const SetFilterOptions = (filters: UpdatedFilterOptions) => {
    setResourceData((prev: ResourceContextValues) => ({
      ...prev,
      filters: { ...prev.filters, ...filters },
    }));
  };

  const UpdateFilterOptions = (
    { entryKey, entryValue }: { entryKey: string; entryValue: string },
    resetSubcategory?: boolean,
  ) => {
    setResourceData((prev: ResourceContextValues) => ({
      ...prev,
      filters: {
        ...prev.filters,
        [entryKey]: entryValue,
        subcategory: resetSubcategory ? undefined : prev.filters.subcategory,
      },
    }));
  };

  const clearFilters = (key: string) => {
    setResourceData((prev: ResourceContextValues) => ({
      ...prev,
      filters: {
        ...prev.filters,
        [key]: undefined,
      },
    }));
  };

  const FetchDocuments = () => {
    setResourceData((prev: ResourceContextValues) => ({
      ...prev,
    }));
  };

  const FilterAllDocumentsBySubcategory = (filters: UpdatedFilterOptions, value: string) => {
    let filteredDocuments: Resources[] = [];

    let willPass = true;

    for (let i = 0; i < resourceData.documents.length; i++) {
      willPass = true;

      if (resourceData.documents[i].type !== filters.docType) {
        willPass = false;
      }

      if (filters.grade !== undefined && !resourceData.documents[i].grade.includes(filters.grade)) {
        willPass = false;
      }

      if (filters.subject !== undefined && !resourceData.documents[i].subject.includes(filters.subject)) {
        willPass = false;
      }

      if (
        (filters.subcategory !== undefined &&
          !resourceData.documents[i].subcategories?.includes(filters.subcategory)) ||
        resourceData.documents[i].subcategories?.includes(value) === false
      ) {
        willPass = false;
      }

      willPass && filteredDocuments.push(resourceData.documents[i]);
    }

    return filteredDocuments;
  };

  const filterDocumentsBySubcategory = (filters: UpdatedFilterOptions, value: string) => {
    let filteredDocuments: Resources[] = [];

    let willPass = true;

    for (let i = 0; i < resourceData.documents.length; i++) {
      willPass = true;

      if (resourceData.documents[i].type !== filters.docType) {
        willPass = false;
      }

      if (filters.grade !== undefined && !resourceData.documents[i].grade.includes(filters.grade)) {
        willPass = false;
      }

      if (filters.subject !== undefined && !resourceData.documents[i].subject.includes(filters.subject)) {
        willPass = false;
      }

      if (
        (filters.subcategory !== undefined &&
          !resourceData.documents[i].subcategories?.includes(filters.subcategory)) ||
        resourceData.documents[i].subcategories?.includes(value) === false
      ) {
        willPass = false;
      }

      willPass && filteredDocuments.push(resourceData.documents[i]);

      if (filteredDocuments.length >= 6) {
        break;
      }
    }

    return filteredDocuments;
  };

  const findSixDocumentsByFilter = (filters: UpdatedFilterOptions, isSubject?: boolean, value?: string) => {
    let filteredDocuments: Resources[] = [];

    //we know that the subject is available in filters...
    const targetArray = resourceData.documents;

    for (let i = 0; i < targetArray.length; i++) {
      //see if that value matches filters
      let isValid = true;
      if (targetArray[i].type !== filters.docType) {
        isValid = false;
      }

      if (
        (filters.grade && targetArray[i].grade.includes(filters.grade) === false) ||
        (!isSubject && value && targetArray[i].grade.includes(value) === false)
      ) {
        isValid = false;
      }

      if (filters.subject && targetArray[i].subject.includes(filters.subject) === false) {
        isValid = false;
      }

      if (filters.subcategory && targetArray[i].subcategories?.includes(filters.subcategory) === false) {
        isValid = false;
      }
      //pass the value into filteredDocuments
      isValid && filteredDocuments.push(targetArray[i]);
      if (filteredDocuments.length >= 6) {
        break;
      }
    }

    return filteredDocuments;
  };

  const FetchRowDocuments = ({
    key,
    value,
    current_filters,
  }: {
    key: keyof UpdatedFilterOptions;
    value: string;
    current_filters?: UpdatedFilterOptions;
  }) => {
    let returnArray: Resources[] = [];

    if (key === 'subject' || key === 'grade') {
      returnArray = findSixDocumentsByFilter(
        current_filters ? current_filters : resourceData.filters,
        key === 'subject' ? true : false,
        value,
      );
    }

    if (key === 'subcategory') {
      returnArray = filterDocumentsBySubcategory(current_filters ? current_filters : resourceData.filters, value);
    }

    return returnArray;
  };

  const AllDocsForFilters = (filters: UpdatedFilterOptions, searchText?: string) => {
    let filtered_documents = resourceData.documents.filter((singleDocument) => {
      let willPass = true;

      if (singleDocument.type.toLowerCase() !== filters.docType.toLowerCase()) {
        willPass = false;
      }

      if (
        filters.grade !== undefined &&
        !singleDocument.grade.map((mappedGrade) => mappedGrade.toLowerCase()).includes(filters.grade.toLowerCase())
      ) {
        willPass = false;
      }

      if (
        filters.subject !== undefined &&
        !singleDocument.subject.map((subject) => subject.toLowerCase()).includes(filters.subject.toLowerCase())
      ) {
        willPass = false;
      }

      if (
        filters.subcategory !== undefined &&
        !singleDocument.subcategories?.map((subcat) => subcat.toLowerCase()).includes(filters.subcategory.toLowerCase())
      ) {
        willPass = false;
      }

      return willPass;
    });
    if (searchText && searchText !== '') {
      filtered_documents = filtered_documents.filter(
        (doc) =>
          doc.title.toLowerCase().includes(searchText) ||
          doc.description.toLowerCase().includes(searchText) ||
          doc.tags?.toString().toLowerCase().includes(searchText) ||
          doc.subject.toString().toLowerCase().includes(searchText) ||
          doc.subcategories?.toString().toLowerCase().includes(searchText),
      );
    }
    return filtered_documents;
  };

  //Hook to Find new Row Identifyer
  const ReturnRowIdentifier = () => {
    let newRowIdentifier = 'none';
    if (
      resourceData.filters.grade === undefined &&
      resourceData.filters.subject === undefined &&
      resourceData.filters.subcategory === undefined
    ) {
      newRowIdentifier = 'subject';
    } else if (
      resourceData.filters.grade !== undefined &&
      resourceData.filters.subject === undefined &&
      resourceData.filters.subcategory === undefined
    ) {
      newRowIdentifier = 'subject';
    } else if (
      (resourceData.filters.grade === undefined &&
        resourceData.filters.subject !== undefined &&
        resourceData.filters.subcategory === undefined) ||
      (resourceData.filters.grade === undefined &&
        resourceData.filters.subject !== undefined &&
        resourceData.filters.subcategory !== undefined)
    ) {
      newRowIdentifier = 'grade';
    } else if (
      resourceData.filters.grade !== undefined &&
      resourceData.filters.subject !== undefined &&
      resourceData.filters.subcategory === undefined
    ) {
      newRowIdentifier = 'subcategory';
    }
    return newRowIdentifier;
  };

  //Hook to create Array of rows considering the row identifier value (that will be passed into the hook.)
  const FetchResourceRows = (identifier: string) => {
    let rows: string[] | string[][] = [];
    if (identifier === 'subject') {
      rows = subjectListArray.map((subject) => subject.value);
    } else if (identifier === 'grade') {
      rows = grades.map((grade) => grade.value);
    } else if (identifier === 'subcategory') {
      rows = resourceData.documents.map((doc) => {
        return doc.subcategories ? doc.subcategories : [];
      });
    }
    return Array.from(new Set(rows.flat()));
  };

  const FetchRelevantSubcategories = (subject?: string) => {
    let documents = resourceData.documents.map((singleDoc) => {
      return singleDoc.subject.includes(subject || '') && singleDoc.subcategories !== undefined
        ? singleDoc.subcategories
        : [];
    });

    return Array.from(new Set(documents.flat()));
  };

  const FetchRelevantSubjectBySubcategory = (subcategory: string) => {
    const docWithSubject = resourceData.documents.find((singleDoc) =>
      singleDoc.subcategories?.map((subcat) => subcat.toLowerCase()).includes(subcategory.toLowerCase()),
    );
    return docWithSubject ? docWithSubject.subject[0] : undefined;
  };

  return {
    resourceData,
    UpdateFilterOptions,
    clearFilters,
    FetchDocuments,
    SetFilterOptions,
    FetchRowDocuments,
    ReturnRowIdentifier,
    FetchResourceRows,
    FetchRelevantSubcategories,
    AllDocsForFilters,
    FilterAllDocumentsBySubcategory,
    FetchRelevantSubjectBySubcategory,
  };
};
