import React, { useMemo } from 'react';
import Helmet from 'react-helmet';
import { generateID } from '../Utils';

type MetaDataProps = {
  pageTitle: string;
  postTitle: string;
  description: string;
  image: any; //should be square and 600 pixels (non-webp)
  url: string;
  keywords: string[];
  canonical?: string;
};

const MetaData: React.FC<MetaDataProps> = ({ pageTitle, postTitle, description, image, url, keywords, canonical }) => {
  return (
    <React.Fragment key={generateID()}>
      <Helmet>
        {/* <!-- HTML Meta Tags --> */}

        <title>{pageTitle}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords.join(',')} />
        {canonical && <link rel="canonical" href={canonical} />}

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemProp="name" content={postTitle} />
        <meta itemProp="description" content={description} />
        <meta itemProp="image" content={image} />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={postTitle} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@NoFussTutors" />
        <meta name="twitter:title" content={postTitle} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
      </Helmet>
    </React.Fragment>
  );
};

export default MetaData;
