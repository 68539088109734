import React from 'react';
import { Link } from 'react-router-dom';
import { DateOptions } from '../../Models';
import AWSImage from '../Partials/AWSImage';

type BlogCardProps = {
  link: string;
  src: any;
  alt: string;
  date: string;
  title: string;
};

const BlogCard: React.FC<BlogCardProps> = ({ link, src, alt, date, title }) => {
  return (
    <div className="blog-page__blog-card-wrapper">
      <Link className="blog-page__blog-card-link" to={link} aria-label={title}>
        <article className="blog-page__card-body">
          <AWSImage
            pngFile={src}
            imageAlt={alt}
            documentPrefix={'blog/cards'}
            picClass="blog-page__blog-card-img-wrapper"
            hasWebp
          />
          <div className="blog-page__blog-card-info">
            <span className="blog-page__blog-card-date">{new Date(date!).toLocaleString('en-US', DateOptions)}</span>
            <h2 className="blog-page__blog-card-title">{title}</h2>
          </div>
        </article>
      </Link>
    </div>
  );
};

export default BlogCard;
