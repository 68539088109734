export const FooterNavOptions1 = [
  { label: 'Subjects', link: '#subjects' },
  { label: 'Pricing', link: '#pricing' },
  { label: 'Locations', link: '#locations' }
];

export const FooterNavOptions2 = [
  { label: 'Blog', link: 'https://nofusstutors.com/blog' },
  { label: 'Worksheets', link: 'https://nofusstutors.com/worksheets' },
];

export const FooterNavOptions3 = [
  { label: 'Terms & Conditions', link: 'https://nofusstutors.com/terms-of-service' },
  { label: 'Privacy Policy', link: 'https://nofusstutors.com/privacy-policy' },
];

export const AllPhoneNumbers = [
  { label: 'TO: (647) 696-8289', link: 'tel:+6476968289' },
  { label: 'NY: (917) 905-3402', link: 'tel:+9179053402' },
  { label: 'CA: (323) 645-2793', link: 'tel:+3236452793' },
  { label: 'FLA: (305) 376-7679', link: 'tel:+3053767679' },
];
