import React from 'react';

type ColorPaletteProps = {
  colors: string[];
  activeColor: string;
  onSelectColor: (color: string) => void;
};

export const ColorPalette: React.FC<ColorPaletteProps> = ({ colors, activeColor, onSelectColor }) => {
  const handleColorChange = (color: string) => {
    onSelectColor(color);
  };
  return (
    <div className="color-grid" style={{ display: 'inline-flex' }}>
      {colors.map((c) => (
        <div key={c} className="color-square" style={{ backgroundColor: c }} onClick={() => handleColorChange(c)} />
      ))}
    </div>
  );
};
