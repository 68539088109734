import React from 'react';
import { FacebookShareButton, TwitterShareButton, PinterestShareButton } from 'react-share';
import TwitterIcon from '../../assets/twitter.png';
import FacebookIcon from '../../assets/facebook.png';

type ShareProps = {
  shareUrl: string;
  facebookQuote: string;
  imagePreviewString?: string;
};

const ShareIcons: React.FC<ShareProps> = ({ shareUrl, facebookQuote, imagePreviewString }) => {
  return (
    <div className="sharing">
      <FacebookShareButton url={shareUrl} quote={facebookQuote}>
        <div className="icon-container">
          <img src={FacebookIcon} alt="Share with Facebook" className="icon" loading="lazy" />
        </div>
      </FacebookShareButton>
      <TwitterShareButton url={shareUrl}>
        <div className="icon-container">
          <img src={TwitterIcon} alt="Share with Twitter" className="icon" loading="lazy" />
        </div>
      </TwitterShareButton>
    </div>
  );
};

export default ShareIcons;
