import React, { useEffect, useState, useRef } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Banner from '../Components/Partials/Banner';
import SignInModal from '../Components/SignInModal';
import BackToPage from '../Components/Partials/BackToPage';
import { useDisplayData } from '../Hooks/useDisplayData';
import { useResourceFilters } from '../Hooks/useResourceFilter';
import { ErrorProps } from '../Models';
import { GetBaseURL, encrypt, ValidateEmail, SendEmail, generatedRoute, generatedLocation } from '../Utils';
import { GetDecryptedEmail } from '../Utils/GetDecryptedEmail';

const ResetPassword: React.FC = () => {
  const { displayData, hideModal, startBodyScroll } = useDisplayData();
  const { resourceData } = useResourceFilters();
  const [cookies] = useCookies(['email']);
  const [email, setEmail] = useState('initial');
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<ErrorProps>({
    isActive: false,
    message: '',
    type: 'error',
  });

  useEffect(() => {
    setEmail(GetDecryptedEmail(cookies.email));
  }, []);

  const resetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    if (emailRef.current && passwordRef.current && confirmPasswordRef.current) {
      if (emailRef.current.value === email) {
        if (passwordRef.current.value !== '' && passwordRef.current.value === confirmPasswordRef.current.value) {
          const request = await fetch(`${GetBaseURL()}/api/users/resetPassword?key=${process.env.REACT_APP_API_KEY}`, {
            method: 'POST',
            body: JSON.stringify({ email: email, password: encrypt(passwordRef.current.value) }),
            headers: {
              'Content-Type': 'application/json',
            },
          });
          const res = await request.json();
          if (request.status === 200) {
            setError({ isActive: true, message: res.message, type: 'success' });
            const now = new Date(Date.now());
            await SendEmail(
              `Your No Fuss Tutors account password was changed on ${now.toLocaleDateString('en-US', {
                weekday: 'short',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })} at ${now.toLocaleTimeString(
                'en-US',
              )}. If this was not you, please contact Ruth. If you did change your password at the aforementioned date and time, then please ignore this email. Have a great day!`,
              email,
              `NFT Password Change Confirmation`,
            );
          } else {
            setError({ isActive: true, message: res.errorMessage, type: 'error' });
          }
        } else if (
          passwordRef.current.value !== '' &&
          confirmPasswordRef.current.value !== '' &&
          passwordRef.current.value !== confirmPasswordRef.current.value
        ) {
          setError({ isActive: true, message: 'Passwords must match.', type: 'error' });
        } else {
          setError({
            isActive: true,
            message: 'Please enter a password and confirm it.',
            type: 'error',
          });
        }
      } else if (emailRef.current.value === '' || !ValidateEmail(emailRef.current.value)) {
        setError({
          isActive: true,
          message: 'Please enter a valid email.',
          type: 'error',
        });
      } else {
        setError({
          isActive: true,
          message: "You do not have the authorization to change the password of the given email's account.",
          type: 'error',
        });
      }
    } else {
      setError({ isActive: true, message: 'Something went wrong. Please try again later.', type: 'error' });
    }
  };

  const resetError = () => {
    setError({
      isActive: false,
      message: '',
      type: 'error',
    });
  };

  return email !== '' ? (
    <main className="reset-password">
      <div
        className={displayData.showModal ? 'overlay' : 'transparent'}
        onClick={() => {
          hideModal();
          startBodyScroll();
        }}
      ></div>
      <Banner lineOne={'Reset Password'} main imageName="worksheets_banner" alt="No Fuss Tutors banner" />

      <BackToPage
        route={`/${generatedRoute({ ...resourceData.filters })}`}
        location={generatedLocation(generatedRoute({ ...resourceData.filters }))}
      />

      <span
        data-cy="resetPassword__span--resetPasswordError"
        className={error.type === 'error' ? 'error-span' : 'success-span'}
        onClick={resetError}
      >
        {error.message + ' '}
        {error.message !== '' && (
          <a
            onClick={() => {
              window.location.reload();
            }}
          >
            <Link to="/">Return to homepage</Link>
          </a>
        )}
      </span>
      <div className="reset-password__content-wrapper">
        <div className="reset-password__heading-wrapper">
          <h2 className="reset-password__heading">Do you want to update your password?</h2>
          <p className="reset-password__sub-heading">Enter your email and your desired new password.</p>
        </div>
        <form className="reset-password__reset-form" onSubmit={(e) => resetPassword(e)}>
          <input
            data-cy="resetPassword__input--email"
            ref={emailRef}
            type="email"
            name="email"
            placeholder="Confirm email"
            aria-label="email"
            className="reset-password__input"
          />
          <input
            data-cy="resetPassword__input--password"
            ref={passwordRef}
            type="password"
            name="password"
            placeholder="New password"
            aria-label="password"
            className="reset-password__input"
          />
          <input
            data-cy="resetPassword__input--confirmPassword"
            ref={confirmPasswordRef}
            type="password"
            name="password"
            placeholder="Confirm new password"
            aria-label="password"
            className="reset-password__input"
          />
          <button data-cy="resetPassword__button--update" type="submit" className="reset-password__update-button">
            Update
          </button>
          <Link to="/account" className="reset-password__return-to-account">
            Return To Account Settings
          </Link>
        </form>
      </div>
      {displayData.showModal && <SignInModal HideModal={hideModal} />}
    </main>
  ) : (
    <Redirect to="/worksheets-for-kids" />
  );
};

export default ResetPassword;
