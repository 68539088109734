// ! program cards
export const TutoringProgramCards = [
  {
    cardHeading: 'Spelling & Grammar',
    icon: 'spellingIcon.png',
    awsPrefix: 'homepage/writing',
    picClass: 'tutoring-program__card-img',
  },
  {
    cardHeading: 'Paragraph Development',
    icon: 'paragraphIcon.png',
    awsPrefix: 'homepage/writing',
    picClass: 'tutoring-program__card-img',
  },
  {
    cardHeading: 'Punctuation',
    icon: 'punctuationIcon.png',
    awsPrefix: 'homepage/writing',
    picClass: 'tutoring-program__card-img',
  },
  {
    cardHeading: 'Sentence Structure',
    icon: 'sentenceIcon.png',
    awsPrefix: 'homepage/writing',
    picClass: 'tutoring-program__card-img',
  },
  {
    cardHeading: 'Writing Mechanics',
    icon: 'writingIcon.png',
    awsPrefix: 'homepage/writing',
    picClass: 'tutoring-program__card-img',
  },
  {
    cardHeading: 'Grammar',
    icon: 'advancedgrammarIcon.png',
    awsPrefix: 'homepage/writing',
    picClass: 'tutoring-program__card-img',
  },
];
export const TutoringProgramCardsHeader = {
  heading: 'Beginner Tutoring Program',
  subHeading: 'Take the battle out of writing',
  headingContent:
    'Children of all abilities struggle with writing. Some children will learn to write with ease while others need the one-on-one attention of a tutor to develop an understanding of basic concepts, build specific skills, and gain confidence. We can help with topics like:',
};

// ! advanced program cards
export const AdvancedTutoringProgramCards = [
  {
    cardHeading: 'Academic Essays',
    icon: 'essaysIcon.png',
    awsPrefix: 'homepage/writing',
    picClass: 'tutoring-program__card-img',
  },
  {
    cardHeading: 'Creative Writing',
    icon: 'creativeIcon.png',
    awsPrefix: 'homepage/writing',
    picClass: 'tutoring-program__card-img',
  },
  {
    cardHeading: 'Research Papers',
    icon: 'researchIcon.png',
    awsPrefix: 'homepage/writing',
    picClass: 'tutoring-program__card-img',
  },
  {
    cardHeading: 'Writing Assignments',
    icon: 'assignmentIcon.png',
    awsPrefix: 'homepage/writing',
    picClass: 'tutoring-program__card-img',
  },
  {
    cardHeading: 'Editing / Proofreading',
    icon: 'editingIcon.png',
    awsPrefix: 'homepage/writing',
    picClass: 'tutoring-program__card-img',
  },
  {
    cardHeading: 'Outlining',
    icon: 'outliningIcon.png',
    awsPrefix: 'homepage/writing',
    picClass: 'tutoring-program__card-img',
  },
];
export const AdvancedTutoringProgramCardsHeader = {
  heading: 'Advanced Tutoring Program',
  subHeading: 'Get ahead in your class',
  headingContent:
    'Writing tutoring will help students build the research-based writing skills needed for high school and college success. We can help with topics like:',
};

// ! beginner programs
export const BeginnerProgramsInfo = [
  {
    img: 'writing2.jpg',
    awsPrefix: 'homepage/writing',
    alt: 'young student studying next to computer',
    tag: 'JK - Grade 3',
    headingOne: 'Clearly organize and convey thoughts',
    contentOne: `Your child deserves to be reading at the same level as their peers which is why we are committed to your child’s success. We will always do our best to ensure your child achieves their goals.`,
    headingTwo: 'Find the fun in writing',
    contentTwo: `Your child deserves to be reading at the same level as their peers which is why we are committed to your child’s success. We will always do our best to ensure your child achieves their goals.`,
    className: 'programs__jk-grade-3',
    tagClassName: 'programs__tag programs__tag--jk-grade-3',
  },

  {
    img: 'writing3.jpg',
    awsPrefix: 'homepage/writing',
    alt: 'young student studying on computer',
    tag: 'Grade 4 - 8',
    headingOne: 'Craft compelling paragraphs',
    contentOne:
      'Your child deserves to be reading at the same level as their peers which is why we are committed to your child’s success. We will always do our best to ensure your child achieves their goals.',
    headingTwo: 'Gain Confidence in Writing',
    contentTwo:
      'Your child deserves to be reading at the same level as their peers which is why we are committed to your child’s success. We will always do our best to ensure your child achieves their goals.',
    className: 'programs__grade-4',
    tagClassName: 'programs__tag programs__tag--grade-4',
  },
];

export const BeginnerProgramsInfoHeader = {
  header: 'With our beginner writing programs, your child will:',
};

// ! advanced programs
export const AdvancedProgramsInfo = [
  {
    img: 'writing4.jpg',
    awsPrefix: 'homepage/writing',
    alt: 'grade 9 student studying in front of computer',
    tag: 'Grade 9 - 12',
    headingOne: 'Become a better test taker',
    contentOne:
      'Your child deserves to be reading at the same level as their peers which is why we are committed to your child’s success. We will always do our best to ensure your child achieves their goals.',
    headingTwo: 'Be prepared for the future',
    contentTwo:
      'Your child deserves to be reading at the same level as their peers which is why we are committed to your child’s success. We will always do our best to ensure your child achieves their goals.',
    className: 'programs__grade-9',
    tagClassName: 'programs__tag programs__tag--grade-9',
  },
];
export const AdvancedProgramsInfoHeader = {
  header: 'With our advanced writing programs, your child will:',
};
