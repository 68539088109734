import React, { useState, useEffect, useRef, useCallback } from 'react';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

interface Obstacle {
  x: number;
  y: number;
  width: number;
  height: number;
  isPassed: boolean;
  obstacleImage: number;
}
interface secondCar {
  x: number;
  y: number;
  width: number;
  height: number;
  speed: number;
  rotation: number;
}

interface Car {
  x: number;
  y: number;
  width: number;
  height: number;
  speed: number;
  rotation: number;
}

type Option = {
  value: number;
  label: string;
};

interface Question {
  question: string;
  answer: number;
  options: Option[];
}

const questions: Question[] = [
  {
    question: '2 x 3',
    answer: 6,
    options: [
      { value: 4, label: '4' },
      { value: 6, label: '6' },
      { value: 8, label: '8' },
      { value: 10, label: '10' },
    ],
  },
  {
    question: '5 x 4',
    answer: 20,
    options: [
      { value: 12, label: '12' },
      { value: 16, label: '16' },
      { value: 20, label: '20' },
      { value: 24, label: '24' },
    ],
  },
  {
    question: '3 x 4',
    answer: 12,
    options: [
      { value: 8, label: '8' },
      { value: 10, label: '10' },
      { value: 12, label: '12' },
      { value: 14, label: '14' },
    ],
  },

  {
    question: '7 x 8',
    answer: 56,
    options: [
      { value: 48, label: '48' },
      { value: 52, label: '52' },
      { value: 56, label: '56' },
      { value: 60, label: '60' },
    ],
  },
  {
    question: '9 x 9',
    answer: 81,
    options: [
      { value: 72, label: '72' },
      { value: 81, label: '81' },
      { value: 90, label: '90' },
      { value: 99, label: '99' },
    ],
  },
  {
    question: '6 x 6',
    answer: 36,
    options: [
      { value: 30, label: '30' },
      { value: 36, label: '36' },
      { value: 42, label: '42' },
      { value: 48, label: '48' },
    ],
  },
  {
    question: '10 x 10',
    answer: 100,
    options: [
      { value: 90, label: '90' },
      { value: 100, label: '100' },
      { value: 110, label: '110' },
      { value: 120, label: '120' },
    ],
  },
  {
    question: '4 x 7',
    answer: 28,
    options: [
      { value: 20, label: '20' },
      { value: 24, label: '24' },
      { value: 28, label: '28' },
      { value: 32, label: '32' },
    ],
  },
  {
    question: '8 x 5',
    answer: 40,
    options: [
      { value: 32, label: '32' },
      { value: 36, label: '36' },
      { value: 40, label: '40' },
      { value: 44, label: '44' },
    ],
  },
  {
    question: '6 x 3',
    answer: 18,
    options: [
      { value: 18, label: '18' },
      { value: 24, label: '24' },
      { value: 15, label: '15' },
      { value: 30, label: '30' },
    ],
  },
  {
    question: '6 x 9',
    answer: 54,
    options: [
      { value: 40, label: '40' },
      { value: 45, label: '45' },
      { value: 54, label: '54' },
      { value: 56, label: '56' },
    ],
  },
  {
    question: '7 x 4',
    answer: 28,
    options: [
      { value: 20, label: '20' },
      { value: 24, label: '24' },
      { value: 28, label: '28' },
      { value: 32, label: '32' },
    ],
  },
  {
    question: '7 x 3',
    answer: 21,
    options: [
      { value: 18, label: '18' },
      { value: 21, label: '21' },
      { value: 22, label: '22' },
      { value: 24, label: '24' },
    ],
  },
  {
    question: '6 x 9',
    answer: 54,
    options: [
      { value: 42, label: '42' },
      { value: 48, label: '48' },

      { value: 60, label: '60' },
      { value: 54, label: '54' },
    ],
  },
  {
    question: '8 x 6',
    answer: 48,
    options: [
      { value: 38, label: '38' },
      { value: 48, label: '48' },
      { value: 44, label: '44' },

      { value: 52, label: '52' },
    ],
  },
  {
    question: '6 x 8',
    answer: 48,
    options: [
      { value: 48, label: '48' },
      { value: 49, label: '49' },
      { value: 42, label: '42' },

      { value: 56, label: '56' },
    ],
  },
  {
    question: '9 x 8',
    answer: 72,
    options: [
      { value: 62, label: '62' },
      { value: 66, label: '66' },
      { value: 72, label: '72' },
      { value: 76, label: '76' },
    ],
  },
  {
    question: '7 x 7',
    answer: 49,
    options: [
      { value: 49, label: '49' },
      { value: 40, label: '40' },
      { value: 45, label: '45' },

      { value: 55, label: '55' },
    ],
  },
  {
    question: '9 x 5',
    answer: 45,
    options: [
      { value: 40, label: '40' },
      { value: 50, label: '50' },
      { value: 45, label: '45' },
      { value: 54, label: '54' },
    ],
  },
  {
    question: '8 x 4',
    answer: 32,
    options: [
      { value: 24, label: '24' },
      { value: 36, label: '36' },
      { value: 40, label: '40' },
      { value: 32, label: '32' },
    ],
  },
  {
    question: '3 x 8',
    answer: 24,
    options: [
      { value: 21, label: '21' },
      { value: 32, label: '32' },
      { value: 16, label: '16' },
      { value: 24, label: '24' },
    ],
  },
  {
    question: '4 x 4',
    answer: 16,
    options: [
      { value: 10, label: '10' },
      { value: 12, label: '12' },

      { value: 18, label: '18' },
      { value: 16, label: '16' },
    ],
  },
  {
    question: '5 x 5',
    answer: 25,
    options: [
      { value: 20, label: '20' },
      { value: 22, label: '22' },
      { value: 25, label: '25' },
      { value: 28, label: '28' },
    ],
  },
  {
    question: '6 x 6',
    answer: 36,
    options: [
      { value: 30, label: '30' },
      { value: 36, label: '36' },
      { value: 40, label: '40' },
      { value: 42, label: '42' },
    ],
  },
  {
    question: '7 x 6',
    answer: 42,
    options: [
      { value: 42, label: '42' },
      { value: 34, label: '34' },
      { value: 38, label: '38' },

      { value: 46, label: '46' },
    ],
  },
  {
    question: '8 x 7',
    answer: 56,
    options: [
      { value: 45, label: '45' },
      { value: 56, label: '56' },
      { value: 49, label: '49' },

      { value: 62, label: '62' },
    ],
  },
  {
    question: '9 x 6',
    answer: 54,
    options: [
      { value: 42, label: '42' },
      { value: 48, label: '48' },
      { value: 54, label: '54' },
      { value: 60, label: '60' },
    ],
  },
  {
    question: '6 x 4',
    answer: 24,
    options: [
      { value: 24, label: '24' },
      { value: 18, label: '18' },
      { value: 27, label: '27' },
      { value: 12, label: '12' },
    ],
  },
  {
    question: '3 x 9',
    answer: 27,
    options: [
      { value: 27, label: '27' },
      { value: 18, label: '18' },
      { value: 36, label: '36' },
      { value: 24, label: '24' },
    ],
  },
  {
    question: '8 x 8',
    answer: 64,
    options: [
      { value: 64, label: '64' },
      { value: 56, label: '56' },
      { value: 63, label: '63' },
      { value: 72, label: '72' },
    ],
  },
  {
    question: '9 x 4',
    answer: 36,
    options: [
      { value: 54, label: '54' },
      { value: 27, label: '27' },
      { value: 36, label: '36' },
      { value: 24, label: '24' },
    ],
  },
  {
    question: '9 x 7',
    answer: 63,
    options: [
      { value: 63, label: '63' },
      { value: 56, label: '56' },
      { value: 64, label: '64' },
      { value: 54, label: '54' },
    ],
  },
  {
    question: '3 x 9',
    answer: 27,
    options: [
      { value: 35, label: '35' },
      { value: 18, label: '18' },
      { value: 36, label: '36' },
      { value: 27, label: '27' },
    ],
  },
];

function RacingGame() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [playerCar, setPlayerCar] = useState<Car>({
    x: 375,
    y: 450,
    width: 70,
    height: 125,
    speed: 0,
    rotation: 0,
  });
  const [score, setScore] = useState(0);
  const [speed, setSpeed] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState<Question>(questions[0]);
  const [playerAnswer, setPlayerAnswer] = useState<number>();
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [incorrectOption, setIncorrectOption] = useState<Option | null>(null);

  const [carImage, setCarImage] = useState<HTMLImageElement>();
  const [secondCarImage, setSecondCarImage] = useState<HTMLImageElement>();

  const [correctOption, setCorrectOption] = useState<Option | null>(null);
  const [isLeftPressed, setIsLeftPressed] = useState(false);
  const [isRightPressed, setIsRightPressed] = useState(false);
  const [roadPosition, setRoadPosition] = useState(0);
  const [obstacles, setObstacles] = useState<Obstacle[]>([]);
  const [isGameOver, setIsGameOver] = useState(false);
  const [isWin, setIsWin] = useState(false);
  const [direction, setDirection] = useState<string | null>(null);

  const leftButtonPressed = useRef(false);
  const rightButtonPressed = useRef(false);
  const [stopAnimation, setStopAnimation] = useState(false);
  const animationFrameId = useRef<number | null>(null);
  const [isGameStarted, setIsGameStarted] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [distance, setDistance] = useState(0);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [firstQuestionAnswered, setFirstQuestionAnswered] = useState(false);

  const [secondCar, setSecondCar] = useState<secondCar>({
    x: 375,
    y: 100,
    width: 70,
    height: 110,
    speed: 0,
    rotation: 0,
    // Initialize other properties as needed
  });

  useEffect(() => {
    let timerId: NodeJS.Timeout;

    if (isGameStarted) {
      setSecondCar((prevSecondCar) => ({ ...prevSecondCar, speed: 20 }));

      timerId = setInterval(() => {
        setSecondCar((prevSecondCar) => ({ ...prevSecondCar, speed: prevSecondCar.speed + 1 }));
      }, 10000);
    }

    return () => clearInterval(timerId);
  }, [isGameStarted]);

  const obstacleImages = [
    {
      src: 'https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/car3.png',
      width: 60,
      height: 100,
      weight: 1,
    },
    {
      src: 'https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/car4.png',
      width: 60,
      height: 120,
      weight: 1,
    },
    {
      src: 'https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/car5.png',
      width: 70,
      height: 140,
      weight: 1,
    },
    {
      src: 'https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/car6.png',
      width: 80,
      height: 160,
      weight: 1,
    },
    {
      src: 'https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/truck.png',
      width: 95,
      height: 180,
      weight: 0.5,
    },
    {
      src: 'https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/limo.png',
      width: 80,
      height: 240,
      weight: 0.5,
    },
    {
      src: 'https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/bus.png',
      width: 110,
      height: 300,
      weight: 0.25,
    },
  ];

  const loadedObstacleImages = [];

  function useObstacleImages(): HTMLImageElement[] {
    const [images, setImages] = useState<HTMLImageElement[]>([]);

    useEffect(() => {
      const imagePromises = obstacleImages.map((imageData) => {
        return new Promise<HTMLImageElement>((resolve) => {
          const image = new Image();
          image.src = imageData.src;
          image.onload = () => resolve(image);
        });
      });

      Promise.all(imagePromises).then((loadedImages) => {
        setImages(loadedImages);
      });
    }, []);

    return images;
  }

  let distanceTraveled = 0;

  // Update the stopMovingLeft and stopMovingRight functions

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!isGameOver && !isWin) {
        setSecondCar((prevCar) => ({
          ...prevCar,
          y: prevCar.y - prevCar.speed,
        }));
        setPlayerCar((prevCar) => ({
          ...prevCar,
          y: prevCar.y - speed / 3, // Move firstCar closer to secondCar
        }));
      }
    }, 50);

    return () => clearInterval(intervalId);
  }, [isGameOver, speed, isWin]);

  function generateObstacle(playerCar: Car, canvas: HTMLCanvasElement) {
    const roadX = canvas.width * 0.1;
    const roadWidth = canvas.width * 0.8;
    const obstacleWidth = 40;
    const obstacleHeight = 97;

    const images = [
      {
        url: 'https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/car3.png',
        width: 50,
        height: 80,
        weight: 3,
      },
      {
        url: 'https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/car4.png',
        width: 50,
        height: 80,
        weight: 3,
      },
      {
        url: 'https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/car5.png',
        width: 50,
        height: 80,
        weight: 3,
      },
      {
        url: 'https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/car6.png',
        width: 50,
        height: 87,
        weight: 3,
      },
      {
        url: 'https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/truck.png',
        width: 60,
        height: 77,
        weight: 1,
      },
      {
        url: 'https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/limo.png',
        width: 50,
        height: 87,
        weight: 1,
      },
      {
        url: 'https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/bus.png',
        width: 60,
        height: 97,
        weight: 1,
      },
    ];

    const totalWeight = images.reduce((sum, image) => sum + image.weight, 0);
    let randomImageIndex = -1;
    let randomWeight = Math.random() * totalWeight;
    for (let i = 0; i < images.length; i++) {
      randomWeight -= images[i].weight;
      if (randomWeight < 0) {
        randomImageIndex = i;
        break;
      }
    }
    const image = images[randomImageIndex];
    const x = roadX + Math.random() * (roadWidth - obstacleWidth);
    const y = playerCar.y - 900;

    const obstacleImage = Math.floor(Math.random() * obstacleImages.length);

    return {
      x: x,
      y: y,
      width: obstacleWidth,
      height: obstacleHeight,
      isPassed: false,
      obstacleImage, // Add this line
    };
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!isGameOver) {
        // Check if the game is not over
        setRoadPosition((prevPosition) => (prevPosition + speed / 100) % (20 * 2));
        setObstacles((prevObstacles) => {
          const updatedObstacles = prevObstacles.map((obstacle) => ({
            ...obstacle,
            y: obstacle.y - speed / 100,
          }));

          if (speed > 0 && Math.random() < 0.009 && canvasRef.current) {
            const newObstacle = generateObstacle(playerCar, canvasRef.current);
            // Check for overlaps before adding the new obstacle
            if (!isObstacleOverlapping(newObstacle, updatedObstacles, obstacleImages)) {
              updatedObstacles.push(newObstacle);
            }
          }

          return updatedObstacles.slice(-50);
        });
      }
    }, 1);

    return () => clearInterval(intervalId);
  }, [speed, isGameOver, playerCar]);

  useEffect(() => {
    const img = new Image();
    img.src = 'https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/policeCar.png'; // Replace this with the path to your car image
    img.onload = () => {
      setCarImage(img);

      const canvas = canvasRef.current;
      if (!canvas) return;

      const context = canvas.getContext('2d');
      if (!context) return;

      context.clearRect(0, 0, canvas.width, canvas.height);
    };
  }, [playerCar]);

  useEffect(() => {
    const img = new Image();
    img.src = 'https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/secondCar.png'; // Replace this with the path to your car image
    img.onload = () => {
      setSecondCarImage(img);

      const canvas = canvasRef.current;
      if (!canvas) return;

      const context = canvas.getContext('2d');
      if (!context) return;

      context.clearRect(0, 0, canvas.width, canvas.height);
    };
  }, [secondCar]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const context = canvas.getContext('2d');
    if (!context) return;

    return () => {};
  }, [canvasRef, playerCar, speed]);

  useEffect(() => {
    const moveCar = () => {
      if (isLeftPressed) {
        moveCarLeft();
      } else if (isRightPressed) {
        moveCarRight();
      } else {
        setPlayerCar((prevCar) => ({
          ...prevCar,
          rotation: 0,
        }));
      }
    };

    const intervalId = setInterval(moveCar, 10);

    return () => clearInterval(intervalId);
  }, [isLeftPressed, isRightPressed]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const context = canvas.getContext('2d');
    if (!context) return;

    const loop = () => {
      drawRoad(context, canvas);
      animationFrameId.current = requestAnimationFrame(loop);
    };

    loop();

    return () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    };
  }, [canvasRef, playerCar, speed, obstacles]);

  // 6. Check for collisions between the player's car and the obstacles
  // 6. Check for collisions between the player's car and the obstacles
  const checkCollision = useCallback(
    (car: Car, obstacle: Obstacle) => {
      const obstacleImage = obstacleImages[obstacle.obstacleImage]; // Get the obstacle image
      const obstacleWidth = obstacleImage.width - 20;

      return (
        car.x < obstacle.x + obstacleWidth &&
        car.x + car.width > obstacle.x &&
        car.y < obstacle.y + obstacleImage.height &&
        car.y + car.height > obstacle.y
      );
    },
    [obstacleImages],
  );

  useEffect(() => {
    const collidedObstacle = obstacles.find((obstacle) => checkCollision(playerCar, obstacle));

    if (collidedObstacle && !collidedObstacle.isPassed) {
      setIsGameOver(true); // Set the game over state to true
    }
  }, [playerCar, obstacles, checkCollision]);

  function drawObstacles(context: CanvasRenderingContext2D | null, obstacles: Obstacle[]) {
    if (!context) return;

    obstacles.forEach((obstacle) => {
      // Use the obstacleImage index from the obstacle
      const obstacleImage = obstacleImages[obstacle.obstacleImage];

      // Draw the obstacle image
      const img = new Image();
      img.src = obstacleImage.src;
      context.drawImage(img, obstacle.x, obstacle.y, obstacleImage.width, obstacleImage.height);
    });
  }

  function isObstacleOverlapping(
    newObstacle: Obstacle,
    existingObstacles: Obstacle[],
    obstacleImages: { src: string; width: number; height: number; weight: number }[],
  ) {
    for (const obstacle of existingObstacles) {
      const newObstacleImage = obstacleImages[newObstacle.obstacleImage];
      const existingObstacleImage = obstacleImages[obstacle.obstacleImage];

      if (
        newObstacle.x < obstacle.x + existingObstacleImage.width &&
        newObstacle.x + newObstacleImage.width > obstacle.x &&
        newObstacle.y < obstacle.y + existingObstacleImage.height &&
        newObstacle.y + newObstacleImage.height > obstacle.y
      ) {
        return true;
      }
    }
    return false;
  }

  function drawRoad(context: CanvasRenderingContext2D | null, canvas: HTMLCanvasElement | null) {
    if (!context) return;
    if (!canvas) return;

    // Translate the context based on the player car's position
    const offsetY = -playerCar.y + canvas.height * 0.75;
    context.setTransform(1, 0, 0, 1, 0, offsetY);

    const roadWidth = canvas.width * 0.85;
    const roadX = canvas.width * 0.05;
    const squareSize = 10;

    // Drawing the road
    context.fillStyle = '#333333';
    context.fillRect(roadX, -100000, roadWidth, 100000 * 2); // Increase the height to cover more area
    context.fillStyle = 'white';

    // // Loop to draw the repeating dashed lines
    // for (let y = -100000; y < 100000 * 2; y += 90) {
    //   context.fillRect(roadX + roadWidth / 2 - squareSize / 2, y + roadPosition, squareSize, squareSize + 40);
    // }
    // Drawing the checkerboard pattern
    const checkerboardSize = 40;
    const leftCheckerboardX = roadX - checkerboardSize;
    const rightCheckerboardX = roadX + roadWidth;

    for (let y = -100000; y < 100000 * 2; y += checkerboardSize * 2) {
      for (let x = 0; x < checkerboardSize * 2; x += checkerboardSize) {
        context.fillStyle = x % (checkerboardSize * 2) === 0 ? '#F8F8F8' : 'white';
        context.fillRect(leftCheckerboardX + x, y, checkerboardSize, checkerboardSize);
        context.fillRect(rightCheckerboardX + x, y, checkerboardSize, checkerboardSize);

        context.fillStyle = x % (checkerboardSize * 2) === 0 ? 'white' : '#F8F8F8';
        context.fillRect(leftCheckerboardX + x, y + checkerboardSize, checkerboardSize, checkerboardSize);
        context.fillRect(rightCheckerboardX + x, y + checkerboardSize, checkerboardSize, checkerboardSize);
      }
    }
    if (carImage && secondCarImage) {
      drawCar(context, playerCar, carImage, playerCar.rotation);
      drawCar(context, secondCar, secondCarImage, 0); // Keep the rotation angle 0 for the second car
    }

    // Draw obstacles
    drawObstacles(context, obstacles);
    context.setTransform(1, 0, 0, 1, 0, 0);
    const newDistance = updateDistanceDisplay(playerCar, secondCar);
    setDistance(newDistance);
    checkWinCondition(playerCar, secondCar);
  }

  function drawCar(context: CanvasRenderingContext2D | null, car: Car, image: HTMLImageElement, rotation: number) {
    if (!context) return;
    context.save();
    context.translate(car.x + car.width / 2, car.y + car.height / 2);
    context.rotate(rotation);
    context.drawImage(image, -car.width / 2, -car.height / 2, car.width, car.height);
    context.restore();
  }

  function moveCarLeft() {
    setPlayerCar((prevCar) => {
      const newX = prevCar.x - 3;
      const roadX = canvasRef.current ? canvasRef.current.width * 0.1 : 0;
      const minCarX = roadX;
      return {
        ...prevCar,
        x: newX >= minCarX ? newX : prevCar.x,
        rotation: newX >= minCarX ? -0.1 : prevCar.rotation,
      };
    });
  }

  function moveCarRight() {
    setPlayerCar((prevCar) => {
      const newX = prevCar.x + 3;
      const roadX = canvasRef.current ? canvasRef.current.width * 0.1 : 0;
      const roadWidth = canvasRef.current ? canvasRef.current.width * 0.8 : 0;
      const maxCarX = roadX + roadWidth - prevCar.width;
      return {
        ...prevCar,
        x: newX <= maxCarX ? newX : prevCar.x,
        rotation: newX <= maxCarX ? 0.1 : prevCar.rotation,
      };
    });
  }

  function handleOptionClick(option: Option) {
    setSelectedOption(option);
    setPlayerAnswer(option.value);

    if (option.value === currentQuestion.answer) {
      console.log('Correct answer!');
      setCorrectOption(option);
      setScore((prevScore) => prevScore + 100);
      setSpeed((prevSpeed) => prevSpeed + 10);
      setFirstQuestionAnswered(true);
    } else {
      console.log('Incorrect answer!');
      setIncorrectOption(option);
      setCorrectOption(currentQuestion.options.find((o) => o.value === currentQuestion.answer) || null);

      setTimeout(() => {
        setIncorrectOption(null);
        setCorrectOption(null);
        if (speed > 10) {
          setSpeed((prevSpeed) => prevSpeed - 10);
        }
      }, 100);
    }

    // Change the question after some time
    setTimeout(() => {
      const randomIndex = Math.floor(Math.random() * questions.length);
      setCurrentQuestion(questions[randomIndex]);
    }, 100);
  }

  function updateDistanceDisplay(car1: Car, car2: Car) {
    const dx = car1.x + car1.width / 2 - (car2.x + car2.width / 2);
    const dy = car1.y + car1.height / 2 - (car2.y + car2.height / 2);
    const distance = Math.sqrt(dx * dx + dy * dy) / 20;

    const distanceDisplay = document.getElementById('distance-display');
    if (distanceDisplay) {
      distanceDisplay.textContent = `DISTANCE: ${distance.toFixed(0)} M`;

      // Create an img element for the second car
      const secondCarImg = document.createElement('img');
      secondCarImg.src = 'https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/secondCar.png';
      secondCarImg.classList.add('second-car-img');

      // Append the second car img element to the distance display
      distanceDisplay.appendChild(secondCarImg);
    }

    return distance;
  }

  function checkWinCondition(car1: Car, car2: Car) {
    const dy = car1.y + car1.height / 2 - (car2.y + car2.height / 2);

    if (dy <= 10) {
      setIsWin(true);
    }
  }

  const moveCarWithButton = () => {
    if (leftButtonPressed.current) {
      moveCarLeft();
      setPlayerCar((prevCar) => ({
        ...prevCar,
        rotation: -0.1,
      }));
    } else if (rightButtonPressed.current) {
      moveCarRight();
      setPlayerCar((prevCar) => ({
        ...prevCar,
        rotation: 0.1,
      }));
    } else {
      setPlayerCar((prevCar) => ({
        ...prevCar,
        rotation: 0,
      }));
    }
    if (leftButtonPressed.current || rightButtonPressed.current) {
      requestAnimationFrame(moveCarWithButton);
    }
  };

  const buttonStartMovingLeft = () => {
    if (!leftButtonPressed.current) {
      console.log('Starting to move left');

      leftButtonPressed.current = true;
      moveCarWithButton();
    }
  };

  const buttonStopMovingLeft = () => {
    console.log('Stopping moving left');
    leftButtonPressed.current = false;
  };

  const buttonStartMovingRight = () => {
    if (!rightButtonPressed.current) {
      console.log('Starting to move right');

      rightButtonPressed.current = true;
      moveCarWithButton();
    }
  };

  const buttonStopMovingRight = () => {
    console.log('Stopping moving right');

    rightButtonPressed.current = false;
  };

  // useEffect(() => {
  //   if (distance >= 90 && distance <= 110) {
  //     setShowPopup(true);

  //     if (timeoutId) clearTimeout(timeoutId);

  //     const timer = setTimeout(() => {
  //       setShowPopup(false);
  //     }, 2000);

  //     setTimeoutId(timer);
  //   }
  // }, [distance, timeoutId]);

  // useEffect(() => {
  //   return () => {
  //     if (timeoutId) clearTimeout(timeoutId);
  //   };
  // }, [timeoutId]);

  function handleKeyDown(event: KeyboardEvent) {
    if (event.code === 'ArrowLeft') {
      buttonStartMovingLeft();
    } else if (event.code === 'ArrowRight') {
      buttonStartMovingRight();
    } else if (event.code === 'Digit1' || event.code === 'Numpad1') {
      handleOptionClick(currentQuestion.options[0]);
    } else if (event.code === 'Digit2' || event.code === 'Numpad2') {
      handleOptionClick(currentQuestion.options[1]);
    } else if (event.code === 'Digit3' || event.code === 'Numpad3') {
      handleOptionClick(currentQuestion.options[2]);
    } else if (event.code === 'Digit4' || event.code === 'Numpad4') {
      handleOptionClick(currentQuestion.options[3]);
    }
  }

  function handleKeyUp(event: KeyboardEvent) {
    if (event.code === 'ArrowLeft' || event.code === 'ArrowRight') {
      buttonStopMovingRight();
      buttonStopMovingLeft();
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [currentQuestion]);

  return (
    <div className="race-game-page-container">
      {!isGameStarted ? (
        <div className="race-start-container">
          <h1>WELCOME TO MULTIPLY CHASE!</h1>
          <p>Catch the bad guys! </p>
          <p>Increase your speed by answering multiplication questions correct. </p>
          <p>Be careful not to hit other cars on the road!</p>
          <button onClick={() => setIsGameStarted(true)}>START GAME</button>
        </div>
      ) : (
        <div className="race-game-wrapper">
          <div className="race-game-container" tabIndex={0}>
            <div className="race-distance-wrapper">
              <div className="race-distance-display" id="distance-display"></div>
            </div>
            {isGameOver && (
              <div className="race-game-over">
                <h1>GAME OVER</h1>
                <button
                  onClick={() => {
                    setIsGameOver(false);
                    setScore(0);
                    setSpeed(10);
                    setPlayerCar({
                      x: 375,
                      y: 450,
                      width: 70,
                      height: 125,
                      speed: 0,
                      rotation: 0,
                    });
                    setSecondCar({
                      x: 375,
                      y: 100,
                      width: 70,
                      height: 110,
                      speed: 20,
                      rotation: 0,
                    });

                    setObstacles([]);
                  }}
                >
                  PLAY AGAIN
                </button>
              </div>
            )}

            {isWin && (
              <div className="race-game-over">
                <h1>YOU CAUGHT THEM!</h1>
                <button
                  onClick={() => {
                    setIsWin(false);
                    setScore(0);
                    setSpeed(10);
                    setPlayerCar({
                      x: 375,
                      y: 450,
                      width: 50,
                      height: 97,
                      speed: 0,
                      rotation: 0,
                    });
                    setSecondCar({
                      x: 375,
                      y: 100,
                      width: 50,
                      height: 97,
                      speed: 20,
                      rotation: 0,
                    });

                    setObstacles([]);
                  }}
                >
                  PLAY AGAIN
                </button>
              </div>
            )}

            <div className="race-question-container">
              {!firstQuestionAnswered && <div className="answer-question-msg">ANSWER QUESTIONS TO INCREASE SPEED!</div>}
              <div className="race-question-box">{currentQuestion.question}</div>
              <div className="race-option-container">
                {currentQuestion.options.map((option, index) => (
                  <div key={index} className="race-option-wrapper">
                    <div
                      className={`race-option ${
                        option === correctOption ? 'race-correct' : option === incorrectOption ? 'race-incorrect' : ''
                      }`}
                      onClick={() => handleOptionClick(option)}
                    >
                      {option.label}
                    </div>
                    <img
                      src={`https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/${index + 1}-key.png`}
                      alt={`Option ${index + 1} key`}
                      className="race-key-image desktop-only"
                    />
                  </div>
                ))}
              </div>

              <div className="race-controls">
                <button
                  onMouseDown={buttonStartMovingLeft}
                  onMouseUp={buttonStopMovingLeft}
                  onMouseLeave={buttonStopMovingLeft}
                  onTouchStart={buttonStartMovingLeft}
                  onTouchEnd={buttonStopMovingLeft}
                  className="race-left-button"
                >
                  <BsChevronLeft size={32} />
                </button>
                <img
                  className="race-left-arrow desktop-only"
                  src="https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/arrowKey.png"
                  alt="Left arrow key"
                />
                <button
                  onMouseDown={buttonStartMovingRight}
                  onMouseUp={buttonStopMovingRight}
                  onMouseLeave={buttonStopMovingRight}
                  onTouchStart={buttonStartMovingRight}
                  onTouchEnd={buttonStopMovingRight}
                  className="race-right-button"
                >
                  <BsChevronRight size={32} />
                </button>
                <img
                  src="https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/arrowKey.png"
                  alt="Right arrow key"
                  className="rotate-180 race-right-arrow desktop-only"
                />
              </div>
            </div>

            {/* {showPopup && <div className="popup-text">THEY'RE GETTING AWAY!</div>} */}

            <canvas ref={canvasRef} width={800} height={600} />

            <div className="race-score-container">Score: {score}</div>
            <div className="race-speed-container">Speed: {speed.toFixed(1)}</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RacingGame;
