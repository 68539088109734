import React from 'react';
import { JourneyInfo } from '../../Models';
import Picture from '../Partials/Picture';
import src from '../../assets/home/mins/boy-studying.jpg';
import webp from '../../assets/home/webp/boy-studying.webp';

const Journey: React.FC = () => {
  return (
    <section className="journey">
      <div className="journey__left-side">
        <h2 className="journey__heading">Your Personalized Learning Journey</h2>
        <Picture src={src} webp={webp} alt={'boy studying'} picClass="journey__header-img" />
      </div>
      <div className="journey__right-side">
        {JourneyInfo.map((item, index) => {
          return (
            <div className="journey__steps" key={index}>
              <div className="journey__number-wrapper">
                <span className="journey__number">{item.number}</span>
              </div>
              <div className="journey__steps-content">
                <h5 className="journey__steps-content-header">{item.header}</h5>
                <p className="journey__steps-content-info">{item.content}</p>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Journey;
