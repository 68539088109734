import React from 'react';
import { HeroProps } from '../../Models';
import Picture from './Picture';

const Hero: React.FC<HeroProps> = ({ content }) => {
  return (
    <section className={content.className}>
      <Picture src={content.src} webp={content.webp} alt={'banner'} picClass={'homepage__banner--image'} />
      <div className="hero__text-wrapper">
        <h1 className="hero__header">{content.header}</h1>
        <h2 className="hero__sub-header">{content.subheader}</h2>
        <button
          className="hero__cta-btn"
          onClick={() => {
            window.location.href.includes('#form')
              ? (window.location.href = window.location.href)
              : (window.location.href = window.location.href + '#form');
          }}
        >
          Catch My Child Up
        </button>
      </div>
    </section>
  );
};

export default Hero;
