import experiencedTutorsImg from '../../assets/location-pages/experienced-tutors-canada-location-pg.jpg';
import universitiesImg from '../../assets/location-pages/university-canada-location-pg.jpg';
import whyUsImg from '../../assets/location-pages/why-choose-us-canada-location-pg.jpg';

export const CanadaPageContent = [
  {
    heading: 'Learn from the best online tutor Canada has to offer',
    content:
      'We help you find the perfect tutor for your child. With effective teaching skills and expert subject knowledge, our tutors are hand-picked to meet your child’s needs. Watch your child’s confidence increase and grades improve in no time. Book a free consultation today! ',
  },
  {
    heading: 'Experienced tutors for every subject',
    content: [
      'Are you looking for the most experienced math online tutors in Canada? We can help you with that, and more. Whether you’re on the hunt for a math tutor or an online French tutor – we’ve got you covered for all subjects!',
      'Our tutors are well-versed with the curriculum of every school board, including Toronto District School Board, Upper Canada District School Board, members of Manitoba School Boards Association, and many more.',
      'We provide the best online English tutoring in Canada, with great emphasis on building reading and writing skills. By following a step-by-step approach, our tutors help your child quickly become fluent in the English language. Outlining specific areas for improvement, and helping your child overcome them, is at the core of our teaching methodology.',
    ],
    img: experiencedTutorsImg,
    imgAlt: 'tutor and tutee at desk',
  },
  {
    heading: 'Make your child fluent in languages',
    content: [
      'We can help you find the best reading online tutor Canada has to offer. Whether it is English or French, our tutor will help your child pick up the pace and keep getting better every day. With the main focus on basic concepts like phonetics and vocabulary building, reading will soon become your child’s strength.',
      'We also provide highly effective online tutoring for writing, helping each student unleash the author in them. Our tutors promote creative thinking from an early stage, helping your child shine as a writer. We can help your child overcome frequent spelling mistakes through regular practice and learning new words every day.',
    ],
  },
  {
    heading: 'Help your child get into top Canadian universities',
    content: [
      'As your child approaches high school, help them prepare for their future. The first step towards their successful career would be to get into the top-ranked college of their dreams. Whether it’s an international institute or Canadian, we can help your child nurture the intellect to get there.',
      'Our tutors help your child polish their aptitude and get good grades to secure a seat in premier Canadian institutes like the University of Toronto, McMaster University, University of Waterloo, McGill University, University of British Columbia, and many more.',
    ],
    img: universitiesImg,
    imgAlt: 'Two university students standing in front of a building and smiling at camera.',
  },
  {
    heading: 'Why is an online tutor best for you?',
    content: [
      'An online tutor can give your child individual attention and a personalized study experience, all from the comfort of your home. The timing is flexible and sessions are always one-on-one, making learning more effective. You don’t even have to drive your child anywhere!',
      'Online tutoring increases your options - you’re no longer limited to the handful of tutors in your locality. Instead, you get access to the best and most talented tutors from across the country. For example, you can find the top-rated French online tutor in Canada, and not just in your city! With learning made accessible, you can say goodbye to all the fuss and focus on what truly matters – quality tutoring for your child.',
    ],
  },
  {
    heading: 'Why choose us?',
    content: [
      'We match you with a tutor who has worked with children just like yours. Your child receives targeted learning from expert online tutors who deliver lessons seamlessly. What’s more, is that you can pick a date and time of your choice to ensure that your child never has to skip a lesson.',
      'Our tutors always keep your child one week ahead of the class curriculum, so that studying in the school classroom feels like a recap of concepts. This can help your child boost retention and perform better in exams.',
      'In every tutoring session, concepts are simplified and made easy to grasp, helping your child score better. Despite the high-quality tutoring, our hourly prices are much lower than you might expect. So, go ahead and give us a try – book a free consultation right away!',
    ],
    img: whyUsImg,
    imgAlt: 'Young child reading book while sitting on the couch.',
  },
];

export const CanadaPageQuickLinks = {
  pages: [
    {
      pageName: 'English Tutor',
      pageLink: '/online-english-tutor',
    },
    {
      pageName: 'Reading Tutor',
      pageLink: '/online-reading-tutor',
    },
    {
      pageName: 'Writing Tutor',
      pageLink: '/online-writing-tutor',
    },
    {
      pageName: 'Math Tutor',
      pageLink: '/online-math-tutor',
    },
    {
      pageName: 'Science Tutor',
      pageLink: '/online-science-tutor',
    },
  ],
  worksheets: [
    {
      worksheetsName: 'All Worksheets',
      worksheetsLink: '/worksheets-for-kids',
    },
    {
      worksheetsName: 'Preschool',
      worksheetsLink: '/preschool-worksheets',
    },
    {
      worksheetsName: 'Kindergarten',
      worksheetsLink: '/worksheets-for-kindergarten',
    },
    {
      worksheetsName: 'Grade 1',
      worksheetsLink: '/grade-1-worksheets',
    },
    {
      worksheetsName: 'Grade 2',
      worksheetsLink: '/grade-2-worksheets',
    },
    {
      worksheetsName: 'Grade 3',
      worksheetsLink: '/grade-3-worksheets',
    },
    {
      worksheetsName: 'Reading',
      worksheetsLink: '/reading-worksheets',
    },
    {
      worksheetsName: 'Writing',
      worksheetsLink: '/writing-worksheets',
    },
    {
      worksheetsName: 'Math',
      worksheetsLink: '/math-worksheets',
    },
    {
      worksheetsName: 'Science',
      worksheetsLink: '/science-worksheets',
    },
  ],
};

export const BCPageContent = [
  {
    heading: 'Tutor British Columbia',
    content: [
      'Your search for BC’s most experienced and affordable online tutor ends here. At No Fuss Tutors, we understand that finding the perfect tutor for your child can be a tedious process, often feeling like a massive burden on your shoulders. On top of that, the chances of stumbling upon a tutor who is not only an expert but also fully understands your child, are extremely rare. The odds become even lower when you’re searching only amongst the handful of tutors in your locality.',
      'Online tutoring with No Fuss Tutors widens your periphery. You get access to our network of hundreds of tutors, all from the comfort of your home. Parents can finally say goodbye to desperate phone calls to tutoring agencies. Never waste a whole day interviewing a tutor ever again! Book a free consultation with us where we can discuss your needs and connect you with the ultimate tutor for your child, all within a few hours. Once you’re matched, pick a timing that best suits your schedule and begin classes seamlessly.',
      'The best part about hiring a tutor through us is that we never leave parents stranded alone in the tutoring process. If you feel that any modifications need to be made to your child’s course plan, our team is always there to listen and deliver exactly what you require. We believe that our role is not over until your child achieves academic success. With live one-to-one sessions between the student and the tutor, we help children meet their goals by offering personalized learning powered by the best technology. We threw the one-size-fits-all approach out of the window a long time ago!',
      'So, if you’re seeking an expert tutor for kids in British Columbia who is well-qualified, experienced, and comes highly recommended by hundreds of other parents, get in touch with us today.',
    ],
  },
  {
    heading: 'Why Should You Hire An Online Tutor?',
    content: [
      'Parents often contemplate whether online tutoring is truly the right option. To help you make up your mind, here are the top three reasons why choosing an online tutor will be the best decision you ever make:',
    ],
  },
  {
    heading: '1. Safety',
    content: [
      'Your child will spend a few hours every day of the week with their tutor. If you opt for in-person classes with the tutor, you should be able to trust them as you welcome them into your house. You also need to be confident they will be a good influence on your child. The problem is this – tutors, no matter how much other parents recommend them, are still strangers. If you’re concerned about your child’s safety, online tutoring can be the most secure option with zero compromises on teaching quality.',
    ],
  },
  {
    heading: '2. Accessibility',
    content: [
      'Tutoring can be a hassle for most working parents, especially those who have a 9 to 5 job. They often struggle to find a perfect time for conducting their child’s lessons because not many offline tutors are available in the odd evening hours. Those who are, often charge exuberant fees. On the other hand, if you opt for group classes at the tutor’s premises, driving your child to and fro every day, can be a time-consuming inconvenience. Once again, online tutoring proves to be the perfect solution. All you need is a computer, an internet connection, and a child willing to learn.',
    ],
  },
  {
    heading: '3. Dependability',
    content: [
      'Parents are always looking for fast results without skipping steps, at affordable prices. They also want tutoring to be uninterrupted and the tutor to be dependable. Online tutoring offers just that. Classes always begin on time because no one is ‘stuck in traffic’. Even if the student moves to another city and changes schools, there’s no need to begin searching for a new tutor all over again. Such dependability is only offered by online tutoring, making it the number one choice.',
    ],
  },
  {
    heading: 'Top-Rated English Tutors in British Columbia Offered By NFT',
    content: [
      'Fluent-speaking children make the most confident adults. The importance of language skills is thus evident. Reading and writing ability need to be nurtured from a very young age. Parents who understand this make the wise decision of investing in reading and writing tutors for their preschool to primary school-going children. So, if you are looking for the perfect reading tutor in British Columbia, No Fuss Tutors can offer the best for your child.',
      'Our tutors can help your child write better too. In our online classes, we use a whiteboard to make lessons feel natural, like classroom learning. This is a great tool for teaching young children how to write neatly. You can supplement your child’s learning with our amazing worksheet collection as well.',
      'For older students, being able to write terrific essays can be crucial while applying to top Canadian universities like the University of British Columbia, as well other top international institutes. To hire one of the top-rated writing tutors British Columbia has to offer, get in touch with us today, and lets your child awaken the Shakespeare in them.',
    ],
  },
  {
    heading: 'Looking for an Affordable French Tutor in British Columbia?',
    content: [
      'There are over 60 different school districts in British Columbia, and many of them have French as one of the main subjects. Our French tutors are qualified to teach students in all school districts as well as the top school boards of BC, including the West Vancouver Schools board, Vancouver School Board, and others.',
      'Children, especially those who don’t come from French-speaking households, regard French as one of the toughest languages to master. Our tutors make things simpler. We focus on everything from vocabulary building and interpreting pronunciations to sentence formation and writing texts in French. By providing a wholesome education of the language in a step-by-step manner, we clear doubts in the early stages hence encouraging the child to build a solid foundation for future learning.',
      'Before you know it, your child becomes an authentic French speaker, able to communicate with a powerful command and confidence.',
    ],
  },
  {
    heading: 'No Fuss Tutors’ Advantages Explained',
    content: [
      'Our hand-picked tutors always keep your child’s tutoring lessons one week ahead of the school curriculum, so that classroom learning feels somewhat like re-watching a movie. We try to make learning interactive and effective, helping your child quickly improve grades and increase their confidence in class.',
      `Hundreds of parents trust us and have consistently rated us 5-stars for our services. We have an easy three-step journey: you place a request, get matched with the tutor, and watch your child’s academics improve in a matter of weeks. Our team doesn’t take a backseat even after finding you the right fit - we stay on and monitor your child’s progress, ready to jump in whenever you need us.
Our services are available for all children studying in grades 1 to 12. We also offer fun lessons full of learning for children in kindergarten because we understand the value of early literacy. For elementary school, we have an effective learn-to-read program. We also teach the fundamentals of math, the fundamentals of writing and reading, provide an introduction to French and Spanish, and more.`,
      'For children in middle school, our tutors can help them with grammar, syntax, vocabulary, mathematics, essay writing, and advanced languages. For high-schoolers, we offer advanced essay writing classes, advanced mathematics, chemistry, physics, biology, and language tutoring. The most qualified French, English, science, and math tutors in British Columbia are just a click away at No Fuss Tutors. Book a free consultation today!',
    ],
  },
];

export const BCPageQuickLinks = {
  pages: [
    {
      pageName: 'English Tutor',
      pageLink: '/online-english-tutor',
    },
    {
      pageName: 'Reading Tutor',
      pageLink: '/online-reading-tutor',
    },
    {
      pageName: 'Writing Tutor',
      pageLink: '/online-writing-tutor',
    },
    {
      pageName: 'Math Tutor',
      pageLink: '/online-math-tutor',
    },
    {
      pageName: 'Science Tutor',
      pageLink: '/online-science-tutor',
    },
  ],
  worksheets: [
    {
      worksheetsName: 'All Worksheets',
      worksheetsLink: '/worksheets-for-kids',
    },
    {
      worksheetsName: 'Preschool',
      worksheetsLink: '/preschool-worksheets',
    },
    {
      worksheetsName: 'Kindergarten',
      worksheetsLink: '/worksheets-for-kindergarten',
    },
    {
      worksheetsName: 'Grade 1',
      worksheetsLink: '/grade-1-worksheets',
    },
    {
      worksheetsName: 'Grade 2',
      worksheetsLink: '/grade-2-worksheets',
    },
    {
      worksheetsName: 'Grade 3',
      worksheetsLink: '/grade-3-worksheets',
    },
    {
      worksheetsName: 'Reading',
      worksheetsLink: '/reading-worksheets',
    },
    {
      worksheetsName: 'Writing',
      worksheetsLink: '/writing-worksheets',
    },
    {
      worksheetsName: 'Math',
      worksheetsLink: '/math-worksheets',
    },
    {
      worksheetsName: 'Science',
      worksheetsLink: '/science-worksheets',
    },
  ],
};

export const OntarioPageContent = [
  {
    heading: 'Looking for An Experienced Tutor in Toronto?',
    content: [
      'The hunt for a perfect tutor can be a tiring process for most parents. You need someone who not only bonds well with your children and has an effective teaching style but also doesn’t charge a hefty fee. Thanks to No Fuss Tutors, finding a tutor who checks all these boxes has become much easier than you can imagine.',
      'We have a short, three-step journey: you place a request, we match your child with a compatible tutor, and you get to watch your child improve at academics in no time. The great thing is, No Fuss Tutors doesn’t stop even after finding you the perfect fit. We stay on and actively monitor the progress of your child, always ready to jump in should you need us.',
      'You can connect with us for tutoring children in any grade from 1 to 12 – we’ve got you fully covered. Matching your child with a highly qualified and experienced tutor is our promise, and we never fail to deliver. If you’re still having second thoughts, book a free consultation today!',
    ],
  },
  {
    heading: 'Make the Right Choice with No Fuss Tutors',
    content: [
      'We have a network of 500 tutors and one out of them is hand-picked to specifically meet your child’s needs. Your options are no longer limited to the handful of tutors in your locality. Also, our reliable tutors in Toronto will never leave you stranded, because nothing is scarier than your tutor quitting the job halfway, whatever the reason may be. Lastly, since we teach online, your child can attend the class without you having to drive them anywhere and without letting a stranger into your home.',
      'We always keep your child one week ahead of their classroom study, so that lessons at school feel like replaying a movie. This technique boosts the retention of concepts and lets your child get more practice. With No Fuss Tutors, you get to see fast results and instant improvement without missing any steps involved in teaching subjects.',
    ],
  },
  {
    heading: 'Find a Tutor in Toronto for Every Subject',
    content: [
      'Our network includes tutors who are qualified to teach all grades and all subjects. For elementary school, we have a specially designed learn-to-read program. Our expert tutors also cover the fundamentals of mathematics, fundamentals of writing, and give a brief introduction to the English and French languages.',
      'For middle school children, we can help you out with grammar, vocabulary, and syntax. Accelerated mathematics and advanced languages are also our specialties. We also help your child get better at reading and writing. For children in high school, we offer help with advanced essay writing, higher mathematics, chemistry, physics, biology, languages, and more.',
      'Our tutors are well-versed with the curriculum of Toronto District School Board, Toronto Catholic District School Board, Halton District School Board, Bloorview School Authority, and many more in the area. You can rely on us to never skip a topic from your child’s syllabus.',
      'If you have a high schooler aiming to get into the nearby University of Toronto, York University, Ryerson University, Centennial College, George Brown College, or Tyndale University, our tutors can help them get perfect grades that will look great on a college application. We can also help them out with basic concepts for exams that help you get into Canada’s top institutes like the University of British Columbia, McGill University, University of Waterloo, University of Montreal, and others.',
    ],
  },
  {
    heading: 'The No Fuss Tutors Advantage',
    content: [
      'Whether you’re looking for the best reading tutor Toronto has or just someone to help out with basic math, you can trust us to offer the cheapest hourly rates with no compromise on quality. In fact, No Fuss Tutors has been consistently rated 5-star by parents, despite our low cost. We offer flexible timings and personalized learning for your child, from the comfort of home.',
      'Since children are used to studying in a classroom, we use a whiteboard while teaching. We truly believe that it’s the small things that help children feel comfortable while studying. No Fuss Tutors offers great ways to supplement your kid’s learning as well. For example, you can access our large collection of free online worksheets for all grades and subjects.',
      'So, how can we help your child? Call us, drop a text, or directly book a free consultation and we would love to discuss!',
    ],
  },
];

export const OntarioPageQuickLinks = {
  pages: [
    {
      pageName: 'English Tutor',
      pageLink: '/online-english-tutor',
    },
    {
      pageName: 'Reading Tutor',
      pageLink: '/online-reading-tutor',
    },
    {
      pageName: 'Writing Tutor',
      pageLink: '/online-writing-tutor',
    },
    {
      pageName: 'Math Tutor',
      pageLink: '/online-math-tutor',
    },
    {
      pageName: 'Science Tutor',
      pageLink: '/online-science-tutor',
    },
  ],
  worksheets: [
    {
      worksheetsName: 'All Worksheets',
      worksheetsLink: '/worksheets-for-kids',
    },
    {
      worksheetsName: 'Preschool',
      worksheetsLink: '/preschool-worksheets',
    },
    {
      worksheetsName: 'Kindergarten',
      worksheetsLink: '/worksheets-for-kindergarten',
    },
    {
      worksheetsName: 'Grade 1',
      worksheetsLink: '/grade-1-worksheets',
    },
    {
      worksheetsName: 'Grade 2',
      worksheetsLink: '/grade-2-worksheets',
    },
    {
      worksheetsName: 'Grade 3',
      worksheetsLink: '/grade-3-worksheets',
    },
    {
      worksheetsName: 'Reading',
      worksheetsLink: '/reading-worksheets',
    },
    {
      worksheetsName: 'Writing',
      worksheetsLink: '/writing-worksheets',
    },
    {
      worksheetsName: 'Math',
      worksheetsLink: '/math-worksheets',
    },
    {
      worksheetsName: 'Science',
      worksheetsLink: '/science-worksheets',
    },
  ],
};

export const TorontoPageContent = [
  {
    heading: "Tailored Tutoring in Toronto: Building Strong Foundations",
    content: [
      "No Fuss Tutors specializes in creating personalized learning experiences that cater to the individual needs of each student. Based in Toronto, we understand the unique educational landscape of the city and how to navigate its challenges. Our goal is to build a strong academic foundation for students in grades 1 to 12, focusing on reinforcing core concepts, improving problem-solving skills, and fostering a love for learning.",
      "Through our initial assessment and ongoing feedback, we identify specific areas of improvement and customize our teaching strategies accordingly. Whether your child is struggling with math concepts, needs to enhance their reading comprehension, or requires support in science, our Toronto tutors are equipped to make a difference.",
    ],
  },
  {
    heading: "Achieving Academic Goals with Proven Strategies",
    content: [
      "Our tutoring approach is grounded in evidence-based strategies that promote active learning and critical thinking. We go beyond just helping students with homework; we equip them with the tools and techniques to tackle new challenges independently. This empowers students to become proactive learners, ready to excel in their current grade and beyond.",
      "Our flexible scheduling and online sessions mean that your child can access the best tutoring support in Toronto without leaving home. This convenience ensures consistent learning progress, regardless of external circumstances.",
    ],
  },
  {
    heading: "Focused Preparation for Toronto’s Educational Milestones",
    content: [
      "Toronto's education system is rich and varied, offering numerous opportunities for academic achievement. Our tutors are intimately familiar with the curriculum expectations of the Toronto District School Board and the Toronto Catholic District School Board, ensuring that our tutoring sessions are relevant and aligned with classroom learning.",
      "For high school students, our tutoring services extend to exam preparation, including EQAO tests, SSAT, SAT, and ACT. We provide targeted practice, review key concepts, and teach test-taking strategies to help students approach their exams with confidence and achieve their desired scores.",
    ],
  },
  {
    heading: "Committed to Every Student’s Success in Toronto",
    content: [
      "At No Fuss Tutors, we take pride in the success stories of our students. Our tutors in Toronto are not just subject matter experts; they are mentors who invest in the academic and personal growth of each student. We celebrate every improvement, understanding that each step forward is a building block for future success.",
      "Our tutoring sessions are interactive and engaging, utilizing a variety of online tools and resources to enhance the learning experience. We encourage curiosity, foster resilience, and support students in developing a positive attitude towards learning.",
      "Choosing No Fuss Tutors means partnering with a team that’s dedicated to making quality education accessible and affordable. Let us show you how our personalized tutoring services can make a difference in your child's academic journey. Contact us today for a free consultation and take the first step towards a brighter educational future in Toronto.",
    ],
  },
];



export const TorontoPageQuickLinks = {
  pages: [
    {
      pageName: 'English Tutor',
      pageLink: '/online-english-tutor',
    },
    {
      pageName: 'Reading Tutor',
      pageLink: '/online-reading-tutor',
    },
    {
      pageName: 'Writing Tutor',
      pageLink: '/online-writing-tutor',
    },
    {
      pageName: 'Math Tutor',
      pageLink: '/online-math-tutor',
    },
    {
      pageName: 'Science Tutor',
      pageLink: '/online-science-tutor',
    },
  ],
  worksheets: [
    {
      worksheetsName: 'All Worksheets',
      worksheetsLink: '/worksheets-for-kids',
    },
    {
      worksheetsName: 'Preschool',
      worksheetsLink: '/preschool-worksheets',
    },
    {
      worksheetsName: 'Kindergarten',
      worksheetsLink: '/worksheets-for-kindergarten',
    },
    {
      worksheetsName: 'Grade 1',
      worksheetsLink: '/grade-1-worksheets',
    },
    {
      worksheetsName: 'Grade 2',
      worksheetsLink: '/grade-2-worksheets',
    },
    {
      worksheetsName: 'Grade 3',
      worksheetsLink: '/grade-3-worksheets',
    },
    {
      worksheetsName: 'Reading',
      worksheetsLink: '/reading-worksheets',
    },
    {
      worksheetsName: 'Writing',
      worksheetsLink: '/writing-worksheets',
    },
    {
      worksheetsName: 'Math',
      worksheetsLink: '/math-worksheets',
    },
    {
      worksheetsName: 'Science',
      worksheetsLink: '/science-worksheets',
    },
  ],
};

export const TorontoMathPageContent = [
  {
    heading: "Adapting to Canada's New Math Curriculum: Our Commitment to Toronto Students",
    content: [
      "The recent overhaul of Canada's math curriculum reflects a forward-thinking approach to education, emphasizing critical thinking over memorization. At No Fuss Tutors, we've diligently updated our teaching methods to align with these national changes, ensuring our students in the Toronto District School Board (TDSB), Toronto Catholic District School Board (TDCSB), and private institutions across Toronto are not just keeping pace but leading the curve in mathematical understanding.",
      "Our tutors, skilled in the latest pedagogical strategies, are adept at the new curriculum's focus areas: financial literacy, coding, and data literacy. We believe that these are not just academic subjects, but life skills that will empower students in our increasingly digital world. By integrating technology and real-life scenarios into our lessons, we make math relevant and engaging for the Toronto student of today.",
      "With a thousand-word promise to excellence, we delve deep into the curriculum specifics, adapting our lessons to reflect the classroom reality of each student. For those in the TDSB and TDCSB, we focus on curriculum-mandated competencies, ensuring every tutoring session builds upon what they learn at school. For our private school students, we tailor our sessions to their unique curriculum demands, offering a bespoke educational experience that addresses their specific learning needs and goals.",
    ],
  },
  {
    heading: "Elementary Excellence: Aligning With Classroom Learning",
    content: [
      "In the foundational years of elementary school, math forms the bedrock of a child's academic career. Our tutoring services for Toronto's youngest learners are crafted to echo the curriculum they encounter in their classrooms. We collaborate with parents and, when possible, educators to ensure our tutoring is a direct extension of their schoolwork.",
      "Our elementary math tutoring sessions emphasize the joy of learning. We use manipulatives, interactive digital tools, and engaging activities that mirror the new curriculum's hands-on approach. This ensures that our students are not just learning math but are also enjoying the process, which is vital for long-term educational engagement.",
      "Whether it's understanding the basics of arithmetic or exploring the beginnings of geometry and algebra, our tutors make every concept clear and accessible. We celebrate every 'aha' moment and nurture a learning environment that encourages questions and curiosity, laying down a solid math foundation that will support their future academic endeavors.",
    ],
  },
  {
    heading: "Achieve Top High School Grades: Preparing for Advanced Math Success",
    content: [
      "As Toronto students transition to the complexities of high school mathematics, No Fuss Tutors evolves with them. We understand the leap from elementary to high school math can be daunting. Our high school program is meticulously designed to bridge this gap, with specialized focus on courses like advanced functions, calculus, and vectors, which are pivotal for post-secondary ambitions in STEM fields.",
      "Our tutors, with their finger on the pulse of Toronto’s high school math curriculum, provide an anticipatory learning approach. This means that our students are introduced to concepts like trigonometry, quadratic equations, and differential calculus ahead of their school schedule. This proactive strategy equips them with the knowledge and confidence to face class tests, provincial assessments, and college entrance exams head-on.",
      "We also recognize the unique challenges faced by students in specialized programs such as International Baccalaureate (IB) and Advanced Placement (AP) in Toronto. Our targeted tutoring sessions ensure these students are well-prepared for the rigorous mathematical standards these programs demand. By simulating exam conditions and providing comprehensive test preparation, we help students aim for top scores that will open doors to prestigious universities.",
    ],
  },
  {
    heading: "Catering to Toronto’s Diverse Educational Settings",
    content: [
      "Toronto's educational ecosystem is diverse, with students hailing from a variety of school settings including public, Catholic, private, and alternative schools. Each setting has its own ethos and academic approach, especially when it comes to math education. No Fuss Tutors prides itself on its ability to navigate this diversity, offering customized tutoring that respects and reinforces the ethos of each student’s school.",
      "Our personalized tutoring plans are responsive to the needs of students across different boards and educational philosophies. For TDSB and TDCSB students, we ensure our tutoring aligns with the Ontario Curriculum's five strands of mathematical knowledge: Number Sense and Numeration, Measurement, Geometry and Spatial Sense, Patterning and Algebra, and Data Management and Probability. For students in private and alternative schools, our tutoring sessions are tailored to the specific curricular requirements and educational objectives of their institutions.",
      "We also extend our services beyond the typical school structure, supporting homeschooled students in Toronto with math curriculum that adheres to provincial standards while allowing for the flexibility and individualization homeschooling offers.",
    ],
  },
  {
    heading: "Math Tutoring That Grows With Toronto’s Students",
    content: [
      "At No Fuss Tutors, we are not just educators; we are lifelong mentors. We understand that the math curriculum is ever-evolving, and so are the needs of our students. Our commitment is to provide continuous support that adapts to both curriculum changes and the developmental stages of our students.",
      "As Toronto students grow from handling basic math operations to solving complex calculus problems, our tutoring services evolve to meet their changing academic requirements. We offer advanced courses and enrichment programs for students who excel in mathematics and seek additional challenges beyond the classroom curriculum.",
      "Ultimately, our goal is to foster a positive relationship with math, one where students feel empowered and equipped to tackle any mathematical challenge they encounter. With over a thousand words of dedication, we invite you to begin this journey with No Fuss Tutors, where each word translates into a step towards academic success for every math student in Toronto.",
    ],
  },
];



export const USAPageContent = [
  {
    heading: 'Does your lorem ipsum text long for something a little meatier? Give our generator a try… it’s tasty!',
    content: [
      'Bacon ipsum dolor amet filet mignon shankle capicola pork chop turkey biltong. Hamburger fatback t-bone chuck flank. Pork venison cow alcatra t-bone andouille brisket kevin tenderloin prosciutto pig cupim sirloin chicken boudin. Meatloaf buffalo prosciutto chuck turducken fatback, doner chicken alcatra t-bone rump ribeye. Ground round chislic rump, landjaeger pork belly picanha short ribs leberkas buffalo.',
      'Kevin fatback turducken pig, boudin pork loin brisket turkey rump strip steak buffalo meatloaf ribeye sirloin chislic. Bacon pancetta pork belly sausage strip steak fatback filet mignon ham hock landjaeger. Short loin biltong sausage pork loin bresaola pancetta pork belly, turkey chislic buffalo alcatra meatball. Pig turkey hamburger buffalo, turducken sirloin cow jerky pancetta flank. Chislic bresaola alcatra leberkas, pig tongue tenderloin venison kevin jerky meatloaf swine flank brisket shankle. Rump pork chop turkey, ball tip pork filet mignon boudin meatloaf brisket.',
      'Sausage swine pork meatloaf kielbasa corned beef. Cupim shank tongue ribeye. T-bone porchetta tail spare ribs capicola ham. Buffalo andouille alcatra burgdoggen, frankfurter strip steak turkey prosciutto rump chuck salami tail biltong picanha pork chop.',
      'Shoulder sirloin turducken ball tip porchetta hamburger flank ribeye bresaola pork ham meatball chuck cupim. Rump burgdoggen pork tail jerky buffalo pork loin pastrami pork belly sirloin meatloaf turkey andouille. Pastrami doner cupim ham hock pancetta. Meatball sirloin alcatra ham short loin.',
    ],
  },
  {
    heading: 'Does your lorem ipsum text long for something a little meatier? Give our generator a try… it’s tasty!',
    content: [
      'Bacon ipsum dolor amet filet mignon shankle capicola pork chop turkey biltong. Hamburger fatback t-bone chuck flank. Pork venison cow alcatra t-bone andouille brisket kevin tenderloin prosciutto pig cupim sirloin chicken boudin. Meatloaf buffalo prosciutto chuck turducken fatback, doner chicken alcatra t-bone rump ribeye. Ground round chislic rump, landjaeger pork belly picanha short ribs leberkas buffalo.',
      'Kevin fatback turducken pig, boudin pork loin brisket turkey rump strip steak buffalo meatloaf ribeye sirloin chislic. Bacon pancetta pork belly sausage strip steak fatback filet mignon ham hock landjaeger. Short loin biltong sausage pork loin bresaola pancetta pork belly, turkey chislic buffalo alcatra meatball. Pig turkey hamburger buffalo, turducken sirloin cow jerky pancetta flank. Chislic bresaola alcatra leberkas, pig tongue tenderloin venison kevin jerky meatloaf swine flank brisket shankle. Rump pork chop turkey, ball tip pork filet mignon boudin meatloaf brisket.',
      'Sausage swine pork meatloaf kielbasa corned beef. Cupim shank tongue ribeye. T-bone porchetta tail spare ribs capicola ham. Buffalo andouille alcatra burgdoggen, frankfurter strip steak turkey prosciutto rump chuck salami tail biltong picanha pork chop.',
      'Shoulder sirloin turducken ball tip porchetta hamburger flank ribeye bresaola pork ham meatball chuck cupim. Rump burgdoggen pork tail jerky buffalo pork loin pastrami pork belly sirloin meatloaf turkey andouille. Pastrami doner cupim ham hock pancetta. Meatball sirloin alcatra ham short loin.',
    ],
  },
  {
    heading: 'Does your lorem ipsum text long for something a little meatier? Give our generator a try… it’s tasty!',
    content: [
      'Bacon ipsum dolor amet filet mignon shankle capicola pork chop turkey biltong. Hamburger fatback t-bone chuck flank. Pork venison cow alcatra t-bone andouille brisket kevin tenderloin prosciutto pig cupim sirloin chicken boudin. Meatloaf buffalo prosciutto chuck turducken fatback, doner chicken alcatra t-bone rump ribeye. Ground round chislic rump, landjaeger pork belly picanha short ribs leberkas buffalo.',
      'Kevin fatback turducken pig, boudin pork loin brisket turkey rump strip steak buffalo meatloaf ribeye sirloin chislic. Bacon pancetta pork belly sausage strip steak fatback filet mignon ham hock landjaeger. Short loin biltong sausage pork loin bresaola pancetta pork belly, turkey chislic buffalo alcatra meatball. Pig turkey hamburger buffalo, turducken sirloin cow jerky pancetta flank. Chislic bresaola alcatra leberkas, pig tongue tenderloin venison kevin jerky meatloaf swine flank brisket shankle. Rump pork chop turkey, ball tip pork filet mignon boudin meatloaf brisket.',
      'Sausage swine pork meatloaf kielbasa corned beef. Cupim shank tongue ribeye. T-bone porchetta tail spare ribs capicola ham. Buffalo andouille alcatra burgdoggen, frankfurter strip steak turkey prosciutto rump chuck salami tail biltong picanha pork chop.',
      'Shoulder sirloin turducken ball tip porchetta hamburger flank ribeye bresaola pork ham meatball chuck cupim. Rump burgdoggen pork tail jerky buffalo pork loin pastrami pork belly sirloin meatloaf turkey andouille. Pastrami doner cupim ham hock pancetta. Meatball sirloin alcatra ham short loin.',
    ],
  },
  {
    heading: 'Does your lorem ipsum text long for something a little meatier? Give our generator a try… it’s tasty!',
    content: [
      'Bacon ipsum dolor amet filet mignon shankle capicola pork chop turkey biltong. Hamburger fatback t-bone chuck flank. Pork venison cow alcatra t-bone andouille brisket kevin tenderloin prosciutto pig cupim sirloin chicken boudin. Meatloaf buffalo prosciutto chuck turducken fatback, doner chicken alcatra t-bone rump ribeye. Ground round chislic rump, landjaeger pork belly picanha short ribs leberkas buffalo.',
      'Kevin fatback turducken pig, boudin pork loin brisket turkey rump strip steak buffalo meatloaf ribeye sirloin chislic. Bacon pancetta pork belly sausage strip steak fatback filet mignon ham hock landjaeger. Short loin biltong sausage pork loin bresaola pancetta pork belly, turkey chislic buffalo alcatra meatball. Pig turkey hamburger buffalo, turducken sirloin cow jerky pancetta flank. Chislic bresaola alcatra leberkas, pig tongue tenderloin venison kevin jerky meatloaf swine flank brisket shankle. Rump pork chop turkey, ball tip pork filet mignon boudin meatloaf brisket.',
      'Sausage swine pork meatloaf kielbasa corned beef. Cupim shank tongue ribeye. T-bone porchetta tail spare ribs capicola ham. Buffalo andouille alcatra burgdoggen, frankfurter strip steak turkey prosciutto rump chuck salami tail biltong picanha pork chop.',
      'Shoulder sirloin turducken ball tip porchetta hamburger flank ribeye bresaola pork ham meatball chuck cupim. Rump burgdoggen pork tail jerky buffalo pork loin pastrami pork belly sirloin meatloaf turkey andouille. Pastrami doner cupim ham hock pancetta. Meatball sirloin alcatra ham short loin.',
    ],
  },
  {
    heading: 'Does your lorem ipsum text long for something a little meatier? Give our generator a try… it’s tasty!',
    content: [
      'Bacon ipsum dolor amet filet mignon shankle capicola pork chop turkey biltong. Hamburger fatback t-bone chuck flank. Pork venison cow alcatra t-bone andouille brisket kevin tenderloin prosciutto pig cupim sirloin chicken boudin. Meatloaf buffalo prosciutto chuck turducken fatback, doner chicken alcatra t-bone rump ribeye. Ground round chislic rump, landjaeger pork belly picanha short ribs leberkas buffalo.',
      'Kevin fatback turducken pig, boudin pork loin brisket turkey rump strip steak buffalo meatloaf ribeye sirloin chislic. Bacon pancetta pork belly sausage strip steak fatback filet mignon ham hock landjaeger. Short loin biltong sausage pork loin bresaola pancetta pork belly, turkey chislic buffalo alcatra meatball. Pig turkey hamburger buffalo, turducken sirloin cow jerky pancetta flank. Chislic bresaola alcatra leberkas, pig tongue tenderloin venison kevin jerky meatloaf swine flank brisket shankle. Rump pork chop turkey, ball tip pork filet mignon boudin meatloaf brisket.',
      'Sausage swine pork meatloaf kielbasa corned beef. Cupim shank tongue ribeye. T-bone porchetta tail spare ribs capicola ham. Buffalo andouille alcatra burgdoggen, frankfurter strip steak turkey prosciutto rump chuck salami tail biltong picanha pork chop.',
      'Shoulder sirloin turducken ball tip porchetta hamburger flank ribeye bresaola pork ham meatball chuck cupim. Rump burgdoggen pork tail jerky buffalo pork loin pastrami pork belly sirloin meatloaf turkey andouille. Pastrami doner cupim ham hock pancetta. Meatball sirloin alcatra ham short loin.',
    ],
  },
  {
    heading: 'Does your lorem ipsum text long for something a little meatier? Give our generator a try… it’s tasty!',
    content: [
      'Bacon ipsum dolor amet filet mignon shankle capicola pork chop turkey biltong. Hamburger fatback t-bone chuck flank. Pork venison cow alcatra t-bone andouille brisket kevin tenderloin prosciutto pig cupim sirloin chicken boudin. Meatloaf buffalo prosciutto chuck turducken fatback, doner chicken alcatra t-bone rump ribeye. Ground round chislic rump, landjaeger pork belly picanha short ribs leberkas buffalo.',
      'Kevin fatback turducken pig, boudin pork loin brisket turkey rump strip steak buffalo meatloaf ribeye sirloin chislic. Bacon pancetta pork belly sausage strip steak fatback filet mignon ham hock landjaeger. Short loin biltong sausage pork loin bresaola pancetta pork belly, turkey chislic buffalo alcatra meatball. Pig turkey hamburger buffalo, turducken sirloin cow jerky pancetta flank. Chislic bresaola alcatra leberkas, pig tongue tenderloin venison kevin jerky meatloaf swine flank brisket shankle. Rump pork chop turkey, ball tip pork filet mignon boudin meatloaf brisket.',
      'Sausage swine pork meatloaf kielbasa corned beef. Cupim shank tongue ribeye. T-bone porchetta tail spare ribs capicola ham. Buffalo andouille alcatra burgdoggen, frankfurter strip steak turkey prosciutto rump chuck salami tail biltong picanha pork chop.',
      'Shoulder sirloin turducken ball tip porchetta hamburger flank ribeye bresaola pork ham meatball chuck cupim. Rump burgdoggen pork tail jerky buffalo pork loin pastrami pork belly sirloin meatloaf turkey andouille. Pastrami doner cupim ham hock pancetta. Meatball sirloin alcatra ham short loin.',
    ],
  },
];

export const USAPageQuickLinks = {
  pages: [
    {
      pageName: 'English Tutor',
      pageLink: '/online-english-tutor',
    },
    {
      pageName: 'Reading Tutor',
      pageLink: '/online-reading-tutor',
    },
    {
      pageName: 'Writing Tutor',
      pageLink: '/online-writing-tutor',
    },
    {
      pageName: 'Math Tutor',
      pageLink: '/online-math-tutor',
    },
    {
      pageName: 'Science Tutor',
      pageLink: '/online-science-tutor',
    },
  ],
  worksheets: [
    {
      worksheetsName: 'All Worksheets',
      worksheetsLink: '/worksheets-for-kids',
    },
    {
      worksheetsName: 'Preschool',
      worksheetsLink: '/preschool-worksheets',
    },
    {
      worksheetsName: 'Kindergarten',
      worksheetsLink: '/worksheets-for-kindergarten',
    },
    {
      worksheetsName: 'Grade 1',
      worksheetsLink: '/grade-1-worksheets',
    },
    {
      worksheetsName: 'Grade 2',
      worksheetsLink: '/grade-2-worksheets',
    },
    {
      worksheetsName: 'Grade 3',
      worksheetsLink: '/grade-3-worksheets',
    },
    {
      worksheetsName: 'Reading',
      worksheetsLink: '/reading-worksheets',
    },
    {
      worksheetsName: 'Writing',
      worksheetsLink: '/writing-worksheets',
    },
    {
      worksheetsName: 'Math',
      worksheetsLink: '/math-worksheets',
    },
    {
      worksheetsName: 'Science',
      worksheetsLink: '/science-worksheets',
    },
  ],
};

export const CaliPageContent = [
  {
    heading: 'Connect With Hundreds Of Professional Tutors In California',
    content: [
      'Looking for the best online tutor for kids California has to offer? There are many tutors out there, and finding just the perfect one for your child can sometimes be a bit difficult. Yet, it is important for you as a parent to choose the right tutor for your child, or else they may neither enjoy nor understand their lessons.',
    ],
  },
  {
    content: [
      'No Fuss Tutors helps you find and book an expert private tutor for home tuition in multiple subjects at any level, with ease and at the best price. We not only find the best tutor but also continuously track your child’s progress and keep you updated regarding the same. Make the right choice with No Fuss Tutors’ smart tutoring program in California, and watch your child improve their grades and increase confidence.',
    ],
  },
  {
    content: ['To get rid of all your doubts, book a free consultation today!'],
  },
  {
    heading: 'Why Choose Our Online Tutoring In California',
    content: [
      'A little too many schools in California are understaffed. Even though teachers give their best, it might not be enough for your child. The education system might be too complex for certain children too. On top of that, there is a varying distribution of experienced and qualified educators across schools in California. This makes many parents turn towards tutoring for their child but the high-cost and all the fuss involved can make them rethink their decision to find a tutor.',
    ],
  },
  {
    content: [
      'Online tutoring is surely a simple and revolutionary way to provide education to students. It allows them to nurture their competency in the comfort of their private time without any interference or distraction. Online tutoring is the most brilliant solution for families looking to make sure that their children don’t get behind as the school year continues.',
    ],
  },
  {
    content: [
      'Some of the features offered by No Fuss Tutors as part of our out-of-the-box online tutoring are listed below:',
    ],
  },
  {
    heading: 'Flexible Schedule',
    content: [
      'Our tutors are, in most cases, available 24/7 to assist students. Moreover, with online learning, students can consult their tutors and get immediate help in their studies, whether it’s in assignments or tests they’re working on.',
    ],
  },
  {
    heading: 'Better Time Management',
    content: [
      'Rush-hour commutes can be truly horrible, which is why the elimination of travel time for both parties is an outstanding feature of e-learning. You also save on transport costs and also end up opting for a more eco-friendly and sustainable option, especially if you don’t travel by public transport.',
    ],
  },
  {
    heading: 'No Location Constraints',
    content: [
      'Let’s say you’re on a family holiday and your child has a big test coming up, as soon as you return. Our online tutoring lets them cover their syllabus from any location and at any time. Also, we let students find the perfect tutors from our extensive network of hundreds, instead of just the few tutors living in their locality. Indeed, students can be matched with experts, according to their needs and without being limited by the location in which they live.',
    ],
  },
  {
    heading: 'Cost-Effective',
    content: [
      'Our online tutoring is much more reasonable than hiring an individual tutor offline. Why – you ask? Since there is no wastage of travel time and the schedule of online classes is followed strictly, our tutors can teach more students in less time. That’s why we cost as low as $30 an hour without compromising on the quality of teaching.',
    ],
  },
  {
    heading: 'Personal Growth',
    content: [
      'At No Fuss Tutors, our guidance doesn’t stop at just teaching - we also help your child improve Social skills and give them ample practice of communicating effectively. We enable our students to become comfortable with asking for help and, at the same time, more receptive to instruction and feedback on their work.',
    ],
  },
  {
    content: [
      'Select your private tutor from our networks of 500+ top-rated tutors available in the best virtual tutoring center California has, for truly personalized tutoring.',
    ],
  },
  {
    heading: 'Expert Tutors For Multiple Subjects',
    content: [
      'Your search for a tutor for different subjects ends here, whether it’s math, English, science, or any other language tutor such as the top Spanish tutor in California. Our tutors are well updated with the syllabus and curriculum of every school board in California such as the California County Boards of Education, Education Legal Alliance, National School Boards Association, and more.',
    ],
  },
  {
    content: [
      'California contains multiple types of school districts. The most prevalent are Unified Districts (K-12) that contain both elementary and high schools, High School Districts (9-12) that contain only high schools, and Elementary Districts (K-6 or K-8) that contain only elementary schools. At No Fuss Tutors, we have offerings for all.',
    ],
  },
  {
    content: [
      'For elementary school students, we have English and math tutors in California who teach the fundamentals of writing, introduction to Spanish & French, offer a learn-to-read program, fundamentals of mathematics, and more.',
    ],
  },
  {
    content: [
      'For middle school students, we have expert English and writing tutors in California, who teach grammar, vocabulary, syntax, accelerated mathematics, introduction to essay writing, and advanced languages',
    ],
  },
  {
    content: [
      'For high school students, we have language tutors who help with fluency and advanced essay writing, math tutors who help with tricky concepts, and science tutors in California who teach chemistry, physics, and biology.',
    ],
  },
  {
    content: [
      'With us, you can get a tutor 24/7 in all subjects. We help hundreds of students get better grades every day, contact us if you want the same for your child!',
    ],
  },
  {
    heading: 'Top-Rated Test Prep Tutors In California',
    content: [
      'Why restrict yourself to the tutor the staff recommended at your school? Boost your SAT or ACT scores with proven SAT and ACT tutors in California, many of whom are a part of the No Fuss Tutors family, consistently helping students reach their determined testing goals.',
    ],
  },
  {
    content: [
      'The SAT is provided in the United States and internationally as a paper-based test about 8 times a year, including once in the winter. No Fuss gets you in touch with the best SAT Tutors in California. The ACT, which stands for American College Testing, is an alternative, equally important, and accepted college admissions exam that measures English, math, reading, and science reasoning abilities.',
    ],
  },
  {
    heading: 'Get Admission To Californian Universities',
    content: [
      'It’s hard to get into any university if you don’t know what they’re looking for. Most parents and students we work with have no idea about how the holistic review process works, and hence, spend so much time and money on all the not-so-important things just to make their child “look good” for college applications. To prevent students and parents from spending money on unproductive practices, No Fuss Tutors guides them in the right direction to make their career bright and helps them to get 100% success.',
    ],
  },
  {
    heading: 'Benefits Of Connecting With No Fuss Tutors',
    content: [
      'At No Fuss Tutors, we only work with the very best tutors, who are able to make it through our rigorous screening process - every tutor undergoes tough assessment criteria and interview with us. This is why our tutors are so perfectly trained to be compassionate and enthusiastic towards students of all backgrounds, especially young children. They understand exactly your child’s tuition needs.',
    ],
  },
  {
    content: [
      'Our tutors make sure that their teaching is instilling confidence in students and making them enjoy the subject. We track achievements and progress with easy-to-use measurement tools. We offer the highest quality at the cheapest hourly rates. Moreover, you can have access to our large collection of free online worksheets for all grades and subjects too, to supplement education. No Fuss Tutors is constantly rated 5-stars for our tutoring services',
    ],
  },
  {
    content: [
      'So, why wait? Call us, drop a text, or directly book a free consultation with us where we can discuss your needs and connect you with the ultimate tutor for your child, all within a few hours.',
    ],
  },
];

export const CaliPageQuickLinks = {
  pages: [
    {
      pageName: 'English Tutor',
      pageLink: '/online-english-tutor',
    },
    {
      pageName: 'Reading Tutor',
      pageLink: '/online-reading-tutor',
    },
    {
      pageName: 'Writing Tutor',
      pageLink: '/online-writing-tutor',
    },
    {
      pageName: 'Math Tutor',
      pageLink: '/online-math-tutor',
    },
    {
      pageName: 'Science Tutor',
      pageLink: '/online-science-tutor',
    },
  ],
  worksheets: [
    {
      worksheetsName: 'All Worksheets',
      worksheetsLink: '/worksheets-for-kids',
    },
    {
      worksheetsName: 'Preschool',
      worksheetsLink: '/preschool-worksheets',
    },
    {
      worksheetsName: 'Kindergarten',
      worksheetsLink: '/worksheets-for-kindergarten',
    },
    {
      worksheetsName: 'Grade 1',
      worksheetsLink: '/grade-1-worksheets',
    },
    {
      worksheetsName: 'Grade 2',
      worksheetsLink: '/grade-2-worksheets',
    },
    {
      worksheetsName: 'Grade 3',
      worksheetsLink: '/grade-3-worksheets',
    },
    {
      worksheetsName: 'Reading',
      worksheetsLink: '/reading-worksheets',
    },
    {
      worksheetsName: 'Writing',
      worksheetsLink: '/writing-worksheets',
    },
    {
      worksheetsName: 'Math',
      worksheetsLink: '/math-worksheets',
    },
    {
      worksheetsName: 'Science',
      worksheetsLink: '/science-worksheets',
    },
  ],
};
export const FloridaPageQuickLinks = {
  pages: [
    {
      pageName: 'English Tutor',
      pageLink: '/online-english-tutor',
    },
    {
      pageName: 'Reading Tutor',
      pageLink: '/online-reading-tutor',
    },
    {
      pageName: 'Writing Tutor',
      pageLink: '/online-writing-tutor',
    },
    {
      pageName: 'Math Tutor',
      pageLink: '/online-math-tutor',
    },
    {
      pageName: 'Science Tutor',
      pageLink: '/online-science-tutor',
    },
  ],
  worksheets: [
    {
      worksheetsName: 'All Worksheets',
      worksheetsLink: '/worksheets-for-kids',
    },
    {
      worksheetsName: 'Preschool',
      worksheetsLink: '/preschool-worksheets',
    },
    {
      worksheetsName: 'Kindergarten',
      worksheetsLink: '/worksheets-for-kindergarten',
    },
    {
      worksheetsName: 'Grade 1',
      worksheetsLink: '/grade-1-worksheets',
    },
    {
      worksheetsName: 'Grade 2',
      worksheetsLink: '/grade-2-worksheets',
    },
    {
      worksheetsName: 'Grade 3',
      worksheetsLink: '/grade-3-worksheets',
    },
    {
      worksheetsName: 'Reading',
      worksheetsLink: '/reading-worksheets',
    },
    {
      worksheetsName: 'Writing',
      worksheetsLink: '/writing-worksheets',
    },
    {
      worksheetsName: 'Math',
      worksheetsLink: '/math-worksheets',
    },
    {
      worksheetsName: 'Science',
      worksheetsLink: '/science-worksheets',
    },
  ],
};
export const FloridaPageContent = [
  {
    heading: 'Want to Find an Experienced Expert Tutor in Florida?',
    content: [
      'Learning skills can be challenging at nearly every level of education, be it elementary school, middle school, or high school. This gives rise to many reasons why parents choose tutoring for their children. Some parents feel they are unable to help their children with schoolwork. Others may find their children are more receptive to working through school struggles with another person. Whatever their concern be, No Fuss Tutors can help. We offer tutoring solutions that allow students to not just pass subjects but to master them.',
    ],
  },
  {
    heading: 'Our High-Quality, Affordable Tutoring Programs',
    content: [
      "Tutoring can help strengthen subject comprehension, boost confidence, and build important learning skills. Our programs help students achieve precisely this, by giving them the individual attention that they don't get in a crowded classroom. Children who struggle to keep up, as well as those who aren't challenged enough, we offer guidance to all.",
    ],
  },
  {
    content: [
      'The effectiveness of tutoring programs offered by us is in the intimacy of the connection between two minds in joint exploration of a concept. Our tutors attempt to understand the thinking of the student in order to evaluate the degree of comprehension of an idea that he/she possesses and hopefully increase it. This can be unifying as the two people commune intellectually.',
    ],
  },
  {
    content: [
      'Our tutoring programs in Florida are the best-ever that you can get your child enrolled in. As parents, you will it awe-inspiring to see your child’s courage as they will work through their confusion and frustration to find and understand the solutions to various problems. So, if you need a qualified and well-understanding tutor for kids in Florida, who will teach your child the skills needed to deal with the challenges of school and help develop critical thinking and learning skills that are used for life, No Fuss Tutors is your destination. Book a free consultation today and get closer to finding an affordable tutor.',
    ],
  },
  {
    heading: 'Advantages of Online Tutoring With Us',
    content: [
      'Online Tutoring has never been so simple, with technology creating new opportunities to learn. It renders all types of assistance, coming from a tutor who belongs to a global network of individuals who are trained in particular subjects. Here are some of the top benefits of online tutoring that make this new method the number one choice for thousands of parents and students:',
    ],
  },
  {
    heading: '24x7 Availability',
    content: [
      'Learning can happen anytime and anywhere. You do not have to wait until a scheduled time for problem-solving, rather you can get your child’s doubts cleared at any moment you choose. Our tutoring services are available for every second of your time!',
    ],
  },
  {
    heading: 'Cheapest Rates and High-Quality Service',
    content: [
      'The affordability of our courses helps you make big savings. Not to mention, your time and transportation cost are not wasted.',
    ],
  },
  {
    heading: 'Convenience',
    content: [
      'A few basic requirements such as a computer and an internet connection, and your child can learn from anywhere! They may study an extra hour, indulge in extra-curricular activities, or spend time with their families with the time that they save.',
    ],
  },
  {
    heading: 'Selection',
    content: [
      'Your child gets the benefit of working with multiple tutors who specialize in different areas, choosing the ones where he/she needs help. At No Fuss Tutors, we offer you a network of 500+ tutors!',
    ],
  },
  {
    content: [
      'Do these advantages sound interesting to you? Explore our website to learn more about our services, what our happy customers have to say, and to request a tutor near you!',
    ],
  },
  {
    heading: 'Find The Top Math Tutor Florida Has',
    content: [
      "Many students find math extremely difficult as it is a subject that doesn't need to be studied but practiced. The absence of practice can hamper your child’s mathematical skills easily. Thus, it is wise to work regularly under the guidance of a math tutor. No Fuss Tutors helps you find the best subject experts who have been reviewed and trusted by thousands of parents and their children. You can find top-rated math tutors here.",
    ],
  },
  {
    heading: 'Best Language Tutors in Florida',
    content: [
      "Language exchanges are great, but they're imperfect. Their loose, informal nature makes it a challenge to keep each session focused, relevant, and impactful to the learning of each individual. Our language tutor will be more effective at helping you improve your language skills, especially when compared to an untrained language exchange partner.",
    ],
  },
  {
    content: [
      'English language plays an essential role in our lives as it helps in communication. It is the main language for studying any subject all over the world. Find the best fluent English tutors in Florida here.',
    ],
  },
  {
    content: [
      'Language tutoring, quite simply, is the best way to get well-organized, highly-targeted language practice with a native speaker. With an affordable online language tutor, your child can dictate what they wish to learn and how they wish to receive feedback on their performance.',
    ],
  },
  {
    content: [
      'Florida Hispanics who are fluent in Spanish earn significantly higher incomes and are less likely to live in poverty than those who speak only English, according to a new study. Find the best and fluent Spanish tutors in Florida here.',
    ],
  },
  {
    content: [
      'With language, it is really important to understand and master reading, writing, listening, and speaking. To hire top-rated writing tutors in Florida, reading tutors in Florida, and many other skilled tutors, we connect you with the best tutors in the industry.',
    ],
  },
  {
    heading: 'Expert ACT and SAT Tutors in Florida',
    content: [
      'ACT stands for “American College Test”. The ACT is now accepted by almost every college in America, meaning a high score can help you get into the school of your dreams. The ACT is considered an easier test to study for than the SAT, so check out the No Fuss SAT/ACT tutors in Florida and get started now!',
    ],
  },
  {
    heading: 'Choosing Florida Universities',
    content: [
      'Selecting the right university after high school is a very important decision for every student. The University of Florida is one of the most selective public colleges or universities in the US, with a 45.90% acceptance rate, an average of 1275 on the SAT, an average of 29 on the ACT, and a rough average unweighted GPA of 3.7. The average acceptance rate for colleges in Florida is 50.27% and the enrollment rate is 26.98%. The University of South Florida-Sarasota-Manatee is the tightest school to admit with a 23.61% acceptance rate and the University of Miami has the second-lowest acceptance rate of 27.13%.',
    ],
  },
  {
    content: [
      'Our tutors help you cut through these tough numbers and guide you through the process of getting admitted to these universities.',
    ],
  },
  {
    heading: 'Why No Fuss Tutors?',
    content: [
      'The vast majority of students who use No Fuss Tutor’s services would recommend them to a friend. Our happy customers have NEVER said that tutors weren’t helpful or students couldn’t reach one when they needed help, simply because we never let this happen. We have repeatedly shown significant improvements in key student success metrics such as course completion too.',
    ],
  },
  {
    content: [
      'No Fuss Tutors helps you replace every tutoring center Florida has, by giving students anytime, anywhere access to academic help from experts. We extend your resources with on-time online tutoring, helping students arrive prepared, succeed in class, and work towards career goals.',
    ],
  },
  {
    content: [
      'With No Fuss tutors, you can provide 24x7 live, personalized tutoring in core subjects, and expert guidance for improving students’ writing — from essays to resumes. With so many benefits ready to be availed, what are you waiting for? Book a free consultation today!',
    ],
  },
];
export const NewYorkPageQuickLinks = {
  pages: [
    {
      pageName: 'English Tutor',
      pageLink: '/online-english-tutor',
    },
    {
      pageName: 'Reading Tutor',
      pageLink: '/online-reading-tutor',
    },
    {
      pageName: 'Writing Tutor',
      pageLink: '/online-writing-tutor',
    },
    {
      pageName: 'Math Tutor',
      pageLink: '/online-math-tutor',
    },
    {
      pageName: 'Science Tutor',
      pageLink: '/online-science-tutor',
    },
  ],
  worksheets: [
    {
      worksheetsName: 'All Worksheets',
      worksheetsLink: '/worksheets-for-kids',
    },
    {
      worksheetsName: 'Preschool',
      worksheetsLink: '/preschool-worksheets',
    },
    {
      worksheetsName: 'Kindergarten',
      worksheetsLink: '/worksheets-for-kindergarten',
    },
    {
      worksheetsName: 'Grade 1',
      worksheetsLink: '/grade-1-worksheets',
    },
    {
      worksheetsName: 'Grade 2',
      worksheetsLink: '/grade-2-worksheets',
    },
    {
      worksheetsName: 'Grade 3',
      worksheetsLink: '/grade-3-worksheets',
    },
    {
      worksheetsName: 'Reading',
      worksheetsLink: '/reading-worksheets',
    },
    {
      worksheetsName: 'Writing',
      worksheetsLink: '/writing-worksheets',
    },
    {
      worksheetsName: 'Math',
      worksheetsLink: '/math-worksheets',
    },
    {
      worksheetsName: 'Science',
      worksheetsLink: '/science-worksheets',
    },
  ],
};

export const NewYorkPageContent = [
  {
    heading: 'Help Your Child Grow With The Best Tutor New York Has',
    content: [
      'We at No Fuss Tutors believe in providing the best online tutor experience by bringing compassionate mentors closer to students. This is what distinguishes our services from the other ones that you may find in your locality. Another key differentiating factor is that we don’t just offer tutors for kids, but the complete package so that not only the child but the parents as well become an integral part of their academic journey.',
    ],
  },
  {
    content: [
      'Parents who hire tutors expect them to be sufficiently qualified, and to have undergone some form of formal training. That’s why the tutors who work for us are checked and thoroughly vetted, ensured to have relevant tutoring experience, and willing to continue to invest in their tutoring skills after their initial recruitment. We believe that every tutor can improve some aspect of their teaching and by providing regular training opportunities, we ensure that yours stays ahead of the game and offers the best possible service to you.',
    ],
  },
  {
    content: [
      'Our tutoring program in New York saves you from becoming a passive spectator. It provides you with an opportunity that lets you become a proactive part of your child’s scholastic progress, by empowering you to make any modifications that you feel are required to your child’s course plan. Our team is readily available to welcome your requests and contribute to your child’s progress – exactly how you want it. We believe in transparency and don’t consider our job complete until your child achieves academic accomplishment.',
    ],
  },
  {
    content: [
      'No Fuss Tutors’ live sessions are kept between the students and the tutors exclusively, ensuring that children get the tutor’s complete attention and achieve their goals all through a child-focused approach, offering a learning environment that promises growth accompanied by the best technology. Each session is truly seamless and the only problem is that math statement, not a lagging screen.',
    ],
  },
  {
    content: [
      'We are against the one-size-fits-all approach and so we provide a tailored experience that’s uniquely developed for your child. This is so because we believe that each child has the potential to achieve the best in life, but not all get the optimum environment that’s conducive to their growth. We are determined to provide you with just that, in the form of one successful and happy student at a time.',
    ],
  },
  {
    heading: 'Here’s How An Online Tutor Can Be A Complete Game-Changer',
    content: [
      'Parents often find themselves delving deep into thought about the most suitable tutoring method for their children, which is understandable. We are here to clear the fog and present the top reasons why you should opt for online tutoring and how it will quickly become your favored choice of tutoring.',
    ],
  },
  {
    heading: '1. Improved results compared to regular home tutoring :',
    content: [
      'The paradigm shift of children, parents, and faculty that makes them opt for online tutoring happened because online tutoring caters to individual demands of the student, making it one of the most important assets in general. Online tutoring sessions have proven to adopt a child-friendly approach that reflects a continuous and comprehensive delivery needed to penetrate the tangible progress of a child’s mind and their understanding of coursework.',
    ],
  },
  {
    content: [
      'Parents gain access to be present and participate during the online session with online tutors for a better assessment of their child’s performance.',
    ],
  },
  {
    heading: '2. A conducive environment for learning :',
    content: [
      'Online tutoring extends an optimized method of learning and understanding as a whole. Normally, the classroom environment is noisy and aggravating, and may hinder the focus of students. The knowledge imparted by the tutor goes down the drain! Online tutoring occurs in the quiet and comfort of one’s own personal space. Here, any kind of distraction is completely nulled. This helps studentsbenefit every second of their session, making them achieve and absorb learning in a more focused manner.',
    ],
  },
  {
    heading: '3. More flexible than classroom learning :',
    content: [
      'Online tutoring provides students with an individualistic approach and recognizes their individual needs. However, in the case of classroom tutoring, both parties are limited to a more or less fixed curriculum, whereas it’s not so in online tutoring designs. Diagrams, pictorial explanations, etc. are just some of the elements infused in every session that an online tutor is assigned to teach. There is always active participation taking place and great continuity - students never miss their sessions even when on vacation, which certainly earns a medal!',
    ],
  },
  {
    heading: 'Find The Ace Math Tutor New York Has',
    content: [
      'Every child does not learn the same way. Some pick up material they are being taught with relative ease as opposed to others. Fortunately, for students who feel overwhelmed and may risk falling a little behind in math, NFT math tutors can do more than just help with their coursework. We aim to cultivate their interest in math and with adequate practice, make it blossom into a love for the subject. Math is often dreaded by students but with us - not anymore.',
    ],
  },
  {
    heading: 'The Best English Tutors New York Offers Today',
    content: [
      'All language acquisition takes place with children learning reading and writing. No Fuss Tutors provides you with the perfect reading tutors in New York. Being patient with children and making sure that they acquire language for the long term, using worksheets and playful alphabet activities so that children not only learn but love the process in itself.',
    ],
  },
  {
    content: [
      'Also, students aiming to score in exams may find the need to write terrific essays adeptly within the confines of their surroundings and in a time-bound manner. We offer writing tutors in New York, to make sure that each essay they deliver, delivers their very best.',
    ],
  },
  {
    heading: 'Looking for an Affordable Spanish Tutor in New York?',
    content: [
      'Many schools in New York have Spanish as one of the main subjects, and a few teach Spanish off formal education pathway. Our Spanish tutors are qualified to impart language skills to students in all school districts as well as the top school boards of NYC. Children, especially those not having a Spanish-speaking background, consider Spanish a tough cookie.',
    ],
  },
  {
    content: [
      'Our tutors make sure that vocabulary is built properly and pronunciations are easily interpreted, to aid in sentence formation and speaking Spanish. Imparting the rules of language in a step-by-step manner hence promotes the formation of a solid foundation for future learning to build upon.',
    ],
  },
  {
    heading: 'Trained ACT and SAT Tutors in New York',
    content: [
      'ACT is the abbreviation for “American College Test”. The ACT is now recognized by virtually every college in the United States, meaning a high enough score will ensure that the child gets into the school of their dreams. The ACT is considered much easier to prepare for in comparison to the SAT. No Fuss Tutors offer ACT tutors in New York to pave way for your child’s success in the future.',
    ],
  },
  {
    heading: 'Why Choose No-Fuss Tutors ?',
    content: [
      'A good tutor can only be defined by parents and students - whether they feel comfortable in their tutor’s presence, the course-work is easily understandable, and there’s an overall improvement in their academic performance. A good tutoring company will be one that will promise on establishing regular contact and providing decent customer service. How we achieve this, is what separates us from others.',
    ],
  },
  {
    content: [
      'Your search for the best tutors New York has to offer ends here! Most parents are working and coming home to a proper meal is a task in itself, let alone teaching the child – it often becomes a luxury. That’s why they hire tutors, and we aim to provide highly trained ones. Exam time is often considered as dread time by students, but with No Fuss Tutors by your side, it becomes performance time instead.',
    ],
  },
];
