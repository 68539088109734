import React from 'react';

const Success: React.FC = () => {
  return (
    <div className="success">
      <div className="success__text">Thanks for booking!</div>
      <div className="success__text">Our team will reach out to confirm your tutor </div>
      <div className="success__text">
        <span className="success__bold">within 3 business days.</span>
      </div>
      <div className="success__text">Please text or call (917) 905-3402 with any questions or concerns.</div>
    </div>
  );
};

export default Success;
