import React, { useEffect, useRef } from 'react';

const Balloons: React.FC = () => {
  interface IBalloon {
    balloonImages: string[];
    balloonImage: string;
    width: number;
    height: number;
    x: number;
    y: number;
    directionX: number;
    directionY: number;
    markedForDeletion: boolean;
    image: HTMLImageElement;
    spriteWidth: number;
    spriteHeight: number;
    bWords?: string[];
    bWord?: string;
    dWords?: string[];
    dWord?: string;
    randomColors: number[];
    color: string;
  }
  interface IExplosion {
    x: number;
    y: number;
    frame: number;
    markedForDeletion: boolean;
    image: HTMLImageElement;
    sound: HTMLAudioElement;
    spriteWidth: number;
    spriteHeight: number;
    size: number;
    frameInterval: number;
    timeSinceLastFrame: number;
  }
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const collisionCanvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (canvasRef.current && collisionCanvasRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');

      const collisionCanvas = collisionCanvasRef.current;
      const collisionContext = collisionCanvas.getContext('2d');

      if (context && collisionContext) {
        canvas.width = window.innerWidth;
        canvas.height = 500;

        collisionCanvas.width = window.innerWidth;
        collisionCanvas.height = 500;

        let score = 0;
        let gameOver = false;

        let timeToNextBalloon = 0;
        let balloonInterval = 2000;
        let timeToNextDBalloon = 0;
        let balloonDInterval = 3000;
        let lastTime = 0;

        let balloons: Balloon[] = [];

        class Balloon implements IBalloon {
          constructor() {
            this.balloonImages = [
              'blue-balloon.png',
              'pink-balloon.png',
              'green-balloon.png',
              'purple-balloon.png',
              'orange-balloon.png',
            ];
            this.balloonImage = this.balloonImages[Math.floor(Math.random() * this.balloonImages.length)];
            this.width = 180;
            this.height = 200;
            this.x = canvas.width;
            this.y = Math.random() * (canvas.height - this.height - 90);
            this.directionX = Math.random() * 1 + 1;
            this.directionY = Math.random() * 3 - 1.5;
            this.markedForDeletion = false;
            this.image = new Image();
            this.image.src = `https://nft-image-pool.s3.us-east-2.amazonaws.com/worksheets/${this.balloonImage}`;
            this.spriteWidth = 200;
            this.spriteHeight = 320;
            this.bWords = [
              'baby',
              'beach',
              'bread',
              'bed',
              'bean',
              'bike',
              'bent',
              'back',
              'boy',
              'been',
              'body',
              'bite',
              'best',
              'bake',
              'but',
              'bat',
              'beef',
              'black',
              'bird',
              'ball',
              'bark',
              'bear',
              'bar',
              'boil',
              'boost',
              'big',
              'belt',
              'bee',
              'berry',
              'brown',
              'bye',
              'bell',
              'band',
              'blue',
              'box',
              'blink',
              'barn',
              'bus',
              'bad',
              'bun',
            ];
            this.bWord = this.bWords[Math.floor(Math.random() * this.bWords.length)];
            // this.dWords = ['dog', 'door', 'dance', 'dot', 'den', 'radar', 'red', 'dream'];
            // this.dWord = this.dWords[Math.floor(Math.random() * this.dWords.length)];
            this.randomColors = [
              Math.floor(Math.random() * 255),
              Math.floor(Math.random() * 255),
              Math.floor(Math.random() * 255),
            ];
            this.color = 'rgb(' + this.randomColors[0] + ',' + this.randomColors[1] + ',' + this.randomColors[2] + ')';
          }
          bWord: string;
          randomColors: number[];
          color: string;
          bWords: string[];
          balloonImages: string[];
          balloonImage: string;
          width: number;
          height: number;
          x: number;
          y: number;
          directionX: number;
          directionY: number;
          markedForDeletion: boolean;
          image: HTMLImageElement;
          spriteWidth: number;
          spriteHeight: number;

          update() {
            if (this.y < 0 || this.y > canvas.height - this.height - 90) {
              this.directionY = this.directionY * -1;
            }
            this.x -= this.directionX;
            this.y += this.directionY;
            if (this.x < 0 - this.width) this.markedForDeletion = true;

            if (this.x < 0 - this.width) gameOver = true;
          }
          draw() {
            collisionContext!.fillStyle = this.color;
            collisionContext!.fillRect(this.x - 40, this.y - 10, this.width + 80, this.height + 200);
            context!.drawImage(this.image, this.x, this.y, this.width, this.height);
            context!.fillStyle = 'black';
            context!.font = '48px Fellix-Bold';
            context!.textAlign = 'center';
            context!.fillText(this.bWord, this.x + 87, this.y + 100);
          }
        }

        let balloonsD: BalloonD[] = [];
        class BalloonD implements IBalloon {
          constructor() {
            this.balloonImages = [
              'blue-balloon.png',
              'pink-balloon.png',
              'green-balloon.png',
              'purple-balloon.png',
              'orange-balloon.png',
            ];
            this.balloonImage = this.balloonImages[Math.floor(Math.random() * this.balloonImages.length)];
            this.width = 180;
            this.height = 200;
            this.x = canvas.width;
            this.y = Math.random() * (canvas.height - this.height - 90);
            this.directionX = Math.random() * 1 + 1;
            this.directionY = Math.random() * 3 - 1.5;
            this.markedForDeletion = false;
            this.image = new Image();
            this.image.src = `https://nft-image-pool.s3.us-east-2.amazonaws.com/worksheets/${this.balloonImage}`;
            this.spriteWidth = 200;
            this.spriteHeight = 320;
            this.dWords = [
              'dog',
              'door',
              'dome',
              'done',
              'dinner',
              'donut',
              'do',
              'dot',
              'drink',
              'dock',
              'dip',
              'duck',
              'dust',
              'dirt',
              'dollar',
              'doll',
              'desk',
              'dunk',
              'dark',
              'deer',
              'dear',
              'dream',
              'draw',
              'duel',
              'dent',
            ];
            this.dWord = this.dWords[Math.floor(Math.random() * this.dWords.length)];
            this.randomColors = [
              Math.floor(Math.random() * 255),
              Math.floor(Math.random() * 255),
              Math.floor(Math.random() * 255),
            ];
            this.color = 'rgb(' + this.randomColors[0] + ',' + this.randomColors[1] + ',' + this.randomColors[2] + ')';
          }
          dWord: string;
          randomColors: number[];
          color: string;
          dWords: string[];
          balloonImages: string[];
          balloonImage: string;
          width: number;
          height: number;
          x: number;
          y: number;
          directionX: number;
          directionY: number;
          markedForDeletion: boolean;
          image: HTMLImageElement;
          spriteWidth: number;
          spriteHeight: number;

          update() {
            if (this.y < 0 || this.y > canvas.height - this.height - 90) {
              this.directionY = this.directionY * -1;
            }
            this.x -= this.directionX;
            this.y += this.directionY;
            if (this.x < 0 - this.width) this.markedForDeletion = true;
          }
          draw() {
            collisionContext!.fillStyle = this.color;
            collisionContext!.fillRect(this.x, this.y, this.width, this.height + 200);
            context!.drawImage(this.image, this.x, this.y, this.width, this.height);
            context!.fillStyle = 'black';
            context!.font = '48px Fellix-Bold';
            context!.textAlign = 'center';
            context!.fillText(this.dWord, this.x + 87, this.y + 100);
          }
        }

        let explosions: Explosion[] = [];
        class Explosion implements IExplosion {
          constructor(x: number, y: number, size: number) {
            this.image = new Image();
            this.image.src = 'https://nft-image-pool.s3.us-east-2.amazonaws.com/worksheets/balloon-boom.png';
            this.spriteWidth = 200;
            this.spriteHeight = 179;
            this.size = size;

            this.x = x;
            this.y = y;
            this.frame = 0;
            this.sound = new Audio();
            this.sound.src = 'https://nft-image-pool.s3.us-east-2.amazonaws.com/worksheets/Pop.wav';
            this.timeSinceLastFrame = 0;
            this.frameInterval = 100;
            this.markedForDeletion = false;
          }
          x: number;
          y: number;
          frame: number;
          markedForDeletion: boolean;
          image: HTMLImageElement;
          sound: HTMLAudioElement;
          spriteWidth: number;
          spriteHeight: number;
          size: number;
          frameInterval: number;
          timeSinceLastFrame: number;
          update(deltatime: number) {
            if (this.frame === 0) this.sound.play();
            this.timeSinceLastFrame += deltatime;
            if (this.timeSinceLastFrame > this.frameInterval) {
              this.frame++;
              this.timeSinceLastFrame = 0;
              if (this.frame > 5) this.markedForDeletion = true;
            }
          }
          draw() {
            context!.drawImage(
              this.image,
              this.frame * this.spriteWidth,
              0,
              this.spriteWidth,
              this.spriteHeight,
              this.x,
              this.y,
              this.size,
              this.size,
            );
          }
        }

        const drawScore = function (): void {
          context.fillStyle = 'rgba(255, 255, 255, 0.8)';
          context.strokeStyle = 'black';
          context.lineWidth = 2;
          context.font = '34px Fellix-Bold';
          context.strokeText('Score: ' + score, 100, 75);
          context.fillText('Score: ' + score, 100, 75);
        };

        const resetGame = () => {
          score = 0;
          gameOver = false;
          timeToNextBalloon = 0;
          timeToNextDBalloon = 0;
          lastTime = 0;
          balloons = [];
          balloonsD = [];
          explosions = [];
          animate(0);
          playAgainBtn.style.display = 'none';
        };

        // Create a "Play Again" button
        const playAgainBtn = document.createElement('button');
        playAgainBtn.textContent = 'Play Again';
        playAgainBtn.style.position = 'absolute';
        playAgainBtn.style.left = `${canvas.width / 2 - 100}px`;
        playAgainBtn.style.top = `${canvas.height / 1}px`;
        playAgainBtn.style.display = 'none';
        playAgainBtn.style.padding = '30px 48px';
        playAgainBtn.style.backgroundColor = '#4CAF50';
        playAgainBtn.style.color = 'white';
        playAgainBtn.style.border = 'none';
        playAgainBtn.style.cursor = 'pointer';
        playAgainBtn.style.fontSize = '20px';
        playAgainBtn.style.fontFamily = 'Fellix-Bold, sans-serif';
        playAgainBtn.style.fontWeight = 'bold';
        playAgainBtn.style.zIndex = '1000';
        playAgainBtn.style.boxShadow = '0 2px 5px rgba(0, 0, 0, 0.3)';
        playAgainBtn.style.borderRadius = '5px';
        playAgainBtn.style.transition = 'background-color 0.3s, transform 0.3s, box-shadow 0.3s';
        playAgainBtn.addEventListener('click', () => {
          resetGame();
          updateBanner();
        });

        playAgainBtn.addEventListener('mouseenter', () => {
          playAgainBtn.style.backgroundColor = '#66BB6A';
          playAgainBtn.style.transform = 'scale(1.1)';
          playAgainBtn.style.boxShadow = '0 4px 10px rgba(0, 0, 0, 0.3)';
        });

        playAgainBtn.addEventListener('mouseleave', () => {
          playAgainBtn.style.backgroundColor = '#4CAF50';
          playAgainBtn.style.transform = 'scale(1)';
          playAgainBtn.style.boxShadow = '0 2px 5px rgba(0, 0, 0, 0.3)';
        });

        document.body.appendChild(playAgainBtn);

        const links = document.querySelectorAll('a');

        // Add a click event listener to each link
        links.forEach((link) => {
          link.addEventListener('click', () => {
            // Remove the playAgainBtn button

            playAgainBtn.remove();
          });
        });

        const drawGameOver = function (): void {
          context.textAlign = 'center';
          context.fillStyle = 'rgba(255, 255, 255, 0.8)';
          context.strokeStyle = 'black';
          context.lineWidth = 3;
          context.font = '36px Fellix-Bold';
          context.strokeText('GAME OVER', canvas.width / 2, canvas.height / 3);
          context.fillText('GAME OVER', canvas.width / 2, canvas.height / 3);

          context.font = '28px Fellix-Bold';
          context.strokeText('Your score is: ' + score, canvas.width / 2, canvas.height / 3 + 40);
          context.fillText('Your score is: ' + score, canvas.width / 2, canvas.height / 3 + 40);

          // Show the "Play Again" button
          playAgainBtn.style.display = 'block';
        };

        // const cursor = document.querySelector<HTMLElement>('.cursor');

        const screenShake = (duration: number, intensity: number) => {
          const start = new Date().getTime();
          const shake = () => {
            const elapsed = new Date().getTime() - start;
            if (elapsed < duration) {
              const x = (Math.random() * 2 - 1) * intensity;
              const y = (Math.random() * 2 - 1) * intensity;
              canvas.style.transform = `translate(${x}px, ${y}px)`;
              requestAnimationFrame(shake);
            } else {
              canvas.style.transform = 'translate(0px, 0px)';
            }
          };
          shake();
        };

        const updateBanner = () => {
          const banner = document.getElementById('instructions-banner');

          if (banner) {
            const bannerTexts = Array.from(banner.querySelectorAll('.instructions-banner__text')).map(
              (element) => element as HTMLElement,
            );

            if (gameOver) {
              if (banner !== null) {
                banner.classList.add('game-over');
              }
              bannerTexts[0].textContent =
                'You clicked a balloon that starts with D -- Only click balloons that start with B';
              bannerTexts[1].style.display = 'none';
            } else {
              if (banner !== null) {
                banner.classList.remove('game-over');
              }
              bannerTexts[0].textContent = 'Only click balloons that START with the letter "b"';
              bannerTexts[1].style.display = 'block';
            }
          }
        };

        window.addEventListener('click', function (e) {
          const canvasRect = collisionContext.canvas.getBoundingClientRect();
          const canvasX = Math.floor(e.clientX - canvasRect.left);
          const canvasY = Math.floor(e.clientY - canvasRect.top);

          const detectPixelColor = collisionContext.getImageData(canvasX, canvasY, 1, 1);
          console.log(detectPixelColor);
          const pc = detectPixelColor.data;

          if (gameOver) {
            score = 0;
            gameOver = false;
          }

          balloons.forEach((object) => {
            if (
              object.randomColors[0] === pc[0] &&
              object.randomColors[1] === pc[1] &&
              object.randomColors[2] === pc[2]
            ) {
              object.markedForDeletion = true;
              score++;
              explosions.push(new Explosion(object.x, object.y, object.width));
            }
          });
          balloonsD.forEach((object) => {
            if (
              object.randomColors[0] === pc[0] &&
              object.randomColors[1] === pc[1] &&
              object.randomColors[2] === pc[2]
            ) {
              gameOver = true;
              screenShake(200, 5);
              updateBanner();
            }
          });
        });

        const animate = function (timestamp: number): void {
          context!.clearRect(0, 0, canvas.width, canvas.height);
          collisionContext!.clearRect(0, 0, canvas.width, canvas.height);
          let deltatime = timestamp - lastTime;
          lastTime = timestamp;
          timeToNextBalloon += deltatime;
          timeToNextDBalloon += deltatime;

          if (timeToNextDBalloon > balloonDInterval) {
            balloonsD.push(new BalloonD());
            timeToNextDBalloon = 0;
          }
          if (timeToNextBalloon > balloonInterval) {
            balloons.push(new Balloon());
            timeToNextBalloon = 0;
          }
          drawScore();
          [...balloonsD, ...explosions, ...balloons].forEach((object) => object.update(deltatime));
          [...balloonsD, ...explosions, ...balloons].forEach((object) => object.draw());
          balloons = balloons.filter((object) => !object.markedForDeletion);
          balloonsD = balloonsD.filter((object) => !object.markedForDeletion);
          explosions = explosions.filter((object) => !object.markedForDeletion);

          if (!gameOver) requestAnimationFrame(animate);
          else drawGameOver();
        };
        animate(0);
      }
    }
  }, []);

  return (
    <>
      <div id="instructions-banner" className="instructions-banner">
        <div className="instructions-banner__text">Only click balloons that START with the letter "b"</div>
        <div className="instructions-banner__text">Don't let "b" balloons get to the other side!</div>
      </div>

      <div className="canvas-wrapper">
        {/* <img
        src="https://nft-image-pool.s3.us-east-2.amazonaws.com/worksheets/pencil-cursor.png"
        alt="pencil"
        className="cursor"
      /> */}

        <canvas id="canvas1" ref={collisionCanvasRef} className="canvas collision-canvas"></canvas>
        <canvas id="canvas1" ref={canvasRef} className="canvas"></canvas>
      </div>
    </>
  );
};

export default Balloons;
