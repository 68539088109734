import React, { useState, useRef, useEffect } from 'react';
import Card from '../Card';
import { Resources } from '../../Models';
import { useDisplayData } from '../../Hooks/useDisplayData';
import { FaArrowUp } from 'react-icons/fa';

export function useScrollListener(callback: () => void) {
  useEffect(() => {
    function handleScroll() {
      callback();
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
}

type RenderAllDocumentsProps = {
  documents: Resources[];
  title: string;
  source?: string;
};

const RenderAllDocuments: React.FC<RenderAllDocumentsProps> = ({ title, documents, source }) => {
  const { setActiveCard } = useDisplayData();
  const scrollHeightRef = useRef<HTMLDivElement>(null);
  const CARDS = documents.map((singleCardData: Resources, index: number) => {
    return (
      <div
        key={index}
        onClick={() => (source !== 'downloads-page' ? setActiveCard(singleCardData) : null)}
        className="card__wrapper"
      >
        <Card
          link={singleCardData.link}
          src={singleCardData.src}
          webp={singleCardData.webp}
          alt={singleCardData.alt}
          type={singleCardData.type.toLowerCase()! as 'worksheets' | 'assessments'}
          title={singleCardData.title}
          subject={singleCardData.subject}
          grade={singleCardData.grade}
          onClick={() => {}}
          _id={singleCardData._id}
          source={source}
        ></Card>
      </div>
    );
  });
  const [displayCards, setDisplayCards] = useState(CARDS.slice(0, 8));
  const [showScrollButton, setShowScrollButton] = useState(false);

  const handleScroll = () => {
    const scrollPosition = window.pageYOffset;
    const windowHeight = window.innerHeight;
    const scrollHeight = document.documentElement.scrollHeight;

    if (scrollHeightRef.current) {
      const scrollHeightRefOffset = scrollHeightRef.current.offsetTop;
      const distanceFromTop = scrollHeightRefOffset - scrollPosition;

      // Load more cards if the user has scrolled within 300px of the top of the scrollHeightRef element
      if (distanceFromTop < 100) {
        setDisplayCards((prevCards) => [...prevCards, ...CARDS.slice(prevCards.length, prevCards.length + 8)]);
      }
    }
    setShowScrollButton(scrollPosition > 200);
  };

  useScrollListener(handleScroll);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="downloads-page" ref={scrollHeightRef}>
      <h2 className="downloads-page__title">{title}</h2>
      {source === 'downloads-page' &&
        (documents.length > 0 ? (
          <p className="downloads-page__sub-heading">
            Here you can view and re-download previously downloaded documents.
          </p>
        ) : (
          <p className="downloads-page__sub-heading">You haven't downloaded any documents. Try downloading some!</p>
        ))}
      <div className="downloads-page__cards-container">{displayCards}</div>

      {showScrollButton && (
        <button className="scroll-to-top-button" onClick={scrollToTop}>
          <FaArrowUp />
        </button>
      )}
    </div>
  );
};

export default RenderAllDocuments;
