//! returns true if valid
export const ValidateEmail = (email:string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

//! returns user input as numeric values only
export const NumbersOnly = (str:string) => {
  return str.trim().replace(/[^0-9]+/g, '').replace(/\s/g, '');
};

//! returns true if valid US number
export const ValidatePhone = (phone:string) => {
  let numToCheck = NumbersOnly(phone);
  
  if (parseInt(numToCheck[0]) === 1) {
    numToCheck = numToCheck.substring(1);
  }

  return numToCheck.length === 10 ? true : false;
};