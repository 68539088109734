import React, { useEffect, useState, useRef } from 'react';
import Hero from '../../../Components/Partials/Hero';
import MetaData from '../../../Components/MetaData';
import {
  TorontoMathPageContent,
  TorontoMathPageHeaderContent,
  TorontoPageContent,
  TorontoPageHeaderContent,
  TorontoPageQuickLinks,
} from '../../../Models';
import { generateID } from '../../../Utils';
import ShareIcons from '../../Blog/ShareIcons';
import SideContent from '../../Partials/SideContent';
import ConsultationWidget from '../../Partials/ConsultationWidget';
import { Link } from 'react-router-dom';
import OnlineReview from '../../Home/OnlineReview';
import Tutors from '../../Partials/TutorCards/Tutors';
import Subject from '../../SciencePage/Subject';

const TorontoMath: React.FC = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth < 1200 ? true : false);
  const [isLinkVisibleFromTop, setIsLinkVisibleFromTop] = useState(true);
  const [isLinkVisibleFromBottom, setIsLinkVisibleFromBottom] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const scrollHeightRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    //! scroll to top of page
    window.scrollTo(0, 0);
  }, []);

  window.addEventListener('scroll', () => {
    let lastKnownScrollPosition = window.scrollY;

    if (scrollHeightRef.current && lastKnownScrollPosition < 615) {
      setIsLinkVisibleFromTop(true);
    } else {
      setIsLinkVisibleFromTop(false);
    }
    if (scrollHeightRef.current && lastKnownScrollPosition > scrollHeightRef.current.scrollHeight - 815) {
      setIsLinkVisibleFromBottom(true);
    } else {
      setIsLinkVisibleFromBottom(false);
    }
  });

  window.addEventListener('resize', () => {
    setWidth(window.innerWidth);
    if (isMobileScreen && window.innerWidth > 1199) {
      setIsMobileScreen(false);
    }
    if (!isMobileScreen && window.innerWidth < 1200) {
      setIsMobileScreen(true);
    }
  });

  return (
    <>
      <MetaData
        pageTitle="Math Tutors in Toronto"
        postTitle="Math Tutors in Toronto"
        description="Book An Affordable Math Tutor in Toronto. Get a free consultation today!"
        image={'https://nft-development.netlify.app/assets/webp/logo.png'}
        url={'https://nofusstutors.com/math-tutor-toronto'}
        keywords={['Toronto Math Tutors', 'Math Tutoring Toronto', 'Math Tuition Toronto']}
      />
      <div className="toronto-location-page" ref={scrollHeightRef}>
        <Hero content={TorontoMathPageHeaderContent} />
        <div className="toronto-location-page">
          <ConsultationWidget source="form" />

          <div className="city-online-review-widget">
            <OnlineReview />
          </div>

          <Tutors />

          {TorontoMathPageContent.map((item, index) => (
            <section className="city-location-page__section" key={index}>
              <header className="city-location-page__header">
                <h2 className="city-location-page__heading">{item.heading || 'Default Heading'}</h2>
              </header>
              <article className="city-location-page__article">
                <p className="city-location-page__content">{item.content || 'Default content for this section.'}</p>
              </article>
            </section>
          ))}
        </div>
        <ConsultationWidget source="form" />
      </div>
    </>
  );
};

export default TorontoMath;
