import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useResourceFilters } from '../../Hooks/useResourceFilter';

import CloseIcon from '../../assets/close.svg';
import { URLGeneratorByFilter } from '../../Utils';
import { UpdatedFilterOptions } from '../../Models';
export interface FilterProps {
  name: string;
  filterOption: { label: string; value: string };
  children?: string;
}

const TabFilter: React.FC<FilterProps> = ({ name, filterOption, children }) => {
  const { resourceData, UpdateFilterOptions, FetchRelevantSubcategories, clearFilters } = useResourceFilters();
  const [childFilters, setChildFilters] = useState<string[]>([]);

  useEffect(() => {
    if (filterOption.value === resourceData.filters[name! as keyof UpdatedFilterOptions]) {
      let subcategories = FetchRelevantSubcategories(filterOption.value);
      setChildFilters(subcategories);
    } else {
      setChildFilters([]);
    }
  }, [resourceData.filters, resourceData.documents]);

  return (
    <div className="tab-filter__wrapper">
      <div className="tab-filter__filters">
        <div className="tab-filter__filter-link-wrapper">
          <Link
            to={`${`/${URLGeneratorByFilter(
              {
                ...resourceData.filters,
                [name]: filterOption.label,
              },
              name === 'subject' ? true : false,
            )}`}`}
          >
            <button
              className={
                // todo: refactor to condense this
                resourceData.filters.docType === filterOption.value
                  ? 'tab-filter__filter-option tab-filter__filter-option--doc-type-active'
                  : resourceData.filters.grade === filterOption.value
                  ? 'tab-filter__filter-option tab-filter__filter-option--active'
                  : resourceData.filters.subject?.toLowerCase() === filterOption.value.toLowerCase()
                  ? 'tab-filter__filter-option tab-filter__filter-option--active'
                  : resourceData.filters.subcategory?.toLowerCase() === filterOption.value.toLowerCase()
                  ? 'tab-filter__filter-option tab-filter__filter-option--active'
                  : 'tab-filter__filter-option'
              }
              onClick={() => {
                UpdateFilterOptions(
                  { entryKey: name, entryValue: filterOption.label },
                  name === 'subject' ? true : false,
                );
              }}
              name={name}
              value={filterOption.value}
            >
              {filterOption.label}
            </button>
          </Link>
          {name !== 'docType' && (
            <Link
              to={`${
                resourceData.filters.subcategory === undefined && resourceData.filters.subject === undefined
                  ? `${resourceData.filters.docType === 'Assessments' ? '/assessments' : '/worksheets-for-kids'}`
                  : `/${URLGeneratorByFilter({ ...resourceData.filters, [name]: undefined })}`
              }`}
            >
              <div
                className={
                  resourceData.filters.docType === filterOption.value
                    ? 'tab-filter__close-icon'
                    : resourceData.filters.grade === filterOption.value
                    ? 'tab-filter__close-icon'
                    : resourceData.filters.subject?.toLowerCase() === filterOption.value.toLowerCase()
                    ? 'tab-filter__close-icon'
                    : resourceData.filters.subcategory?.toLowerCase() === filterOption.value.toLowerCase()
                    ? 'tab-filter__close-icon'
                    : 'tab-filter__close-icon--hidden'
                }
                onClick={() => {
                  clearFilters(name);
                  if (name === 'subject') {
                    clearFilters('subcategory');
                  }
                }}
              >
                <i className="fa fa-close tab-filter__left-close"></i>
              </div>
            </Link>
          )}
        </div>
      </div>
      <section className="tab-filter__sub-categories">
        {children &&
          childFilters.length > 0 &&
          childFilters.map((singleFilter, index) => {
            return (
              <React.Fragment key={index}>
                <TabFilter filterOption={{ label: singleFilter, value: singleFilter }} name={children} />
              </React.Fragment>
            );
          })}
      </section>
    </div>
  );
};

export default TabFilter;
