import React from 'react';
import MetaData from '../../Components/MetaData';
import Hero from '../../Components/Partials/Hero';
import TestOverview from '../../Components/TestPrepPage/TestOverview';
import PerfectScore from '../../Components/TestPrepPage/PerfectScore';
import WhyTutoring from '../../Components/TestPrepPage/WhyTutoring';
import GoogleReviews from '../../Components/Partials/GoogleReview';
import Tutors from '../../Components/Partials/TutorCards/Tutors';
import ConsultationWidget from '../../Components/Partials/ConsultationWidget';
import ShareIcons from '../../Components/Blog/ShareIcons';
import SideContent from '../../Components/Partials/SideContent';
import { MathPageReview, TestPrepHeaderContent, StandardSubjectQuickLinks } from '../../Models';
import { generateID } from '../../Utils';

const TestPrep = () => {
  return (
    <div className="test-prep-page">
      <MetaData
        pageTitle="No Fuss Tutors | Online Test Prep Tutors"
        postTitle="Online Test Prep Tutors"
        description="Your Destination For Top Online Test Prep Tutors. Our mission is to make quality test prep tutors affordable for everyone!"
        image={'https://nft-development.netlify.app/assets/webp/logo.png'}
        url={'https://nofusstutors.com/onling-test-prep-tutor'}
        keywords={[
          'online test prep tutoring canada',
          'online test prep tutoring new york',
          'online test prep tutoring florida',
        ]}
      />
      <Hero content={TestPrepHeaderContent} />
      <TestOverview />
      <PerfectScore />
      <WhyTutoring />
      <GoogleReviews reviewContent={MathPageReview} />
      <Tutors />
      <ConsultationWidget source="form" />
      {
        <React.Fragment key={generateID()}>
          <aside className={'blog-page__side-content--mobile'}>
            <div className="blog-page__side-links">
              {StandardSubjectQuickLinks && (
                <SideContent
                  links={[...StandardSubjectQuickLinks.pages]}
                  worksheetsLinks={[...StandardSubjectQuickLinks.worksheets]}
                />
              )}
            </div>
            <div className="blog-page__share-icons-wrapper">
              <ShareIcons
                url="https://nofusstutors.com/online-test-prep-tutor"
                facebookQuote="No Fuss Tutoring has a dedicated network of tutors to help your kids improve their grades!"
              />
            </div>
          </aside>
        </React.Fragment>
      }
    </div>
  );
};

export default TestPrep;
