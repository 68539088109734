import React from 'react';
import AWSImage from '../Partials/AWSImage';

type TutoringProgramProps = {
  content: {
    cardHeading: string;
    icon: string;
    awsPrefix: string;
    picClass: string;
  }[];
  header: {
    heading: string;
    subHeading: string;
    headingContent: string;
  };
};

const TutoringProgram: React.FC<TutoringProgramProps> = ({ content, header }) => {
  const { heading, subHeading, headingContent } = header;

  return (
    <section className="tutoring-program">
      <h2 className="tutoring-program__heading">{heading}</h2>
      <h3 className="tutoring-program__sub-heading">{subHeading}</h3>
      <p className="tutoring-program__content">{headingContent}</p>
      <div className="tutoring-program__card-wrapper">
        {content.map((item, index) => {
          return (
            <div className="tutoring-program__card" key={index}>
              <AWSImage
                pngFile={item.icon}
                documentPrefix={item.awsPrefix}
                picClass={item.picClass}
                imageAlt={`${item.cardHeading} + icon`}
                hasWebp
              />

              <h5 className="tutoring-program__card-heading">{item.cardHeading}</h5>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default TutoringProgram;
