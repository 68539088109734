import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Banner from '../Components/Partials/Banner';
import { useUserData } from '../Hooks/useUserData';
import { GetDecryptedEmail } from '../Utils/GetDecryptedEmail';

const Subscription: React.FC = () => {
  const { userData, setSessionAfterPayment, updateUser } = useUserData();
  const [cookies] = useCookies(['email']);
  const [email, setEmail] = useState('initial');

  useEffect(() => {
    setEmail(GetDecryptedEmail(cookies.email));
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get('session_id');
    if (sessionId) {
      setSessionAfterPayment(sessionId);
      updateUser();
    }
  }, []);

  return email !== '' ? (
    <main className="subscribed-page">
      <Banner lineOne={'Successfully Subscribed!'} main imageName="worksheets_banner" alt="No Fuss Tutors banner" />
      {userData.message !== '' && (
        <div className="subscribed-page__content-wrapper">
          <svg className="subscribed-page__checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle className="subscribed-page__checkmark-circle" cx="26" cy="26" r="25" fill="none" />
            <path className="subscribed-page__checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
          </svg>
          <span className="subscribed-page__success-message">{userData.message + '\n'}</span>
          <Link to="/worksheets-for-kids">
            <p className="subscribed-page__worksheet-page-link">
              <i className="fas fa-arrow-left"></i>
              Go to Worksheets page
            </p>
          </Link>
        </div>
      )}
    </main>
  ) : (
    <Redirect to="/worksheets-for-kids" />
  );
};

export default Subscription;
