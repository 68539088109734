import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Banner from './Banner';
import ShareIcons from './ShareIcons';
import MetaData from '../../Components/MetaData';
import SignInModal from '../SignInModal';
import SideContent from '../Partials/SideContent';
import WorksheetsWidget from '../Partials/WorksheetsWidget';
import ConsultationWidget from '../Partials/ConsultationWidget';
import AWSImg from '../Partials/AWSImage';
import ErrorHandler from '../Partials/ErrorHandler';
import { GetBaseURL, generateID } from '../../Utils';
import { ErrorProps } from '../../Models';

type BlogPostProps = {
  content: {
    blogPostClassName: string;
    bannerLineOne: string;
    bannerLineTwo: string;
    bannerAuthor: string;
    bannerDate: string;
    bannerImageName: string;
    bannerImage: any;
    bannerAlt: string;
    metaDataPostTitle: string;
    metaDataPageTitle: string;
    metaDataDescription: string;
    metaDataImage: string;
    metaDataUrl: string;
    metaDataKeywords: string[];
    worksheetWidgetKeywords: string[];
    sideBarPageLinks?: {
      pageName: string;
      pageLink: string;
    }[];
    sideBarWorksheetsLinks?: {
      worksheetsName: string;
      worksheetsLink: string;
    }[];
    blogContent: {
      heading?: string;
      subHeading?: string;
      content?: string[];
      italicContent?: string[];
      boldContent?: string[];
      boldInlineContent?: string[];
      graphic?: { graphicSrc: string; graphicPrefix: string; graphicAlt: string };
      points?: string[];
      downloadDocumentName?: string;
    }[];
  };
};

const BlogPost: React.FC<BlogPostProps> = ({ content }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    blogPostClassName,
    bannerLineOne,
    bannerLineTwo,
    bannerAuthor,
    bannerDate,
    bannerImage,
    bannerImageName,
    bannerAlt,
    metaDataPostTitle,
    metaDataPageTitle,
    metaDataDescription,
    metaDataImage,
    metaDataUrl,
    metaDataKeywords,
    worksheetWidgetKeywords,
    blogContent,
    sideBarPageLinks,
    sideBarWorksheetsLinks,
  } = content;

  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth < 1200 ? true : false);
  const [isLinkVisibleFromTop, setIsLinkVisibleFromTop] = useState(true);
  const [isLinkVisibleFromBottom, setIsLinkVisibleFromBottom] = useState(false);
  const scrollHeightRef = useRef<HTMLDivElement>(null);
  const [displaySignUp, setDisplaySignUp] = useState(false);
  const [downloadSuccess, setDownloadSuccess] = useState(false);
  const [error, setError] = useState<ErrorProps>({
    isActive: false,
    message: '',
    type: 'error',
  });

  const resetError = () => {
    setError({
      isActive: false,
      message: '',
      type: 'error',
    });
  };

  window.addEventListener('scroll', () => {
    let lastKnownScrollPosition = window.scrollY;
    if (scrollHeightRef.current && lastKnownScrollPosition < 500) {
      setIsLinkVisibleFromTop(true);
    } else {
      setIsLinkVisibleFromTop(false);
    }
    // if (scrollHeightRef.current && lastKnownScrollPosition > scrollHeightRef.current.scrollHeight - 915) { //!This was the old break point. Keeping until I verify update is good
    // if (scrollHeightRef.current && lastKnownScrollPosition > scrollHeightRef.current.scrollHeight - 1015) {
    if (scrollHeightRef.current && lastKnownScrollPosition > scrollHeightRef.current.scrollHeight - 650) {
      setIsLinkVisibleFromBottom(true);
    } else {
      setIsLinkVisibleFromBottom(false);
    }
  });

  window.addEventListener('resize', () => {
    if (isMobileScreen && window.innerWidth > 1199) {
      setIsMobileScreen(false);
    }
    if (!isMobileScreen && window.innerWidth < 1200) {
      setIsMobileScreen(true);
    }
  });

  const downloadDocument = async (documentName: string) => {
    const getDocument = await fetch(`${GetBaseURL()}/api/aws/s3?key=${process.env.REACT_APP_API_KEY}`, {
      method: 'POST',
      body: JSON.stringify({ bucket: 'resourcehub', key: documentName }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (getDocument.status === 200) {
      const blob = await getDocument.blob();
      const pdfURL = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = pdfURL;
      link.download = documentName;
      link.click();
      setDownloadSuccess(true);
      setError({
        isActive: true,
        type: 'success',
        message: 'Successfully downloaded! Click the link below to see more on our worksheets page!',
      });
    }
  };

  return (
    <div className={blogPostClassName} ref={scrollHeightRef}>
      <MetaData
        pageTitle={metaDataPageTitle}
        postTitle={metaDataPostTitle}
        description={metaDataDescription}
        image={metaDataImage}
        url={metaDataUrl}
        keywords={metaDataKeywords}
      />
      <Banner
        lineOne={bannerLineOne}
        lineTwo={bannerLineTwo}
        author={bannerAuthor}
        date={bannerDate}
        imageName={bannerImageName}
        image={bannerImage}
        alt={bannerAlt}
        awsPrefix={'blog/banners'}
      />
      <div className="blog-container">
        {!isMobileScreen && (
          <aside
            className={
              isLinkVisibleFromTop
                ? 'blog-page__side-content blog-page__side-content--fix-top'
                : isLinkVisibleFromBottom
                ? 'blog-page__side-content blog-page__side-content--fix-bottom blog__side-content'
                : 'blog-page__side-content blog__side-content'
            }
          >
            {/* <a className="blog-page__free-consult-button" href="#form">
          Free Consult
        </a> */}
            <div className="blog-page__side-links">
              {sideBarPageLinks && sideBarWorksheetsLinks ? (
                <SideContent links={sideBarPageLinks} worksheetsLinks={sideBarWorksheetsLinks} />
              ) : null}
            </div>
            <div className="blog-page__share-icons-wrapper">
              <ShareIcons
                url="welcome.nofusstutors.com"
                facebookQuote="I read this great article on the No Fuss Tutors blog!"
              />
            </div>
          </aside>
        )}

        <div className={`blog-page__articles-wrapper ${isMobileScreen && 'blog__margin--mobile'}`}>
          {blogContent.map((item, index) => {
            return (
              <article className="blog-page__article" key={index}>
                <section>
                  {item.heading ? <h3 className="heading">{item.heading}</h3> : ''}
                  {item.subHeading ? <h4 className="blog-page__h4-heading">{item.subHeading}</h4> : ''}
                  {item.content
                    ? item.content.map((item, index) => {
                        return <p key={index}>{item}</p>;
                      })
                    : ''}
                  {item.italicContent
                    ? item.italicContent.map((item, index) => {
                        return (
                          <p key={index} className="blog-page__article blog-page__article--quote">
                            {item}
                          </p>
                        );
                      })
                    : ''}
                  {item.boldContent
                    ? item.boldContent.map((item, index) => {
                        return (
                          <p key={index} className="blog-page__article blog-page__article--bold">
                            {item}
                          </p>
                        );
                      })
                    : ''}
                  {item.boldInlineContent
                    ? item.boldInlineContent.map((item, index) => {
                        return (
                          <p key={index} className="blog-page__article blog-page__article--inline">
                            {item}
                          </p>
                        );
                      })
                    : ''}
                  {item.points
                    ? item.points.map((item, index) => {
                        return (
                          <ul className="blog-page__points" key={index}>
                            <li>{item}</li>
                          </ul>
                        );
                      })
                    : ''}
                  {item.graphic && (
                    <AWSImg
                      pngFile={item.graphic.graphicSrc}
                      imageAlt={item.graphic.graphicAlt}
                      documentPrefix={item.graphic.graphicPrefix}
                      key={index}
                      picClass="blog__graphic"
                    />
                  )}
                  {item.downloadDocumentName && (
                    <React.Fragment key={generateID()}>
                      {downloadSuccess && item.downloadDocumentName.toLowerCase().includes('multiplication') && (
                        <>
                          {error.isActive && (
                            <ErrorHandler type={error.type} message={error.message} handler={resetError} />
                          )}
                          <Link to="/multiplication-worksheets" target="_blank">
                            {' '}
                            <button className="blog-page__download" style={{ marginTop: '2rem' }}>
                              Check out our Multiplication Worksheets!
                            </button>
                          </Link>
                        </>
                      )}
                      {!downloadSuccess && (
                        <button className="blog-page__download" onClick={() => setDisplaySignUp(true)}>
                          Download
                        </button>
                      )}
                      {displaySignUp && (
                        <SignInModal
                          download={downloadDocument}
                          documentName={item.downloadDocumentName}
                          HideModal={() => setDisplaySignUp(false)}
                          source="blog"
                        ></SignInModal>
                      )}
                    </React.Fragment>
                  )}
                  {index === Math.floor(blogContent.length / 3) && (
                    <WorksheetsWidget keywords={worksheetWidgetKeywords}></WorksheetsWidget>
                  )}

                  {index === Math.floor(blogContent.length / (1 + 1 / 3)) && <ConsultationWidget></ConsultationWidget>}
                </section>
              </article>
            );
          })}
        </div>

        <ConsultationWidget source="form" />
        {isMobileScreen && (
          <React.Fragment key={generateID()}>
            <aside className={'blog-page__side-content--mobile'}>
              {/* <a className="blog-page__free-consult-button" href="#form">
          Free Consult
        </a> */}
              <div className="blog-page__side-links">
                {sideBarPageLinks && sideBarWorksheetsLinks ? (
                  <SideContent links={sideBarPageLinks} worksheetsLinks={sideBarWorksheetsLinks} />
                ) : null}
              </div>
              <div className="blog-page__share-icons-wrapper">
                <ShareIcons
                  url="welcome.nofusstutors.com"
                  facebookQuote="I read this great article on the No Fuss Tutors blog!"
                />
              </div>
            </aside>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default BlogPost;
