import React from 'react';
import Hero from '../Components/Partials/Hero';
import Advantages from '../Components/Partials/Advantages';
import Tutors from '../Components/Partials/TutorCards/Tutors';
import Journey from '../Components/Home/Journey';
import AdvancedLearning from '../Components/Home/AdvancedLearning';
import Subject from '../Components/Home/Subjects';
import Locations from '../Components/Home/Locations';
import Pricing from '../Components/Home/Pricing';
import MetaData from '../Components/MetaData';
import OnlineReview from '../Components/Home/OnlineReview';
import SignInModal from '../Components/SignInModal';
import ConsultationWidget from '../Components/Partials/ConsultationWidget';
import { useDisplayData } from '../Hooks/useDisplayData';
import { useScript } from '../Hooks/useScript';
import { HomePageContent, nftAdvantages } from '../Models';

const Home = () => {
  const { displayData, hideModal, startBodyScroll } = useDisplayData();
  return (
    <>
      <div
        className={displayData.showModal ? 'main-overlay' : 'transparent'}
        onClick={() => {
          hideModal();
          startBodyScroll();
        }}
      ></div>
      <MetaData
        pageTitle="No Fuss Tutors | Online Tutoring"
        postTitle="No Fuss Tutors | 1-on-1 Online Tutoring"
        description="Online Math Tutors. Online Reading Tutors. Online Writing Tutors. Our goal is to get every student an A"
        image={'https://nft-development.netlify.app/assets/webp/logo.png'}
        url={'https://nofusstutors.com/'}
        keywords={['math tutors toronto', 'math tutors new york', 'reading tutors']}
      />
      <Hero content={HomePageContent} />
      {/* <Advantages
        advantages={nftAdvantages}
        sectionClassName="advantages advantages--home"
        wrapperClassName="advantages--home__wrapper advantages--home__wrapper--home"
      /> */}
      <ConsultationWidget source="form" />
      <OnlineReview />
      <AdvancedLearning />
      <Journey />
      <Tutors />
      <Subject />
      {/* <Locations />
      <Pricing /> */}

      <ConsultationWidget source="form" />
      {displayData.showModal && <SignInModal HideModal={hideModal} />}
    </>
  );
};

export default Home;
