import React, { useEffect, useState } from 'react';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import LogoWebp from '../assets/logo-original.webp';
import Logo from '../assets/logo-original.png';

import { useDisplayData } from '../Hooks/useDisplayData';
import { useCookies } from 'react-cookie';
import { useUserData } from '../Hooks/useUserData';
import { GetBaseURL } from '../Utils';
import { Subjects, Locations } from '../Models';
import NavDropdown from '../Components/Partials/NavDropdown';
import NavLink from '../Components/Partials/NavLink';
import Picture from './Partials/Picture';

const Header: React.FC<RouteComponentProps> = ({ location }) => {
  const { displayData, hideModal, showModal, setLoginType, stopBodyScroll, startBodyScroll } = useDisplayData();
  const { userData, logout, willRedirect } = useUserData();
  const [showNav, setShowNav] = useState(false);
  const [showNumbers, setShowNumbers] = useState(true);
  const [cookies] = useCookies(['email']);
  const [showUserOptions, setShowUserOptions] = useState(false);
  const [showSubjectsDropdown, setShowSubjectsDropdown] = useState(false);
  const [showLocationsDropdown, setShowLocationsDropdown] = useState(false);
  const [currentURL, setCurrentURL] = useState(location.pathname);

  //! ------- NEW BREAKPOINT STATE VALUES ----------
  const [isMobileNav, setIsMobileNav] = useState(window.innerWidth > 1023 ? false : true);

  useEffect(() => {
    if (!cookies.email) {
      setShowUserOptions(false);
    }
  }, [cookies.email]);

  useEffect(() => {
    setCurrentURL(location.pathname);
  }, [location.pathname]);

  const toggleNav = () => {
    setShowNav((prev: boolean) => {
      return prev === true ? false : true;
    });
  };

  const closeNav = () => {
    setShowNav(false);
    toggleSubjectsDropdown(true);
    toggleLocationsDropdown(true);
    numbersDropdown(true);
  };

  const toggleSubjectsDropdown = (close?: boolean) => {
    close
      ? setShowSubjectsDropdown(false)
      : setShowSubjectsDropdown((prev: boolean) => {
          return !prev;
        });
  };

  const toggleLocationsDropdown = (close?: boolean) => {
    close
      ? setShowLocationsDropdown(false)
      : setShowLocationsDropdown((prev: boolean) => {
          return !prev;
        });
  };

  const numbersDropdown = (close?: boolean) => {
    close
      ? setShowNumbers(true)
      : setShowNumbers((prev: boolean) => {
          return prev === true ? false : true;
        });
  };

  const HandleCustomerBilling = async (e: React.FormEvent) => {
    e.preventDefault();

    if (userData.customer_id !== '') {
      const request = await fetch(`${GetBaseURL()}/api/payments/customer-portal?key=${process.env.REACT_APP_API_KEY}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customer_id: userData.customer_id,
        }),
      });
      const response = await request.json();
      if (request.status === 200) {
        window.location.href = response.url;
      } else {
        console.error(response.error);
      }
    } else {
      console.error('Could not locate subscription.');
    }
  };

  const IsWorksheetPage = () => {
    return (
      currentURL.includes('worksheet') ||
      currentURL.includes('account') ||
      currentURL.includes('reset') ||
      currentURL.includes('premium') ||
      currentURL.includes('download') ||
      currentURL.includes('subscription') ||
      currentURL.includes('art-off') ||
      currentURL.includes('assessments') ||
      currentURL.includes('racing-game') ||
      currentURL.includes('ai-tutor')
    );
  };

  //!------- RESIZE LISTENERS ------------

  window.addEventListener('resize', () => {
    if (isMobileNav && window.innerWidth > 1023) {
      setIsMobileNav(false);
      closeNav();
    }
    if (!isMobileNav && window.innerWidth <= 1023) {
      setIsMobileNav(true);
      closeNav();
    }
  });

  return currentURL.includes('enroll') ? (
    <section className="header__nav-container--landing-page">
      <div
        className={displayData.showModal ? 'header__logo-wrapper header__logo-wrapper--hide' : 'header__logo-wrapper'}
      >
        <Link to="/" rel="noopener noreferrer">
          <Picture webp={LogoWebp} src={Logo} alt="No Fuss Tutors Logo" picClass="header__logo" />
        </Link>
      </div>{' '}
    </section>
  ) : (
    <div className={displayData.showModal ? 'header-overlay' : ''}>
      <div
        className={displayData.showModal ? 'overlay' : 'transparent'}
        onClick={() => {
          hideModal();
          startBodyScroll();
        }}
      ></div>
      <header className="header">
        {/* // ! top section of nav ======================================== */}
        <section
          className={displayData.showModal ? 'top-section hide-image' : 'header__contact-section'}
          style={IsWorksheetPage() ? { display: 'none' } : undefined}
        >
          <div className="header__contact-info">
            <div className="header__phone-email-wrapper">
              <div className="header__call-wrapper">
                <a href="tel:+16476968289" className="header__phone">
                  <i className="fa fa-phone"></i>
                  <span>Call or Text Us (647) 696-8289</span>
                </a>
              </div>

              <div className="header__email-wrapper">
                <a href="mailto:info@nofusstutors.com" className="header__email">
                  <i className="fa fa-envelope"></i>
                  <span className="email__text">info@nofusstutors.com</span>
                </a>
              </div>
            </div>
            <div className="header__g-star-wrapper">
              <i className="fa fa-google"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
          </div>
        </section>

        {/* // ! main section on nav ================================================ */}
        <section className="header__nav-container">
          <div
            className={
              displayData.showModal ? 'header__logo-wrapper header__logo-wrapper--hide' : 'header__logo-wrapper'
            }
          >
            <Link to="/" rel="noopener noreferrer">
              <Picture webp={LogoWebp} src={Logo} alt="No Fuss Tutors Logo" picClass="header__logo" />
            </Link>
          </div>

          <nav className="main-nav">
            <div
              className="main-nav__nav-items-wrapper"
              style={IsWorksheetPage() ? { display: 'none' } : { display: 'flex' }}
            >
              <div className="main-nav__desktop-container">
                <NavDropdown
                  title="Subjects"
                  options={Subjects}
                  handler={() => {
                    toggleSubjectsDropdown();
                  }}
                  toggleDropdown={() => {
                    toggleSubjectsDropdown();
                  }}
                  closeNav={toggleNav}
                  toggleState={showSubjectsDropdown}
                />
                <NavDropdown
                  title="Locations"
                  options={Locations}
                  handler={() => {
                    toggleLocationsDropdown();
                  }}
                  toggleDropdown={() => {
                    toggleLocationsDropdown();
                  }}
                  closeNav={toggleNav}
                  toggleState={showLocationsDropdown}
                />
                <NavLink href="/blog" title="Blog Home" text="Blog" />
                <NavLink href="/worksheets" title="Worksheets" text="Worksheets" />
              </div>
              <div
                className="phone-dropdown-container"
                onMouseEnter={() => {
                  numbersDropdown();
                }}
                onMouseLeave={() => {
                  numbersDropdown();
                }}
              >
                <div className="phone">
                  <a href="tel:+6476968289" className="main-number">
                    TO: (647) 696-8289
                  </a>
                  <i className="fas fa-caret-down"></i>
                </div>
                <div className={showNumbers ? 'phone-options' : 'phone-options phone-options--active__desktop'}>
                  <a href="tel:+3236452793">CAL: (323) 645-2793</a>
                  <a href="tel:+19179053402">NY: (917) 905-3402</a>
                  <a href="tel:+3053767679">FLA: (305) 376-7679</a>
                </div>
              </div>
            </div>
            <div
              className={
                displayData.showModal
                  ? 'main-nav__login-container main-nav__login-container--modal'
                  : 'main-nav__login-container'
              }
            >
              <div
                className="main-nav__cta-btn-wrapper"
                style={IsWorksheetPage() ? { display: 'flex' } : { display: 'none' }}
              >
                {!cookies.email && (
                  <button
                    data-cy="header__button--login"
                    className="main-nav__login-btn main-nav__nav-btn"
                    onClick={() => {
                      setLoginType({ login: true, signup: false });
                      showModal();
                      stopBodyScroll();
                    }}
                  >
                    Log in
                  </button>
                )}
                {!cookies.email && (
                  <button
                    data-cy="header__button--signup"
                    className="main-nav__sign-up-btn main-nav__nav-btn"
                    onClick={() => {
                      setLoginType({ login: false, signup: true });
                      showModal();
                      stopBodyScroll();
                    }}
                  >
                    Sign up
                  </button>
                )}
              </div>

              {cookies.email && userData.user_data.full_name !== '' && (
                <div
                  data-cy="header__div--accountOptions"
                  className="header__account-options"
                  onMouseEnter={() => setShowUserOptions(true)}
                  onMouseLeave={() => setShowUserOptions(false)}
                  style={IsWorksheetPage() ? { display: 'flex' } : { display: 'none' }}
                >
                  <i className="far fa-user user-icon"></i> {`${userData.user_data.full_name}`}
                  <i className={'fas fa-caret-down caret-down-icon'}></i>
                </div>
              )}
              {showUserOptions && (
                <div
                  className="header__user-options-wrapper"
                  onMouseEnter={() => setShowUserOptions(true)}
                  onMouseLeave={() => setShowUserOptions(false)}
                >
                  <ul className="header__user-options">
                    <li className="header__account-settings-link" onClick={() => setShowUserOptions(!showUserOptions)}>
                      <Link to="/account" data-cy="header__link--accountSettings">
                        Account Settings
                      </Link>
                    </li>
                    <li className="header__downloads-link" onClick={() => setShowUserOptions(!showUserOptions)}>
                      <Link to="/downloads" ata-cy="header__link--userDownloads">
                        Your Downloads
                      </Link>
                    </li>
                    {userData.subscription_id !== '' && (
                      <li className="header__manage-billing">
                        <p onClick={HandleCustomerBilling}>Manage Billing</p>
                      </li>
                    )}
                    <li className="header__sign-out-link">
                      <p
                        data-cy="header__a--signOut"
                        onClick={() => {
                          logout();
                        }}
                      >
                        Sign out
                      </p>
                    </li>
                  </ul>
                  {cookies.email && userData.user_data.full_name !== '' && !userData.subscription_active && (
                    <Link to="/premium">
                      <button className="header__account-upgrade-button">Upgrade</button>
                    </Link>
                  )}
                </div>
              )}
            </div>
          </nav>
        </section>

        {/* //! nav menu button ============================== */}
        <p
          aria-label="home"
          className={showNav ? 'hamburger-btn active' : 'hamburger-btn'}
          onClick={toggleNav}
          style={IsWorksheetPage() ? { top: '50%' } : { top: '80%' }}
        >
          <span className="line line-1"></span>
          <span className="line line-2"></span>
          <span className="line line-3"></span>
        </p>
      </header>

      {/* // ! RESPONSIVE NAV BAR ==================================== */}
      {isMobileNav && (
        <section className={showNav ? 'responsive-nav active' : 'responsive-nav'}>
          <div
            className="main-nav__responsive-login-container"
            style={IsWorksheetPage() ? { display: 'none' } : { display: '' }}
          >
            <NavDropdown
              title="Subjects"
              options={Subjects}
              handler={() => {
                toggleSubjectsDropdown();
              }}
              closeNav={toggleNav}
              toggleState={showSubjectsDropdown}
              responsive={true}
            />
            <NavDropdown
              title="Locations"
              options={Locations}
              handler={() => {
                toggleLocationsDropdown();
              }}
              closeNav={toggleNav}
              toggleState={showLocationsDropdown}
              responsive={true}
            />
            <NavLink href="/blog" title="Blog Home" text="Blog" toggleDropdown={toggleNav} closeNav={toggleNav} />
            <NavLink href="/worksheets-for-kids" title="Worksheets" text="Worksheets" closeNav={toggleNav} />
            <div className="phone-dropdown-container">
              <div className="phone">
                <a href="tel:+16476968289" className="main-number">
                  TOR: (647) 696-8289
                </a>
                <i
                  className="fas fa-caret-down"
                  onClick={() => {
                    numbersDropdown();
                  }}
                ></i>
              </div>
              <div className={showNumbers ? 'phone-options' : 'phone-options phone-options--active'}>
                <a href="tel:+3236452793">CAL: (323) 645-2793</a>
                <a href="tel:+19179053402">NY: (917) 905-3402</a>
                <a href="tel:+3053767679">FLA: (305) 376-7679</a>
              </div>
            </div>
          </div>
          <div
            className={
              IsWorksheetPage()
                ? 'main-nav__login-button-wrapper'
                : 'main-nav__login-button-wrapper main-nav__login-button-wrapper--hidden'
            }
            style={isMobileNav ? { display: 'flex' } : { display: 'none' }}
          >
            <div
              className="main-nav__cta-btn-wrapper"
              style={IsWorksheetPage() ? { display: '' } : { display: 'none' }}
            >
              {!cookies.email && (
                <button
                  className="main-nav__login-btn main-nav__nav-btn"
                  onClick={() => {
                    setLoginType({ login: true, signup: false });
                    showModal();
                    stopBodyScroll();
                  }}
                >
                  Log in
                </button>
              )}
              {!cookies.email && (
                <button
                  className="main-nav__sign-up-btn main-nav__nav-btn"
                  onClick={() => {
                    setLoginType({ login: false, signup: true });
                    showModal();
                    stopBodyScroll();
                  }}
                >
                  Sign up
                </button>
              )}
            </div>
            {cookies.email && userData.user_data.full_name !== '' && (
              <div
                className="header__account-options"
                onClick={() => setShowUserOptions(!showUserOptions)}
                style={IsWorksheetPage() ? { display: 'flex' } : { display: 'none' }}
              >
                <i className="far fa-user user-icon"></i> {`${userData.user_data.full_name}`}
                <i className={'fas fa-caret-down caret-down-icon'}></i>
              </div>
            )}
            {showUserOptions && (
              <div className="header__user-options-wrapper header__user-options-wrapper--mobile">
                <ul className="header__user-options">
                  <li className="header__account-settings-link">
                    <Link to="/account" data-cy="header__link--accountSettings">
                      Account Settings
                    </Link>
                  </li>
                  <li className="header__downloads-link">
                    <Link to="/downloads" data-cy="header__link--userDownloads">
                      Your Downloads
                    </Link>
                  </li>
                  {userData.subscription_id !== '' && (
                    <li className="header__manage-billing">
                      <p onClick={HandleCustomerBilling}>Manage Billing</p>
                    </li>
                  )}
                  <li className="header__sign-out-link">
                    <p
                      data-cy="header__a--signOut"
                      onClick={() => {
                        logout();
                      }}
                    >
                      Sign out
                    </p>
                  </li>
                </ul>
                {cookies.email && userData.user_data.full_name !== '' && !userData.subscription_active && (
                  <Link to="/premium">
                    <button className="main-nav__sign-up-btn main-nav__nav-btn">Upgrade</button>
                  </Link>
                )}
              </div>
            )}
          </div>
        </section>
      )}
      {willRedirect && <Redirect to="/worksheets-for-kids" />}
    </div>
  );
};

export default Header;
