import React from 'react';
import AWSImage from '../AWSImage';

type TutorInformationProps = {
  info: {
    name: string;
    occupation: string;
    education: string;
    bio: string;
    img: string;
  };
};

const TutorCard: React.FC<TutorInformationProps> = ({ info }) => {
  const { name, education, occupation, bio, img } = info;

  return (
    <div
      className={
        name === 'Ellison'
          ? 'tutors__tutor-card tutors__tutor-card--ellison'
          : name === 'Natalie'
          ? 'tutors__tutor-card tutors__tutor-card--natalie'
          : 'tutors__tutor-card tutors__tutor-card--rachel'
      }
    >
      <div className="tutors__tutor-header-info-wrapper">
        <AWSImage
          pngFile={img}
          imageAlt={'Tutor Smiling for photo'}
          documentPrefix="homepage/tutors"
          picClass="tutors__tutor-card-img"
          hasWebp
        />
        <div className="tutors__info-wrapper">
          <h4 className="tutors__tutor-card-name">{name}</h4>
          <h5 className="tutors__tutor-occupation">{occupation}</h5>
          <AWSImage
            pngFile={'five-stars.png'}
            documentPrefix={'icons'}
            picClass={'tutors__stars'}
            imageAlt={'Five Star Review'}
            hasWebp
          />
        </div>
      </div>
      <p className="tutors__education">
        <i className="fas fa-graduation-cap tutors__education-bio-icon"></i>
        {education}
      </p>
      <p className="tutors__bio">
        <i className="fas fa-bolt tutors__education-bio-icon"></i>
        {bio}
      </p>
    </div>
  );
};

export default TutorCard;
