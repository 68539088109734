import { UpdatedFilterOptions } from '../Models';

export const GetBaseURL = () => {
  return process.env.REACT_APP_BASE_URL;
};

export const GetStudyPortalURL = () => {
  return process.env.REACT_APP_STUDY_PORTAL_URL;
};

export const CurrentDomain = () => {
  return process.env.REACT_APP_DOMAIN_NAME;
};

export const URLGeneratorByFilter = (
  { grade, subject, subcategory, docType }: UpdatedFilterOptions,
  resetSubcategory?: boolean,
) => {
  let gradeValue = grade === undefined ? '' : encodeURIComponent(grade.toLowerCase().replace(' ', '-')) + '-';
  let subjectValue = subject === undefined ? '' : encodeURIComponent(subject.toLowerCase()) + '-';
  let subcategoryValue = resetSubcategory
    ? ''
    : subject === undefined
    ? ''
    : subcategory === undefined
    ? ''
    : subcategory.toLowerCase().replace(' ', '%20') + '-';
  let docTypeValue = docType.toLowerCase() === 'assessments' ? 'assessments' : 'worksheets';

  if (gradeValue === '' && subjectValue === '' && subcategoryValue === '' && docTypeValue === 'worksheets') {
    docTypeValue = 'worksheets-for-kids';
  }

  return gradeValue === 'kindergarten-' && docTypeValue === 'worksheets'
    ? `worksheets-for-kindergarten${
        subjectValue !== ''
          ? subcategoryValue !== ''
            ? `-${subcategoryValue.slice(0, -1)}`
            : `-${subjectValue.slice(0, -1)}`
          : ''
      }`
    : `${gradeValue}${subcategory && !resetSubcategory ? subcategoryValue : subjectValue}${docTypeValue}`;
  // return 'HEllo';
};

export const ParseURLFilters = (url: string) => {
  let split_url = url.split('-');
  let filters: UpdatedFilterOptions = { docType: 'Worksheets' }; //default Value
  //find the last value ==> worksheets or assessments
  if (split_url[split_url.length - 1] === 'assessments') {
    filters.docType = 'Assessments';
    split_url.splice(split_url.length - 1, 1);
  } else if (split_url[split_url.length - 1] === 'worksheets') {
    split_url.splice(split_url.length - 1, 1);
  }

  //check if after splicing, split url still has content
  if (split_url.length > 0) {
    //see if the first value is a grade
    if (split_url[0] === 'grade') {
      let gradeArray = split_url.splice(0, 2);
      gradeArray[0] = `${gradeArray[0].charAt(0).toUpperCase()}${gradeArray[0].slice(1)}`;
      let grade = gradeArray.join(' ');
      filters.grade = grade;
    } else if (split_url[0] === 'worksheets' && split_url[2] === 'kindergarten') {
      filters.grade = 'Kindergarten';
      split_url.splice(0, 3);
    } else if (split_url[0] === 'kindergarten') {
      filters.grade = 'Kindergarten';
      split_url.splice(0, 1);
    } else if (split_url[0] === 'junior') {
      filters.grade = 'Junior Kindergarten';
      split_url.splice(0, 2);
    } else if (split_url[0] === 'preschool') {
      filters.grade = 'Preschool';
      split_url.splice(0, 1);
    } 
    // else if (split_url[0] === 'college%2Funiversity') {
    //   filters.grade = 'College/University';
    //   split_url[0] = decodeURIComponent(split_url[0]);
    //   split_url.splice(0, 1);
    // }

    //parse the middle value as subject or subcategory
    if (
      split_url[0] === 'math' ||
      split_url[0] === 'english' ||
      (split_url[0] === 'reading' && split_url[1] !== 'comp') ||
      split_url[0] === 'writing' ||
      split_url[0] === 'science' ||
      split_url[0] === 'art' ||
      split_url[0] === 'test prep'
    ) {
      split_url[0] = `${split_url[0].charAt(0).toUpperCase()}${split_url[0].slice(1)}`;
      filters.subject = split_url[0] === 'Test prep' ? 'Test Prep' : split_url[0];
      split_url.splice(0, 1);
    } else {
      //it is a subcategory
      for (let i = 0; i < split_url.length; i++) {
        split_url[i] = `${split_url[i].charAt(0).toUpperCase()}${split_url[i].slice(1)}`;
      }
      filters.subcategory =
        split_url.join(' ').replace('%20', ' ') !== ''
          ? split_url.join(' ').replace('%20', ' ')
          : filters.subcategory?.replace('%20', ' ');
    }
  }

  return filters;
};

export const SingleResourceLink = (documentTitle: string) => {
  return encodeURI(setDocumentCase(documentTitle).split(' ').join('-'));
};

export const setDocumentCase = (title: string) => {
  const capitalizedDocuments = [
    'Letter A',
    'Letter B',
    'Letter C',
    'Letter D',
    'Letter E',
    'Letter F',
    'Letter I',
    'Letter T',
  ];

  return capitalizedDocuments.includes(title) ? title : title.toLowerCase();
};

export const decodeURLString = (title: string) => {
  let decodedTitle = decodeURI(title);

  return decodedTitle
    .split('')
    .map((singleCharacter) => {
      return singleCharacter === '-' ? ' ' : singleCharacter;
    })
    .join('');
};
