import React, { useState } from 'react';
import { SubjectCardInfo } from '../../Models';
import { ErrorProps } from '../../Models';
import { FormatPhoneNumber, GetBaseURL, ValidateEmail, ValidatePhone } from '../../Utils';
import ErrorHandler from './ErrorHandler';
import Picture from './Picture';

type SubjectList = {
  writing: boolean;
  reading: boolean;
  math: boolean;
  science: boolean;
  language: boolean;
  other: boolean;
};

type ConsultationWidgetProps = {
  download?: ({ documentName, targetID }: { documentName: string; targetID: string }) => void;
  documentName?: string;
  modalize?: boolean;
  hideModal?: () => void;
  source?: string;
};

const ConsultationWidget: React.FC<ConsultationWidgetProps> = ({
  download,
  documentName,
  modalize,
  hideModal,
  source,
}) => {
  const [section, setSection] = useState(window.location.href.includes('ref-nofussreaders') ? 2 : 1);
  const [gradeValue, setGradeValue] = useState('-2');
  const [subjects, setSubjects] = useState<SubjectList>({
    science: false,
    math: false,
    writing: false,
    reading: false,
    language: false,
    other: false,
  });
  const [leadData, setLeadData] = useState({ name: '', email: '', phone: '', id: '', childData: {} });
  const [slideInFromBack, setslideInFromBack] = useState(false);
  const [slideInFromFront, setslideInFromFront] = useState(false);
  const [error, setError] = useState<ErrorProps>({
    isActive: false,
    message: '',
    type: 'error',
  });
  const [day, setDay] = useState('Select');
  const [time, setTime] = useState('Select');
  const [message, setMessage] = useState('');
  const resetError = () => {
    setError({
      isActive: false,
      message: '',
      type: 'error',
    });
  };

  const previousSection = () => {
    resetError();
    if (!(slideInFromFront && slideInFromBack)) {
      setslideInFromBack(true);
      setSection(section - 1);
      setTimeout(() => {
        setslideInFromBack(false);
      }, 500);
    }
  };

  const nextSection = () => {
    resetError();
    if (!(slideInFromFront && slideInFromBack)) {
      setslideInFromFront(true);
      setSection(section + 1);
      setTimeout(() => {
        setslideInFromFront(false);
      }, 500);
    }
  };

  const handleSubmit = async () => {
    if (!(ValidateEmail(leadData.email) || ValidatePhone(leadData.phone) || leadData.name !== '')) {
      setError({
        type: 'error',
        isActive: true,
        message: 'Please enter your name and a valid email and phone number.',
      });
    } else if (!(ValidateEmail(leadData.email) || ValidatePhone(leadData.phone))) {
      setError({ type: 'error', isActive: true, message: 'Please enter a valid email and phone number.' });
    } else if (!(ValidateEmail(leadData.email) || leadData.name !== '')) {
      setError({ type: 'error', isActive: true, message: 'Please enter your name and a valid email.' });
    } else if (!(ValidatePhone(leadData.phone) || leadData.name !== '')) {
      setError({ type: 'error', isActive: true, message: 'Please enter a your name and a valid phone number.' });
    } else if (!ValidateEmail(leadData.email)) {
      setError({ type: 'error', isActive: true, message: 'Please enter a valid email.' });
    } else if (!ValidatePhone(leadData.phone)) {
      setError({ type: 'error', isActive: true, message: 'Please enter a valid phone number.' });
    } else if (leadData.name === '') {
      setError({ type: 'error', isActive: true, message: 'Please enter your name.' });
    } else {
      nextSection();
      const childData = {
        grade: gradeValue === '-1' ? 'Preschool' : gradeValue === '0' ? 'Kindergarten' : `Grade ${gradeValue}`,
        subjects: Object.entries(subjects)
          .filter((subject) => subject[1] === true)
          .map((subject) => subject[0][0].toUpperCase() + subject[0].slice(1)),
      };
      setLeadData((prev) => ({
        ...prev,
        childData,
      }));
      const postBody = {
        name: leadData.name,
        email: leadData.email,
        phone: leadData.phone,
        message: JSON.stringify(childData),
        source: source ? source : 'blog',
      };

      const request = await fetch(
        `${GetBaseURL()}/api/leads/postFromForm/blogpost?key=${process.env.REACT_APP_API_KEY}`,
        {
          method: 'POST',
          body: JSON.stringify(postBody),
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      if (request.status === 200) {
        if (download && documentName) {
          download({ documentName, targetID: 'download-button' });
        } else {
          const response = await request.json();
          setLeadData((prev) => ({
            ...prev,
            id: response.lead_id,
          }));
        }
      } else {
        previousSection();
        setError({ type: 'error', isActive: true, message: 'Internal server error. Please try again later.' });
      }
    }
  };

  const updateNotes = async () => {
    const newMessageData = {
      availability: day + ' ' + time,
      notes_from_lead: message,
    };
    const updatedMessageData = { messageData: JSON.stringify(Object.assign(leadData.childData, newMessageData)) };
    const request = await fetch(
      `${GetBaseURL()}/api/leads/updateLead/notes/${leadData.id}?key=${process.env.REACT_APP_API_KEY}`,
      {
        method: 'POST',
        body: JSON.stringify(updatedMessageData),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    if (request.status === 200) {
      if (source === 'form') {
        nextSection();
      } else {
        setError({
          type: 'success',
          isActive: true,
          message: 'Success! Your registration link has opened in a new tab/window.',
        });
        window.open(
          'https://onlineeducation.clickfunnels.com/join-the-group-page-no-fuss-tutors?cf_uvid=c35437e9085dd0588a3a8034c1ef4efb',
          '_blank',
        );
      }
    } else {
      setError({ type: 'error', isActive: true, message: 'Internal server error. Please try again later.' });
    }
  };

  const CONTENT = (
    <>
      {section === 1 && (
        <div
          id="form"
          className={
            slideInFromBack && modalize
              ? 'consultation-widget__start-container-backslide--modalize'
              : slideInFromBack
              ? 'consultation-widget__start-container-backslide'
              : modalize
              ? 'consultation-widget__start-container--modalize'
              : 'consultation-widget__start-container'
          }
          style={source === 'form' ? { flexDirection: 'column' } : undefined}
        >
          {source === 'form' ? (
            <div>
              <h2
                className="form-header"
                id="form"
                style={{
                  fontSize: '3rem',
                  margin: '0 auto 4rem',
                  textAlign: 'center',
                  textTransform: 'none',
                }}
              >
                Interested in our affordable tutoring programs?
              </h2>
              <img
                className="click-arrow-image"
                src="https://nft-image-pool.s3.us-east-2.amazonaws.com/tutor-software/arrow-blue-curved.png"
                alt="Arrow"
              />
            </div>
          ) : (
            <div className="consultation-widget__left-side">
              <h2 className="consultation-widget__heading">
                Learn the <span className="consultation-widget__popout-text">3 steps</span>
              </h2>
              <h2 className="consultation-widget__heading">To help your child</h2>
              <h2 className="consultation-widget__heading">
                <span className="consultation-widget__popout-text">catch up</span> in school
              </h2>
            </div>
          )}
          <div className="consultation-widget__right-side">
            <h4 className="consultation-widget__flavor-text1">
              Programs updated{' '}
              <span className="consultation-widget__date">
                {new Date(Date.now()).toLocaleString('en-US', {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                })}
              </span>
            </h4>
            <div
              className="consultation-widget__start-button"
              style={source === 'form' ? { width: '32.5rem' } : undefined}
              onClick={() => nextSection()}
            >
              <i className="fas fa-arrow-right" style={{ fontSize: '25px' }}></i>
              <span className="consultation-widget__start-button--text">Click Here To Begin</span>
            </div>
            <h4 className="consultation-widget__flavor-text2">Tap And Help Your Child Get Above Grade Level!</h4>
          </div>
        </div>
      )}
      {section === 2 && (
        <div
          className={
            slideInFromBack && modalize
              ? 'consultation-widget__slider-container-backslide--modalize'
              : slideInFromFront && modalize
              ? 'consultation-widget__slider-container-frontslide--modalize'
              : slideInFromBack
              ? 'consultation-widget__slider-container-backslide'
              : slideInFromFront
              ? 'consultation-widget__slider-container-frontslide'
              : modalize
              ? 'consultation-widget__slider-container--modalize'
              : 'consultation-widget__slider-container'
          }
        >
          {error.isActive && <ErrorHandler type={error.type} message={error.message} handler={resetError} />}
          <h2 className="consultation-widget__slider-query">Your Child's Grade:</h2>
          <span className="consultation-widget__slider-value">
            {gradeValue === '-2'
              ? 'Select'
              : gradeValue === '-1'
              ? 'Preschool'
              : gradeValue === '0'
              ? 'Kindergarten'
              : `Grade ${gradeValue}`}
          </span>
          <input
            type="range"
            min="-1"
            max="12"
            defaultValue={gradeValue}
            className="consultation-widget__slider"
            onInput={(e: any) => setGradeValue(e.target.value)}
          />
          <div className="consultation-widget__button-container">
            {!window.location.href.includes('ref-nofussreaders') && (
              <div className="consultation-widget__button--no" onClick={() => previousSection()}>
                <span style={{ margin: 'auto' }}>Back</span>
              </div>
            )}

            <div
              className="consultation-widget__button"
              onClick={() =>
                gradeValue === '-2'
                  ? setError({ type: 'error', isActive: true, message: 'Please select a grade level for your child.' })
                  : nextSection()
              }
            >
              <span style={{ margin: 'auto' }}>Next</span>
            </div>
          </div>
        </div>
      )}
      {section === 3 && (
        <div
          className={
            slideInFromBack && modalize
              ? 'consultation-widget__subject-container-backslide--modalize'
              : slideInFromFront && modalize
              ? 'consultation-widget__subject-container-frontslide--modalize'
              : slideInFromBack
              ? 'consultation-widget__subject-container-backslide'
              : slideInFromFront
              ? 'consultation-widget__subject-container-frontslide'
              : modalize
              ? 'consultation-widget__subject-container--modalize'
              : 'consultation-widget__subject-container'
          }
        >
          {error.isActive && <ErrorHandler type={error.type} message={error.message} handler={resetError} />}
          <span className="consultation-widget__subject-query">Choose Subject(s)</span>
          <div className="consultation-widget__subject-wrapper">
            {SubjectCardInfo.map((subject, index) => {
              return (
                <div
                  key={index}
                  className={
                    subjects[subject.subject.toLowerCase() as keyof SubjectList] ||
                    (subject.subject === 'More' && subjects.other)
                      ? 'consultation-widget__subject--highlight'
                      : 'consultation-widget__subject'
                  }
                  onClick={() => {
                    resetError();
                    subject.subject === 'More'
                      ? setSubjects((prev) => ({
                          ...prev,
                          other: !subjects.other,
                        }))
                      : setSubjects((prev) => ({
                          ...prev,
                          [subject.subject.toLowerCase() as keyof SubjectList]:
                            !subjects[subject.subject.toLowerCase() as keyof SubjectList],
                        }));
                  }}
                >
                  <Picture webp={subject.webpIcon} src={subject.icon} alt="subject" picClass="subjects__icon" />
                  <p className="subjects__label">{subject.subject === 'More' ? 'Other' : subject.subject}</p>
                </div>
              );
            })}
          </div>
          <div className="consultation-widget__button-container">
            <div className="consultation-widget__button--no" onClick={() => previousSection()}>
              <span style={{ margin: 'auto' }}>Back</span>
            </div>
            <div
              className="consultation-widget__button"
              onClick={() =>
                Object.values(subjects).some((val) => val)
                  ? nextSection()
                  : setError({ type: 'error', message: 'Please select at least one subject.', isActive: true })
              }
            >
              <span style={{ margin: 'auto' }}>Next</span>
            </div>
          </div>
        </div>
      )}

      {section === 4 && (
        <div
          className={
            slideInFromBack
              ? 'consultation-widget__form-container-backslide'
              : slideInFromFront
              ? 'consultation-widget__form-container-frontslide'
              : 'consultation-widget__form-container'
          }
        >
          {error.isActive && <ErrorHandler type={error.type} message={error.message} handler={resetError} />}
          {source === 'form' ? (
            <h2 className="consultation-widget__form-heading">
              Please enter your details below to start{' '}
              <span className="consultation-widget__prompt--popout-text">catching your child up!</span>
            </h2>
          ) : (
            <h2 className="consultation-widget__form-heading">
              Enter your details below to get access to our{' '}
              <span className="consultation-widget__prompt--popout-text">3 step training</span> via Facebook!
            </h2>
          )}
          <form className="consultation-widget__form">
            <input
              className="consultation-widget__form-input"
              type="text"
              name="name"
              placeholder="Name (e.g. Jane Doe)"
              onChange={(e) => setLeadData((prev) => ({ ...prev, name: e.target.value }))}
              defaultValue={leadData.name}
              required
            />
            <input
              className="consultation-widget__form-input"
              type="email"
              name="email"
              placeholder="Email (e.g. jd123@gmail.com)"
              onChange={(e) => setLeadData((prev) => ({ ...prev, email: e.target.value }))}
              defaultValue={leadData.email}
              required
            />
            <input
              className="consultation-widget__form-input"
              type="tel"
              id="phone"
              name="phone"
              placeholder="Phone (e.g. (012) 345-6789)"
              pattern="[(]{1}[0-9]{3}[)]{1} [0-9]{3}-[0-9]{4}"
              required
              onChange={(e) => {
                setLeadData((prev) => ({ ...prev, phone: e.target.value }));
                let formattedPhoneNumber = FormatPhoneNumber(e.target.value);
                e.target.value = formattedPhoneNumber ? formattedPhoneNumber : e.target.value;
              }}
              defaultValue={leadData.phone}
            />
          </form>
          <div className="consultation-widget__button-container">
            <div className="consultation-widget__button--no" id="form-button-no" onClick={() => previousSection()}>
              Back
            </div>
            <div className="consultation-widget__button" id="form-button" onClick={() => handleSubmit()}>
              Next
            </div>
          </div>
        </div>
      )}
      {section === 5 && (
        //change to only week day and time picker incremented by 30 mins from 9am to 7pm
        <div
          className={
            slideInFromBack
              ? 'consultation-widget__datetime-picker-backslide'
              : slideInFromFront
              ? 'consultation-widget__datetime-picker-frontslide'
              : 'consultation-widget__datetime-picker'
          }
        >
          <h2 className="consultation-widget__datetime-heading">What is the best date and time for a call?</h2>
          <span className="consultation-widget__datetime-subheading">
            (We operate in all time zones, but please choose a time in EST)
          </span>
          {error.isActive && <ErrorHandler type={error.type} message={error.message} handler={resetError} />}
          <div className="consultation-widget__datetime-select-wrapper">
            <select className="consultation-widget__datetime-select" onChange={(e) => setDay(e.target.value)}>
              <option value="Select">Select</option>
              <option value="Monday">Monday</option>
              <option value="Tuesday">Tuesday</option>
              <option value="Wednesday">Wednesday</option>
              <option value="Thursday">Thursday</option>
              <option value="Friday">Friday</option>
              <option value="Saturday">Saturday</option>
              <option value="Sunday">Sunday</option>
            </select>
            <select className="consultation-widget__datetime-select" onChange={(e) => setTime(e.target.value)}>
              <option value="Select">Select</option>
              <option value="9:00 am">9:00 am</option>
              <option value="9:30 am">9:30 am</option>
              <option value="10:00 am">10:00 am</option>
              <option value="10:30 am">10:30 am</option>
              <option value="11:00 am">11:00 am</option>
              <option value="11:30 am">11:30 am</option>
              <option value="12:00 pm">12:00 pm</option>
              <option value="12:30 pm">12:30 pm</option>
              <option value="1:00 pm">1:00 pm</option>
              <option value="1:30 pm">1:30 pm</option>
              <option value="2:00 pm">2:00 pm</option>
              <option value="2:30 pm">2:30 pm</option>
              <option value="3:00 pm">3:00 pm</option>
              <option value="3:30 pm">3:30 pm</option>
              <option value="4:00 pm">4:00 pm</option>
              <option value="4:30 pm">4:30 pm</option>
              <option value="5:00 pm">5:00 pm</option>
              <option value="5:30 pm">5:30 pm</option>
              <option value="6:00 pm">6:00 pm</option>
              <option value="6:30 pm">6:30 pm</option>
              <option value="7:00 pm">7:00 pm</option>
            </select>
          </div>
          <div className="consultation-widget__button-container">
            <div className="consultation-widget__button--no" onClick={() => previousSection()}>
              Back
            </div>
            <div
              className="consultation-widget__button"
              onClick={() =>
                day === 'Select' || time === 'Select'
                  ? setError({ type: 'error', isActive: true, message: 'Please select a day and time' })
                  : nextSection()
              }
            >
              Next
            </div>
          </div>
        </div>
      )}
      {section === 6 && (
        <div
          className={
            slideInFromBack
              ? 'consultation-widget__end-container-backslide'
              : slideInFromFront
              ? 'consultation-widget__end-container-frontslide'
              : 'consultation-widget__end-container'
          }
        >
          {error.isActive && <ErrorHandler type={error.type} message={error.message} handler={resetError} />}
          <h2 className="consultation-widget__end-heading">
            {source === 'form'
              ? 'Please describe your biggest concern and goal for your child.'
              : 'Anything else you would like to share?'}
          </h2>
          <input className="consultation-widget__end-textbox" onChange={(e: any) => setMessage(e.target.value)}></input>
          <div className="consultation-widget__button-container">
            <div className="consultation-widget__button--no" onClick={() => previousSection()}>
              Back
            </div>
            <div className="consultation-widget__button" onClick={() => updateNotes()}>
              Let's get going!
            </div>
          </div>
        </div>
      )}
      {section === 7 && (
        <div
          className={
            slideInFromBack
              ? 'consultation-widget__true-end-container-backslide'
              : slideInFromFront
              ? 'consultation-widget__true-end-container-frontslide'
              : 'consultation-widget__true-end-container'
          }
        >
          <span className="consultation-widget__true-end">
            <span className="consultation-widget__true-end--blurb" style={{ fontSize: '35px' }}>
              Thanks! Our team will give you a <span className="consultation-widget__true-end--popout-text">call</span>{' '}
              on <span className="consultation-widget__true-end--popout-text">{day + ' at ' + time}</span>{' '}
            </span>
          </span>
        </div>
      )}
      <a id="download-button" download={documentName}></a>
    </>
  );
  return modalize ? (
    <div className="consultation-modal" id="form">
      <div className="sign-in-modal__type-close" onClick={hideModal}>
        <span data-cy="signInModal__span--closeLogin" aria-label="close-window">
          <i className="fas fa-times"></i>
        </span>
      </div>
      <section className="consultation-widget__modalized">{CONTENT}</section>
    </div>
  ) : (
    <section
      id="form"
      className="consultation-widget"
      style={source === 'form' ? { margin: '0% -150% 0%' } : undefined}
    >
      {CONTENT}
    </section>
  );
};

export default ConsultationWidget;
