import React from 'react';
import Hero from '../../Components/Partials/Hero';
import TutoringPrograms from '../../Components/Partials/TutoringPrograms';
import LevelsOfMath from '../../Components/MathPage/LevelsOfMath';
import OurPricing from '../../Components/Partials/OurPricing';
import GoogleReview from '../../Components/Partials/GoogleReview';
import Tutors from '../../Components/Partials/TutorCards/Tutors';
import MetaData from '../../Components/MetaData';
import ConsultationWidget from '../../Components/Partials/ConsultationWidget';
import ShareIcons from '../../Components/Blog/ShareIcons';
import SideContent from '../../Components/Partials/SideContent';
import {
  MathHeaderContent,
  MathPageReview,
  MathTutoringProgramsInfo,
  MathTutoringProgramsHeader,
  StandardSubjectQuickLinks,
} from '../../Models';
import { generateID } from '../../Utils';

const Math = () => {
  const RELATED_BLOGS: { pageName: string; pageLink: string }[] = [
    {
      pageLink: '/blog-posts/my-kid-is-falling-behind-in-math',
      pageName: 'Blog | Behind in Math',
    },
    {
      pageLink: '/blog-posts/distributive-property-of-multiplication',
      pageName: 'Blog | Multiplication Help',
    },
    {
      pageLink: '/blog-posts/multiplication-chart',
      pageName: 'Blog | Multiplication Chart',
    },
    {
      pageLink: '/blog-posts/my-child-cant-focus-on-school-work',
      pageName: 'Blog | Struggling to Focus',
    },
  ];

  const RELATED_WORKSHEETS: { worksheetsName: string; worksheetsLink: string }[] = [
    {
      worksheetsLink: '/math-worksheets',
      worksheetsName: 'Math',
    },
    {
      worksheetsLink: '/addition-worksheets',
      worksheetsName: 'Addition',
    },
    {
      worksheetsLink: '/subtraction-worksheets',
      worksheetsName: 'Subtraction',
    },
    {
      worksheetsLink: '/multiplication-worksheets',
      worksheetsName: 'Multiplication',
    },
    {
      worksheetsLink: '/division-worksheets',
      worksheetsName: 'Division',
    },
    {
      worksheetsLink: '/word-problems-worksheets',
      worksheetsName: 'Word Probems',
    },
  ];

  return (
    <>
      <MetaData
        pageTitle="No Fuss Tutors | Online Math Tutors"
        postTitle="Online Math Tutors"
        description="Your Destination For Top Online Math Tutors. Our mission is to make quality math tutors affordable for everyone!"
        image={'https://nft-development.netlify.app/assets/webp/logo.png'}
        url={'https://nofusstutors.com/online-math-tutors'}
        keywords={['online math tutor canada', 'online math tutor new york', 'online math tutor florida']}
      />
      <div className="math-page">
        <Hero content={MathHeaderContent} />
        <TutoringPrograms content={MathTutoringProgramsInfo} headerContent={MathTutoringProgramsHeader} />
        <LevelsOfMath />
        <OurPricing />
        <GoogleReview reviewContent={MathPageReview} />
        <div className="english__tutors">
          <Tutors />
        </div>
        <ConsultationWidget source="form" />
        {
          <React.Fragment key={generateID()}>
            <aside className={'blog-page__side-content--mobile'}>
              <div className="blog-page__side-links">
                {StandardSubjectQuickLinks && (
                  <SideContent
                    links={[...RELATED_BLOGS, ...StandardSubjectQuickLinks.pages]}
                    worksheetsLinks={[...RELATED_WORKSHEETS, ...StandardSubjectQuickLinks.worksheets]}
                  />
                )}
              </div>
              <div className="blog-page__share-icons-wrapper">
                <ShareIcons
                  url="https://nofusstutors.com/online-math-tutor"
                  facebookQuote="No Fuss Tutoring has a dedicated network of tutors to help your kids improve their grades!"
                />
              </div>
            </aside>
          </React.Fragment>
        }
      </div>
    </>
  );
};

export default Math;
