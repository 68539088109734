// ! languages offered
export const LanguagesOfferedContent = {
  img: 'language1.jpg',
  alt: 'tutor waving to student on computer',
  content: [
    {
      heading: 'Spanish Tutoring',
      summary:
        'Our spanish tutoring is academic orientated as opposed to just wanting to learn the language casually. We teach all ages and grade levels. Our program ranges from basic beginner skills to achieving expert fluency and pronunciation.',
    },
    {
      heading: 'French Tutoring',
      summary:
        'All Ages From Beginner French to French Immersion! We put an emphasis on perfecting grammar, building vocabulary, improving pronunciation and promoting verbal communication with the ultimate objective of achieving fluency.',
    },
  ],
};
