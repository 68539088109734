import React, { useState, useEffect, useContext } from 'react';
import { io, Socket } from 'socket.io-client';
import { useHistory } from 'react-router-dom';
import Eyes from './CustomCharacter/Eyes';
import { SocketContext } from './../../Context/WebsocketContext';

import Hats from './CustomCharacter/Hat';

const ArtOff = () => {
  const socket = useContext(SocketContext);
  // Add the list of 20 flat colors
  const colors = [
    '#1abc9c',
    '#2ecc71',
    '#3498db',
    '#9b59b6',
    '#34495e',
    '#16a085',
    '#27ae60',
    '#2980b9',
    '#8e44ad',
    '#2c3e50',
    '#f1c40f',
    '#e67e22',
    '#e74c3c',
    '#95a5a6',
    '#f39c12',
    '#d35400',
    '#c0392b',
    '#bdc3c7',
    '#7f8c8d',
    '#2ecc71',
  ];
  const [message, setMessage] = useState('');
  const [roomName, setRoomName] = useState('');
  const [username, setUsername] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [hatIndex, setHatIndex] = useState(1);
  const [eyesIndex, setEyesIndex] = useState(3);
  const [shapeColor, setShapeColor] = useState(colors[7]);

  const history = useHistory();

  // List of 40 kid-friendly gamer names
  const gamerNames = [
    'FunnyFrog',
    'GigglyGiraffe',
    'WackyWhale',
    'LivelyLlama',
    'JollyJellyfish',
    'SillySeal',
    'BouncyBear',
    'HappyHedgehog',
    'LaughingLion',
    'CheerfulCheetah',
    'BubblyButterfly',
    'MerryMeerkat',
    'PlayfulPanda',
    'JoyfulJaguar',
    'GrinningGorilla',
    'DelightfulDolphin',
    'ZippyZebra',
    'GleefulGoat',
    'RadiantRaccoon',
    'ChirpyChameleon',
    'EcstaticElephant',
    'EagerEagle',
    'PleasedPenguin',
    'BeamingBee',
    'VibrantVulture',
    'TickledTiger',
    'ThrilledTurtle',
    'ElatedElk',
    'ContentedCat',
    'GratifiedGazelle',
    'UpbeatUrchin',
    'SatisfiedSloth',
    'ExuberantEmu',
    'BlissfulBaboon',
    'EnthusiasticEchidna',
    'OptimisticOstrich',
    'PleasantPlatypus',
    'DelightedDingo',
    'RejoicingRabbit',
    'ChipperCoyote',
  ];

  // Function to randomly select a gamer name from the list
  const getRandomGamerName = () => {
    return gamerNames[Math.floor(Math.random() * gamerNames.length)];
  };

  useEffect(() => {
    // Set a random gamer name when the component mounts
    setUsername(getRandomGamerName());

    if (socket) {
      const onMessage = (message: string) => {
        setMessage(message);
      };

      const onRoomCreated = (newRoomName: string) => {
        // Redirect the user to the new room URL
        history.push(`/art-off/room/${newRoomName}`);
      };

      const onRoomJoined = (roomName: string) => {
        // Redirect the user to the room URL
        history.push(`/art-off/room/${roomName}`);
      };

      const onRoomNotFound = () => {
        setErrorMessage('Room not found. Please try again.');
      };

      socket.on('message', onMessage);
      socket.on('room-created', onRoomCreated);
      socket.on('room-joined', onRoomJoined);
      socket.on('room-not-found', onRoomNotFound);

      // Clean up function
      return () => {
        socket.off('message', onMessage);
        socket.off('room-created', onRoomCreated);
        socket.off('room-joined', onRoomJoined);
        socket.off('room-not-found', onRoomNotFound);
      };
    }
  }, [history, socket]);

  const handleCreateRoom = () => {
    const characterDetails = {
      hat: `hat${hatIndex}`,
      eyes: `eyes${eyesIndex}`,
      color: shapeColor,
    };

    console.log('characterDetails:', characterDetails);
    if (socket) {
      socket.emit('create-room', {
        username,
        characterDetails: Object.keys(characterDetails).length > 0 ? characterDetails : null,
      });
      console.log('Sent create-room with characterDetails:', characterDetails);
      socket.emit('join-room', { roomId: roomName });
      console.log('Joining room:', roomName);
    }
  };

  const handleJoinRoom = () => {
    const characterDetails = {
      hat: `hat${hatIndex}`,
      eyes: `eyes${eyesIndex}`,
      color: shapeColor,
    };

    console.log('Joining room:', roomName);

    if (socket) {
      socket.emit('join-room', {
        roomId: roomName,
        username,
        characterDetails: Object.keys(characterDetails).length > 0 ? characterDetails : null,
      });
    }
    console.log('Sent join-room with characterDetails:', username, characterDetails);
  };

  const updateHatIndex = (direction: number) => {
    setHatIndex((prevIndex) => {
      const newIndex = prevIndex + direction;
      return newIndex < 1 ? 10 : newIndex > 10 ? 1 : newIndex;
    });
  };

  const updateEyesIndex = (direction: number) => {
    setEyesIndex((prevIndex) => {
      const newIndex = prevIndex + direction;
      return newIndex < 1 ? 10 : newIndex > 10 ? 1 : newIndex;
    });
  };

  const updateShapeColor = (direction: number) => {
    setShapeColor((prevColor) => {
      const currentColorIndex = colors.findIndex((color) => color === prevColor);
      const newIndex = (currentColorIndex + direction + colors.length) % colors.length;
      return colors[newIndex];
    });
  };

  return (
    <div>
      <div className="ArtOff__row-wrapper"></div>

      <div>{message}</div>

      <div className="ArtOff__row-wrapper--bottom-1">
        <div className="ArtOff__logo">
          <img
            src="https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/ArtOff.png"
            alt="Art Off"
            className="ArtOff__art-image"
          />
        </div>
        <div className="ArtOff__row-wrapper--bottom">
          <div className="ArtOff__nickname-wrapper">
            {errorMessage && <div>{errorMessage}</div>}
            <label className="ArtOff__label" htmlFor="roomNameInput">
              Enter Game Id:
            </label>
            <input type="text" id="roomNameInput" value={roomName} onChange={(e) => setRoomName(e.target.value)} />
          </div>
          <button className="ArtOff__join" onClick={handleJoinRoom}>
            Join Game
          </button>
        </div>
        <div className="ArtOff__-or-">-or-</div>
        <div>
          <button className="ArtOff__create" onClick={handleCreateRoom}>
            Create New Game
          </button>
        </div>
      </div>

      <div className="ArtOff__custom--label">Customize Your Character:</div>
      <div className="ArtOff__row-wrapper ArtOff__custom-wrapper">
        <div className="ArtOff__nickname-wrapper">
          <label className="ArtOff__label" htmlFor="usernameInput">
            Your Nickname:
          </label>
          <input type="text" id="usernameInput" value={username} onChange={(e) => setUsername(e.target.value)} />
        </div>
        <div className="ArtOff__character--card">
          <div className="ArtOff__character">
            <div className="ArtOff__shape">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="128.000000pt"
                height="105.000000pt"
                viewBox="0 0 1280.000000 1056.000000"
                preserveAspectRatio="xMidYMid meet"
                style={{ filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.7))' } as any}
              >
                <g
                  transform="translate(0.000000,1056.000000) scale(0.100000,-0.100000)"
                  fill={shapeColor}
                  stroke="none"
                >
                  <path
                    d="M6310 9673 c-683 -40 -1382 -259 -1984 -623 -322 -194 -559 -381
                  -865 -683 -754 -744 -1342 -1716 -1801 -2972 -281 -769 -484 -1595 -564 -2291
               -13 -115 -31 -229 -40 -253 -57 -157 -65 -335 -21 -486 108 -372 471 -712
             1070 -1004 505 -246 1173 -451 1900 -582 33 -6 80 -14 105 -19 189 -36 617
            -93 895 -119 539 -51 668 -56 1345 -56 677 0 806 5 1345 56 573 53 1151 152
            1670 284 397 101 813 242 1085 368 41 19 101 46 134 61 90 43 271 143 376 209
            307 195 518 404 635 628 45 87 95 239 95 289 0 15 9 63 20 106 27 108 26 232
             -6 444 -81 553 -225 1139 -424 1730 -460 1364 -1019 2448 -1694 3285 -270 335
             -645 704 -941 925 -548 411 -1144 648 -1760 700 -110 9 -441 11 -575 3z"
                  />
                </g>
              </svg>

              <div className="ArtOff__hat ArtOff__row-wrapper--arrows">
                <button className="ArtOff__arrow-select" onClick={() => updateHatIndex(-1)}>
                  &lt;
                </button>
                <div className="ArtOff__between-arrows">
                  <div className="ArtOff__hat-off">
                    <Hats type={`hat${hatIndex}`} />
                  </div>
                </div>
                <button className="ArtOff__arrow-select" onClick={() => updateHatIndex(1)}>
                  &gt;
                </button>
              </div>
              <div className="ArtOff__eyes ArtOff__row-wrapper--arrows">
                <button className="ArtOff__arrow-select" onClick={() => updateEyesIndex(-1)}>
                  &lt;
                </button>
                <div className="ArtOff__between-arrows">
                  <div className="ArtOff__eyes-off">
                    <Eyes type={`eyes${eyesIndex}`} />
                  </div>
                </div>
                <button className="ArtOff__arrow-select" onClick={() => updateEyesIndex(1)}>
                  &gt;
                </button>
              </div>
              <div className="ArtOff__color ArtOff__row-wrapper--arrows">
                <button className="ArtOff__arrow-select" onClick={() => updateShapeColor(-1)}>
                  &lt;
                </button>
                <div className="ArtOff__between-arrows">
                  <div className="ArtOff__color-display" style={{ backgroundColor: shapeColor }} />
                </div>
                <button className="ArtOff__arrow-select" onClick={() => updateShapeColor(1)}>
                  &gt;
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArtOff;
