import React, { useEffect, useState, useRef } from 'react';
import Hero from '../../Components/Partials/Hero';
import MetaData from '../../Components/MetaData';
import { CanadaPageHeaderContent, CanadaPageContent, CanadaPageQuickLinks } from '../../Models';
import { generateID } from '../../Utils';
import ShareIcons from '../Blog/ShareIcons';
import SideContent from '../Partials/SideContent';
import ConsultationWidget from '../Partials/ConsultationWidget';

const CanadaLocation: React.FC = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth < 1200 ? true : false);
  const [isLinkVisibleFromTop, setIsLinkVisibleFromTop] = useState(true);
  const [isLinkVisibleFromBottom, setIsLinkVisibleFromBottom] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const scrollHeightRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    //! scroll to top of page
    window.scrollTo(0, 0);
  }, []);

  window.addEventListener('scroll', () => {
    let lastKnownScrollPosition = window.scrollY;

    if (scrollHeightRef.current && lastKnownScrollPosition < 615) {
      setIsLinkVisibleFromTop(true);
    } else {
      setIsLinkVisibleFromTop(false);
    }
    if (scrollHeightRef.current && lastKnownScrollPosition > scrollHeightRef.current.scrollHeight - 400) {
      setIsLinkVisibleFromBottom(true);
    } else {
      setIsLinkVisibleFromBottom(false);
    }
  });

  window.addEventListener('resize', () => {
    setWidth(window.innerWidth);
    if (isMobileScreen && window.innerWidth > 1199) {
      setIsMobileScreen(false);
    }
    if (!isMobileScreen && window.innerWidth < 1200) {
      setIsMobileScreen(true);
    }
  });

  return (
    <>
      <MetaData
        pageTitle="No Fuss Tutors | Online Tutors in Canada"
        postTitle="Online Tutors in Canada"
        description="Your Destination For Top Online Tutors in Canada. Our mission is to make quality math tutors affordable for everyone in Canada!"
        image={'https://nft-development.netlify.app/assets/webp/logo.png'}
        url={'https://nofusstutors.com/online-tutor-canada'}
        keywords={['online math tutor canada', 'online reading tutor canada', 'online tutor canada']}
      />
      <div className="canada-location-page" ref={scrollHeightRef}>
        <Hero content={CanadaPageHeaderContent} />
        <div className="canada-location-page__content-wrapper">
          {!isMobileScreen && (
            <aside
              className={
                isLinkVisibleFromTop
                  ? 'blog-page__side-content blog-page__side-content--fix-top'
                  : isLinkVisibleFromBottom
                  ? 'blog-page__side-content blog-page__side-content--fix-bottom location__side-content'
                  : 'blog-page__side-content location__side-content'
              }
              style={
                isLinkVisibleFromBottom
                  ? width < 1700
                    ? { marginLeft: '-0.5rem', bottom: '1rem' }
                    : { bottom: '1rem' }
                  : width < 1700
                  ? { marginLeft: '-0.5rem' }
                  : undefined
              }
            >
              <div className="blog-page__side-links">
                {CanadaPageQuickLinks ? (
                  <SideContent links={CanadaPageQuickLinks.pages} worksheetsLinks={CanadaPageQuickLinks.worksheets} />
                ) : null}
              </div>
              <div className="blog-page__share-icons-wrapper">
                <ShareIcons
                  url="welcome.nofusstutors.com"
                  facebookQuote="I read this great article on the No Fuss Tutors blog!"
                />
              </div>
            </aside>
          )}

          {CanadaPageContent.map((item, index) => {
            return (
              <div className="canada-location-page__flex-wrapper" key={index}>
                <div className="canada-location-page__heading-content-wrapper">
                  <h2 className="canada-location-page__heading">{item.heading ? item.heading : ''}</h2>
                  <p className="canada-location-page__content-text">{item.content ? item.content : ''}</p>
                </div>
                <div className="canada-location-page__img-wrapper">
                  {item.img ? (
                    <img src={item.img} alt={item.imgAlt} className="canada-location-page__content-img" />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <ConsultationWidget source="form" />
      {isMobileScreen && (
        <React.Fragment key={generateID()}>
          <aside className={'blog-page__side-content--mobile'}>
            <div className="blog-page__side-links">
              {CanadaPageQuickLinks && (
                <SideContent links={CanadaPageQuickLinks.pages} worksheetsLinks={CanadaPageQuickLinks.worksheets} />
              )}
            </div>
            <div className="blog-page__share-icons-wrapper">
              <ShareIcons
                url="welcome.nofusstutors.com"
                facebookQuote="I read this great article on the No Fuss Tutors blog!"
              />
            </div>
          </aside>
        </React.Fragment>
      )}
    </>
  );
};

export default CanadaLocation;
