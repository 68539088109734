import React from 'react';
import { TestOverviewContent } from '../../Models';
import AWSImage from '../Partials/AWSImage';

const TestOverview = () => {
  const { sectionHeading, sectionSummary } = TestOverviewContent.headerInfo;
  const { img, alt, picClass, awsPrefix } = TestOverviewContent.images;

  return (
    <section className="test-overview">
      <div className="test-overview__summary-wrapper">
        <div className="test-overview__overview-wrapper">
          <div className="test-overview__summary">
            <h1 className="test-overview__heading">{sectionHeading}</h1>
            <p className="test-overview__summary-content">{sectionSummary}</p>
          </div>
          {TestOverviewContent.testInfo.map((item, index) => {
            return (
              <div className="test-overview__overview" key={index}>
                <h2 className="test-overview__heading">{item.testHeading}</h2>
                <p className="test-overview__section-heading">{item.testSectionHeader}</p>
                <ul className="test-overview__section-list">
                  {item.testSections.map((item, index) => {
                    return (
                      <li className="test-overview__section-items" key={index}>
                        {item}
                      </li>
                    );
                  })}
                </ul>
                <p className="test-overview__test-summary">{item.testSummary}</p>
              </div>
            );
          })}
        </div>
        <AWSImage pngFile={img} picClass={picClass} imageAlt={alt} documentPrefix={awsPrefix} hasWebp />
      </div>
    </section>
  );
};

export default TestOverview;
