import React from 'react';
import ProgramCard from './ProgramCard';
import { ProgramCardInfo } from '../../../Models/ProgramInfo';

type ProgramsProps = {
  onClick: (
    name: string,
    minTimesPerWeek: number,
    subject: string,
    ageRange: string,
    price: number,
    cardText: string,
    bgColor: string,
    lessonLength: string,
    priceLow: number,
    priceMid: number,
    priceHigh: number,
  ) => void;
};

const Programs: React.FC<ProgramsProps> = ({ onClick }) => {
  return (
    <section className="programs">
      {/* <h3 className="programs__heading">Programs</h3> */}

      <div className="programs__program-cards-container">
        {ProgramCardInfo.map((info, index) => {
          return (
            <React.Fragment key={index}>
              <ProgramCard info={info} onClick={onClick} />
            </React.Fragment>
          );
        })}
      </div>
    </section>
  );
};

export default Programs;
