import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Banner from '../Components/Partials/Banner';
import BackToPage from '../Components/Partials/BackToPage';
import { useUserData } from '../Hooks/useUserData';
import { useResourceFilters } from '../Hooks/useResourceFilter';
import { ErrorProps, Headers } from '../Models';
import {
  GetBaseURL,
  NormalizePhone,
  FormatPhoneNumber,
  NumbersOnly,
  ValidatePhone,
  decrypt,
  generatedRoute,
  generatedLocation,
} from '../Utils';

type AccountDetails = {
  full_name?: string;
  phone?: string;
};

const Account: React.FC = () => {
  const [cookies] = useCookies(['email']);
  const { userData, setUser, logout, willRedirect } = useUserData();
  const { resourceData } = useResourceFilters();
  const [accountDetails, setAccountDetails] = useState<AccountDetails>({});
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [error, setError] = useState<ErrorProps>({
    isActive: false,
    message: '',
    type: 'error',
  });

  const submitAccountInfo = async (e: any) => {
    e.preventDefault();
    const url = `${GetBaseURL()}/api/users/updateUser?key=${process.env.REACT_APP_API_KEY}`;

    const body = {
      registered_email: decrypt(cookies.email! as string),
      user_data: accountDetails,
      tutorInterest: e.target.tutorInterest.value,
      userRole: e.target.userRole.value,
    };
    if ((accountDetails.phone && ValidatePhone(accountDetails.phone as string)) || !accountDetails.phone) {
      const request = await fetch(url, { method: 'POST', body: JSON.stringify(body), headers: Headers });
      const res = await request.json();

      if (request.status === 200) {
        setUser(res.result);
        setError({
          isActive: true,
          message: `User with email ${decrypt(cookies.email! as string)} successfully updated!`,
          type: 'success',
        });
      } else {
        setError({
          isActive: false,
          message: res.error,
          type: 'error',
        });
      }
    } else {
      setError({
        isActive: false,
        message: 'Invalid phone',
        type: 'error',
      });
    }
  };

  const updateAccountInfo = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setAccountDetails((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value.trim(),
    }));
  };

  const updateAccountPhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setAccountDetails((prev: any) => ({
      ...prev,
      phone: NumbersOnly(e.target.value.trim()),
    }));
  };

  const deleteAccount = async () => {
    const request = await fetch(`${GetBaseURL()}/api/users/deleteUser?key=${process.env.REACT_APP_API_KEY}`, {
      method: 'POST',
      body: JSON.stringify({ email: decrypt(cookies.email! as string) }),
      headers: {
        'Content-type': 'application/json',
      },
    });
    if (request.status === 200) {
      logout();
    } else {
      const res = await request.json();
      setError({
        isActive: true,
        message: res.errorMessage,
        type: 'error',
      });
    }
  };

  const resetError = () => {
    setError({
      isActive: false,
      message: '',
      type: 'error',
    });
  };

  return (
    <main>
      <Banner lineOne={'My Account'} main imageName="worksheets_banner" alt="No Fuss Tutors banner" />

      <BackToPage
        route={`/${generatedRoute({ ...resourceData.filters })}`}
        location={generatedLocation(generatedRoute({ ...resourceData.filters }))}
      />

      <span
        data-cy="signInModal__span--loginError"
        className={error.type === 'error' ? 'error-span' : 'account__success-span'}
        onClick={resetError}
      >
        {error.message}
      </span>
      <form className="account" onSubmit={(e) => submitAccountInfo(e)}>
        <div className="account__header-container">
          <h1 className="account__header">Hello, {userData.user_data.full_name}!</h1>
          <p className="account__sub-header">
            Welcome to your account settings. Here you can reset your password, update your information or delete your
            account.
          </p>
        </div>
        <p className="account__sub-header">Registered email: {userData.user_data.email}</p>
        <input
          data-cy="account__input--name"
          type="text"
          name="full_name"
          placeholder={userData.user_data.full_name}
          onBlur={(e) => updateAccountInfo(e)}
          aria-label="name"
          className="account__input"
        />
        <input
          data-cy="account__input--phone"
          type="text"
          name="phone"
          placeholder={
            userData.user_data.phone ? FormatPhoneNumber(userData.user_data.phone)?.toString() : 'Phone number'
          }
          onBlur={(e) => {
            NormalizePhone(e);
            updateAccountPhone(e);
          }}
          aria-label="Phone number"
          className="account__input"
        />
        <div className="sign-in-modal__checkbox-wrapper">
          <h4>Interested in tutoring as well?</h4>
          <div className="sign-in-modal__checkbox-options">
            <div className="sign-in-modal__radio-wrapper">
              <input
                type="radio"
                id="tutorInterest1"
                name="tutorInterest"
                value="Yes"
                defaultChecked={userData.tutorInterest === 'Yes' ? true : false}
                onClick={() => {
                  setUser({ tutorInterest: 'Yes' });
                }}
              />
              <label htmlFor="tutorInterest1">Yes</label>
            </div>
            <div className="sign-in-modal__radio-wrapper">
              <input
                type="radio"
                id="tutorInterest2"
                name="tutorInterest"
                value="No"
                defaultChecked={userData.tutorInterest === 'No' ? true : false}
                onClick={() => {
                  setUser({ tutorInterest: 'No' });
                }}
              />
              <label htmlFor="tutorInterest2">No</label>
            </div>
            <div className="sign-in-modal__radio-wrapper">
              <input
                type="radio"
                id="tutorInterest3"
                name="tutorInterest"
                value="Maybe Later"
                defaultChecked={userData.tutorInterest === 'Maybe Later' ? true : false}
                onClick={() => {
                  setUser({ tutorInterest: 'Maybe Later' });
                }}
              />
              <label htmlFor="tutorInterest3">Maybe Later</label>
            </div>
          </div>
        </div>

        <div className="sign-in-modal__checkbox-wrapper">
          <h4>Are you a student, parent, or tutor?</h4>
          <div className="sign-in-modal__checkbox-options">
            <div className="sign-in-modal__radio-wrapper">
              <input
                type="radio"
                id="signup_student"
                name="userRole"
                value="Student"
                defaultChecked={userData.userRole === 'Student' ? true : false}
                onClick={() => {
                  setUser({ userRole: 'Student' });
                }}
              />
              <label htmlFor="signup_student">Student</label>
            </div>
            <div className="sign-in-modal__radio-wrapper">
              <input
                type="radio"
                id="signup_parent"
                name="userRole"
                value="Parent"
                defaultChecked={userData.userRole === 'Parent' ? true : false}
                onClick={() => {
                  setUser({ userRole: 'Parent' });
                }}
              />
              <label htmlFor="signup_parent">Parent</label>
            </div>
            <div className="sign-in-modal__radio-wrapper">
              <input
                type="radio"
                id="signup_tutor"
                name="userRole"
                value="Tutor"
                defaultChecked={userData.userRole === 'Tutor' ? true : false}
                onClick={() => {
                  setUser({ userRole: 'Tutor' });
                }}
              />
              <label htmlFor="signup_tutor">Tutor</label>
            </div>
          </div>
        </div>
        <Link to="/reset" className="account__reset-link">
          Reset Password
        </Link>
        <button data-cy="account__button--update" type="submit" className="account__button account__button--update">
          Update
        </button>
      </form>

      <div className="account__delete-account-wrapper">
        <button
          data-cy="account__button--deleteAccount"
          onClick={() => setShowConfirmDelete(true)}
          className="main-nav__nav-btn account__button account__button--delete"
        >
          Delete Account
        </button>
        {showConfirmDelete && (
          <div className="account__confirm-delete-wrapper">
            <h5 className="account__confirm-delete-text">Are you sure you want to delete your account?</h5>
            {/*//! I removed the main-nav__nav-btn class from this button because I think from a UX perspective, the user should clearly be able to tell the difference between the confirm delete button and the cancel delete button. -MA */}
            <button
              data-cy="account__button--confirmDelete"
              className="account__confirm-delete-button account__confirm-delete-button--yes"
              onClick={deleteAccount}
            >
              Yes
            </button>
            <button
              onClick={() => setShowConfirmDelete(false)}
              className="main-nav__nav-btn account__confirm-delete-button account__confirm-delete-button--no"
            >
              No
            </button>
          </div>
        )}
      </div>
      {willRedirect && <Redirect to="/worksheets-for-kids" />}
    </main>
  );
};

export default Account;
