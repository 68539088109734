import React from 'react';
import Hero from '../../Components/Partials/Hero';
import Advantages from '../../Components/Partials/Advantages';
import LetUsHelp from '../../Components/ReadingPage/LetUsHelp';
import ReadingMethod from '../../Components/ReadingPage/ReadingMethod';
import EnglishSkills from '../../Components/EnglishPage/EnglishSkills';
import SellingPoints from '../../Components/ReadingPage/SellingPoints';
import GoogleReview from '../../Components/Partials/GoogleReview';
import TutorCards from '../../Components/Partials/TutorCards/Tutors';
import MetaData from '../../Components/MetaData';
import ConsultationWidget from '../../Components/Partials/ConsultationWidget';
import ShareIcons from '../../Components/Blog/ShareIcons';
import SideContent from '../../Components/Partials/SideContent';
import ReactPlayer from 'react-player/vimeo';
import {
  ReadingHeaderContent,
  ReadingTutorAdvantages,
  ReadingEnglishSkills,
  MathPageReview,
  StandardSubjectQuickLinks,
} from '../../Models';
import { generateID } from '../../Utils';
import OnlineReview from '../../Components/Home/OnlineReview';
import ReadingConsultationWidget from '../../Components/Partials/ReadingConsultationWidget';

const Reading = () => {
  const RELATED_BLOGS: { pageName: string; pageLink: string }[] = [
    {
      pageLink: '/blog-posts/my-9-year-old-cant-read',
      pageName: 'Blog | Struggling to Read',
    },
    {
      pageLink: '/blog-posts/my-kid-is-afraid-to-read-in-class',
      pageName: 'Blog | Reading in Class',
    },
    {
      pageLink: '/blog-posts/when-does-a-child-learn-to-read',
      pageName: 'Blog | Learning to Read',
    },
    {
      pageLink: '/blog-posts/what-is-text-structure',
      pageName: 'Blog | Text Structure',
    },
  ];

  const RELATED_WORKSHEETS: { worksheetsName: string; worksheetsLink: string }[] = [
    {
      worksheetsLink: '/reading-worksheets',
      worksheetsName: 'Reading',
    },
    {
      worksheetsLink: '/sight-words-worksheets',
      worksheetsName: 'Sight Words',
    },
    {
      worksheetsLink: '/alphabet-worksheets',
      worksheetsName: 'Alphabet',
    },
    {
      worksheetsLink: '/phonics-worksheets',
      worksheetsName: 'Phonics',
    },
  ];

  return (
    <>
      <MetaData
        pageTitle="No Fuss Tutors | Online Reading Tutors"
        postTitle="Online Reading Tutors"
        description="Your Destination For Top Online Reading Tutors. Our mission is to make quality reading tutors affordable for everyone!"
        image={'https://nft-development.netlify.app/assets/webp/logo.png'}
        url={'https://nofusstutors.com/online-reading-tutors'}
        keywords={['online reading tutor canada', 'online reading tutor new york', 'online reading tutor florida']}
      />
      <div className="reading-page">
        <Hero content={ReadingHeaderContent} />
        <div className="consultation-widget__video-container-section">
          <h2 className="consultation-widget__video-heading">Watch What Our Happy Parents Have to Say!</h2>

          <div className="consultation-widget__video-container">
            <div className="react-player-wrapper">
              <ReactPlayer
                className="react-player"
                url="https://vimeo.com/700035115"
                width="100%"
                height="100%"
                controls={true}
                playing={false}
                light="https://nft-image-pool.s3.us-east-2.amazonaws.com/homepage/5-star-parent-review.webp"
                pip={true} // Picture in Picture
              />
            </div>
          </div>
        </div>

        <ReadingConsultationWidget source="form" />

        <OnlineReview />

        <div className="reading-skills-section">
          <h2 className="form-header">The Reading Boost Program: </h2>
          <ul>
            <li>
              <span className="bullet">▪️</span> Revoltionary Curriculum grounded in the Science of Reading.
            </li>

            <li>
              <span className="bullet">▪️</span> We cover all 5 fundamental components of Reading: phonological
              awareness, phonics, vocabulary, fluency, and reading comprehension.
            </li>

            <li>
              <span className="bullet">▪️</span> 1-on-1 virtual sessions with the same tutor every lesson.
            </li>
          </ul>
        </div>

        <ReadingConsultationWidget source="form" />
      </div>
    </>
  );
};

export default Reading;
