export const ProgramCardInfo = [
  {
    name: 'Reading Boost Program',
    minTimesPerWeek: 3,
    subject: 'Reading',
    ageRange: '4-13',
    priceLow: 231.67,
    priceMid: 298.34,
    priceHigh: 365,
    cardText: 'Reading',
    bgColor: 'linear-gradient(-90deg, #396afc, #2948ff)',
  },
  {
    name: 'Math Boost Program',
    minTimesPerWeek: 3,
    subject: 'Math',
    ageRange: '4-13',
    priceLow: 231.67,
    priceMid: 298.34,
    priceHigh: 365,
    cardText: 'Math',
    bgColor: 'linear-gradient(-90deg, #f26931, #f15b49, #ee3f7a)',
  },
  {
    name: 'Writing Boost Program',
    minTimesPerWeek: 3,
    subject: 'Writing',
    ageRange: '6-13',
    priceLow: 231.67,
    priceMid: 298.34,
    priceHigh: 365,
    cardText: 'Writing',
    bgColor: 'linear-gradient(-90deg, #0cebeb, #20e3b2, #29ffc6)',
  },
  {
    name: 'Teach Ahead of My Class (Elementary)',
    minTimesPerWeek: 3,
    subject: 'Any',
    ageRange: '6-13',
    priceLow: 231.67,
    priceMid: 298.34,
    priceHigh: 365,
    cardText: 'Any',
    bgColor: 'linear-gradient(-90deg, #8f94fb, #4e54c8)',
  },

  {
    name: "Kickstart My Preschooler's Education",
    minTimesPerWeek: 3,
    subject: 'Any',
    ageRange: '2-4',
    priceLow: 231.67,
    priceMid: 298.34,
    priceHigh: 365,
    cardText: 'Any',
    bgColor: 'linear-gradient(-90deg, #ffb347, #ffcc33)',
  },

  {
    name: 'Teach Ahead of My Class (High School)',
    minTimesPerWeek: 3,
    subject: 'Any',
    ageRange: '14-18',
    priceLow: 331.67,
    priceMid: 398.34,
    priceHigh: 465,
    cardText: 'Any',
    bgColor: 'linear-gradient(-90deg, #8f94fb, #4e54c8)',
  },

  {
    name: 'Math Boost Program (High School)',
    minTimesPerWeek: 3,
    subject: 'Math',
    ageRange: '14-18',
    priceLow: 331.67,
    priceMid: 398.34,
    priceHigh: 465,
    cardText: 'Math',
    bgColor: 'linear-gradient(-90deg, #f26931, #f15b49, #ee3f7a)',
  },
  {
    name: 'Writing Boost Program (High School)',
    minTimesPerWeek: 3,
    subject: 'Writing',
    ageRange: '14-18',
    priceLow: 331.67,
    priceMid: 398.34,
    priceHigh: 465,
    cardText: 'Writing',
    bgColor: 'linear-gradient(-90deg, #0cebeb, #20e3b2, #29ffc6)',
  },
];

type FAQ = {
  question: string;
  answer: string;
  identifier: string;
};

export const FAQInfo: { firstSet: FAQ[]; secondSet: FAQ[]; thirdSet: FAQ[] } = {
  firstSet: [
    {
      question: 'Can I continue after the 12 lessons are done?',
      answer:
        'Yes absolutely, most of our students continue after 12 lessons. We recommend doing at least 3 months (36 lessons) to see substantial improvement in a subject.',
      identifier: 'continueAfter12Lessons',
    },
    {
      question: "What are your tutors' qualifications?",
      answer:
        'Our qualifications range from Certified Teachers and Early Childhood Educators to Subject Experts that have professional tutoring experience.',
      identifier: 'tutorQualifications',
    },
    {
      question: 'Can I do a trial?',
      answer:
        'Due to increasing demand, we no longer offer trial lessons. However, their is no obligation to continue after the first 12 lessons so you can treat it as a trial!',
      identifier: 'trial',
    },
    {
      question: 'How does it work?',
      answer:
        'We catch students up through online tutoring across North America. The lessons take place in a 1-on-1 video call alongside a colorful whiteboard. We can either provide our own curriculum to boost skills in a specific subject or focus on getting your child better grades at their school.',
      identifier: 'howItWorks',
    },
    {
      question: 'Why 3 lessons a week?',
      answer: 'From working with 1000s of students, we have found 3 lessons a week produces tangible results fast.',
      identifier: 'why3Lessons',
    },
    {
      question: 'How do I book for multiple children?',
      answer:
        'After you book for your first child, we will send you a 10% discount code via email to book for your second child :)',
      identifier: 'bookForMultipleChildren',
    },
    {
      question: 'Can I do more than 1 subject at once?',
      answer:
        'While we recommend focusing on 1 subject 3 times a week to see results fast, we do offer multiple subjects. Just select the "Teach Ahead of My Class" program.',
      identifier: 'multipleSubjects',
    },
  ],
  secondSet: [
    {
      question: 'How do I book multiple children?',
      answer:
        'After you book for your first child, we will send you a 10% discount code via email to book for your second child :)',
      identifier: 'bookMultipleChildren',
    },
    {
      question: 'What if I need to cancel or reschedule a lesson?',
      answer:
        'As long as you give us 24 hours notice, the lesson will not be deducted from the 12 that you purchased. We understand schedules change and are happy to work with you to find new days that work :)',
      identifier: 'reschedule',
    },
  ],
  thirdSet: [
    {
      question: 'Will my child have the same tutor every time?',
      answer: 'If you like them, yes!',
      identifier: 'sameTutor',
    },
  ],
};
