import React, { useEffect } from 'react';
import { Switch, Route, useLocation, useHistory, Redirect } from 'react-router-dom';
import Header from './Components/Header';
import Footer from './Components/Footer';
import WorksheetHome from './Pages/WorksheetHome';
import Account from './Pages/Account';
import Premium from './Pages/Premium';
import ResetPassword from './Pages/ResetPassword';
import Downloads from './Pages/Downloads';
import Home from './Pages/Home';
import BlogHome from './Pages/Blog/BlogHome';
import BlogPosts from './Pages/Blog/BlogPosts';
import ErrorPage from './Pages/404ErrorPage';
import TermsAndConditions from './Pages/TermsConditions';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import { SubjectPagesRoutes, LocationPageRoutes } from './Models';
import { DisplayContextProvider } from './Context/DisplayContext';
import { UserContextProvider } from './Context/UserContext';
import { socket, SocketContext } from './Context/WebsocketContext';
import SingleWorksheet from './Pages/SingleWorksheet';
import Subscription from './Pages/Subscription';
import { ResourceContextProvider } from './Context/ResourceFilterContext';
import HeaderRefactor from './Components/HeaderRefactor';
import EditDocumentDetails from './Pages/EditDocumentDetails';
import LandingPage1 from './Pages/LandingPage1';
import Success from './Pages/Success';
import ReadingRedirect from './Pages/ReadingRedirect';
import GuessTheWord from './Components/Games/GuessTheWord';

// import RollAndRead from './Components/Games/RollAndRead';
import MemoryGame from './Components/Games/MemoryGame';
import Balloons from './Components/Games/Balloons';
import ArtOff from './Components/Games/ArtOff';
import FlyingBird from './Components/Games/FlyingBird';
import ArtOffRoom from './Components/Games/ArtOffRoom';
import RacingGame from './Components/Games/RacingGame';
import AITutor from './Components/Games/AIWritingTutor';

const App = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.charAt(pathname.length - 1) === '/') {
      push(pathname.slice(0, pathname.length - 1));
    }
  }, []);

  return (
    <div className="App">
      <UserContextProvider>
        <DisplayContextProvider>
          <ResourceContextProvider>
            <SocketContext.Provider value={socket}>
              <Route component={Header} path="/" />
              <Route component={HeaderRefactor} path="/" />
              <Switch>
                <Route exact path="/" component={Home} />
                {SubjectPagesRoutes.map((subjectPage, index) => {
                  return <Route exact path={subjectPage.route} component={subjectPage.component} key={index} />;
                })}
                {LocationPageRoutes.map((locationPage, index) => {
                  return <Route exact path={locationPage.route} component={locationPage.component} key={index} />;
                })}
                <Route exact path="/ref-nofussreaders" component={Home} />
                <Route exact path="/notFound" component={ErrorPage} />
                <Route exact path="/blog" component={BlogHome} />
                <Route exact path="/account" component={Account} />
                <Route exact path="/premium" component={Premium} />
                <Route exact path="/art-off" component={ArtOff} />
                <Route path="/art-off/room/:roomId" component={ArtOffRoom} />
                <Route exact path="/balloons" component={Balloons} />
                <Route exact path="/ai-tutor" component={AITutor} />
                {/* <Route exact path="/roll-and-read" component={RollAndRead} /> */}
                <Route exact path="/memory-game" component={MemoryGame} />
                <Route exact path="/flying-bird" component={FlyingBird} />
                <Route exact path="/guess-the-word" component={GuessTheWord} />
                <Route exact path="/racing-game" component={RacingGame} />
                <Route exact path="/reset" component={ResetPassword} />
                <Route exact path="/downloads" component={Downloads} />
                <Route exact path="/terms-of-service" component={TermsAndConditions} />
                <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                <Route exact path="/subscription" component={Subscription} />
                <Route exact path="/enroll" component={LandingPage1} />
                <Route exact path="/success" component={Success} />
                <Route path={'/blog-posts'} component={BlogPosts} />
                <Route path={'/readingRedirect/:email'} component={ReadingRedirect} />
                <Route path={'/worksheets/:documentTitle'} component={SingleWorksheet} />
                <Route path={'/edit/:documentID'} component={EditDocumentDetails} />
                <Route
                  path="/:filterURL"
                  render={(routerProps) => {
                    return <WorksheetHome {...routerProps} />;
                  }}
                />
              </Switch>
              <Footer />
            </SocketContext.Provider>
          </ResourceContextProvider>
        </DisplayContextProvider>
      </UserContextProvider>
    </div>
  );
};

export default App;
