import React from 'react';
import { WorksheetsTextSegment } from '../../Models';
import { generateID } from '../../Utils';

type WorksheetTextParagraphProps = {
  segment: WorksheetsTextSegment;
};

const WorksheetTextParagraph: React.FC<WorksheetTextParagraphProps> = ({ segment }) => {
  return (
    <React.Fragment key={generateID()}>
      <p className="single-worksheet__textBlock-paragraph">
        {segment.type === 'paragraph' && typeof segment.content === 'string' && segment.content}
      </p>
    </React.Fragment>
  );
};

export default WorksheetTextParagraph;
