import { useContext } from 'react';
import { DisplayContext } from '../Context/DisplayContext';
import { DisplayProps, Resources } from '../Models';

export const useDisplayData = () => {
  const { displayData, setDisplayData } = useContext(DisplayContext);

  const hideModal = () => {
    setDisplayData((prev: DisplayProps) => ({
      ...prev,
      showModal: false,
      showFAQModal: false,
    }));
    if (window.location.href.includes('worksheets') || window.location.href.includes('assessments'))
      window.scrollTo(0, 460);
  };

  const showModal = () => {
    setDisplayData((prev: DisplayProps) => ({
      ...prev,
      showModal: true,
    }));
  };

  const stopBodyScroll = () => {
    document.body.classList.add('modal-open');
  };

  const startBodyScroll = () => {
    document.body.classList.remove('modal-open');
  };

  const setActiveCard = (card: Resources) => {
    setDisplayData((prev: DisplayProps) => ({
      showModal: true,
      activeCard: card,
    }));
  };

  // pass in boolean value to display either the login modal or create account modal
  const setLoginType = (loginBooleans: { login: boolean; signup: boolean }, redirect?: boolean) => {
    setDisplayData((prev: DisplayProps) => ({
      ...prev,
      showSignInModal: loginBooleans.login,
      showCreateUserModal: loginBooleans.signup,
      redirectToGame: redirect,
    }));
  };

  const showFAQModal = (FAQInfo: string) => {
    setDisplayData((prev: DisplayProps) => ({
      ...prev,
      showModal: true,
      showFAQModal: true,
      FAQInfo,
    }));
  };

  const showGameModal = (bool: boolean) => {
    setDisplayData((prev: DisplayProps) => ({
      ...prev,
      showModal: bool,
      showGameModal: bool,
      showSignInModal: false,
      showCreateUserModal: false,
    }));
  };
  const redirectReload = (bool: boolean) => {
    setDisplayData((prev: DisplayProps) => ({
      ...prev,
      redirectReload: bool,
    }));
  };

  return {
    displayData,
    hideModal,
    showModal,
    setLoginType,
    setActiveCard,
    stopBodyScroll,
    startBodyScroll,
    showFAQModal,
    showGameModal,
    redirectReload,
  };
};
