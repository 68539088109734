import React from 'react';
import NavLink from './NavLink';

type NavigationProps = {
  navListClass?: string,
  options: {
    label: string;
    link: string;
  }[];
};

const Navigation: React.FC<NavigationProps> = ({ options, navListClass }) => {
  const [items] = React.useState(options);
  return (
    <nav>
      <ul className={navListClass}>
        {items.map((item) => (
          <NavLink key={item.label} href={item.link} title={item.label} text={item.label} footer />
        ))}
      </ul>
    </nav>
  );
};

export default Navigation;
