import React from 'react';
import { Link } from 'react-router-dom';
import AWSImage from '../Components/Partials/AWSImage';

const ErrorPage: React.FC = () => {
  return (
    <section className="error404">
      <AWSImage
        pngFile={'404image.png'}
        documentPrefix={'homepage'}
        picClass={'error404__img'}
        imageAlt={'UFO Unable to Find Page'}
        hasWebp
      />

      <div className="error404__error-text-wrapper">
        <h3 data-cy="404page__header" className="error404__header">
          Whoops! This page doesn't exist.
        </h3>
        <p className="error404__subheader">
          We can't seem to find the page you are looking for. It may have expired, had its name changed or is
          temporarily unavailable.
        </p>
        <button className="error404__return-home-button">
          <Link to="/worksheets-for-kids">Go To Home Page</Link>
        </button>
      </div>
    </section>
  );
};

export default ErrorPage;
