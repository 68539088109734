import crypto from 'crypto-browserify';

const encryptionType = 'aes-256-cbc';
const encryptionEncoding = 'base64';  // Output encoding for encrypted data
const bufferEncoding = 'utf-8';       // Encoding for string inputs to Buffer

class AesEncryption {
    AesKey = 'FEVBAWQXTWUFSPYMUOCGSJSGMSDVWOYF';
    AesIV = 'DOFSPLGDZNUMOPEO';

    encrypt(val: string): string {
        const key = Buffer.from(this.AesKey, bufferEncoding);
        const iv = Buffer.from(this.AesIV, bufferEncoding);
        const cipher = crypto.createCipheriv(encryptionType, key, iv);
        let encrypted = cipher.update(val, bufferEncoding, encryptionEncoding);
        encrypted += cipher.final(encryptionEncoding);
        return encrypted;
    }

    decrypt(base64String: string): string {
        const buff = Buffer.from(base64String, 'base64');
        const key = Buffer.from(this.AesKey, bufferEncoding);
        const iv = Buffer.from(this.AesIV, bufferEncoding);
        const decipher = crypto.createDecipheriv(encryptionType, key, iv);
        let decrypted = decipher.update(buff);  // 'buff' is a Buffer, so no encodings are needed here
        decrypted += decipher.final(bufferEncoding);  // Output as a utf-8 string
        return decrypted;
    }
}

const NewEncryption = new AesEncryption();

export default NewEncryption;
