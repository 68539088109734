import React, { useState, useEffect } from 'react';
import { ResourceContextValues, Resources, SubjectSortedResources } from '../Models';
import { GetBaseURL, URLGeneratorByFilter } from '../Utils';

export interface FilterContextProps {
  resourceData: ResourceContextValues;
  setResourceData: (filterOptions: any) => void;
}

const ResourceContext = React.createContext({} as FilterContextProps);

const ResourceContextProvider = (props: any) => {
  const [resourceData, setResourceData] = useState<ResourceContextValues>({
    filters: { docType: 'Worksheets' },
    documents: [],
    pageTitle: 'Worksheets',
    hasFetched: false,
  });

  useEffect(() => {
    (async () => {
      let documentRequest = await fetch(
        `${GetBaseURL()}/api/resources/fetchV3Resources?key=${process.env.REACT_APP_API_KEY}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'GET',
        },
      );
      const response = await documentRequest.json();
      if (documentRequest.status === 200) {
        let resources: Resources[] = [];
        response.resources.forEach((resource: Resources) => {
          resource.priority !== undefined ? resources.unshift(resource) : resources.push(resource);
        });
        resources = resources.sort((a, b) =>
          a.priority !== undefined && b.priority !== undefined ? a.priority - b.priority : 1,
        );
        setResourceData((prev: ResourceContextValues) => ({
          ...prev,
          documents: resources,
          hasFetched: true,
        }));
      } else {
        console.log(response.errorMessage);
      }
    })();
  }, []);

  useEffect(() => {
    let generatedURL = URLGeneratorByFilter({
      ...resourceData.filters,
    });

    let adjustedURLString = generatedURL.split('-').join(' ').split('%20').join(' ');
    let capitalizedString = adjustedURLString
      .split(' ')
      .map((singleWord) => {
        return `${singleWord.charAt(0).toUpperCase()}${singleWord.length > 1 ? singleWord.slice(1) : ''}`;
      })
      .join(' ')
      .split('%2F')
      .map((singleWord) => {
        return `${singleWord.charAt(0).toUpperCase()}${singleWord.length > 1 ? singleWord.slice(1) : ''}`;
      })
      .join('/');
    setResourceData((prev: ResourceContextValues) => ({
      ...prev,
      pageTitle: capitalizedString,
    }));
  }, [resourceData.filters, resourceData.documents]);

  const providerValue: FilterContextProps = {
    resourceData,
    setResourceData,
  };

  return <ResourceContext.Provider value={providerValue}>{props.children}</ResourceContext.Provider>;
};

export { ResourceContext, ResourceContextProvider };
