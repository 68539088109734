import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Redirect } from 'react-router-dom';
import Banner from '../Components/Partials/Banner';
import RenderAllDocuments from '../Components/Resources/RenderAllDocuments';
import BackToPage from '../Components/Partials/BackToPage';
import { useUserData } from '../Hooks/useUserData';
import { useResourceFilters } from '../Hooks/useResourceFilter';
import { generatedLocation, generatedRoute } from '../Utils';
import { ErrorProps, Resources } from '../Models';
import ErrorHandler from '../Components/Partials/ErrorHandler';

const Downloads: React.FC = () => {
  const { userData, fetchUserDownloads } = useUserData();
  const { resourceData } = useResourceFilters();
  const [finishedFetching, setFinishedFetching] = useState(false);
  const [resourcesToRender, setResourcesToRender] = useState<Resources[]>([]);
  const [cookies] = useCookies(['email']);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [error, setError] = useState<ErrorProps>({
    isActive: false,
    message: '',
    type: 'error',
  });

  useEffect(() => {
    if (cookies.email) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    if (userData.user_data.full_name !== '' && resourcesToRender.length === 0 && finishedFetching === false) {
      renderDownloads();
    }
  }, [resourcesToRender, userData, finishedFetching]);

  const renderDownloads = async () => {
    const fetchResponse = await fetchUserDownloads();
    if (fetchResponse.success === true) {
      const resourceArray = [...userData.assessments, ...userData.worksheets];

      //This function could become a source of hangups if the user has thousands of downloads.
      //The fetched resources and the resources on the front end should have exactly the same length
      //Not dependent on the number of total resources in our Resources collection
      let resourcesToSort: { resource: Resources; date_downloaded: Date }[] = [];
      for (const resource of resourceArray) {
        for (const fetchedResource of fetchResponse.resources) {
          if (resource.resource_id === fetchedResource._id) {
            resourcesToSort.push({ resource: fetchedResource, date_downloaded: resource.date_downloaded });
          }
        }
      }

      const sortedResources = resourcesToSort.sort(
        (
          first: { resource: Resources; date_downloaded: Date },
          second: { resource: Resources; date_downloaded: Date },
        ) => {
          return new Date(second.date_downloaded).getTime() - new Date(first.date_downloaded).getTime();
        },
      );
      const returnedResources = sortedResources.map(
        (resource: { resource: Resources; date_downloaded: Date }) => resource.resource,
      );
      setResourcesToRender(Array.from(new Set(returnedResources)));
      setFinishedFetching(true);
    } else {
      setFinishedFetching(true);
      setError({ isActive: true, message: fetchResponse.message, type: 'error' });
    }
  };

  const resetError = () => {
    setError({
      isActive: false,
      message: '',
      type: 'error',
    });
  };

  return isLoggedIn ? (
    <main>
      <Banner lineOne={'Download History'} main imageName="worksheets_banner" alt="No Fuss Tutors banner" />
      <BackToPage
        route={`/${generatedRoute({ ...resourceData.filters })}`}
        location={generatedLocation(generatedRoute({ ...resourceData.filters }))}
      />
      {error.isActive && <ErrorHandler message={error.message} type={error.type} handler={resetError}></ErrorHandler>}

      {finishedFetching && (
        <RenderAllDocuments
          title="Welcome To Your Download History"
          documents={resourcesToRender}
          source="downloads-page"
        ></RenderAllDocuments>
      )}
    </main>
  ) : (
    <Redirect to="/worksheets-for-kids" />
  );
};

export default Downloads;
