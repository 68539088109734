import React, { useState } from 'react';

export interface Hat {
  id: number;
  name: string;
  imageUrl: string;
}

export interface Clothing {
  id: number;
  name: string;
  imageUrl: string;
}

interface CharacterSelectProps {
  hats: Hat[];
  clothes: Clothing[];
  onCharacterSelect: (hat: Hat, clothing: Clothing) => void;
}

const CharacterSelect: React.FC<CharacterSelectProps> = ({ hats, clothes, onCharacterSelect }) => {
  const [selectedHat, setSelectedHat] = useState<Hat | undefined>(undefined);
  const [selectedClothing, setSelectedClothing] = useState<Clothing | undefined>(undefined);

  const handleHatClick = (hat: Hat) => {
    setSelectedHat(hat);
    if (selectedClothing) {
      onCharacterSelect(hat, selectedClothing);
    }
  };

  const handleClothingClick = (clothing: Clothing) => {
    setSelectedClothing(clothing);
    if (selectedHat) {
      onCharacterSelect(selectedHat, clothing);
    }
  };

  return (
    <div>
      <h2>Choose your character</h2>
      <div>
        <h3>Hats:</h3>
        {hats.map((hat) => (
          <img
            key={hat.id}
            src={hat.imageUrl}
            alt={hat.name}
            onClick={() => handleHatClick(hat)}
            style={{
              border: selectedHat?.id === hat.id ? '3px solid blue' : 'none',
              cursor: 'pointer',
              margin: '10px',
            }}
          />
        ))}
      </div>
      <div>
        <h3>Clothes:</h3>
        {clothes.map((clothing) => (
          <img
            key={clothing.id}
            src={clothing.imageUrl}
            alt={clothing.name}
            onClick={() => handleClothingClick(clothing)}
            style={{
              border: selectedClothing?.id === clothing.id ? '3px solid blue' : 'none',
              cursor: 'pointer',
              margin: '10px',
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default CharacterSelect;
