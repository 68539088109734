import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { SocketContext } from './../../Context/WebsocketContext';
import { io, Socket } from 'socket.io-client';

import { Canvas } from './Whiteboard';
import { MyCanvasPath } from '../../Hooks/useCanvasDraw';
import Hats from './CustomCharacter/Hat';
import Eyes from './CustomCharacter/Eyes';
import Chat from './Chat';
import { regularArtOffWords, easyArtOffWords } from './ArtOffWords';

type Params = {
  roomId: string;
};

type User = {
  id: string;
  username: string;
  characterDetails: {
    color: string;
    hat: string;
    eyes: string;
  };
};

interface Scores {
  [userId: string]: number;
}

const ArtOffRoom = () => {
  const { roomId } = useParams<Params>();
  const [users, setUsers] = useState<User[]>([]);
  const [gameStart, setGameStart] = useState(false);
  const [receivedPaths, setReceivedPaths] = useState<MyCanvasPath[]>([]);
  const [localPaths, setLocalPaths] = useState<MyCanvasPath[]>([]);
  const [currentUserId, setCurrentUserId] = useState<string | null>(null);
  const isMounted = useRef(true);
  const currentUser = users.find((user) => user.id === currentUserId);
  const [creatorId, setCreatorId] = useState<string | null>(users.length > 0 ? users[0].id : null);
  const [isCopied, setIsCopied] = useState(false);
  const [turnLength, setTurnLength] = useState<number>(30);
  const [numRounds, setNumRounds] = useState<number>(3);
  const [wordDifficulty, setWordDifficulty] = useState<'easy' | 'regular'>('regular');
  const [words, setWords] = useState<string[]>([]);
  const [randomWord, setRandomWord] = useState('');
  const [currentTurnUserId, setCurrentTurnUserId] = useState<string | null>(null);
  const [scores, setScores] = useState<Scores>({});
  const [timer, setTimer] = useState<number>(turnLength);

  const socket = useContext(SocketContext);

  type UserWithCharacterDetails = User & {
    characterDetails: {
      color: string;
      hat: string;
      eyes: string;
    };
  };

  const fetchUsers = () => {
    if (socket) {
      socket.emit('get-users', roomId, (updatedUsers: UserWithCharacterDetails[]) => {
        if (isMounted.current) {
          setUsers(updatedUsers);
        }
      });
    }
  };

  useEffect(() => {
    if (wordDifficulty === 'easy') {
      setWords(easyArtOffWords);
    } else {
      setWords(regularArtOffWords);
    }
  }, [wordDifficulty, gameStart]);

  useEffect(() => {
    fetchUsers();
    return () => {
      if (socket) {
        socket.removeAllListeners();
      }
    };
  }, []);

  useEffect(() => {
    if (gameStart && users.length > 0) {
      setCurrentTurnUserId(users[0].id);
      console.log(`CurrentTurnUser id: ${currentTurnUserId}`);
    }
  }, [gameStart, users]);

  useEffect(() => {
    const handleUpdateUsers = (updatedUsers: UserWithCharacterDetails[], newUserId: string | null) => {
      setUsers(updatedUsers.map((user) => ({ ...user })));
      console.log(updatedUsers);

      if (updatedUsers.length > 0) {
        setCreatorId(updatedUsers[0].id);
      }

      if (updatedUsers.length > 0 && socket) {
        setCurrentUserId(() => socket.id ?? null);
      }

      if (newUserId) {
        const newUsername = getUsernameBySocketId(updatedUsers, newUserId);
        console.log(`this is the new user's username: ${newUsername}`);
      }
    };

    const getUsernameBySocketId = (userList: User[], socketId: string) => {
      const user = userList.find((user) => user.id === socketId);
      return user ? user.username : null;
    };

    if (socket) {
      socket.on('room-creator-id', (id) => {
        // Update the creatorId in ArtOffRoom with the value received from the server
        // Perform any other actions or updates required when the room is created
      });

      socket.on('update-users', handleUpdateUsers);
      socket.on('game-started', (gameSettings, randomWord) => {
        setRandomWord(randomWord);

        setGameStart(true);
        setTurnLength(gameSettings.turnLength);
        setNumRounds(gameSettings.numRounds);
        setWordDifficulty(gameSettings.wordDifficulty);

        if (users.length > 0) {
          setCurrentTurnUserId(users[0].id);
          console.log(`CurrentTurnUser id: ${currentTurnUserId}`);
        }
        console.log('Users in the room:', users);
      });
      socket.on('change-turn', (nextPlayerId, newRandomWord) => {
        console.log('Next player ID:', nextPlayerId);
        setRandomWord(newRandomWord);
        setCurrentTurnUserId((prevCurrentTurnUserId) => {
          if (prevCurrentTurnUserId !== nextPlayerId) {
            setTimer(turnLength); // Reset the timer
            return nextPlayerId;
          }

          return prevCurrentTurnUserId;
        });
      });
      socket.on('update-scores', (updatedScores) => {
        console.log(`Emitted 'update-scores' event with scores:`, updatedScores); // Debug log

        setScores(updatedScores);
      });
    }

    return () => {
      if (socket) {
        socket.removeAllListeners(); // Remove all listeners instead of disconnecting the socket
        isMounted.current = false;
      }
    };
  }, [socket]);

  useEffect(() => {
    const timerId = setInterval(() => {
      handleTimer();
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, [timer]);

  const startGame = () => {
    if (users.length >= 2) {
      if (socket) {
        socket.emit('game-started', roomId, {
          turnLength,
          numRounds,
          wordDifficulty,
        });
      }
      setGameStart(true);
    } else {
      return;
    }
  };

  const handleTimer = () => {
    if (timer > 0) {
      setTimer((prevTimer) => prevTimer - 1);
    } else {
      if (socket) {
        socket.emit('change-turn', roomId);
      }
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(roomId);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 500);
  };

  const convertToUnderscores = (content: string) => {
    return content.replace(/[a-zA-Z]/g, '_');
  };

  const renderSettingsForm = () => {
    return (
      <form className="settings-form ArtOff__settings-form">
        <h2 className="form-title">Game Settings</h2>
        <div className="form-field ArtOff__form-field">
          <label htmlFor="turnLength" className="form-label">
            Turn Length (seconds):
          </label>
          <input
            type="number"
            id="turnLength"
            value={turnLength}
            onChange={(event) => setTurnLength(Number(event.target.value))}
            className="form-input"
          />
        </div>

        <div className="form-field ArtOff__form-field">
          <label htmlFor="numRounds" className="form-label">
            Number of Rounds:
          </label>
          <input
            type="number"
            id="numRounds"
            value={numRounds}
            onChange={(event) => setNumRounds(Number(event.target.value))}
            className="form-input"
          />
        </div>

        <div className="form-field ArtOff__form-field">
          <label htmlFor="wordDifficulty" className="form-label">
            Word Difficulty:
          </label>
          <select
            id="wordDifficulty"
            value={wordDifficulty}
            onChange={(event) => setWordDifficulty(event.target.value as 'easy' | 'regular')}
            className="form-select"
          >
            <option value="easy">Easy</option>
            <option value="regular">Regular</option>
          </select>
        </div>
      </form>
    );
  };

  return (
    <div>
      <div className="ArtOff__room-code-container">
        <div className="ArtOff__room-code-text">Copy Room Code:</div>
        <div className="ArtOff__room-code" onClick={handleCopy}>
          {roomId}
          {isCopied && <div className="ArtOff__room-code-copied">Copied!</div>}
        </div>
      </div>
      <div>
        {gameStart ? (
          <div className="ArtOff__game-container">
            <div className="ArtOff__character--place-list">
              {users.map((user) => (
                <div
                  className={`ArtOff__character--row${
                    user.id === currentTurnUserId ? ' ArtOff__character--row-active' : ''
                  }`}
                  key={user.id}
                  style={user.id === currentTurnUserId ? { backgroundColor: '#AAF0D1' } : {}}
                >
                  {' '}
                  <div>1st</div>
                  <div>
                    <div>
                      {user.username}{' '}
                      {user.id === currentTurnUserId && <div className="ArtOff__current-turn-label"> is Drawing!</div>}
                    </div>
                    <div>Points: {scores[user.id] || 0}</div>
                  </div>
                  <div className="ArtOff__character--game">
                    <div className="ArtOff__shape">
                      <svg
                        version="1.0"
                        xmlns="http://www.w3.org/2000/svg"
                        width="64.000000pt"
                        height="52.000000pt"
                        viewBox="0 0 1280.000000 1056.000000"
                        preserveAspectRatio="xMidYMid meet"
                        style={{ filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.7))' } as any}
                      >
                        <g
                          transform="translate(0.000000,1056.000000) scale(0.100000,-0.100000)"
                          fill={user.characterDetails.color}
                          stroke="none"
                        >
                          <path
                            d="M6310 9673 c-683 -40 -1382 -259 -1984 -623 -322 -194 -559 -381
                  -865 -683 -754 -744 -1342 -1716 -1801 -2972 -281 -769 -484 -1595 -564 -2291
                   -13 -115 -31 -229 -40 -253 -57 -157 -65 -335 -21 -486 108 -372 471 -712
                    1070 -1004 505 -246 1173 -451 1900 -582 33 -6 80 -14 105 -19 189 -36 617
                   -93 895 -119 539 -51 668 -56 1345 -56 677 0 806 5 1345 56 573 53 1151 152
                  1670 284 397 101 813 242 1085 368 41 19 101 46 134 61 90 43 271 143 376 209
                    307 195 518 404 635 628 45 87 95 239 95 289 0 15 9 63 20 106 27 108 26 232
                    -6 444 -81 553 -225 1139 -424 1730 -460 1364 -1019 2448 -1694 3285 -270 335
                  -645 704 -941 925 -548 411 -1144 648 -1760 700 -110 9 -441 11 -575 3z"
                          />
                        </g>
                      </svg>

                      <div className="ArtOff__hat">
                        <div className="ArtOff__hat-off-2">
                          <Hats type={user.characterDetails.hat} />
                        </div>
                      </div>
                      <div className="ArtOff__eyes ">
                        <div className="ArtOff__eyes-off-2">
                          <Eyes type={user.characterDetails.eyes} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="ArtOff__middle-game-container">
              {currentUserId === currentTurnUserId && (
                <div className="ArtOff__current-turn-label">is Drawing! {timer} seconds left</div>
              )}

              <div className="ArtOff__random-word">
                {currentUserId === currentTurnUserId ? randomWord : convertToUnderscores(randomWord)}
              </div>
              <Canvas
                colors={[
                  '#FF4136',
                  '#FF851B',
                  '#FFDC00',
                  '#2ECC40',
                  '#0074D9',
                  '#7FDBFF',
                  '#B10DC9',
                  '#F1FAEE',
                  '#111111',
                  '#AAAAAA',
                  '#FF80CC',
                  '#754600',
                ]}
                color="#000000"
                lineWidth={5}
                roomId={roomId}
                currentUserId={currentUserId}
                turnUserId={currentTurnUserId}
              />
            </div>
            <div className="ArtOff__guess--container">
              <Chat roomId={roomId} currentUserId={currentUserId} turnUserId={currentTurnUserId} users={users} />
            </div>
          </div>
        ) : (
          <div>
            {currentUser && creatorId && currentUser.id === creatorId && renderSettingsForm()}
            {currentUser && creatorId && currentUser.id === creatorId && users.length >= 2 && (
              <button className="ArtOff__waiting" onClick={startGame}>
                Start Game
              </button>
            )}

            {currentUser && creatorId && currentUser.id !== creatorId && users.length >= 2 && (
              <div className="ArtOff__waiting">Waiting for the Room Creator to start the game...</div>
            )}

            {currentUser && creatorId && currentUser.id === creatorId && users.length < 2 && (
              <div className="ArtOff__waiting">Waiting for 2 players to start game</div>
            )}

            <div className="ArtOff__players--label">
              Players <span className="ArtOff__players--label-span">({users.length})</span>
            </div>

            <div className="ArtOff__character--list">
              {users.map((user) => (
                <div className="ArtOff__character--card" key={user.id}>
                  <div className="ArtOff__character--username">{user.username}</div>

                  <div className="ArtOff__character">
                    <div className="ArtOff__shape">
                      <svg
                        version="1.0"
                        xmlns="http://www.w3.org/2000/svg"
                        width="128.000000pt"
                        height="105.000000pt"
                        viewBox="0 0 1280.000000 1056.000000"
                        preserveAspectRatio="xMidYMid meet"
                        style={{ filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.7))' } as any}
                      >
                        <g
                          transform="translate(0.000000,1056.000000) scale(0.100000,-0.100000)"
                          fill={user.characterDetails.color}
                          stroke="none"
                        >
                          <path
                            d="M6310 9673 c-683 -40 -1382 -259 -1984 -623 -322 -194 -559 -381
                       -865 -683 -754 -744 -1342 -1716 -1801 -2972 -281 -769 -484 -1595 -564 -2291
                       -13 -115 -31 -229 -40 -253 -57 -157 -65 -335 -21 -486 108 -372 471 -712
                       1070 -1004 505 -246 1173 -451 1900 -582 33 -6 80 -14 105 -19 189 -36 617
                       -93 895 -119 539 -51 668 -56 1345 -56 677 0 806 5 1345 56 573 53 1151 152
                      1670 284 397 101 813 242 1085 368 41 19 101 46 134 61 90 43 271 143 376 209
                      307 195 518 404 635 628 45 87 95 239 95 289 0 15 9 63 20 106 27 108 26 232
                     -6 444 -81 553 -225 1139 -424 1730 -460 1364 -1019 2448 -1694 3285 -270 335
                     -645 704 -941 925 -548 411 -1144 648 -1760 700 -110 9 -441 11 -575 3z"
                          />
                        </g>
                      </svg>

                      <div className="ArtOff__hat">
                        <div className="ArtOff__hat-off-2">
                          <Hats type={user.characterDetails.hat} />
                        </div>
                      </div>
                      <div className="ArtOff__eyes ">
                        <div className="ArtOff__eyes-off-2">
                          <Eyes type={user.characterDetails.eyes} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ArtOffRoom;
