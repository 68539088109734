import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Card from '../Card';
import { useResourceFilters } from '../../Hooks/useResourceFilter';
import { URLGeneratorByFilter } from '../../Utils';
import { prioritizeDocuments } from '../../Utils/WorksheetsWidgetHelpers';
import { Resources, subjectListArray, UpdatedFilterOptions } from '../../Models';

type WorksheetsWidgetProps = {
  keywords: string[];
};

const WorksheetsWidget: React.FC<WorksheetsWidgetProps> = ({ keywords }) => {
  const { resourceData, FetchRelevantSubcategories, AllDocsForFilters } = useResourceFilters();
  const [documents, setDocuments] = useState<Resources[]>([]);
  const [title, setTitle] = useState('');
  const [filters, setFilters] = useState<UpdatedFilterOptions>({ docType: 'Worksheets' });

  useEffect(() => {
    let filter = filterKeywords(keywords);
    setFilters(filter);
    let fetchedDocuments = AllDocsForFilters(filter);
    let prioritizedDocuments = prioritizeDocuments(fetchedDocuments, keywords, filter.subject);
    setDocuments(prioritizedDocuments.randomizedDocuments);
    if (prioritizedDocuments.randomizedDocuments.length < 3) {
      setDocuments((prev) => [...prev, ...prioritizedDocuments.splicedDocuments.sort(() => Math.random() - 0.5)]);
    }
    if (filter.subcategory) {
      setTitle(filter.subcategory + ' Worksheets for Kids');
    } else if (filter.subject) {
      setTitle(filter.subject + ' Worksheets for Kids');
    } else {
      setTitle('Worksheets for Kids');
    }
  }, [keywords, resourceData.documents]);

  const filterKeywords = (keywords: string[]) => {
    for (const subject of subjectListArray) {
      let subcategories = FetchRelevantSubcategories(subject.value);
      for (const subcategory of subcategories) {
        if (keywords.includes(subcategory.toLowerCase())) {
          return { docType: 'Worksheets', subject: subject.value, subcategory };
        }
      }
      if (keywords.includes(subject.value.toLowerCase())) {
        return { docType: 'Worksheets', subject: subject.value };
      }
    }
    return { docType: 'Worksheets' };
  };

  const CARDS = documents.slice(0, 3).map((singleCardData: Resources, index: number) => {
    return (
      <div key={index} onClick={() => {}} className="worksheets-widget__card-wrapper">
        <Card
          link={singleCardData.link}
          src={singleCardData.src}
          webp={singleCardData.webp}
          alt={singleCardData.alt}
          type={singleCardData.type.toLowerCase()! as 'worksheets' | 'assessments'}
          title={singleCardData.title}
          subject={singleCardData.subject}
          grade={singleCardData.grade}
          onClick={() => {}}
          _id={singleCardData._id}
          source={'downloads-page'}
        ></Card>
      </div>
    );
  });

  return (
    <section className="worksheets-widget">
      <div className="downloads-page">
        <h1 className="downloads-page__title">{title}</h1>

        <div className="worksheets-widget__cards-container">{CARDS}</div>
        <Link to={`/${URLGeneratorByFilter(filters)}`}>
          <p className="worksheets-widget__sub-heading" style={{ fontStyle: 'normal' }}>
            Check out our {filters.subcategory ? filters.subcategory : filters.subject ? filters.subject : ''}{' '}
            Worksheets!
          </p>
        </Link>
      </div>
    </section>
  );
};

export default WorksheetsWidget;
