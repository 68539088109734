// ! blog card information ============================================================
export const BlogCardContent = [
  {
    link: '/blog-posts/my-9-year-old-cant-read',
    src: 'cant-read.png',
    alt: 'elementary school girl looks troubled as she watches two classmates reading to each other',
    title: `My 9-Year-Old Can't Read. What should I do?`,
    date: 'October 23, 2020',
  },

  {
    link: '/blog-posts/my-kid-wont-do-homework',
    src: 'wont-do-homework.png',
    alt: 'elementary school girl looking frustrated with head on hand doing homework',
    title: `What to Do When Your Child Won't Do Homework`,
    date: 'October 25, 2020',
  },

  {
    link: '/blog-posts/my-kid-is-falling-behind-in-math',
    src: 'falling-behind-in-math.png',
    alt: 'elementary school student doing math homework',
    title: `My Kid is Falling Behind in Math`,
    date: 'November 3, 2020',
  },

  {
    link: '/blog-posts/why-does-my-child-ask-so-many-questions',
    src: 'asking-questions.png',
    alt: 'teacher and elementary school student doing a tutoring session on the computer',
    title: `Why Does My Child Ask So Many Questions?`,
    date: 'November 15, 2020',
  },

  {
    link: '/blog-posts/my-child-is-playing-video-games',
    src: 'video-games-instead-of-homework.png',
    alt: 'two elementary school children playing video games',
    title: `My Child is Playing Video Games Instead of Doing Homework`,
    date: 'November 16, 2020',
  },

  {
    link: '/blog-posts/what-should-i-ask-in-a-parent-teacher-conference',
    src: 'what-should-i-ask-in-a-parent-teacher-conference.png',
    alt: 'Parent student and teacher in parent teacher conference',
    title: `What Should I Ask in a Parent-Teacher Conference?`,
    date: 'November 19, 2020',
  },

  {
    link: '/blog-posts/my-child-cant-focus-on-school-work',
    src: 'my-child-cant-focus-on-school-work.png',
    alt: 'elementary school child on phone',
    title: `My Child Can't Focus on School Work`,
    date: 'November 21, 2020',
  },

  {
    link: '/blog-posts/how-to-help-my-child-with-spelling',
    src: 'how-to-help-my-child-with-spelling.png',
    alt: 'Young child and tutor practicing spelling',
    title: `How To Help My Child with Spelling?`,
    date: 'November 26, 2020',
  },

  {
    link: '/blog-posts/my-kid-is-afraid-to-read-in-class',
    src: 'my-kid-is-afraid-to-read-in-class.png',
    alt: 'elementary school student reading book',
    title: `My Kid is Afraid to Read in Class`,
    date: 'December 1, 2020',
  },

  {
    link: '/blog-posts/distributive-property-of-multiplication',
    src: 'distributiveProperty.jpg',
    alt: 'elementary school age student looking at chalk board with multiplication tables',
    title: 'Distributive Property of Multiplication',
    date: 'Feb 13, 2021',
  },

  {
    link: '/blog-posts/multiplication-chart',
    src: 'multiplicationChart.jpg',
    alt: 'elementary school age student looking at chalk board with multiplication tables',
    title: 'Multiplication Chart',
    date: 'February 20, 2021',
  },

  {
    link: '/blog-posts/what-are-blends',
    src: 'what-are-blends.jpg',
    alt: 'pre kindergarten child and tutor looking and pointing at notebook',
    title: 'What Are Blends?',
    date: 'February 25, 2021',
  },

  {
    link: '/blog-posts/sight-words-done-right',
    src: 'sight-words.jpg',
    alt: 'middle school age student and tutor looking over words in a book',
    title: 'Sight Words Done Right',
    date: 'March 1, 2021',
  },

  {
    link: '/blog-posts/why-are-spelling-activities-important',
    src: 'spelling-activites.jpg',
    alt: 'elementary school child and tutor writing in notebook',
    title: 'Why Are Spelling Activities Important?',
    date: 'March 10, 2021',
  },
  {
    link: '/blog-posts/when-does-a-child-learn-to-read',
    src: 'whendokidslearntoread.jpeg',
    alt: 'child reading sitting against a tree',
    title: 'When Does a Child Learn to Read?',
    date: 'March 14, 2021 ',
  },
  {
    link: '/blog-posts/what-is-text-structure',
    src: 'text-structure.jpeg',
    alt: 'child reading book',
    title: 'What is Text Structure?',
    date: 'March 18, 2021 ',
  },
  {
    link: '/blog-posts/coloring-pages-for-kids',
    src: 'coloring-pages.jpeg',
    alt: 'crayons for coloring pages',
    title: 'Benefits of Coloring Pages for Kids',
    date: 'March 23, 2021 ',
  },
];

// ! blog posts - why does my child ask so many questions ================================
export const WhenYourChildWontDoHomeWorkContent = {
  blogPostClassName: 'blog-post__my-kid-wont-do-homework',
  bannerLineOne: 'What to Do When Your Child',
  bannerLineTwo: "Won't Do Homework",
  bannerAuthor: 'No Fuss Tutors',
  bannerDate: 'October 23, 2020',
  bannerImageName: 'my-kid-wont-do-homework',
  bannerImage: 'wont-do-homework-banner.png',
  bannerAlt: 'elementary school student looking unhappy studying',
  metaDataPostTitle: "When Your Child Won't Do Homework",
  metaDataPageTitle: "Won't Do Homework",
  metaDataDescription: 'Is your child leaving homework incomplete? Read on to learn some tips on building good habits!',
  metaDataImage: 'https://nft-development.netlify.app/assets/webp/logo.png',
  metaDataUrl: 'https://nofusstutors.com/blog/when-your-child-wont-do-homework',
  metaDataKeywords: ['online tutoring', 'homework', 'help with homework', 'homework tips'],
  worksheetWidgetKeywords: [],
  sideBarPageLinks: [
    {
      pageName: 'English Tutor',
      pageLink: '/online-english-tutor',
    },
    {
      pageName: 'Reading Tutor',
      pageLink: '/online-reading-tutor',
    },
    {
      pageName: 'Writing Tutor',
      pageLink: '/online-writing-tutor',
    },
    {
      pageName: 'Math Tutor',
      pageLink: '/online-math-tutor',
    },
    {
      pageName: 'Science Tutor',
      pageLink: '/online-science-tutor',
    },
  ],
  sideBarWorksheetsLinks: [
    {
      worksheetsName: 'All Worksheets',
      worksheetsLink: '/worksheets-for-kids',
    },
    {
      worksheetsName: 'Preschool',
      worksheetsLink: '/preschool-worksheets',
    },
    {
      worksheetsName: 'Kindergarten',
      worksheetsLink: '/worksheets-for-kindergarten',
    },
    {
      worksheetsName: 'Grade 1',
      worksheetsLink: '/grade-1-worksheets',
    },
    {
      worksheetsName: 'Grade 2',
      worksheetsLink: '/grade-2-worksheets',
    },
    {
      worksheetsName: 'Grade 3',
      worksheetsLink: '/grade-3-worksheets',
    },
    {
      worksheetsName: 'Reading',
      worksheetsLink: '/reading-worksheets',
    },
    {
      worksheetsName: 'Writing',
      worksheetsLink: '/writing-worksheets',
    },
    {
      worksheetsName: 'Math',
      worksheetsLink: '/math-worksheets',
    },
    {
      worksheetsName: 'Science',
      worksheetsLink: '/science-worksheets',
    },
  ],
  blogContent: [
    {
      content: [
        'As a parent, you’re bound to run into the issue of your kid not wanting to do their homework. It’s frustrating for both you and your child. Banish your homework woes with these tried-and-true tips!',
      ],
    },
    {
      heading: 'First, Figure Out Why Your Kid Won’t Do Homework.',
      content: [
        'There’s always a reason why your kid hasn’t finished their homework. Did they simply forget? Do they want to do something else instead? Do they not understand the work? Or, do they not know where math’s “x” went and “y” it left? Joking aside, the reasons can range from simple to complex. Talk it through and figure out what’s stopping them from getting the assignment done. From there, you can look into ways of aiding your child further.',
        'If your child is procrastinating because they don’t understand their assignment, help them break it down or get additional help from a tutor. Working one-on-one with an adult can be very helpful for your child’s understanding of the subject.',
      ],
    },
    {
      heading: 'Make Homework A Game',
      content: [
        'If your child is just not interested in the work at hand, do your best to make it more interesting! Turn the homework into a fun game. One way is to create flashcards, quizzes with fun rewards, or even a song if it’s something to do with memorization.',
        ' Another option is to offer rewards for getting things done. You can offer smaller rewards for reaching a designated amount of points, and a big reward once they have accomplished a larger goal (such as a good test score or the end of a quarter).',
      ],
    },
    {
      heading: 'Create a Routine',
      content: [
        'Creating a homework routine can wipe out the homework problem early on. Dedicate a certain time of night to homework time. This starts a habit with your kids, and once they get homework time into their nightly routine, they’ll begin to understand that nothing else gets to happen during that time, only homework. This will make it much easier to convince them to get their homework done, even if they don’t want to do it.',
        'Creating a to-do list each night is also helpful. Put this up on the fridge so that each of your kids knows what they need to get done before homework time is officially over. It makes things visual, and a lot easier to get through.',
      ],
    },
    {
      heading: 'Homework Is Necessary, But Not Always Easy',
      content: [
        'Homework time isn’t going to be smooth sailing every time, even with an established routine. Kids just don’t always want to do homework. Give them (and you!) a break from time to time when they’re doing their work. And honestly, just get it done. Their work isn’t always going to be perfect, and it’s not always your child’s cup of tea. As long as they have plenty of support, they will be much more likely to succeed!',
      ],
    },
  ],
};

// ! blog posts - my 9 year old cant read ========================================
export const My9YearOldCantReadContent = {
  blogPostClassName: 'blog-post__my-9-year-old-cant-read',
  bannerLineOne: "My 9-Year-Old Can't Read.",
  bannerLineTwo: 'What should I do?',
  bannerAuthor: 'No Fuss Tutors',
  bannerDate: 'October 23, 2020',
  bannerImageName: 'my-9-year-old-cant-read',
  bannerImage: 'cant-read-banner.png',
  bannerAlt: 'elementary school student looking at other students reading',
  metaDataPostTitle: "My 9 Year Old Can't Read. What should I do? ",
  metaDataPageTitle: 'Struggling to Read',
  metaDataDescription: 'Is your child having trouble reading? Read on to learn some tips on building good habits!',
  metaDataImage: 'https://nft-development.netlify.app/assets/webp/logo.png',
  metaDataUrl: 'https://nofusstutors.com/blog-posts/my-9-year-old-cant-read',
  metaDataKeywords: ['online tutoring', 'help with reading', 'reading tips'],
  worksheetWidgetKeywords: ['reading'],
  sideBarPageLinks: [
    {
      pageName: 'English Tutor',
      pageLink: '/online-english-tutor',
    },
    {
      pageName: 'Reading Tutor',
      pageLink: '/online-reading-tutor',
    },
  ],
  sideBarWorksheetsLinks: [
    {
      worksheetsName: 'Reading',
      worksheetsLink: '/reading-worksheets',
    },
    {
      worksheetsName: 'Reading Comprehension',
      worksheetsLink: '/reading-comprehension-worksheets',
    },
    {
      worksheetsName: 'Vowels',
      worksheetsLink: '/vowels-worksheets',
    },
  ],
  blogContent: [
    {
      content: [
        'You know it’s important for your child to learn to read, and for many kids, reading seems to come naturally. So, you may be wondering, “My 9-year-old can’t read. Why?”',
        'Take a deep breath, mama. It’s just as frustrating for you as it is for your child. Some kids simply don’t enjoy it, while others struggle to make sense of it.',
        'Recognizing they’re struggling is a great first step to helping them. From there, you can do the following things:',
      ],
    },
    {
      heading: 'Start with the basics.',
      content: [
        'If your 9 year old can’t read, forget what they’re learning in class and go back to the basics.',
        'Reading is simply making sense of words. Words are made of letters, and letters are made of sounds. So, start by making sure your child knows their letters and the sounds they make.',
        'Don’t assume they know the basics just because the teacher went over them in Kindergarten. If your child didn’t understand something then, they can’t apply that knowledge to future learning.',
        'If your child, struggles to see what letters are in a word, this a strong sign that you should have them tested for Dyslexia.',
      ],
    },
    {
      heading: 'Find the Missing Links',
      content: [
        'Once your child has proven they know their letters and sounds, move on to simple words and start ramping up from there. In some cases, it might be wise to have your child take a proficiency test so you can identify knowledge gaps.',
      ],
    },
    {
      heading: 'Potential Learning Disability',
      content: [
        'It’s also important to consider if there’s a family history with learning to read. Reading difficulties have a heavy genetic component that could help shine a light on your child’s reading issues. If there is an underlying learning disability that has gone undiagnosed, identifying what is causing their difficulty is the first step to working toward a solution.',
      ],
    },
    {
      heading: 'Get 1-on-1 Help',
      content: [
        'Working with a skilled reading tutor with experience in tutoring 9 year olds can be invaluable to your child. If you’re unsure about an underlying condition, choose a company, like No Fuss Tutors, that has worked with kids that have dyslexia. Oftentimes, parents want to help their kids excel in school but simply don’t know how. A tutor can help fill these gaps because of their knowledge in how kids learn and different methods to make learning fun.',
        'At No Fuss Tutors, we smile just as big as your child when they find the confidence to read. All of our tutoring is done online around you and your child’s schedule to keep them learning. Contact us today for a consultation to learn more.',
      ],
    },
  ],
};

// ! blog posts - why does my child ask so many questions ========================================
export const WhyDoesMyChildAskSoManyQuestionsContent = {
  blogPostClassName: 'blog-post__ask-questions',
  bannerLineOne: 'Why Does My Child Ask So',
  bannerLineTwo: 'Many Questions?',
  bannerAuthor: 'No Fuss Tutors',
  bannerDate: 'November 14, 2020',
  bannerImageName: 'ask-questions',
  bannerImage: 'asking-questions-banner.png',
  bannerAlt: 'elementary school student looking computer with tutor',
  metaDataPostTitle: 'Why Does My Child Ask So Many Questions?',
  metaDataPageTitle: 'So Many Questions',
  metaDataDescription: 'Is your child asking a lot of questions? Read on to learn some tips on building good habits!',
  metaDataImage: 'https://nft-development.netlify.app/assets/webp/logo.png',
  metaDataUrl: 'https://nofusstutors.com/blog-posts/why-does-my-child-ask-so-many-questions',
  metaDataKeywords: ['online tutoring', 'asking questions', 'help with asking questions', 'asking too many questions'],
  worksheetWidgetKeywords: [],
  sideBarPageLinks: [
    {
      pageName: 'English Tutor',
      pageLink: '/online-english-tutor',
    },
    {
      pageName: 'Reading Tutor',
      pageLink: '/online-reading-tutor',
    },
    {
      pageName: 'Writing Tutor',
      pageLink: '/online-writing-tutor',
    },
    {
      pageName: 'Math Tutor',
      pageLink: '/online-math-tutor',
    },
    {
      pageName: 'Science Tutor',
      pageLink: '/online-science-tutor',
    },
  ],
  sideBarWorksheetsLinks: [
    {
      worksheetsName: 'All Worksheets',
      worksheetsLink: '/worksheets-for-kids',
    },
    {
      worksheetsName: 'Preschool',
      worksheetsLink: '/preschool-worksheets',
    },
    {
      worksheetsName: 'Kindergarten',
      worksheetsLink: '/worksheets-for-kindergarten',
    },
    {
      worksheetsName: 'Grade 1',
      worksheetsLink: '/grade-1-worksheets',
    },
    {
      worksheetsName: 'Grade 2',
      worksheetsLink: '/grade-2-worksheets',
    },
    {
      worksheetsName: 'Grade 3',
      worksheetsLink: '/grade-3-worksheets',
    },
    {
      worksheetsName: 'Reading',
      worksheetsLink: '/reading-worksheets',
    },
    {
      worksheetsName: 'Writing',
      worksheetsLink: '/writing-worksheets',
    },
    {
      worksheetsName: 'Math',
      worksheetsLink: '/math-worksheets',
    },
    {
      worksheetsName: 'Science',
      worksheetsLink: '/science-worksheets',
    },
  ],
  blogContent: [
    {
      content: [
        '“Mom, why is the moon round?”',
        '“Mom, why are my teeth white?”',
        '“Mom, why do I have to take my bath?”',
        '“Mom, why this…mom why that…mom!!!!”',
        'The funny thing is you might start out answering each question with elaborate answers and enthusiasm but as they go on and on with no sign of stopping your response can go from few words to shutting down the line of questioning.',
        'It’s exhausting and also one of the most beautiful things about being a parent and watching your child grow.',
      ],
    },
    {
      heading: 'Asking So Many Questions is Good',
      content: [
        'According to a report by Harvard-based child psychologist Paul Harris, a child can ask around 40, 000 questions between 2-4 years of age.',
        'Other studies also show that some 4-year-olds ask as many as 200-300 questions daily.',
        'Asking questions for children is a good thing that indicates growth and curiosity. As soon as your child is big enough to explore the outside world, they can get overwhelmed by the things around them. They will want to figure things out, know how things work and this spark of curiosity leads to naturally asking about everything.',
        'Note the part where we say this comes to kids naturally and as such when your child doesn’t get the answers they need they continue asking and even rephrase the same questions.',
        'It’s important to nurture this curiosity in your child to help them develop mentally and birth great things in their future.',
        'Aside from this desire to learn and understand the world around them, kids can also ask questions to get your attention or act stubborn in doing something.',
        'Whatever the reason for your consistent share of preschooler questioning, here’s how to handle all the whys:',
      ],
    },
    {
      subHeading: '1. Answer plain and simple',
      content: [
        'When your child asks a question, they often expect a straight and simple answer that satisfies their curiosity. Hence on most days when you can, provide the clear answers your child needs.',
      ],
    },
    {
      subHeading: '2. Become the asker',
      content: [
        'Can’t keep up? Then turn the tables around to ask your child why. For example, if your child asks why they have to brush their teeth, you can ask them why they think they have to brush their teeth. Turning the question on your kids isn’t you been a sneaky parent. It actually helps your little one develop critical thinking skills.',
        'Although you might have to clarify their answers in the end. Be sure to use the right tone to get the right results. If you sound annoyed while asking then your child will interpret this wrongly.',
      ],
    },
    {
      subHeading: '3. Postpone the inevitable',
      content: [
        'Trust us this is a good one. Depending on when your child asks the questions you can postpone answering for later. For example, if your little one asks the questions during dinner or while you are working, you can say you’ll answer later after dinner or work is finished. This gives you a break but don’t expect your child to forget because they won’t so plan to answer at a more suitable time.',
      ],
    },
    {
      subHeading: '4. Find the answers with them',
      content: [
        'We’ve all been in that position where your little one asks a question you don’t have the answer to. Rather than try to brush it aside or just say you don’t know, research the answers with your child. Grab a book or computer together and let your child know the answer. This encourages the habit of reading and researching to get accurate answers.',
      ],
    },
    {
      subHeading: "5. It's okay not to have all the answers",
      content: [
        'Yes, there are times when admitting you don’t know is best. It’s okay to admit this to your child. You can then promise to find out and tell them the answer later.',
      ],
    },
    {
      subHeading: '6. Keep your promises',
      content: [
        '“I don’t know baby, I will find out,”, “we will talk about it after dinner,”, “just ask me later okay?”.',
        'These are not escape routes, they are promises and while it’s not right to break so little a promise to your curious preschooler, know that they will remember to ask again. Be sure to always follow through.',
        'It can be hard to do this smoothly but patience is key. Remind yourself of how these questions are proof of your child’s growth and can improve their learning. Need help? Hire an experienced tutor to take on all the answers.',
      ],
    },
  ],
};

// ! blog posts - My child is playing video games instead of doing homework ========================================
export const PlayingVideoGamesContent = {
  blogPostClassName: 'blog-post__video-games',
  bannerLineOne: 'Playing Video Games Instead',
  // todo: fix margin top
  bannerLineTwo: 'of Doing Homework',
  bannerAuthor: 'No Fuss Tutors',
  bannerDate: 'November 16, 2020',
  bannerImageName: 'video-games',
  bannerImage: 'video-games-instead-of-homework-banner.png',
  bannerAlt: 'two elementary school children playing video games',
  metaDataPostTitle: 'My Child is Playing Video Games Instead of Doing Homework',
  metaDataPageTitle: 'Video Games Instead of Homework',
  metaDataDescription:
    'Is your child playing video games instead of doing homework? Read on to learn some tips on building good habits!',
  metaDataImage: 'https://nft-development.netlify.app/assets/webp/logo.png',
  metaDataUrl: 'https://nofusstutors.com/blog-posts/my-child-is-playing-video-games',
  metaDataKeywords: ['online tutoring', 'video games', 'help with video games instead of homework'],
  worksheetWidgetKeywords: [],
  sideBarPageLinks: [
    {
      pageName: 'English Tutor',
      pageLink: '/online-english-tutor',
    },
    {
      pageName: 'Reading Tutor',
      pageLink: '/online-reading-tutor',
    },
    {
      pageName: 'Writing Tutor',
      pageLink: '/online-writing-tutor',
    },
    {
      pageName: 'Math Tutor',
      pageLink: '/online-math-tutor',
    },
    {
      pageName: 'Science Tutor',
      pageLink: '/online-science-tutor',
    },
  ],
  sideBarWorksheetsLinks: [
    {
      worksheetsName: 'All Worksheets',
      worksheetsLink: '/worksheets-for-kids',
    },
    {
      worksheetsName: 'Preschool',
      worksheetsLink: '/preschool-worksheets',
    },
    {
      worksheetsName: 'Kindergarten',
      worksheetsLink: '/worksheets-for-kindergarten',
    },
    {
      worksheetsName: 'Grade 1',
      worksheetsLink: '/grade-1-worksheets',
    },
    {
      worksheetsName: 'Grade 2',
      worksheetsLink: '/grade-2-worksheets',
    },
    {
      worksheetsName: 'Grade 3',
      worksheetsLink: '/grade-3-worksheets',
    },
    {
      worksheetsName: 'Reading',
      worksheetsLink: '/reading-worksheets',
    },
    {
      worksheetsName: 'Writing',
      worksheetsLink: '/writing-worksheets',
    },
    {
      worksheetsName: 'Math',
      worksheetsLink: '/math-worksheets',
    },
    {
      worksheetsName: 'Science',
      worksheetsLink: '/science-worksheets',
    },
  ],
  blogContent: [
    {
      content: [
        'Decades ago sitting for hours in front of a TV or laptop playing games alone or with others would have seemed strange. But when you look around today, it’s never far-fetched.',
        'The development of technology has made so many things possible, and while we enjoy most of them, the video game aspect can drive you crazy as parents.',
        'If your child seems obsessed with playing video games over homework and maybe other areas of his/her life, don’t panic. In fact, the don’ts in this situation include not panicking, not responding with threats, or nagging.',
        'We are about to walk you through why kids get obsessed with video games, why this may be a problem, and how to handle it.',
        'The funny thing is you might start out answering each question with elaborate answers and enthusiasm but as they go on and on with no sign of stopping your response can go from few words to shutting down the line of questioning.',
        'It’s exhausting and also one of the most beautiful things about being a parent and watching your child grow.',
      ],
    },
    {
      heading: 'The why',
      content: [
        'How often have you solved a problem without knowing its source or the reason for it? Knowing why your child is playing video games at an addictive level can help you handle it effectively.',
        'Your child may play video games so much to escape the difficulty of his/her schoolwork. In this situation, your child has likely found himself trying and failing to achieve good grades or understand what is being taught. This feeling of inadequacy can lead them to turn to video games where they feel a sense of achievement.',
        'Problems with social interactions can also give a child anxiety or cause them to withdraw into gaming as an escape.',
        'Not all of these whys are bad. Your child’s gaming obsession could be a passion. A genuine interest in gaming and how it works may indicate that he would like to pursue a career in the industry later in life.',
        'Also, playing video games instead of doing homework could be your typical teenage behavior/rebellion kicking in.',
        'The important thing is not to assume your child’s reason for engaging tirelessly in video games. Try to have a conversation to discuss this or look out for signs and clues to help you.',
      ],
    },
    {
      heading: 'What’s Really Wrong With Playing Video Games',
      content: [
        'Don’t get us wrong, video games are great. When approached correctly, video games offer important benefits for kids such as:',
      ],
      points: [
        'Improving coordination',
        'Enhancing memory',
        'Improving problem-solving skills',
        'Enhancing multi-tasking skills',
        'Improving social skills',
      ],
    },
    {
      content: [
        'However, when it’s overdone, playing games and neglecting important activities like schoolwork can have the following bad effects;',
      ],
      points: [
        'Sleep deprivation in kids which affects learning and attention',
        'Health problems due to lack of exercise',
        'Constant obsessive thinking of playing the game',
        'Social isolation',
        'Developing dishonesty',
        'Depression and irritability',
      ],
    },
    {
      heading: "How to Handle Your Child's Video Game Obsession",
      content: [
        '1. Understand that kids must be kids, and video games are fun',
        'The hard truth. Yes, video games are fun and kids are not the only ones at risk of being addicted to it. But kids do love to play and in a time where outdoor play has almost been replaced by games and technology, you can hardly expect less. Understanding this is the first step to correcting your approach to the situation.',
        '2. Work on the reason',
        'If you’ve identified why your child is playing video games and ignoring homework, then you start there. For a child having difficulty with schoolwork, you can get additional help from a tutor. Watch the results and if your child is doing better with his education to know if you’ve made the right choice of tutors.',
        'For a lack of social interaction, encourage your child to meet and play with other kids. Plan visits to friends or places were other kids his age play.',
        '3. Decide how much time is enough time',
        'Rules, rules, rules! When it comes to kids, it’s important to have ground rules. Set a time in which your child is allowed to play video games. This could be on the weekends or a few hours on certain days. There should be consequences for breaking the rules and rewards for keeping them.',
        'Finally, introduce your child to other fun activities like sports, biking, playing an instrument, or reading. Find a platform where education can be fun and simple for your child.',
      ],
    },
  ],
};

// ! blog posts - What Should I Ask in a Parent-Teacher Conference? ============================
export const ParentTeacherConferenceContent = {
  blogPostClassName: 'blog-post__parent-teacher-conference',
  bannerLineOne: 'What Should I Ask in a',
  // todo: fix margin top
  bannerLineTwo: 'Parent-Teacher Conference?',
  bannerAuthor: 'No Fuss Tutors',
  bannerDate: 'November 19, 2020',
  bannerImageName: 'parent-teacher-conference',
  bannerImage: 'what-should-i-ask-in-a-parent-teacher-conference-banner.png',
  bannerAlt: 'Parent, student and teacher in a conference',
  metaDataPostTitle: 'What Should I Ask in a Parent-Teacher Conference?',
  metaDataPageTitle: 'Parent-Teacher Conference?',
  metaDataDescription:
    'Are you going to a parent teacher conference soon? Read on to learn some tips on building good habits!',
  metaDataImage: 'https://nft-development.netlify.app/assets/webp/logo.png',
  metaDataUrl: 'https://nofusstutors.com/blog-posts/what-should-i-ask-in-a-parent-teacher-conference',
  metaDataKeywords: [
    'online tutoring',
    'parent teacher conference',
    'help on tips to ask at a parent teacher conference',
  ],
  worksheetWidgetKeywords: [],
  sideBarPageLinks: [
    {
      pageName: 'English Tutor',
      pageLink: '/online-english-tutor',
    },
    {
      pageName: 'Reading Tutor',
      pageLink: '/online-reading-tutor',
    },
    {
      pageName: 'Writing Tutor',
      pageLink: '/online-writing-tutor',
    },
    {
      pageName: 'Math Tutor',
      pageLink: '/online-math-tutor',
    },
    {
      pageName: 'Science Tutor',
      pageLink: '/online-science-tutor',
    },
  ],
  sideBarWorksheetsLinks: [
    {
      worksheetsName: 'All Worksheets',
      worksheetsLink: '/worksheets-for-kids',
    },
    {
      worksheetsName: 'Preschool',
      worksheetsLink: '/preschool-worksheets',
    },
    {
      worksheetsName: 'Kindergarten',
      worksheetsLink: '/worksheets-for-kindergarten',
    },
    {
      worksheetsName: 'Grade 1',
      worksheetsLink: '/grade-1-worksheets',
    },
    {
      worksheetsName: 'Grade 2',
      worksheetsLink: '/grade-2-worksheets',
    },
    {
      worksheetsName: 'Grade 3',
      worksheetsLink: '/grade-3-worksheets',
    },
    {
      worksheetsName: 'Reading',
      worksheetsLink: '/reading-worksheets',
    },
    {
      worksheetsName: 'Writing',
      worksheetsLink: '/writing-worksheets',
    },
    {
      worksheetsName: 'Math',
      worksheetsLink: '/math-worksheets',
    },
    {
      worksheetsName: 'Science',
      worksheetsLink: '/science-worksheets',
    },
  ],
  blogContent: [
    {
      content: [
        'Did you know that parent involvement in a child’s education is a strong indicator of academic success?',
        'Sometimes as parents we want to leave all the academic worries to the teachers and schools after all you’re paying for them to do that. Although this is understandable, the truth is your involvement is still vital to your child’s success in school.',
        'On days like a parent-teacher conference, you come to realize this.',
        'For many parents, this parent-teacher conference can catch you by surprise and leave you confused on what questions to ask or how to handle it. We help you head in the right direction in this article where we will discuss what’s involved and what questions to ask.',
      ],
    },
    {
      heading: 'What is a parent-teacher conference?',
      content: [
        'Parent-teacher conferences or parent-teacher interviews are brief meetings between parents and teachers that last between 10-30 minutes and are scheduled a few times a year. Parent-teacher conferences aim to:',
        'The dates are often placed on weekdays, which means parents have to disrupt their schedules to meet up. You can discuss with your child’s school to fix a more convenient time within the week if you are in a tight spot.',
      ],
      points: [
        'Get parents involved in their child’s academics',
        'Share academic progress based on data, assignments, and classroom observation.',
        'Discuss any concerns that may interfere with your child’s education',
      ],
    },
    {
      heading: 'Tips for a satisfactory parent-teacher conference',
      points: [
        'Do some preparation before you go. We have discussed below what’s important to do before the scheduled date and time.',
        'Always ask direct and specific questions. Parent-teacher conferences are often brief, so you don’t want to waste a moment of your time on irrelevant questions or broad questions that won’t maximize your time. Our list of questions will help you out.',
        'Always take notes at the interview to show your seriousness and to ensure you can remember vital points or agreement.',
        'Don’t take a confrontational approach. Your child’s teacher should be your ally in grooming the educational part of your child’s life. Don’t be hostile during the conference or make their opinion seem irrelevant. Stay positive and polite and take the approach of working together towards a common goal.',
      ],
    },
    {
      heading: 'What to do before your parent-teacher conference?',
      content: [
        'Before you jump into what questions should parents ask teachers in a parent-teacher conference, you have to do some groundwork. Here are the steps to help you prepare:',
      ],
    },
    {
      heading: 'Review your child’s school work',
      content: [
        'If you haven’t checked your child’s schoolwork anytime earlier, you will have to dive in now. Check your child’s report cards for recent times, their school work, and assignment, and so on. What you are looking for are issues that stand out or progress/decline in any areas. Did your child get better in one area and decline in another? Does your child seem to have challenges in certain areas? How’s their spelling, grammar, math, organization, grades, and more? Compare with what is expected for a child your child’s age. Write out whatever stands out to you or your observations.',
      ],
    },
    {
      heading: 'Get insight from your child',
      content: [
        'The parent-teacher interview is about your child, so it’s relevant to gain some insight from them. Ask your child how he/she has been doing in school so far; do they like their teacher; are they experiencing difficulties in any area, do they have questions they want you to ask the teacher; what do they think their teacher will say about them?',
        'Take your child’s answers into consideration and add some to your questions.',
      ],
    },
    {
      heading: 'What should I ask at a parent-teacher conference?',
      content: [
        'The right questions help you maximize your time at the parent-teacher interview. Here are some specific questions to ask',
      ],
      points: [
        'What are your concerns about my child’s skill level?',
        'Would you like to share any details about my child’s behavior in school or academic performance?',
        'What are my child’s best and worst subjects?',
        'What can we do about the worst ones?',
        'Is my child good with organizing?',
        'Does my child follow instructions or listen?',
        'What do you think about my child’s level of focus and ability to stay on task?',
        'What can we do at home to help my child’s education?',
        'How are my child’s reading and understanding?',
        'Does my child take part actively in class?',
        'How is my child doing socially?',
        'What is your homework policy, and how is my child doing with homework?',
        'Does my child turn in homework on time?',
        'What type of tests or evaluations will my child take this year?',
      ],
    },
    {
      content: [
        'You can add more questions or less depending on what you think you need to get information about and work with. It’s important to know that sometimes, a parent-teacher conference might have you walking away disappointed or deflated. Not all the answers may be what you want to hear. But disappointment is part of parenting and so is finding the solutions to help your child in areas they find difficult.',
      ],
    },
    {
      heading: 'What to do after a parent-teacher conference?',
      content: [
        'There’s a follow-up part to what questions you should ask at a parent-teacher conference. After noting all your answers, take the next steps below to truly reach the benefits of this meeting.',
      ],
    },
    {
      subHeading: 'Ask the teacher’s opinion',
      content: [
        'For those answers that leave you deflated, ask the teacher for suggestions on what you can do to help. These suggestions should lead to step 2.',
      ],
    },
    {
      subHeading: 'Create an action plan',
      content: [
        'Don’t settle for a vague or generic response that leaves you with no way forward. Fix the same goals for your child with the teacher and agree on an action plan you can complete to help your child from home.',
        'Set a goal that can be achieved, measured by results, and is believable for your child. Don’t set the bar above your child’s capability. Part of your plan should involve monitoring and follow-up dates to measure your child’s progress with the teacher. Since this doesn’t mean waiting for another parent-teacher interview, you can choose a more convenient way to communicate with the teacher. For example, via phone call or email.',
      ],
    },
    {
      subHeading: 'Seek other ways out',
      content: [
        'Part of the teacher’s advice may be supplementary education, or you might just know you need such extra help. Our expert online tutors can help restore confidence and success to your child’s education, so you can reach us for help.',
      ],
    },
  ],
};

// ! blog posts - My Child Can't Focus on School Work ========================================
export const MyChildCantFocusContent = {
  blogPostClassName: 'blog-post__cant-focus',
  bannerLineOne: "My Child Can't Focus",
  // todo: fix margin top
  bannerLineTwo: 'on School Work',
  bannerAuthor: 'No Fuss Tutors',
  bannerDate: 'November 21, 2020',
  bannerImageName: 'cant-focus',
  bannerImage: 'my-child-cant-focus-on-school-work-banner.png',
  bannerAlt: 'elementary school child on phone instead of doing work',
  metaDataPostTitle: "My Child Can't Focus on School Work",
  metaDataPageTitle: "Can't Focus on School Work",
  metaDataDescription: 'Is your child not focusing on school work? Read on to learn some tips on building good habits!',
  metaDataImage: 'https://nft-development.netlify.app/assets/webp/logo.png',
  metaDataUrl: 'https://nofusstutors.com//blog-posts/my-child-cant-focus-on-school-work',
  metaDataKeywords: ['online tutoring', "my child can't focus", 'help on getting your child to focus'],
  worksheetWidgetKeywords: [],
  sideBarPageLinks: [
    {
      pageName: 'English Tutor',
      pageLink: '/online-english-tutor',
    },
    {
      pageName: 'Reading Tutor',
      pageLink: '/online-reading-tutor',
    },
    {
      pageName: 'Writing Tutor',
      pageLink: '/online-writing-tutor',
    },
    {
      pageName: 'Math Tutor',
      pageLink: '/online-math-tutor',
    },
    {
      pageName: 'Science Tutor',
      pageLink: '/online-science-tutor',
    },
  ],
  sideBarWorksheetsLinks: [
    {
      worksheetsName: 'All Worksheets',
      worksheetsLink: '/worksheets-for-kids',
    },
    {
      worksheetsName: 'Preschool',
      worksheetsLink: '/preschool-worksheets',
    },
    {
      worksheetsName: 'Kindergarten',
      worksheetsLink: '/worksheets-for-kindergarten',
    },
    {
      worksheetsName: 'Grade 1',
      worksheetsLink: '/grade-1-worksheets',
    },
    {
      worksheetsName: 'Grade 2',
      worksheetsLink: '/grade-2-worksheets',
    },
    {
      worksheetsName: 'Grade 3',
      worksheetsLink: '/grade-3-worksheets',
    },
    {
      worksheetsName: 'Reading',
      worksheetsLink: '/reading-worksheets',
    },
    {
      worksheetsName: 'Writing',
      worksheetsLink: '/writing-worksheets',
    },
    {
      worksheetsName: 'Math',
      worksheetsLink: '/math-worksheets',
    },
    {
      worksheetsName: 'Science',
      worksheetsLink: '/science-worksheets',
    },
  ],
  blogContent: [
    {
      content: [
        'Your child might have no problem watching their favorite show or playing their addicting games. But when it comes to doing schoolwork, they can’t seem to sit still or get anything done.',
        'For most parents, this can drive you nuts. Some even consider whether their child might have a ‘disability’ like ADHD. It’s important to know that it’s common for kids to have trouble staying focused at school age. Still, that doesn’t mean you just watch them fail at schoolwork.',
      ],
    },
    {
      heading: 'Signs your child is having trouble focusing on schoolwork',
      content: [
        'We know many parents can spot a distracted child from a mile away, but when you need to be sure, the biggest signs will be:',
      ],
      points: ['Low grades on schoolwork', 'Disruptive classroom behavior', 'Dislike for school or classes'],
    },
    {
      heading: 'Reasons why your child can’t concentrate on schoolwork that has nothing to do with ADHD',
      subHeading: 'Lack of sleep or good nutrition',
      content: [
        'It’s recommended that children get 8-10 hours of sleep every night to help with growth and mental development. Proper sleep and nutrition give kids energy to focus and avoids distraction due to hunger or not enough sleep.',
      ],
    },
    {
      subHeading: 'Just coming off the holidays',
      content: [
        'Young children can have difficulty staying focused because the school or schoolwork seems like a new environment for them. They might have just finished a long break or holidays with no academic practice.',
      ],
    },
    {
      subHeading: 'There’s no challenge',
      content: [
        'When schoolwork gets boring and doesn’t challenge young students, they can get tired and lose interest altogether.',
      ],
    },
    {
      subHeading: 'Distractions',
      content: [
        'Being a child can make it hard to focus on when everything can be distracting. Children can get distracted by other kids laughing in the hallway, chatty classmates or siblings, a bird by the window, and so on. They have trouble filtering out these activities and just lose concentration.',
      ],
    },
    {
      subHeading: 'Problems learning',
      content: [
        'When schoolwork gets too tough, confusing, or frustrating you can bet children will lose focus. Your child could have difficulty understanding the material being taught, and this leads to poor grades and disinterest.',
      ],
    },
    {
      heading: 'How to help your child focus on schoolwork',
      subHeading: '1. Use physical activity',
      content: [
        'If you are like most parents, you probably use the promise of playtime to get your child to do things. While playtime can serve as a source of motivation to your child it can have other benefits to improve focus. Physical activity can motivate the brain, get the blood flowing, and help your child focus when it’s time to study.',
        'The activities include anything your child likes to examples such as walking from school, biking, swimming, plying, and sports.',
      ],
    },
    {
      subHeading: '2. Remove the distractions and create order',
      content: [
        'Distractions are kids’ toughest obstacles. To help your child, start by creating a dedicated schoolwork space or study space. This place should be equipped with a desk, chair, books, writing materials, and anything else needed. The study space should be clutter-free. When it is time for schoolwork, remove all the distractions such as toys, computers, phones, television, and so on. Ideally, the study space should be far from these things.',
      ],
    },
    {
      subHeading: '3. Set goals and breakdown tasks',
      content: [
        'Not every child can multi-task and with a lot to do at once, your child might be feeling confused or overwhelmed. Help them focus by setting goals and breaking down tasks into smaller parts. Give your child a clear picture of what needs to be done first, second, and vice versa. This helps them navigate tasks and feel a sense of accomplishment when they have reached goals.',
      ],
    },
    {
      subHeading: '4. Establish consistent routines',
      content: [
        'Structure is important for children, so set a routine or schedule that states study time, playtime, home activities, and so on. Along with your schedule, be sure to add study breaks to give your child time to recharge and rest.',
      ],
    },
    {
      subHeading: '5. Well-rested and well-fed',
      content: [
        'Nutrition is important, and so is proper sleep. Establishing bedtime routines is still good parenting. Ensure your child sleeps enough hours at night and feeds well during the day.',
      ],
    },
    {
      subHeading: '6. Get help',
      content: [
        'When learning seems difficult for your child, you can get help from experienced tutors. The lessons help kids stay active, sharp, and practice schoolwork. This renews their focus, confidence in themselves, and helps students return to school environments without feeling lost.',
        'You can get help from great tutors with us here.',
      ],
    },
  ],
};

// ! blog posts - How to help my child with spelling ====================================
export const HelpWithSpellingContent = {
  blogPostClassName: 'blog-post__help-with-spelling',
  bannerLineOne: 'How To Help My Child',
  // todo: fix margin top
  bannerLineTwo: 'With Spelling?',
  bannerAuthor: 'No Fuss Tutors',
  bannerDate: 'November 26, 2020',
  bannerImageName: 'help-with-spelling',
  bannerImage: 'how-to-help-my-child-with-spelling-banner.png',
  bannerAlt: 'elementary school child looking at letters with tutor',
  metaDataPostTitle: 'How To Help My Child With Spelling?',
  metaDataPageTitle: 'Help With Spelling',
  metaDataDescription:
    'Is your child having difficulty with spelling? Read on to learn some tips on building good habits!',
  metaDataImage: 'https://nft-development.netlify.app/assets/webp/logo.png',
  metaDataUrl: 'https://nofusstutors.com/blog-posts/how-to-help-my-child-with-spelling',
  metaDataKeywords: ['online tutoring', 'difficulty with spelling', 'help your child to spell better'],
  worksheetWidgetKeywords: ['spelling'],
  sideBarPageLinks: [
    {
      pageName: 'English Tutor',
      pageLink: '/online-english-tutor',
    },
    {
      pageName: 'Reading Tutor',
      pageLink: '/online-reading-tutor',
    },
    {
      pageName: 'Writing Tutor',
      pageLink: '/online-writing-tutor',
    },
    {
      pageName: 'Math Tutor',
      pageLink: '/online-math-tutor',
    },
    {
      pageName: 'Science Tutor',
      pageLink: '/online-science-tutor',
    },
  ],
  sideBarWorksheetsLinks: [
    {
      worksheetsName: 'All Worksheets',
      worksheetsLink: '/worksheets-for-kids',
    },
    {
      worksheetsName: 'Preschool',
      worksheetsLink: '/preschool-worksheets',
    },
    {
      worksheetsName: 'Kindergarten',
      worksheetsLink: '/worksheets-for-kindergarten',
    },
    {
      worksheetsName: 'Grade 1',
      worksheetsLink: '/grade-1-worksheets',
    },
    {
      worksheetsName: 'Grade 2',
      worksheetsLink: '/grade-2-worksheets',
    },
    {
      worksheetsName: 'Grade 3',
      worksheetsLink: '/grade-3-worksheets',
    },
    {
      worksheetsName: 'Reading',
      worksheetsLink: '/reading-worksheets',
    },
    {
      worksheetsName: 'Writing',
      worksheetsLink: '/writing-worksheets',
    },
    {
      worksheetsName: 'Math',
      worksheetsLink: '/math-worksheets',
    },
    {
      worksheetsName: 'Science',
      worksheetsLink: '/science-worksheets',
    },
  ],
  blogContent: [
    {
      content: [
        'Spelling matters. This is precisely why you would find that spelling is the most tested literacy skill, but ironically the least taught.',
        'For some children, learning to spell accurately can be frustrating or the biggest task ever. Some kids can’t grasp it at all, while some who are auditory learners tend to spell words the way they sound. Regardless of the nature of your child’s spelling problem, the good news is you can help him spell better with these great tips and ideas.',
      ],
    },
    {
      heading: '1. Learning activities',
      content: [
        'With kids, it’s easy to incorporate fun and learning or just create activities that help them learn. Some activities to try with your child are:',
      ],
    },
    {
      subHeading: 'Finding which is it?',
      content: [
        'In this activity, you write a word list with two words each lying side by side. This way your child sees two versions of one word and has to pick the right one. This is a great learning and fun activity, especially for kids who tend to spell wrongly by sound. So, for example, side-by-side words can be nephew and nefew, fone and phone, etc.',
      ],
    },
    {
      subHeading: 'Scattered word web',
      content: [
        'This is a different form of which is it. Here you scatter the different versions of both correct and incorrect words and tell your child to circle the correct words while striking out the incorrect ones.',
      ],
    },
    {
      subHeading: 'Spelling word maze',
      content: [
        'The spelling word maze can be a box with words that are both wrong and correct. Tell your child to find the path of correctly spelled words and cross out the wrong ones as they go. You can add fun elements to make the path fun.',
      ],
    },
    {
      heading: '2. Encourage your child to master sight words',
      content: [
        'Sight words can be described as the most common words in English language. They are regular words, easy to identify but sometimes have irregular spellings. Encouraging your child to master sight words can help build a critical foundation for your child’s spelling.',
      ],
    },
    {
      heading: '3. Help them learn the different sounds letter combinations make',
      content: [
        'Consonants and vowels are letters with different sounds, just like blends, diphthongs, and digraphs. Examples of blends are pl and sm, diphthongs include au and ai, and diagraphs sh and ng.',
      ],
    },
    {
      heading: '4. Spelling rules matter',
      content: [
        'Spelling rules can be a lot, but getting into them one after the other can help your child master spelling. These include knowing about compound words, prefixes, suffixes, and word plurals.',
      ],
    },
    {
      heading: '5. Spelling phonetically',
      content: [
        'Many beginners like to spell phonetically, but this can lead to lots of spelling mistakes. However, for beginners just learning to spell you can get away with allowing them to spell phonetically. Teach your child to spell words as they sound and focus on teaching the correct sound of each letter.',
      ],
    },
    {
      heading: '6. Practice with rhyming words',
      content: [
        'Giving kids a list of words to learn at home to be tested on the next day in school is not teaching. But most teachers can recognize the effectiveness of rhyming words. It’s fun reading or learning with rhyming words. So use them for your kids. Use stories, songs, and reciting the words to help your child with spelling. Examples are all, ball, fall, call, and so on.',
      ],
    },
    {
      heading: '7. Find the meaning',
      content: [
        'Your child should know the meaning of the words they spell. It’s pointless to just force words on them to master without teaching the meaning to help your child truly learn and understand the words. Knowing what words mean help with recognition and making spelling easier.',
      ],
    },
    {
      heading: '8. Practice',
      content: [
        'Practicing consistently is how anyone masters anything. Since you want to help your child with spelling make sure they practice over and over. They should practice recognizing the words, writing them out, identifying the wrong ones too. Make practicing fun with spelling games.',
      ],
    },
    {
      heading: '9. Get help',
      content: [
        'Sometimes what your child needs is that extra boost from an experienced tutor. Tutors can provide lessons that suit your child’s learning needs and make it both fun and effective.',
        'Need that extra help with your child’s spelling? Contact No Fuss Tutors.',
      ],
    },
  ],
};

// ! blog posts - my kid is afraid to read in class ========================================
export const AfraidToReadContent = {
  blogPostClassName: 'blog-post__afraid-to-read',
  bannerLineOne: 'My Kid is Afraid',
  bannerLineTwo: 'to Read in Class',
  bannerAuthor: 'No Fuss Tutors',
  bannerDate: 'December 1, 2020',
  bannerImageName: 'afraid-to-read',
  bannerImage: 'my-kid-is-afraid-to-read-in-class-banner.png',
  bannerAlt: 'elementary school child reading book',
  metaDataPostTitle: 'My Kid is Afraid to Read in Class',
  metaDataPageTitle: 'Afraid to Read in Class',
  metaDataDescription: 'Is your child afraid to read in class? Read on to learn some tips on building good habits!',
  metaDataImage: 'https://nft-development.netlify.app/assets/webp/logo.png',
  metaDataUrl: 'https://nofusstutors.com/blog-posts/my-kid-is-afraid-to-read-in-class',
  metaDataKeywords: [
    'online tutoring',
    'afraid to read in class',
    'help your child to gain confidence reading in class',
  ],
  worksheetWidgetKeywords: ['reading'],
  sideBarPageLinks: [
    {
      pageName: 'English Tutor',
      pageLink: '/online-english-tutor',
    },
    {
      pageName: 'Reading Tutor',
      pageLink: '/online-reading-tutor',
    },
  ],
  sideBarWorksheetsLinks: [
    {
      worksheetsName: 'Reading',
      worksheetsLink: '/reading-worksheets',
    },
    {
      worksheetsName: 'Reading Comprehension',
      worksheetsLink: '/reading-comprehension-worksheets',
    },
    {
      worksheetsName: 'Vowels',
      worksheetsLink: '/vowels-worksheets',
    },
  ],
  blogContent: [
    {
      content: [
        'Maybe not all of us, but at least some of us have experienced what it’s like to freeze or perform poorly at something you’re good at because of a fear of crowds or performing.',
        'This fright is what some children experience when they are called upon to read in school.',
        'Your child’s teacher might have confirmed that your child is capable of reading, but you both find this child freezes or stumbles on his words when called to read in class. The issue can have everything to do with reading anxiety and just like any other phobia, the fear of reading is real.',
      ],
    },
    {
      heading: 'Signs of reading anxiety',
      content: [
        'Reading anxiety can be officially diagnosed by a psychologist, but more often, the signs are there if you look.',
      ],
      points: [
        'Avoiding reading at home or in school and showing strong dislike for it. Mostly kids with reading anxiety can be vocal with not wanting to read out loud.',
        'Showing restlessness while reading',
        'Making complaints',
        'Refusing to take part or requesting help they clearly don’t need',
        'Stalling or asking to leave during reading',
        'Fidgeting, shaky hands, flushed face, shaky voice, missing words, or sweating',
        'Tantrums when asked to read',
        'Expressing worry by saying things like “Are we going to read out loud today?”',
        'Refusing to try',
        'Giving up and saying “I don’t get it”',
      ],
    },
    {
      heading: 'Effects of reading anxiety on students',
      content: [
        'Most phobias have no serious or direct effects on a person, but reading anxiety does. Since reading anxiety develops as your child starts to relate reading with strong negative emotions like fear and shame, the brain is forced to focus on this emotion when your child is asked to read in class.',
        'This leads to difficulty in processing information and eventually learning is impaired. Reading also affects your child’s overall academic performance and so reading anxiety can make them struggle in other subjects too.',
        'Other effects reading anxiety can have on a child experiencing it include –',
      ],
      points: ['Lack of sleep', 'Restlessness', 'Low self-esteem', 'Fear of being humiliated in public'],
    },
    {
      heading: 'Reasons for reading anxiety',
      content: [
        'Beyond the signs and effects, why does reading anxiety occur for some children? Is it all about the fear of reading in public? Here are some reasons why your child might experience reading anxiety:',
      ],
      points: [
        'Trouble sounding words, especially in situations where other classmates have already mastered this.',
        'Not knowing the meaning of words',
        'Lack of focus',
        'Getting lost in the jumble of words on the page and not being able to find their place',
        'Thinking about past failures of reading',
      ],
    },
    {
      heading: 'What you can do to help',
      content: [
        'Reading anxiety is hardly one of those things children grow out of. It can take specific actions and solutions to help your child overcome reading anxiety. You can help your child with these steps.',
      ],
    },
    {
      subHeading: 'Sit and talk about it',
      content: [
        'You should sit with your child to discuss how they feel about reading. Ask questions like how they feel when asked to read in class, do they like reading, and what’s scary about it?',
        'Hear your child’s answers. This discussion helps you identify where the problem lies and helps your child too to understand their situation better.',
      ],
    },
    {
      subHeading: 'Work with the teachers',
      content: [
        'To solve your child’s reading anxiety, you have to work with their educators. Contact their tutors or school teachers to discuss this problem and how you can both help your child.',
        'Strategies that can be practiced both at home and in school include pre-reading books before asking the child to read in class; one-on-one reading lessons; improving vocabulary for the child to understand what they read.',
      ],
    },
    {
      subHeading: 'Use encouragement always',
      content: [
        'Create reading goals for your child and praise them when they complete these goals. Show your support and use words of encouragement even when they make mistakes.',
      ],
    },
    {
      subHeading: 'Find books your child likes',
      content: [
        'Video games are always an interesting activity, but have you tried finding books your child likes as well? A visit to the library can help you find books on your child’s reading level they would love. It doesn’t have to be a textbook or educational book. Children’s stories or graphic novels can have the best results for a child afraid to read in class.',
        'If you still can’t handle your child’s reading anxiety on your own, reach out to professional educators, private tutors, or even a doctor.',
      ],
    },
  ],
};

// ! my child is falling behind in math ========================================
export const FallingBehindContent = {
  blogPostClassName: 'blog-post__falling-behind',
  bannerLineOne: 'My Kid is Falling Behind in Math.',
  bannerLineTwo: 'What Can I do?',
  bannerAuthor: 'No Fuss Tutors',
  bannerDate: 'November 3, 2020',
  bannerImageName: 'falling-behind',
  bannerImage: 'falling-behind-in-math-banner.png',
  bannerAlt: 'elementary school child doing math problems',
  metaDataPostTitle: 'My Kid is Falling Behind in Math. What Can I Do?',
  metaDataPageTitle: 'Falling Behind in Math',
  metaDataDescription: 'Is your child falling behind in math? Read on to learn some tips on building good habits!',
  metaDataImage: 'https://nft-development.netlify.app/assets/webp/logo.png',
  metaDataUrl: 'https://nofusstutors.com/blog-posts/my-kid-is-falling-behind-in-math',
  metaDataKeywords: ['online tutoring', 'falling behind in math', 'help your child to gain confidence in math'],
  worksheetWidgetKeywords: ['math'],
  sideBarPageLinks: [
    {
      pageName: 'Math Tutor',
      pageLink: '/online-math-tutor',
    },
  ],
  sideBarWorksheetsLinks: [
    {
      worksheetsName: 'Math',
      worksheetsLink: '/math-worksheets',
    },
    {
      worksheetsName: 'Adding',
      worksheetsLink: '/adding-worksheets',
    },
    {
      worksheetsName: 'Counting',
      worksheetsLink: '/counting-worksheets',
    },
    {
      worksheetsName: 'Algebra',
      worksheetsLink: '/algebra-worksheets',
    },
    {
      worksheetsName: 'Division',
      worksheetsLink: '/division-worksheets',
    },
    {
      worksheetsName: 'Fractions',
      worksheetsLink: '/fractions-worksheets',
    },
  ],
  blogContent: [
    {
      content: [
        'Math is like a game: it’s fun when you’re good at it, but you loathe it when you’re not. And for kids, it’s a lot harder to learn about something you truly detest. If the words “My kid is behind in math” have been keeping you up at night, you’ll be glad to know there’s hope.',
        'Investing some extra time can make a huge difference in not just helping your child stay on par with their peers, but also begin to look forward to math class. Here’s how you can help your child catch up:',
      ],
    },
    {
      heading: '1. Find Out Why They’re Falling Behind',
      content: [
        'My child is falling behind in math, but why?',
        'This is the first question you should ask. There is a myriad of reasons why your child isn’t on track in math class. It could have something to do with the classroom environment, the pace of the program, or even their learning style. Or maybe they never mastered a certain fundamental and therefore cannot build on it.',
        'Math is a subject that builds on itself each year. Pre-K and Kindergarten set the basics, so if your child missed something here, then future math lessons won’t make much sense.',
        'The best practice is to go back to the basics. Start with the most simple concepts, then progress until you find the disconnect.',
      ],
    },
    {
      heading: '2. Keep Them Engaged',
      content: [
        'Numbers might not be your child’s thing, but making math fun is the best way to keep every student engaged. Turning math lessons into games, offering incentives, and incorporating other subjects into math can be excellent tools to put math into a different light for them.',
        'Another way to make math more engaging is to use props or items instead of pencil and paper. For example, if your child is learning the concepts of time, give them a clock instead of drawing one on paper. If they’re counting money, use real money. For multiplication, fractions, and divisions, Legos or candy work well.',
      ],
    },
    {
      heading: '3. Play Math Games on the Computer',
      content: [
        'Most parents want their child to have less screen time, but if your child is falling behind in math, a little extra screen time might prove worthwhile.',
        'The reason for this is to see if a different teaching style can help. Some kids might learn better by playing math games, while others are fine doing workbook exercises. Some math games can also give your child real-time feedback when they click on a wrong answer and provide visuals that put problems into perspective.',
      ],
    },
    {
      heading: '4. Work with a Math Tutor',
      content: [
        'Teaching your own child math can be a struggle, especially if you’re not a fan of the subject. Instead, consider the advantages of working with a tutor who is experienced in helping kids who have fallen behind in math.',
        'At No Fuss Tutors, we consistently see our students go from hating math to smiling when they do their homework. Our goal is to identify what they don’t know and what’s preventing them from moving forward, then working on their level to help them gain skills and confidence. Contact us today to learn how we can help your child get back on track with math!',
      ],
    },
  ],
};

// ! distributive property of multiplication blog post ===================================
export const DistributivePropertyContent = {
  blogPostClassName: 'blog-post__distributive-property',
  bannerLineOne: 'Distributive Property',
  bannerLineTwo: 'Of Multiplication',
  bannerAuthor: 'No Fuss Tutors',
  bannerDate: 'Feb 13, 2021',
  // todo: change to distributive property images
  bannerImageName: 'distributive-property',
  bannerImage: 'distributiveProperty.jpg',
  bannerAlt: 'elementary school child doing math problems',
  metaDataPostTitle: 'Distributive Property - Detailed Explanation with Examples',
  metaDataPageTitle: 'Distributive Property',
  metaDataDescription:
    'Is your child having trouble with the distributive property of multiplication? Find out how it works and read about the steps below.',
  metaDataImage: 'https://nft-development.netlify.app/assets/webp/logo.png',
  metaDataUrl: 'https://nofusstutors.com/blog-posts/distributive-property-of-multiplication',
  metaDataKeywords: [
    'online tutoring',
    'distributive property of multiplication',
    'help with distributive property',
    'help with multiplication',
  ],
  worksheetWidgetKeywords: ['multiplication'],
  sideBarPageLinks: [
    {
      pageName: 'Math',
      pageLink: '/online-math-tutor',
    },
  ],
  sideBarWorksheetsLinks: [
    {
      worksheetsName: 'Math',
      worksheetsLink: '/math-worksheets',
    },
    {
      worksheetsName: 'Adding',
      worksheetsLink: '/adding-worksheets',
    },
    {
      worksheetsName: 'Counting',
      worksheetsLink: '/counting-worksheets',
    },
    {
      worksheetsName: 'Algebra',
      worksheetsLink: '/algebra-worksheets',
    },
    {
      worksheetsName: 'Division',
      worksheetsLink: '/division-worksheets',
    },
    {
      worksheetsName: 'Fractions',
      worksheetsLink: '/fractions-worksheets',
    },
  ],
  blogContent: [
    {
      content: [
        'To “distribute” means to divide one whole amongst many parts. The distributive property of multiplication is based on this concept and states that,',
      ],
      italicContent: [
        '"Multiplying a number to a sum of two or more addends yields the same result as obtained by multiplying the number to each addend individually and then adding the products."',
      ],
    },
    {
      content: [
        'Let us consider an example to understand what this means. We have to carry out multiplication for the following expression:',
      ],
      boldContent: ['4(2 + 3)'],
    },
    {
      content: [
        'The ‘order of operations’ rule of mathematics tempts us to solve this expression using the following steps:',
      ],
      // todo: image should be inserted here
    },
    {
      boldContent: ['Step 1: Perform addition inside the parenthesis to get 4 x 5'],
    },
    {
      boldContent: ['Step 2: Perform multiplication to get the result = 20'],
    },
    {
      content: [
        'The distributive property changes the approach. We first distribute the number being multiplied, over the addends. The new steps followed are:',
      ],
    },
    {
      boldContent: ['Step 1: Split the parenthesis to distribute 4. We get (4 x 2) + (4 x 3)'],
    },
    {
      boldContent: ['Step 2: Perform multiplication inside the parenthesis to get 8 + 12'],
    },
    {
      boldContent: ['Step 3: Add the products to get the result = 20'],
    },
    {
      subHeading:
        'We notice that the result is the same in both cases and the distributive property indeed holds true. Visually, we can represent this as:',
    },
    {
      graphic: {
        graphicSrc: 'distributive-property-1.png',
        graphicPrefix: 'blog',
        graphicAlt: 'Demonstrating the distributive property of multiplication',
      },
    },
    {
      content: [
        'It is interesting to note that the distributive property of multiplication is valid not just for addition, but for subtraction as well. For example, let’s say we have to solve the expression:',
        // todo: image should be inserted here
      ],
      boldContent: ['3(5 - 2)'],
    },
    {
      content: ['We can use the normal approach to calculate it as:'],
      boldContent: ['3(5 - 2) = 3 x 3 = 9'],
    },
    {
      content: ['Using distributive property, we can calculate it as:'],
      boldContent: ['3(5 - 2) = (3 x 5) – (3 x 2) = 15 – 6 = 9'],
    },
    {
      content: [
        'Once again, we can see that the result is the same in both cases and the distributive property holds as expected. In fact, the property is valid even when we have a combination of addition and subtraction inside the parenthesis, as shown below.',
        // todo: image should be inserted here
      ],
    },
    {
      content: [
        'When dealing with a combination of addition and subtraction inside the parenthesis, we must be extra careful with the + and - signs. At this point, we can modify the statement of the distributive property of multiplication to now state that,',
      ],
      italicContent: [
        '“Multiplying a number to a sum or difference of two or more numbers (called operands) yields the same result as obtained by multiplying the number to each operand individually and then adding or subtracting the products (depending on the operation).”',
      ],
    },
    {
      heading: 'Why do we use the distributive property of multiplication?',
      content: [
        'From the examples above, it seems as if using the distributive property makes multiplication lengthy because we are using three steps to do what could have been in two steps. However, using the distributive property actually makes calculations easier in some cases. For example, let us consider the expression:',
      ],
      boldContent: ['6(57)'],
    },
    {
      content: [
        'Most children would find this multiplication complicated, but if we use the distributive property of multiplication over addition, we can simplify this expression as:',
      ],
      boldContent: ['6(50 + 7) = 300 + 42 ', '= 342'],
    },
    {
      content: [
        'This expression is easier to solve because now we are multiplying 6 with a multiple of 10 and with a single-digit number. There is still some scope to simply the multiplication further. If we use the distributive property of multiplication over subtraction instead of addition, we will get:',
      ],
      boldContent: ['6(60 - 3) = 360 – 18', '= 342'],
    },
    {
      // todo: the words as small as possible should be in italics
      content: [
        'Clearly, the aim is to carry out multiplication with multiples of 10 and with numbers as small as possible.This is obviously simpler than multiplying with a complicated number having two or more digits. Let us now consider an example with a three-digit number inside the parenthesis:',
      ],
      boldContent: ['3(597) = 3(500 + 90 + 7)', '= 1500 + 270 + 21', '= 1791'],
    },
    {
      content: ['In this example, it would be better to use subtraction. Can you guess why?'],
      boldContent: ['3(597) = 3(600 - 3)', '= 1800 – 9', '= 1791'],
    },
    {
      content: [
        'By using subtraction, we reduce the number of times we have to multiply – we are doing it twice instead of thrice. Also, the number 3 is smaller than 9 and 7, which makes calculations fast.',
        'In both the above examples, since the three-digit number is a multiple of hundred, we are essentially multiplying 3 with a one-digit number only – either 5 or 6 and simply appending two zeroes in the end. We have effectively reduced a three-digit multiplication with 397 into simple one-digit multiplication.',
        'However, using direct subtraction always is also not a good idea. If we were to evaluate an expression such as 5(287) it would be better to use a mixture of addition and subtraction. Here’s why:',
      ],
    },
    {
      points: [
        'Direct subtraction would give us 5(300 – 13) where 13 is a two-digit number.',
        'So, we go one step ahead and split 13.',
        'Wecaneitherwrite13as10+3or20–7.Theformerisabetteroptionbecause3<7.',
      ],
    },
    {
      content: ['Therefore,'],
      boldContent: ['5(286) = 5(300 - (10 + 3))', '= 5(300 – 10 – 3)', '= 1500 – 50 -15', '= 1435'],
    },
    {
      content: [
        'At this point you’re probably wondering – are there any rules to determine which approach to follow in which case? The answer is, no. Figuring out if and how you need to split the term in the parenthesis is something that one learns only with practice. Personal preferences matter too – some children might find two-digit multiplication easier and more fun than three-digit addition or subtraction.',
        'As a parent or teacher, you must teach the concept of distributivity to children but don’t pressure them to always use it. Give them the freedom to choose the approach that they feel most comfortable with.',
      ],
    },
    {
      heading: 'How does distributivity work with division?',
      content: [
        'Given that division is the inverse of multiplication, we cannot expect distributivity to work exactly the same in both. In multiplication, we have the freedom to split either of the two numbers being multiplied. For example, in 124 x 35, (100 + 20 + 4) x 35 as well as 124 x (30 + 5) will give the same answer when we distribute.',
        'However, if we were to evaluate 124 ÷ 35, then (100 + 20 + 4) ÷ 35 will not give the same answer as 124 ÷ (30 + 5), as demonstrated below.',
      ],
      // todo: image should be here
    },
    {
      heading: 'Distributivity as a building block for variables and unknowns',
      content: [
        'In addition to simplifying calculations, one big reason behind teaching the distributive property to children is that it serves as a preparation for dealing with variables like ‘x’ and ‘y’. When we have unlike terms inside the parenthesis, we often use distributivity to solve equations. For example, if we have to solve the equation 5(3 + x) = 17, we distribute 5 over the terms inside the parenthesis to get:',
      ],
    },
    {
      boldContent: ['5(3 + x) = 17', 'or 15 + 5x = 17', 'or 5x = 2', 'or x = 5/2'],
    },
    {
      heading: 'How can you get children to practice the distributive property?',
      content: [
        'Now that you’re equipped with examples, tips, and tricks for distributivity, it’s time to help children apply the concept. The next time you go to the grocery store, ask your child to total the bill for you before you get to the billing counter. Teachers can share math worksheets with their students, based on the distributive property.',
        'Give opportunities to children to apply the concept wherever possible!',
      ],
    },
  ],
};

// ! multiplication chart blog post ===================================
export const MultiplicationChart = {
  blogPostClassName: 'blog-post__multiplication-chart',
  bannerLineOne: 'Multiplication',
  bannerLineTwo: 'Chart',
  bannerAuthor: 'No Fuss Tutors',
  bannerDate: 'February 20, 2021',
  bannerImageName: 'multiplication-chart',
  bannerImage: 'multiplicationChart2.jpg',
  bannerAlt: 'elementary school child doing math problems',
  metaDataPostTitle: 'Multiplication Chart: More Room for Pictures and Worksheet Exercise',
  metaDataPageTitle: 'Multiplication Chart - Free Download & Printable',
  metaDataDescription:
    'Is your child having trouble with their multiplication tables? Find out how to help them learn them effectively.',
  metaDataImage: 'https://nft-development.netlify.app/assets/webp/logo.png',
  metaDataUrl: 'https://nofusstutors.com/blog-posts/multiplication-chart',
  metaDataKeywords: [
    'online tutoring',
    'multiplication chart',
    'help with learning multiplication chart',
    'help with multiplication',
  ],
  worksheetWidgetKeywords: ['multiplication'],
  sideBarPageLinks: [
    {
      pageName: 'Math',
      pageLink: '/online-math-tutor',
    },
  ],
  sideBarWorksheetsLinks: [
    {
      worksheetsName: 'Math',
      worksheetsLink: '/math-worksheets',
    },
    {
      worksheetsName: 'Adding',
      worksheetsLink: '/adding-worksheets',
    },
    {
      worksheetsName: 'Counting',
      worksheetsLink: '/counting-worksheets',
    },
    {
      worksheetsName: 'Algebra',
      worksheetsLink: '/algebra-worksheets',
    },
    {
      worksheetsName: 'Division',
      worksheetsLink: '/division-worksheets',
    },
    {
      worksheetsName: 'Fractions',
      worksheetsLink: '/fractions-worksheets',
    },
  ],
  blogContent: [
    {
      graphic: {
        graphicSrc: 'multiplicationChart.png',
        graphicPrefix: 'blog',
        graphicAlt: 'Multiplication Chart',
      },
    },
    {
      downloadDocumentName: 'MultiplicationChartPrintable.pdf',
    },
    {
      content: [
        'Helping children master multiplication charts can be a daunting task for parents, teachers and even the children. The thought that they have to memorize the entire table to succeed in math can be scary for kids, especially those struggling with number work.',
        'Whether you are a parent trying to help your child study at home, or a student looking for ways to master your multiplication table, starting from the basics and following through step by step is the key to a good mathematical foundation.',
        'What are these step by step strategies and are they the best ways to learn multiplication charts?',
        `Well, let's dig in to find out.`,
        'This article explains how to learn multiplication using pictures and charts. It further provides steps to consider when learning it.',
      ],
    },
    {
      heading: 'What is multiplication?',
      content: [
        'Multiplication is calculating the total of a number multiplied by another.',
        'It is also considered a repeated addition because it shares the same concepts in arriving at an answer.',
        'Multiplication is also a way of adding equal groups of numbers.',
        'Because every school child at some point must learn and perfect the multiplication chart, they must know the steps to follow.',
      ],
    },

    {
      subHeading: 'Basic Steps To Start With',
      content: [
        'When working on perfecting the multiplication tables, consider knowing the fundamentals first.',
        'This makes it easy to manipulate bigger numbers and memorize the table.',
        `Rote learning isn't the best way to make it stick.`,
        `What's the best way? Consider these 6 step by step tips.`,
      ],
    },
    {
      subHeading: '1. Perfect your skip counting:',
      content: [
        'By first and second grades pupils are introduced to skip counting. Being able to perfect skip counting from 2 through to 10, sets a solid ground for multiplication charts. They are both linked.',
        'Skip counting is counting forward. If you observe you will see that it sets the pattern for the multiplication table.',
        `When skip counting you just keep adding the original number to a new product it gives you. For example, skip counting in 3's. You start with the number 3 and add another 3 to it, which gives you 6, you continue adding 3 until you get the final product you want.`,
      ],
    },
    {
      boldContent: ['Procedure', '3, 6, 9, 12, 15……'],
    },
    {
      content: [
        `When you skip count in 2's note that they are all even numbers. The odd numbers are left out.`,
        'Example, 2 -- 4-- 6-- 8--10…….',
        'Skip counting helps you easily find the answer to a multiplication problem and master the time table.',
        `For instance, skip counting in 4's leads you to the answer for the 4-times table.`,
      ],
    },
    {
      boldContent: [
        'Procedure',
        `4, 8, 12, 16, 20 is number  4 repeated 5 times which is the same as 4×5= 20, that's`,
        '4×1= 4',
        '4×2= 8',
        '4×3= 12',
        '4×4= 16',
        '4×5= 20',
      ],
    },
    {
      content: [
        'There is no way a child will perfect this step in second grade and not find the multiplication table easy.',
      ],
    },
    {
      subHeading: '2. Use Repeated Addition',
    },
    {
      content: [
        'Repeated addition means adding up equal groups of numbers again and again. It sets the basis for multiplication.',
        'For example, a question that says: 6 groups of 2, or 6 lots of 2, is the same as 2 in 6 places. That is 2 added to itself 6 times.',
      ],
    },
    {
      boldContent: ['Procedure', '2 + 2 + 2 + 2 + 2 + 2=12', 'is the same as 2×6 = 12.'],
    },
    {
      content: [
        'Learning this step is a fast way of knowing the concept of the multiplication table.',
        `Note that using repeated addition means that the number of times you add an equal group of numbers is the same as the number you added multiplied by how many times you've added it.`,
      ],
    },
    {
      subHeading: '3. Learn the smaller numbers first:',
    },
    {
      content: [
        'Learning starts from simple to complex.',
        `Whether you're a teacher teaching a pupil, a parent helping your child study at home, or a student learning multiplication table on your own, ensure the smaller numbers are perfected first.`,
        `Don't just go learning the 5 or 10 times table because you know they are the easiest. It's best to start from as small as a 1-time table, master it before moving to the next.`,
        'Knowing the rules that, 1× any number will result in the same number is very vital. E.g 1×1=1, 1×2=2…..',
      ],
    },
    { subHeading: '4. Reinforce the commutative property:' },
    {
      content: [
        'In cumulative property, all you need to do is turn the given numbers to reverse then multiple and you still get the same end product.',
      ],
    },
    {
      boldContent: ['For example:', '2×3=6 and 3×2=6', '3×4=12 and 4×3=12.'],
    },
    {
      content: ['Learning this step will help master all of such numbers in the table.'],
    },
    {
      subHeading: '5. Learn these hidden tips:',
    },
    {
      content: [
        'The eleven times table is just a repeat of the number you are multiplying with that serves as the product.',
        'For example, if you multiply 11×2, just write the 2 again and add another two, it gives you the product.',
      ],
    },
    {
      boldContent: ['So,', '11×1=11', '11×2= 22', '11×3= 33…..'],
    },
    {
      content: [
        `It's the same with the 10 times table, just add 0 to the number you are multiplying the 10 with and you get the answer you need.`,
      ],
    },
    {
      boldContent: [`That's,`, '10×1= 10', '10×2= 20', '10×3= 30…'],
    },
    {
      subHeading: '6. Learn Using Visuals',

      content: [
        'Everyone learns differently.',
        'Some children will need the aid of visuals to enable them to recall what has been learnt. Including pictures, worksheets and manipulative can serve as a big help.',
        'Use pictures and concrete examples to elaborate when teaching.',
      ],
    },
    {
      heading: 'How To Use Visuals?',
      subHeading: 'Make Button Arrays',
      content: [
        'An array is arranging sets of objects into columns and rows.',
        'Objects in columns are arranged from top to bottom while objects in rows, from left to right.',
        'You can get different colored buttons, arrange them according to the times table number you are struggling with, then stick them on cardboard.',
        `When 5×4 is arranged, it will appear as 4 buttons on a row and 5 buttons on a column. Count all buttons together and you get 20 in total. That's 5×4=20`,
      ],
    },
    {
      subHeading: 'Paper Wheel Chart',
      content: [
        'Many kids struggle with memorizing the 7 and 9 times tables. To make it easy, create a paper wheel chart of each multiplication number a kid is struggling with.',
      ],
    },
    {
      subHeading: 'Procedure',
      content: [
        `First, divide the wheel into 3 circles. Let the digit number you're multiplying with be in the middle. The numbers you are multiplying stays in the second part of the circle. And the product you get, on the third part of the circle.`,
        'You can draw a line across each number set to make your work appear neat.',
      ],
    },
    {
      subHeading: 'Create Your Chart',
      content: [
        'Doing it yourself is the best way to learn. Children need to make their multiplication chart.',
        `It's a task a learner should be taught to commit to.`,
        'Decide how many days your child needs to complete their table. Do the rough work and use repeated addition to draw up the chart.',
        'For example, 5+5=10 is 5×2=10',
        'When this rough addition is completed, pupils can start transferring the results to a template to complete their multiplication chart.',
        `With this, it's easy to memorize the chart. Because it's much easier to recall what you did.`,
      ],
    },
    {
      subHeading: 'Practice with worksheets',
      content: [
        `A worksheet is a helpful resource for kids' practice. They are fully engaging.`,
        'Practising multiplication with a worksheet gives pupils the chance to problem-solve and think up answers.',
        'For kids, worksheets are more fun to practice with than regular notebooks.',
        'Teachers or parents can provide the next step of the multiplication worksheet once pupils perfect a table.',
        'You should provide children with access to practice exercise sheets with colourful details that appeal to the senses. Like using worksheets with animated character templates.',
        'Have them colour every correct answer they provide.',
      ],
    },
    {
      heading: 'Displaying Pictures and Charts As A Guide',
      content: [
        `After all the pictures, charts and worksheets that children are exposed to while learning multiplication, it's most important that these visuals are displayed as a reminder and guide for them; in the classroom or at home, in study spots or their bedroom.`,
        'The overall message is that seeing these visuals every day makes it easy to memorize the time table. Because they become familiar with it.',
      ],
    },
    {
      heading: 'Conclusion',
      content: [
        'Multiplication is one of the basic parts of mathematics that is needed in everyday life.',
        'It also sets the foundation for further higher math concepts like fractions, among others.',
        'Following the above steps is an effective way of learning the multiplication chart.',
        'Furthermore, when dealing with math, kids have to practice daily. The concept is the same as memorizing the chart.',
        'Dedicating at least 10 to 15 minutes every day (maybe before bed) to reciting the table makes it stick to your memory.',
      ],
    },
  ],
};

// ! what are blends blog post ===================================
export const whatAreBlendsPost = {
  blogPostClassName: 'blog-post__multiplication-chart',
  bannerLineOne: 'What Are Blends',
  bannerLineTwo: '',
  bannerAuthor: 'No Fuss Tutors',
  bannerDate: 'February 25, 2021',
  bannerImageName: 'multiplication-chart',
  bannerImage: 'what-are-blends.jpg',
  bannerAlt: 'pre kindergarten child and tutor looking and pointing at notebook',
  metaDataPostTitle: 'What Are Blends?',
  metaDataDescription:
    'Is your child having trouble understanding blends? Find out how to help them learn them effectively.',
  metaDataPageTitle: 'What are blends?',
  metaDataImage: 'https://nft-development.netlify.app/assets/webp/logo.png',
  metaDataUrl: 'https://nofusstutors.com/blog-posts/what-are-blends',
  metaDataKeywords: ['online tutoring', 'blends', 'what are blends'],
  worksheetWidgetKeywords: ['phonics', 'blends'],
  sideBarPageLinks: [
    {
      pageName: 'English',
      pageLink: '/online-english-tutor',
    },
    {
      pageName: 'Reading',
      pageLink: '/online-reading-tutor',
    },
    {
      pageName: 'Writing',
      pageLink: '/online-writing-tutor',
    },
  ],
  sideBarWorksheetsLinks: [
    {
      worksheetsName: 'Reading',
      worksheetsLink: '/reading-worksheets',
    },
    {
      worksheetsName: 'Reading Comp',
      worksheetsLink: '/reading-comprehension-worksheets',
    },
    {
      worksheetsName: 'Vowels',
      worksheetsLink: '/vowels-worksheets',
    },
    {
      worksheetsName: 'Alphabets',
      worksheetsLink: '/alphabet-worksheets',
    },
  ],
  blogContent: [
    {
      content: [
        `Phonic blends or clusters are groups of consonants whose sounds merge together. Consonant blends are so called because they do not have any vowels between them. Also, each letter within the blend
        is pronounced distinctively but quickly so that they truly ‘blend’ together. As children gain more confidence with consonants, the obvious next step is to begin blending them to form words. The knowledge of this topic is hence crucial.`,
        'Blends usually consist of two or three consonants and can begin or end a syllable. Consonant blends that appear at the beginning of a word, are referred to as initial consonant blends or beginning blends. Those that appear at the end of a word are referred to as final consonant blends or end blends. For example, best, ask, desk, and fast.',
        'Then there are word blends - a word blend is formed by combining two separate words with different meanings to form a new one. These words are often created to describe a new phenomenon or something that pops up on a casual basis that requires two words to fully describe it. It combines the definitions or traits of two existing things. For example, camcorder and brunch.',
      ],
    },
    {
      heading: 'What Are The Types of Blends?',
      subHeading:
        '1. Consonant Blends: These blends are made up of more than one consonant containing no vowel between them. There are two ways in which consonant blends can be further classified, depending on the placement of the consonants:',
      points: [
        'Initial Consonant Blends or Beginning Blends: These refer to the consonant blends that appear at the beginning of a word, such as bl-, br-, cl-, cr-, fl-, etc.',
        'Final Consonant Blends or End Blends: These refer to consonant blends that show up at the end of a word, such as -ct, -ft, -lb, -lt, -mp, etc.',
      ],
    },
    {
      content: ['Another way of classification for consonant blends is by the number of alphabets blended together:'],
      points: [
        'Two Consonant Blends: Examples include blue, black, clay, cloud, climb, etc.',
        'Three Consonant Blends: Examples include spring, spray, scrub, screen, stretch, stripe, etc.',
      ],
    },
    {
      subHeading:
        '2. Silent Blends: These include blends such as wr- (as in write), -tle (as in castle), and gh- (as in ghost). The placement or number of alphabets do not matter in this case.',
    },
    {
      subHeading: `3. Word Blends: There are several different ways of creating word blends. One way is to combine
      portions of two other words to make an entirely new word. These word fragments are called morphemes, the tiniest units that provide meaning in a language. The word "camcorder," for example, combines parts of "camera" and "recorder." Word blends can also be created by joining one complete word with a portion of another word (called a splinter). For example, the word "brunch" combines a portion of "breakfast" along with a portion of “lunch".`,
      content: [
        'Word blends may also be formed by overlapping or combining phonemes, which are parts of two words that sound alike. One example of an overlapping word blend is "Hinglish," which is an informal mix of spoken English and Hindi. Blends can also be constructed through skipping of the phonemes. People sometimes refer to the landmass that combines Europe and Asia as "Eurasia". This blend is formed by taking the first syllable of "Europe" and adding it to the other word "Asia.”',
      ],
    },
    {
      heading: 'Problems Usually Faced by Children',
      content: [
        'Blends can be tough for children for various reasons. For starters, many consonants’ sounds when pronounced individually end with a vowel (for example, “c” is spoken as /cuh/). As a result, the sounding out of a word like “cat” comes out as /cuh/ + /aah/ + /tuh/. When these are blended together, the result is far different from the word that the child would expect. So, the “cuh-aah-tuh” is reduced to “cat.” This is a fairly complex process that many children can find overwhelming.',
        'One of the best ways in which parents can help children get over this difficulty is by changing the perspective about blends. Try including the vowel sound that follows the blend and then let the child add a separate sound that completes the word. For example, with a word like ‘break’, the parent can say “bray” and then ask the child to complete the word (so that he/she ends up saying ‘bray-kuh’).',
      ],
    },
    {
      heading: 'How To Teach Blends to Children',
      content: [
        'Consonant Blends are one of the most important concepts that we should teach our young readers. This is a skill they will get to use in both single- and multi-syllabic words, and even in reading and writing various forms of syllables across multiple languages that share the same rules.',
        'One of our early teaching decisions needs to weigh knowledge of the children and their needs at that time, and create a pace of teaching that meets the needs of each learner. There are a few general guidelines and consonant blends activities which are key to making blends easier to understand. Some of the ways are given below:',
      ],
    },
    {
      content: [
        '1. Use motions to provide students with a sensory way of grasping concepts: For example, begin a game asking them to put their hands up. Then, explain that a blend consists of two letters who come together (hands pressed together) but each makes its own sound. Gestures are a particularly powerful tool for triggering memory and promoting retention.',
        '2. Move from simpler or shorter words to more complex ones: Introduce words with initial blends only of two or three sounds. When children are ready, introduce more word/consonant blends with initial and final blends both, and at least three-letter blends at the beginning. Eventually, children should be able to read and write syllables of five and six sounds.',
      ],
    },
    {
      heading: 'Making Blends Easy To Comprehend',
      content: [
        'As children learn to read, write, and communicate, they are taught individual sounds and how to link them together to form words. They usually begin by learning all about alphabets. Once they have learned how to identify the unique sound made by each letter individually as well as when letters are joined together, children are then ready to experiment with joining the consonants to discover what new sound they will get this time.',
        'Along with letter sounds, children are also introduced to the consonant-vowel-consonant (CVC) type of words, where there is a vowel sandwiched between two consonants. For example, words such as: cat, bed, pin, rug, and mat. The reason these words are taught early is that the child can sound these out while reading. Early practice enables kids to spell these words more easily and helps with writing too.',
        'After these words are well-practiced and children begin to show interest in learning different letter sounds, it is the perfect moment to introduce blends. A good approach is to encourage children to actively speak out and learn simple words that showcase the use of consonant blends.',
        'As they grow older and reading begins to take up a major portion of their day, they start to identify words that are a blend of two different words. These words will catch the attention of the child because each blended word holds a characteristic sound and spelling that’s obtained by merging two different words. The meaning of the blended word is also derived by merging the meanings of the two words in it. Asking your child to guess these meanings will build connections in their brains and help them comprehend blends faster.',
        'So, when your child asks you “What are blends?”, you’ll be fully prepared to answer and explain!',
      ],
    },
  ],
};

// ! sight words done right blog post ===================================
export const sightWordsDoneRightPost = {
  blogPostClassName: 'blog-post__sight-words',
  bannerLineOne: 'Sight Words',
  bannerLineTwo: 'Done Right',
  bannerAuthor: 'No Fuss Tutors',
  bannerDate: 'March 1, 2021',
  bannerImageName: 'multiplication-chart',
  bannerImage: 'sight-words.jpg',
  bannerAlt: 'middle school age student and tutor looking over words in a book',
  metaDataPostTitle: 'Sight Words Done Right',
  metaDataDescription:
    'Is your child having trouble understanding sight words? Find out how to help them learn them effectively.',
  metaDataPageTitle: 'Sight words done right',
  metaDataImage: 'https://nft-development.netlify.app/assets/webp/logo.png',
  metaDataUrl: 'https://nofusstutors.com/blog-posts/sight-words-done-right',
  metaDataKeywords: ['online tutoring', 'sight words', 'sight words done right'],
  worksheetWidgetKeywords: ['sight words'],
  sideBarPageLinks: [
    {
      pageName: 'English',
      pageLink: '/online-english-tutor',
    },
    {
      pageName: 'Reading',
      pageLink: '/online-reading-tutor',
    },
    {
      pageName: 'Writing',
      pageLink: '/online-writing-tutor',
    },
  ],
  sideBarWorksheetsLinks: [
    {
      worksheetsName: 'Reading',
      worksheetsLink: '/reading-worksheets',
    },
    {
      worksheetsName: 'Reading Comp',
      worksheetsLink: '/reading-comprehension-worksheets',
    },
    {
      worksheetsName: 'Vowels',
      worksheetsLink: '/vowels-worksheets',
    },
    {
      worksheetsName: 'Alphabets',
      worksheetsLink: '/alphabet-worksheets',
    },
  ],
  blogContent: [
    {
      content: [
        'Once children have learned all their alphabets, colors, names of various household items, and their favorite cereal type, it’s about time that they learn how to form simple sentences and convey their thoughts meaningfully.',
        'You might ask yourself, what is it that distinguishes a meaningful sentence from a mere cluster of words that somehow communicate what needs to be said? The answer - a beautifully structured sentence that makes complete sense and features a proper arrangement of verbs, adjectives, nouns, and pronouns, along with accurate grammar.',
        'However, a child’s journey to perfection and accuracy does not begin with such ideals but actually with a very crude understanding of the language. One might expect that the first step would be to begin with joining alphabets and learning phonetics. To many parents’ surprise, the first step is learning how to ‘sight’ words. Before you teach alphabets and how to make words using them, it’s more important to help the child understand – what is the purpose of alphabets? What are words? What do reading and writing even mean? What are some examples of words?',
        'Sight words activities answer precisely these questions. They also teach the child some commonly used words in the English language and how to use them in sentences. These activities are also great for making the child understand the meaning of words like ‘a’, ‘the’, ‘of’, ‘this’, ‘that’, etc. Since these words don’t have a defined dictionary meaning, using them in sentences is the best way to infer their meanings.',
        'Another problem that is often associated with teaching these words to young children is that they do not have a concrete image that accompanies them. How does one draw ‘a’, ‘an’, ‘of’, and ‘the’? We are not able to picture them, and as such, the method that must be followed for these words is that these should be memorized and understood.',
        'Given how frequently these words are encountered, children need to be able to read, recognize, and understand them on the dot, upon seeing them. What this does is that by easily recognizing these words, kids are more likely to approach their text with a lot more confidence. They are eventually able to read with more fluency, better comprehend the meaning of the text, and make it less likely for them to get tripped over by such words in their reading.',
      ],
    },
    {
      heading: 'Why Kids Should Befriend Sight Words At An Early Age',
      content: [
        'The reason sight words should be taught early, as and when the child is ready for this next part in grammar, is because they appear so frequently and make up a significant amount of all text. This is why educators recommend that children should be able to immediately spot these words so that their time and efforts can be spent decoding other, tougher words in that text.',
        'If students face difficulty with sight words, it is more than likely that they will be frustrated and less interested in decoding other words in the text, like those that follow a certain rule or have complex spellings. Having a stockpile of sight words ready, helps children read better and become more confident in the language so that it can be readily deployed in the service of reading.',
        'There happens to be a handful (about 100) of words that make up more than fifty-percent of text that young children begin to read. These are words like ‘a’, ‘I’, ‘or’, ‘and’, ‘the’, ‘but’, ‘then’ and so on. These are usually small and easily spotted, but the spelling of these words is usually not straightforward in regards to how they sound.',
        ' When children enter kindergarten, they are most often introduced to a sight word list and are asked to memorize the words shown so that they can spot them when they see one. The number of words can alter depending on the syllabus and the learning pace of the child.',
      ],
    },
    {
      heading: 'Characteristics of Sight Words',
      content: ['So far, to summarise sight words, these are words that:'],
    },
    {
      content: [
        '1. Pop-up frequently in a text.',
        '2. Are not accompanied by pictures to give them more meaning, for example: ‘the’, ‘or’, ‘and’, ‘so’, ‘a’, ‘but’ etc.',
        '3. Often do not follow the typical rules of spelling, making them stand out from other words because these are not phonetically regular, meaning that their sounds do not parallel their spellings.',
        '4. Are helping words in the language and are mostly adjectives, adverbs, pronouns, prepositions,conjunctions, and some common verbs. They give direction and cohesiveness to the language.',
        'The reason these are called Sight Words is that these words appear so frequently that early readers reach a point where they no longer need to sound out these words. They recognize them by sight. Mastering a sight word means children skip having to blend its letter-sounds together, or think about spelling rules.Building up a huge stock of sight words helps them become eloquent readers.',
      ],
    },
    {
      heading: 'Types of Sight Words',
      content: ['Sight words fall into two categories:'],
      points: [
        'Frequently Used Words — Words that occur repeatedly in the English language, such as it, can, will, shall, a, but, did, each, for, had, in, etc. . Retaining these words makes reading much easier and helps it proceed more smoothly because the child is already familiar with most of the words and can focus her efforts on new words.',
        'Non-Phonetic Words — Words that cannot be interpreted phonetically, such as buy, talk, or come. Memorizing these words that don’t follow the phonetic way of spelling them and irregular pronunciations teaches not only these words but also helps the child get more familiar with similar words, like guy, walk, or some.',
      ],
    },
    {
      heading: 'When Should A Child Start Learning Sight Words?',
      content: [
        'Before a child explores sight words, it is important that he/she is able to recognize and name all the letters of the alphabet. When prompted with a letter, he/she should be able to name the letter in a quick and confident manner.',
        'If a student’s knowledge of letter names is still wobbly, it is advised to spend time practicing this skill, before jumping into sight words.Having a firm foundation in the ability to quickly spot and name the letters will make teaching sight words easier and more meaningful to the child, also promoting retention.',
      ],
    },
    {
      heading: 'How to Teach Sight Words to Children',
      content: [
        `The best way to engage young readers is to make sight word learning fun and direct. Playing games, using sight words coloring worksheets, and hands-on clay activities will help children master skills in
        a playful manner.`,
        'Sight words coloring pages are an especially useful tool for learning sight words easily because it allows children to spot and color, making the experience fun and helping them revise it at a later point in time. This is achieved by keeping the worksheet aside, and reviewing it a week later, helping kids repeat what they did, and memorizing the words effectively.',
        'Children follow the color key and color in the pictures by sight words. These words follow the new and review sight words from those they are familiar with already. Once children start enjoying learning these words, they’ll be able to read through most children’s books easily which will then motivate them to progress even more speedily. This is because once they realize that they can read effortlessly and sound sharp, these very sight words will begin to seem liberating, instead of an obstruction.',
      ],
    },
  ],
};

// ! spelling activities blog post ===================================
export const spellingActivitiesPost = {
  blogPostClassName: 'blog-post__spelling-activities',
  bannerLineOne: 'Why Are Spelling',
  bannerLineTwo: 'Activities Important?',
  bannerAuthor: 'No Fuss Tutors',
  bannerDate: 'March 10, 2021',
  bannerImageName: 'multiplication-chart',
  bannerImage: 'spelling-activites.jpg',
  bannerAlt: 'elementary school child and tutor writing in notebook',
  metaDataPostTitle: 'Why Are Spelling Activities Important?',
  metaDataDescription: 'Is your child having trouble with spelling? Find out how to help them spell more effectively.',
  metaDataPageTitle: 'Spelling Activities',
  metaDataImage: 'https://nft-development.netlify.app/assets/webp/logo.png',
  metaDataUrl: 'https://nofusstutors.com/blog-posts/why-are-spelling-activities-important',
  metaDataKeywords: ['online tutoring', 'spelling', 'spelling activities'],
  worksheetWidgetKeywords: ['spelling'],
  sideBarPageLinks: [
    {
      pageName: 'English',
      pageLink: '/online-english-tutor',
    },
    {
      pageName: 'Reading',
      pageLink: '/online-reading-tutor',
    },
    {
      pageName: 'Writing',
      pageLink: '/online-writing-tutor',
    },
  ],
  sideBarWorksheetsLinks: [
    {
      worksheetsName: 'Reading',
      worksheetsLink: '/reading-worksheets',
    },
    {
      worksheetsName: 'Reading Comp',
      worksheetsLink: '/reading-comprehension-worksheets',
    },
    {
      worksheetsName: 'Vowels',
      worksheetsLink: '/vowels-worksheets',
    },
    {
      worksheetsName: 'Alphabets',
      worksheetsLink: '/alphabet-worksheets',
    },
  ],
  blogContent: [
    {
      content: [
        'Practice makes perfect, but only if your child is practicing correctly. Each time your child memorizes an incorrect spelling, their practice ergo their time is wasted. The bigger problem is that when educating young children, there’s no time to waste in the first place. They need to be taught so much in such a short period and spelling activities can help children bridge that gap.',
        'Besides, being able to spell correctly is an important basic skill that prepares a child for reading and writing. Acquiring the knowledge of how to spell words helps in building the foundation that all children require not just throughout their education but life in general. Spellings are necessary because they help cement the connection between letters and sounds.',
        'The interesting this is that language is all around us - there are words on the street signs, on the posters, on the TV, and even on t-shirts. The more that children hear and see a word, the more permanently it gets engraved in their memory. They are more likely to catch up on correct spelling patterns from frequent exposure, and this is exactly what spelling activities aim to achieve.',
        `Students can, however, easily become discouraged when they get low grades for spelling in school. They may begin to indulge in self-blame, feel incompetent, and think badly about themselves and their mental abilities. This a particularly terrible thing to happen, given that spelling ability has nothing to do with intelligence - it’s all about practice. That’s why it is important to help every student to build their spelling skills by practicing spelling activities.`,
      ],
    },
    {
      heading: 'The Right Time To Begin Spelling Education For Children',
      content: [
        'Young, three-year-old children whose alphabet knowledge is fresh and complete, are capable of beginning spelling education. However, the best indicator of a child’s readiness is when they start ‘inventing’ spellings. For example, if you ask your child to write “flow” and they write something like “flo” – a very close variant of the correct spelling, your child is ready for more detailed learning.',
        'Also, spellings come much more easily after kids know how to read common words and short sentences. When your child has finished their ‘sight words’ practice, is also a great time for beginning spelling lessons. If not, it is best to hold back on spellings until the child can read at a basic level.',
        'Even though many schools wait until the second- or third-grade level to begin teaching spellings, logic suggests that if your child can read at a basic level, grade two or three is too long to wait. Here’s why:',
      ],
    },
    {
      content: [
        '1. Spellings should be taught before your child gets burdened with the rest of the subjects in school.',
        '2. You do not want your child to be guessing how to spell words. This is a bad habit and every parent knows that bad habits are very hard to correct. Also, over time, patterns form in the brain which can make it even more difficult to break bad habits.',
        '3. Acquiring skills and instilling confidence early in school time will keep your child far from ever thinking that they are a poor student and that education is a struggle. This newfound confidence can be the thing that makes your child fall in love with studies, and no parent would want their child to be devoid of this.',
      ],
    },
    {
      content: [
        'This is why it’s always preferred to start teaching spellings by the end of first grade, but even if your child is older than that, there’s no need to despair. Spelling activities are ideal and effective for older kids as well.',
      ],
    },
    {
      heading: 'Fun And Engaging Spelling Activities For Your Child',
      content: [
        'Involving your child in activities where learning spellings is the main focus, is a great way to spend time with your child and help them do something productive – both at once. In more technical terms, such activities also help children to figure out which letters appear together more often, such as the ‘tt’ in letter, better, wetter, etc.',
      ],
    },
    {
      content: ['Some activities you can try with your kids are:'],
    },
    {
      subHeading: '1. Creating a ‘spelling wall’ at home:',
      content: [
        'Every week hang a new blank paper on one of the walls in your kids’ room so as to create a ‘learning and spelling center’. Encourage your kids to write down every new word that they learn on that blank paper. Don’t remove the sheet of paper once it fills up, instead just add more sheets. Eventually, the whole wall will fill up with sheets of new words. The continuous exposure to spelled-out words will help your child memorize them faster. Use colorful pens to make the wall look pretty!',
      ],
    },
    {
      subHeading: '2. Making a crossword:',
      content: [
        'Crossword puzzles are a superb way to make spelling education appealing, as they involve a more energetic type of learning and problem-solving. Crossword activities are also effective in boosting both long-term and short-term memory. Timed crosswords are even better as they require quick thinking, which has a knock-on effect on learning. The best part is, there are many fun-themed crosswords available for free on the internet and are a boon for working parents who are always short of time.',
      ],
    },
    {
      subHeading: '3. Using rhymes and rhyming words:',
      content: [
        'Thanks to rhymes, you can help your kids better understand the words that they are trying to learn, that too in a singsongy, fun, and frolicky way. The addition of rhythms makes children more likely to start murmuring them. Kids can make connections faster, and once they know how to spell one word, the spellings of its rhyming counterparts are much easier to figure out. For example, the road from ‘fast’ to ‘last’ and ‘vast’ is pretty straightforward.',
      ],
    },
    {
      subHeading: '4. Making the world a spelling playground:',
      content: [
        `Words are far and wide.Go to the supermarket, find the child's favorite packed food, and ask them to spell it into a notebook and draw and color a simple image of it. Then, check to see if the child has gotten it correct. Turn this out into a game by giving them a small prize (probably their favorite food itself!) for every time they win.`,
      ],
    },
    {
      subHeading: '5. Getting personal:',
      content: [
        'Make a list of the things that mean most to your child, like photographs of family members, friends, and familiar places.Ask them to spell out the names of the people and also identify and spell the objects appearing in the pictures.It’s easier for the kids to remember something that is relevant and important to them.Having their very own spelling list can give them a great deal of confidence too!',
      ],
    },
    {
      heading: 'The Bottomline of Spelling Education',
      content: [
        'By now, it’s clear that spellings are the stepping stones to reading and writing seamlessly. In fact, some kids are able to grasp meanings better and more effortlessly through spelling than reading. Even if a child is struggling with spellings or simply finds them boring or too exhaustive, when they start with spelling activities, they are suddenly struck with inspiration and a new attraction towards learning, because it seems so much more fun.',
        'Now that you’re ready with a bag full of activities to try with your child, get started today!',
      ],
    },
  ],
};

export const WhenDoesAChildLearnToReadContent = {
  blogPostClassName: 'blog-post__spelling-activities',
  bannerLineOne: 'When Does A Child',
  bannerLineTwo: 'Learn To Read?',
  bannerAuthor: 'No Fuss Tutors',
  bannerDate: 'March 14, 2021',
  bannerImageName: 'child-reading',
  bannerImage: 'whendokidslearntoread.jpeg',
  bannerAlt: 'child reading sitting against a tree',
  metaDataPostTitle: 'When Does a Child Learn to Read?',
  metaDataDescription:
    'Do you want to set your child up for success? Learning to read is the first step, and we can help.',
  metaDataPageTitle: 'Blog | Learning to Read',
  metaDataImage: 'https://nft-development.netlify.app/assets/webp/logo.png',
  metaDataUrl: 'https://nofusstutors.com/blog-posts/when-does-a-child-learn-to-read',
  metaDataKeywords: ['Learn to Read', 'reading comprehension', 'learning to read'],
  worksheetWidgetKeywords: ['reading'],
  sideBarPageLinks: [
    {
      pageName: 'English Tutor',
      pageLink: '/online-english-tutor',
    },
    {
      pageName: 'Reading Tutor',
      pageLink: '/online-reading-tutor',
    },
    {
      pageName: 'Writing Tutor',
      pageLink: '/online-writing-tutor',
    },
    {
      pageName: 'Math Tutor',
      pageLink: '/online-math-tutor',
    },
    {
      pageName: 'Science Tutor',
      pageLink: '/online-science-tutor',
    },
  ],
  sideBarWorksheetsLinks: [
    {
      worksheetsName: 'All Worksheets',
      worksheetsLink: '/worksheets-for-kids',
    },
    {
      worksheetsName: 'Preschool',
      worksheetsLink: '/preschool-worksheets',
    },
    {
      worksheetsName: 'Kindergarten',
      worksheetsLink: '/worksheets-for-kindergarten',
    },
    {
      worksheetsName: 'Grade 1',
      worksheetsLink: '/grade-1-worksheets',
    },
    {
      worksheetsName: 'Grade 2',
      worksheetsLink: '/grade-2-worksheets',
    },
    {
      worksheetsName: 'Grade 3',
      worksheetsLink: '/grade-3-worksheets',
    },
    {
      worksheetsName: 'Reading',
      worksheetsLink: '/reading-worksheets',
    },
    {
      worksheetsName: 'Writing',
      worksheetsLink: '/writing-worksheets',
    },
    {
      worksheetsName: 'Math',
      worksheetsLink: '/math-worksheets',
    },
    {
      worksheetsName: 'Science',
      worksheetsLink: '/science-worksheets',
    },
  ],
  blogContent: [
    {
      heading: 'How And When Does A Child Learn To Read?',
      content: [
        'Education plays an important role at every stage of life in making a person ready to face the world, in terms of self-advancement. Since parents are the first teachers of their children, they play a critical role right from the moment when their kids start going to school. What children learn at school is taken care of only by the teachers but after they back come home, making sure that whatever knowledge was imparted stays in children’s memory even after school hours, is the responsibility of parents.',
        'According to a study, parents who involve themselves in their child’s education have a positive impact on the child’s academic achievement. The more they get involved, the better their child performs.',
        'For most parents beginning their child’s educational journey is a great milestone. As parents, we expect our kids to perform well and shine in every sphere of life, which is why, unknowingly, we may end up putting an unnecessary burden on them. Therefore, the question arises - what should be your approach when it comes to encouraging your child to read?',
        'Fluent reading is undoubtedly an important skill for kids to develop. Most children learn to read by 5 or 6 years of age, but some smart blokes begin to read at the age of 3 or 4 years also. In any case, forcing a child to learn how to read before he is ready can make the entire process more frustrating and hampers the child’s development. On the other hand, reading together and playing some fun games make reading interesting for kids, and in this guide, teaching to read with fun is going to be our mission.',
      ],
    },
    {
      heading: 'Fun And Effective Ways You Can Help Your Child Learn To Read',
      content: [
        '1. Teach alphabets to lay the groundwork:',
        'It is important to pay more attention while teaching alphabets because this is the foundational step for reading. Use these tips to teach alphabets more easily:',
      ],
      points: [
        'Make things Interesting - Your child will not retain a thing if it is boring!',
        'Go slow - You can teach only a few letters every day. Revise the same thing over and over again, before rushing on to the next one.',
        'Play an A B C song every morning - You can sing along with your child as you show them an animated video of the 26 alphabets.',
        'Teach letter by letter - Teach them to letter one by one, making sure your child is seeing the alphabets in different shapes and sizes. Once they master the upper case, teach the lower case, before you move on to the next word.',
      ],
    },
    {
      content: [
        '2. Begin with sight words:',
        'Most educators would agree that every parent should start by teaching kids some sight words. Teaching sight words to kids helps them read easily, fluidly, and write more efficiently too. The more they see the words, the quicker they will learn them, hence the name ‘sight’ words. When beginning sight words, work on no more than three unfamiliar words and use some smart teaching techniques to introduce new words.',
        'Some examples of sight words are:',
      ],
      points: [
        'Level 1- a, and, away, big, can, come, funny, go, help, here',
        'Level 2- blue, down, find, for, in, look, make, me',
        'Level 3- area, body, certain, complete, notice, piece',
      ],
    },
    {
      content: [
        '3. Reading books aloud:',
        'The more excitement you show your child about reading, the more your child will enjoy it. So, you should try to use funny voices and animal noises. This will make your kid more excited about the story. Then, once your child begins to read, start making him/her read out loud now. This helps in building your child’s confidence in his/her ability to read and learn new skills.',
        'As your child learns, to read, use the following tips to make the process a more engaging and rewarding experience:',
      ],
      points: [
        'Bedtime stories are a must. Set some time aside for every night, where you put your child to bed and read together. Stories that have a moral are the best ones to choose.',
        'Encourage your child to become an independent reader as well. Leave some colorful reading books with beautiful images, in your child’s bedroom. Ensure that there are comfortable lighting and a cozy chair on which they can sit and read.',
        'As you read stories to your child, they may start having favorites. When you read the same story multiple times to them, they begin memorizing words and sentences, and may even chime in to complete your sentences.',
        'Lastly, try to make a game out of every activity. Don’t pester your kids with boring numbers and long words. Find a way to encourage creativity and curiosity.',
      ],
    },
    {
      heading: 'How To Develop Fluency When A Child Learns To Read',
      content: [
        'Reading fluency is the most important skill for a child to be a junior expert in early elementary. It helps the child figure out the text quickly and accurately. It plays an important role in many subjects like science and maths too, where the language is not so straightforward and words are complicated.',
        'The following steps could help your child to be a fluent reader:',
        '1. Paired reading: Paired reading is the best fluency strategy used with readers. Children usually lack fluency. In paired reading children read aloud to each other. You can pair your child with more fluent readers. It’s a good opportunity to built literacy skills.',
        '2. Pick a book kids can relate to: Nothing gets children more involved than knowing that the characters in the book are having the same struggles as theirs. It will spark your child’s imagination. You can also introduce the books that hook kids. When your child gets interested in a certain character he will be more likely to select subsequent books in a series. Some of the most popular characters that kids love are: Winnie the Pooh, Rainbowfish, Marry Poppins, and The Adventures of TIN TIN. Do give them a try!',
        '3. Use audio recordings: It’s a great and fantastic way for children to follow along as someone else reads. Audio recordings are created by voice actors who know exactly how to pronounce and how to model fluency. If your child follows along with the recordings and listens to them carefully, his/her sightwords skill will get better and he/she will be able to improve them immensely. Also, ask your child to repeat the passage multiple times, it will improve their removing skill.',
        '4. Work on Vocabulary: Do you think your child understands the meaning of all the words he/she is reading? If not, then language will be a tough battle for your child. You probably had to re-teach each sentence in order for your child to fully understand it. Use the following tips to do this in a better way:',
      ],
      points: [
        'Use post-it notes to label the surroundings objects, as this will help your child to read new words and understand what they represent.',
        'You can play word games with your child. There is a wide range of word games that will help your child to learn new words.',
        'The child who has learned and heard hundred of picture hooks before reaching school will surely have a large vocabulary than the child who’s had fewer books. It will be a very successful method for your child.',
      ],
    },
  ],
};

export const WhatIsTextStructureContent = {
  blogPostClassName: 'blog-post__spelling-activities',
  bannerLineOne: 'What is Text Structure?',
  bannerLineTwo: '',
  bannerAuthor: 'No Fuss Tutors',
  bannerDate: 'March 18, 2021',
  bannerImageName: 'text-structure',
  bannerImage: 'text-structure.jpeg',
  bannerAlt: 'child reading book',
  metaDataPostTitle: 'What is Text Structure?',
  metaDataDescription:
    "Are you concerned with your Child's reading comprehension? Core concepts like understanding text struture may help!",
  metaDataPageTitle: 'Blog | What is Text Structure',
  metaDataImage: 'https://nft-development.netlify.app/assets/webp/logo.png',
  metaDataUrl: 'https://nofusstutors.com/blog-posts/what-is-text-structure',
  metaDataKeywords: ['text structure', 'teach communication', 'reading comprehension'],
  worksheetWidgetKeywords: ['reading'],
  sideBarPageLinks: [
    {
      pageName: 'English Tutor',
      pageLink: '/online-english-tutor',
    },
    {
      pageName: 'Reading Tutor',
      pageLink: '/online-reading-tutor',
    },
    {
      pageName: 'Writing Tutor',
      pageLink: '/online-writing-tutor',
    },
    {
      pageName: 'Math Tutor',
      pageLink: '/online-math-tutor',
    },
    {
      pageName: 'Science Tutor',
      pageLink: '/online-science-tutor',
    },
  ],
  sideBarWorksheetsLinks: [
    {
      worksheetsName: 'All Worksheets',
      worksheetsLink: '/worksheets-for-kids',
    },
    {
      worksheetsName: 'Preschool',
      worksheetsLink: '/preschool-worksheets',
    },
    {
      worksheetsName: 'Kindergarten',
      worksheetsLink: '/worksheets-for-kindergarten',
    },
    {
      worksheetsName: 'Grade 1',
      worksheetsLink: '/grade-1-worksheets',
    },
    {
      worksheetsName: 'Grade 2',
      worksheetsLink: '/grade-2-worksheets',
    },
    {
      worksheetsName: 'Grade 3',
      worksheetsLink: '/grade-3-worksheets',
    },
    {
      worksheetsName: 'Reading',
      worksheetsLink: '/reading-worksheets',
    },
    {
      worksheetsName: 'Writing',
      worksheetsLink: '/writing-worksheets',
    },
    {
      worksheetsName: 'Math',
      worksheetsLink: '/math-worksheets',
    },
    {
      worksheetsName: 'Science',
      worksheetsLink: '/science-worksheets',
    },
  ],
  blogContent: [
    {
      heading: 'What is Text Structure?',
      content: [
        'Often, while we are communicating our thoughts over a text, or trying to solve that mystery while reading a suspense thriller, it is the structure that is at the core of how the particular text is interpreted by us. As adults, we become fluent with language - both reading and writing and don’t tend to think much about this text structure. Since we’re already effusive readers, the structure of the text along with its information seems to flow naturally and effortlessly — sans overt overthinking.',
        'Even though it usually goes unnoticed, the structure of a text is its backbone — making use of good vocabulary and fancier words only add to its aesthetics — and so it goes a long way towards building our concepts. The way information is arranged in a sentence, paragraph, chapter, or a complete book helps us perceive which bit of information is the most crucial and how we can classify the rest, placing it in the right place. This provides structure to our apprehension and helps us abrade these facts in our minds for the long term.',
        'Many young readers are often unable to interpret the use of text structure, let alone using it to form every sentence. Being able to identify and grasp the concept regarding the objective of common text structures is a finesse often recognized in our language theory that’s taught in our classrooms.',
        'There are a number of ways students can be taught text structures and cultivate their interest so that they experiment with it and start creating new phrases and creative sentences in a jiffy. They must be encouraged to be direct when asking questions about text structures in the classroom and also on standardized tests, but being able to investigate the way information is grouped will help beginners get a hold of where they can discern the author’s purpose while reading.',
        'Perhaps the most essential of all is being able to grasp text structures and it is this concept that gradually helps children become better writers. It’s like a module they’ll ultimately follow while writing sentences and paragraphs, so the groundwork we establish now will act as an ally that serves them well for a lifetime by aiding in their writing, whether they choose to become writers or write a simple email to a coworker.',
      ],
    },
    {
      heading: 'Type of Text Structures:',
      content: [
        'Before getting started with attaining the conceptual understanding of text structures, it is vital to know about their types and how they’re categorized. There are 7 common text structures :',
      ],
    },
    {
      subHeading: '1. Cause and Effect:',
      content: [
        'Such texts demonstrate the message by highlighting an event and detailing its results. Science and history texts often make use of this structure. Keywords to look for include “because,” “therefore,” and “why”.',
      ],
    },
    {
      subHeading: '2. Chronological:',
      content: [
        'These texts are important in the way that they organize events in the order in which they happened. This structure is commonly used to describe current events, history, and works of fiction or a memoir. Keywords include time markers like “first,” “next,” “then”, “ultimately” and “finally.”',
      ],
    },
    {
      subHeading: '3. Comparison / Contrast:',

      content: [
        'These texts are essentially detailed and identifying, but handle two or more topics to create a focal point around similarities and contrasts between them. This type of text structure is useful when dealing with nearly all subjects. keywords include “more,” “less,” “as [adjective] as,” “than” and “however.”',
      ],
    },
    {
      subHeading: '4. Order of Importance:',
      content: [
        'These texts present facts or information in a pecking order, typically with the most important element first. This structure is often used in news stories and topics related to science but can be used in a wide range of topics. Keywords include “most,” “least,” and “important.”',
      ],
    },
    {
      subHeading: '5. Problem and Solution:',
      content: [
        'These texts begin by describing an issue and then moving on with explaining a way to solve or rectify it. This structure is common in science, math, and social studies including a range of informative articles. Keywords include “issue,” “problem,” “trouble,” “fix,” “solve” and “how.”',
      ],
    },
    {
      subHeading: '6. Sequence / Process:',
      content: [
        'Comparable to chronological text structures, this structure puts elements in order, but with an aim to explain the directive of the way something has to occur. This is usually seen in lab reports and how-to pieces. keywords include “first,” and “next” as well as “how” and “why”.',
      ],
    },
    {
      subHeading: '7. Spatial / Descriptive:',
      content: [
        'These texts provide a description of a scene, typically arranging information by neighborhood; for example, portraying a room by moving from the doorway to the wall on the opposite end. keywords include prepositions like “above,” “below,” “behind,” etc. Adjectives are also an indication of this text structure.',
      ],
    },
    {
      heading: 'How To Get Started With Text Structures',
      content: [
        'The whole concept of text structures can come off as a bit dry to young children. We can follow some important steps that will help students get the crux and help make this topic easy for them to grasp and establish a proper flow.',
      ],
    },
    {
      subHeading: '1. Elucidate why text structures are important',
      content: [
        'To get students ready to learn about text structures, it helps to explain the reason they’re being taught about this topic — making sure we’re doing this with a kid-friendly approach. That may imply skipping that bit about the state tests while focusing solely on the importance of understanding an author’s purpose. We can also point out its benefits by stating it’s a nice way to set up good writing habits to let its relevance stand out even more.',
      ],
    },
    {
      subHeading: '2. Using age-appropriate examples',
      content: [
        'The best way to discuss and teach about text structure is to demonstrate with the help of examples, rather than simply talking about them in the abstract - this makes the child lose interest. It’s considered optimum to keep examples short and sweet, making sure they’re at the child’s reading level.',
      ],
    },
    {
      subHeading: '3. Discuss and dissect the sample',
      content: [
        'You can have children read a sample out loud and pick out sentences that display how the writer is trying to get the point across. For example in one such sentence, ask the child - Which words show that a comparison is being made? After working the way through a sample or two, you can have students solidify this idea of identifying text structures by writing their own short pieces that follow the structure that’s currently being taught.',
      ],
    },
    {
      subHeading: '4. Brainstorm key words',
      content: [
        'You can also have students brainstorm keywords so that they can search for them — they’re asked to come up with more examples than those listed above! For each text structure, make it a point to find out ways in which children can fill a piece of chart paper with words that will tip them off to the textstructure that’s going to be used. These posters made by children themselves make for a great resource to hang around in the classroom to act as a revision to prepare them for future structures.',
      ],
    },
    {
      subHeading: '5. Take it slow',
      content: [
        'Trying to conquer all seven text structures in one lesson would be disastrous. It’s best to go slow and do one at a time — reinforcing the previous knowledge as you go, and spread your lessons across a week or more. You’ll have ample chances to revisit this information as the year goes by. This is a natural way to build upon the concepts learned in a more meaningful way, so keep these review opportunities coming and relish how soon children stop wondering - “What is Text Structure” and begin to form works of art, one sentence at a time.',
      ],
    },
  ],
};

export const BenefitsOfColoringPagesForKidsContent = {
  blogPostClassName: 'blog-post__spelling-activities',
  bannerLineOne: 'Benefits of Coloring',
  bannerLineTwo: 'Pages for Kids',
  bannerAuthor: 'No Fuss Tutors',
  bannerDate: 'March 23, 2021',
  bannerImageName: 'benefits-of-coloring-pages',
  bannerImage: 'coloring-pages.jpeg',
  bannerAlt: 'crayons for coloring pages',
  metaDataPostTitle: 'Benefits of Coloring Pages for Kids',
  metaDataDescription:
    'Coloring is more than just fun for children. Coloring stimulates creativity, improves focus, and more! Find out more of the benefits with No Fuss Tutors!',
  metaDataPageTitle: 'Blog | Coloring Pages Benefits',
  metaDataImage: 'https://nft-development.netlify.app/assets/webp/logo.png',
  metaDataUrl: 'https://nofusstutors.com/blog-posts/what-is-text-structure',
  metaDataKeywords: ['coloring pages', 'kids coloring', 'benefits of coloring'],
  worksheetWidgetKeywords: [],
  sideBarPageLinks: [
    {
      pageName: 'English Tutor',
      pageLink: '/online-english-tutor',
    },
    {
      pageName: 'Reading Tutor',
      pageLink: '/online-reading-tutor',
    },
    {
      pageName: 'Writing Tutor',
      pageLink: '/online-writing-tutor',
    },
    {
      pageName: 'Math Tutor',
      pageLink: '/online-math-tutor',
    },
    {
      pageName: 'Science Tutor',
      pageLink: '/online-science-tutor',
    },
  ],
  sideBarWorksheetsLinks: [
    {
      worksheetsName: 'All Worksheets',
      worksheetsLink: '/worksheets-for-kids',
    },
    {
      worksheetsName: 'Preschool',
      worksheetsLink: '/preschool-worksheets',
    },
    {
      worksheetsName: 'Kindergarten',
      worksheetsLink: '/worksheets-for-kindergarten',
    },
    {
      worksheetsName: 'Grade 1',
      worksheetsLink: '/grade-1-worksheets',
    },
    {
      worksheetsName: 'Grade 2',
      worksheetsLink: '/grade-2-worksheets',
    },
    {
      worksheetsName: 'Grade 3',
      worksheetsLink: '/grade-3-worksheets',
    },
    {
      worksheetsName: 'Reading',
      worksheetsLink: '/reading-worksheets',
    },
    {
      worksheetsName: 'Writing',
      worksheetsLink: '/writing-worksheets',
    },
    {
      worksheetsName: 'Math',
      worksheetsLink: '/math-worksheets',
    },
    {
      worksheetsName: 'Science',
      worksheetsLink: '/science-worksheets',
    },
  ],
  blogContent: [
    {
      heading: 'Benefits Of Coloring Pages For Kids',
      content: [
        'The numerous advantages of using coloring pages with children make them the number one choice of parents and teachers all over the world. They have become such an integral part of a toddler’s education journey that the process of teaching can not be imagined without them. Here are some of the top benefits:',
      ],
    },
    {
      subHeading: '1. Stimulates creative thinking:',
      content: [
        'Coloring worksheets introduce kids to a world of imagination, helps develop drawing skills, and are simply a pleasurable activity to take up. It gives birth to creative thinking as kids learn how to plan, decide the colors they will use in their picture, and experiment with color and special effects. How they color will slowly improve as they try to make their work will look more appealing. During the process, praise your kids for their efforts, even if they coloring outside the lines. Tell them to use complementary colors to make the picture more vibrant, and help your kids show their personality through coloring.',
      ],
    },
    {
      subHeading: '2. Helps improve focus :',
      content: [
        'Focus is very important because it is the doorway to all thinking, learning, reasoning, and problem-solving. Without good focus, one can’t think clearly or pay attention to any work. Solving coloring pages will not only help your child learn the importance of boundaries in coloring but also will teach how to focus on one task, ultimately enabling your child to develop his/her overall fine motor skills level.',
      ],
    },
    {
      subHeading: '3. Color identification:',
      content: [
        'Identifying a color’s name by looking at its shade is a crucial part of a child’s mental development. Children are naturally attracted to colors and things that are fun - that’s why activities like coloring are great for kids. Solving coloring pages helps encourage color awareness in children as using different colors allows them to identify different combinations and they experience the color spectrum on their own. By being engaged in coloring activities, it’s easy for them to come up with a favorite too!',
      ],
    },
    {
      subHeading: '4. Improves hand and eye coordination:',
      content: [
        'Most activities that your kids do in day-to-day life use some degree of hand and eye coordination, which is why it’s important to make sure that it should be perfectly developed by doing sufficient practice – coloring helps. The proper way of holding the crayons and identifying what color needs to be used can increase your child’s hand and eye coordination to a great extent.',
        'Initially, children may have a tough time because they could end up coloring outside the outline of the drawing. However, with continuous practice, their motor skills will improve, their hands will become steady, and they will be able to color neatly, well within the lines. It’s a long process but the sense of accomplishment they will feel when they master drawing skills will surely be worth the time and effort.',
      ],
    },
    {
      subHeading: '5. Helps develop hand strength and a better grip:',
      content: [
        'Hand and finger strength is very important because it is required for everyday activities. In fact, the cause of the poor handwriting of your child can be an improper hand grip. Coloring supports both pencil and hand strength practice. Solving coloring pages will also help your child to gradually develop the enduranceto complete activities such as writing a full page of text. Coloring is a great exercise that helps develop the particular muscles in a child’s fingers and hands. Stronger hands and grip will also support your child’s pencil grip as they practice it with crayons and it makes future writing tasks easier.',
      ],
    },
    {
      subHeading: '6. Helps with memory power:',
      content: [
        'Our memory refers to the mental process of retaining and retrieving our surrounding environmental information. Color is the most important visual experience for children because colors help kids in memorizing particular information by increasing their attention level. It not only takes part in developing memory but also enhancing memory performance. This enhanced memory power and better attention help your child to contribute more towards better academic achievements. Many studies have reported that good attention can improve the reaction time, making it faster.',
        'To get your child started with colors, take the example of traffic lights and tell them what the green, yellow, and red signals mean. Also, tell them how and why these colors are chosen – red draws attention to something that is important, yellow indicates points that needed to be remembered, and green resonates with care-free.',
      ],
    },
    {
      subHeading: '7. Low-cost option to learn difficult things:',
      content: [
        'To buy coloring books from a local craft shop is very costly. These books have price tags ranging up to tens of dollars. Given that your child is going to fill up coloring books really fast, you will soon end up spending hundreds of dollars on these books. Being able to easily download coloring pages from the internet, with unique designs in every shape and size, is a total gamechanger for parents as well as teachers. You can find fun new pages and this is an easy way to teach your child through activities-based education. Kids are restless and often move quickly from one activity to another but engaging them in coloring activities will help you keep them quiet and occupied for a good few hours.',
      ],
    },
    {
      heading: 'When And How To Start Coloring Pages',
      content: [
        'Teaching your child how to color is not as difficult as teaching your child how to read. In fact, once your child so much as touches a crayon, it is impossible to stop them even from scribbling on the walls. The best time to begin coloring for your child is typically when he/she is developing fine motor skills. In other words, you can get started as soon as your child can hold a crayon.',
        'Large muscle movements take place when your child uses his arm and hands to drag a crayon across coloring pages. Small muscle movement refers to the movement of the wrist and fingers. Experts say that preschools age is the most important time for motor skills development and children must demonstrate large and small muscle movements by then. Motor skill tasks also include everyday tasks, and those little movements really are a big deal!',
      ],
    },
    {
      heading: 'Types Of Coloring Pages Your Child Can Experiment With',
      content: [
        'There are several different kinds of coloring pages available and each helps your child develop a uniques part of their brain.',
      ],
    },
    {
      subHeading: '1. Flower coloring pages',
      content: [
        'Flowers are much fun to color and these pages are very popular and well-liked. You will find a huge range of beautiful flower designs and much more categories like a spring flower, giant flower, flower heads, etc.on almost all good quality websites, most of them for free, although better quality ones are more likely to be paid and for good reason.',
      ],
    },
    {
      subHeading: '2. Pattern coloring pages',
      content: [
        'Your child can enjoy magical patterns with wonderful coloring pages, enjoy the symmetrical and simple beauty of adorable patterns, fun -geometric shapes, and much more. Designs vary in complexity from simple to big curves that are easy to fill in and increase the focus and concentration of your child. The main focus of these pages is to teach your child how to color in tight-spots and how to color neatly inside the lines.',
      ],
    },
    {
      subHeading: '3. Fruits and vegetable coloring pages',
      content: [
        'These pages can be a great way to help your child memorize the names of fruits and vegetables, and what they look like. Ranging from pineapple, sweet potato, cabbage, currants, raspberry, grape, radish, kiwi to watermelon, pumpkin, passion fruit, guava, apple, banana, tomato, and cucumber - these illustrations with their wide-ranging color scheme can be super fun!',
        'Coloring is the beginning of a great journey towards growth and coloring worksheets, although a cheap option, can be the boat on which your child’s creativity sails. If you are contemplating on when to begin, the answer is now!',
      ],
    },
  ],
};
