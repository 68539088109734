import { useContext, useState } from 'react';
import { encrypt, GetBaseURL } from '../Utils';
import { UserContext } from '../Context/UserContext';
import { useCookies } from 'react-cookie';

export const useUserData = () => {
  const { userData, setUserData } = useContext(UserContext);
  const [cookies, setCookie, removeCookie] = useCookies(['email']);
  const [willRedirect, setWillRedirect] = useState(false);

  const setUser = (user: any) => {
    setUserData((prev: any) => ({
      ...prev,
      ...user,
      shouldUpdate: true,
    }));
  };

  const updateUser = () => {
    setUserData((prev: any) => ({
      ...prev,
      shouldUpdate: true,
    }));
  };

  const setSessionAfterPayment = async (sessionId: string) => {
    setUserData((prev: any) => ({
      ...prev,
      session_id: encrypt(sessionId),
    }));
  };

  const setMessage = (message: string) => {
    setUserData((prev: any) => ({
      ...prev,
      message,
    }));
  };

  const logout = () => {
    localStorage.removeItem('id');
    removeCookie('email');
    setUser({
      _id: '',
      user_data: { full_name: '', email: '', phone: '', street_address: '', city: '', postcode: '' },
      password: '',
      tutorInterest: 'Maybe Later',
      userRole: 'Incomplete',
      assessments: [],
      worksheets: [],
      products: [],
      receipts: [],
      customer_id: '',
      subscription_active: false,
      shouldUpdate: true,
      session_id: '',
      subscription_id: '',
    });
    setWillRedirect(true);
  };

  const addDocument = (docType: string, documentData: { date_downloaded: Date; resource_id: string }) => {
    if (docType === 'assessments') {
      setUserData((prev: any) => ({
        ...prev,
        assessments: [...prev.assessments, documentData],
      }));
    }

    if (docType === 'worksheets') {
      setUserData((prev: any) => ({
        ...prev,
        worksheets: [...prev.worksheets, documentData],
      }));
    }
  };

  const DownloadDocument = async ({
    document_id,
    type,
  }: {
    document_id: string;
    type: 'Worksheets' | 'Assessments';
  }) => {
    let request_body = {
      user_id: userData._id === undefined ? '' : userData._id,
      documentData: {
        resource_id: document_id,
        date_downloaded: new Date(),
      },
      docType: type.toLowerCase(), //As of January 19 server acceps lower case docType for this request
    };

    let request = await fetch(`${GetBaseURL()}/api/users/addDocument?key=${process.env.REACT_APP_API_KEY}`, {
      method: 'POST',
      body: JSON.stringify(request_body),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (request.status === 200) {
      return true;
    } else {
      return false;
    }
  };

  const fetchUserDownloads = async () => {
    const resourcesToFetch = [...userData.assessments, ...userData.worksheets];
    const request = await fetch(`${GetBaseURL()}/api/resources/fetchDownloads?key=${process.env.REACT_APP_API_KEY}`, {
      method: 'POST',
      body: JSON.stringify({ resourcesToFetch }),
      headers: { 'Content-Type': 'application/json' },
    });
    const response = await request.json();
    if (request.status === 200) {
      return { success: true, resources: response.resources };
    } else {
      return { success: false, message: response.message };
    }
  };

  return {
    userData,
    setUser,
    setSessionAfterPayment,
    logout,
    willRedirect,
    setWillRedirect,
    DownloadDocument,
    addDocument,
    fetchUserDownloads,
    setMessage,
    updateUser,
  };
};
