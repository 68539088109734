import React from 'react';
import { FacebookShareButton, TwitterShareButton, PinterestShareButton } from 'react-share';
import TwitterIcon from '../../assets/twitter.png';
import FacebookIcon from '../../assets/facebook.png';
import PinterestIcon from '../../assets/pinterest.png';

type ShareProps = {
  url: string;
  facebookQuote: string;
  imagePreviewString?: string;
};

const ShareIcons: React.FC<ShareProps> = ({ url, facebookQuote, imagePreviewString }) => {
  return (
    <>
      <FacebookShareButton url={url} quote={facebookQuote}>
        <div className="icon-container">
          <img src={FacebookIcon} alt="Share with Facebook" className="icon" loading="lazy" />
        </div>
      </FacebookShareButton>
      <TwitterShareButton url={url}>
        <div className="icon-container">
          <img src={TwitterIcon} alt="Share with Twitter" className="icon" loading="lazy" />
        </div>
      </TwitterShareButton>
      {imagePreviewString && (
        <PinterestShareButton url={url} description={facebookQuote} media={imagePreviewString}>
          <div className="icon-container">
            <img src={PinterestIcon} alt="Share with Pinterest" className="icon" loading="lazy" />
          </div>
        </PinterestShareButton>
      )}
    </>
  );
};

export default ShareIcons;
