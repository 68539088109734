import React, { useEffect, useState, useRef } from 'react';
import Hero from '../../Components/Partials/Hero';
import MetaData from '../../Components/MetaData';
import { FloridaPageHeaderContent, FloridaPageContent, FloridaPageQuickLinks } from '../../Models';
import { generateID } from '../../Utils';
import ShareIcons from '../Blog/ShareIcons';
import SideContent from '../Partials/SideContent';
import ConsultationWidget from '../Partials/ConsultationWidget';

const FloridaLocation: React.FC = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth < 1200 ? true : false);
  const [isLinkVisibleFromTop, setIsLinkVisibleFromTop] = useState(true);
  const [isLinkVisibleFromBottom, setIsLinkVisibleFromBottom] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const scrollHeightRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    //! scroll to top of page
    window.scrollTo(0, 0);
  }, []);

  window.addEventListener('scroll', () => {
    let lastKnownScrollPosition = window.scrollY;

    if (scrollHeightRef.current && lastKnownScrollPosition < 615) {
      //!once there is content here, this needs to be tweaked
      setIsLinkVisibleFromTop(true);
    } else {
      setIsLinkVisibleFromTop(false);
    }
    if (scrollHeightRef.current && lastKnownScrollPosition > scrollHeightRef.current.scrollHeight - 815) {
      //!once there is content here, this needs to be tweaked
      setIsLinkVisibleFromBottom(true);
    } else {
      setIsLinkVisibleFromBottom(false);
    }
  });

  window.addEventListener('resize', () => {
    setWidth(window.innerWidth);
    if (isMobileScreen && window.innerWidth > 1199) {
      setIsMobileScreen(false);
    }
    if (!isMobileScreen && window.innerWidth < 1200) {
      setIsMobileScreen(true);
    }
  });

  return (
    <>
      <MetaData
        pageTitle="No Fuss Tutors | Online Tutors in Florida"
        postTitle="Online Tutors Florida"
        description="Your Destination For Top Online Tutors in Florida. Our mission is to make quality math tutors affordable for everyone in Florida!"
        image={'https://nft-development.netlify.app/assets/webp/logo.png'}
        url={'https://nofusstutors.com/online-tutor-canada'}
        keywords={['online math tutor florida', 'online reading tutor florida', 'online tutor florida']}
      />
      <div className="florida-location-page" ref={scrollHeightRef}>
        <Hero content={FloridaPageHeaderContent} />

        <div className="florida-location-page__content-wrapper">
          {!isMobileScreen && (
            <aside
              className={
                isLinkVisibleFromTop
                  ? 'blog-page__side-content blog-page__side-content--fix-top'
                  : isLinkVisibleFromBottom
                  ? 'blog-page__side-content blog-page__side-content--fix-bottom'
                  : 'blog-page__side-content'
              }
              style={
                isLinkVisibleFromBottom
                  ? width < 1700
                    ? { marginLeft: '-0.5rem', bottom: '1rem' }
                    : { bottom: '1rem' }
                  : width < 1700
                  ? { marginLeft: '-0.5rem' }
                  : undefined
              }
            >
              <div className="blog-page__side-links">
                {FloridaPageQuickLinks && (
                  <SideContent links={FloridaPageQuickLinks.pages} worksheetsLinks={FloridaPageQuickLinks.worksheets} />
                )}
              </div>
              <div className="blog-page__share-icons-wrapper">
                <ShareIcons
                  url="welcome.nofusstutors.com"
                  facebookQuote="I read this great article on the No Fuss Tutors blog!"
                />
              </div>
            </aside>
          )}

          <div className="florida-location-page__content-wrapper">
            {FloridaPageContent.map((item, index) => {
              return (
                <div className="florida-location-page__flex-wrapper" key={index}>
                  <div className="florida-location-page__heading-content-wrapper">
                    <h2 className="florida-location-page__heading">{item.heading ? item.heading : ''}</h2>
                    <p className="florida-location-page__content-text">{item.content ? item.content : ''}</p>
                  </div>
                  {/* <div className="florida-location-page__img-wrapper">
            {
              item.img
                ? <img
                  src={item.img}
                  alt={item.imgAlt}
                  className="ontario-location-page__content-img"
                />
                : ''
            }
          </div> */}
                </div>
              );
            })}
          </div>
        </div>
        <ConsultationWidget source="form" />
        {isMobileScreen && (
          <React.Fragment key={generateID()}>
            <aside className={'blog-page__side-content--mobile'}>
              <div className="blog-page__side-links">
                {FloridaPageQuickLinks && (
                  <SideContent links={FloridaPageQuickLinks.pages} worksheetsLinks={FloridaPageQuickLinks.worksheets} />
                )}
              </div>
              <div className="blog-page__share-icons-wrapper">
                <ShareIcons
                  url="welcome.nofusstutors.com"
                  facebookQuote="I read this great article on the No Fuss Tutors blog!"
                />
              </div>
            </aside>
          </React.Fragment>
        )}
      </div>
    </>
  );
};

export default FloridaLocation;
