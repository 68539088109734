import React, { useEffect, useState } from 'react';
import { generateID } from '../../Utils';
import AWSImage from '../Partials/AWSImage';

const OnlineReview = () => {
  const [reviewData, setReviewData] = useState<any[]>([]);
  const [reviewCount, setReviewCount] = useState('');
  const [reviewRating, setReviewRating] = useState('');

  useEffect(() => {
    (async () => {
      let request = await fetch('https://quick-feedback.co/reviews-widget/merchants/5e3dad8ac8a4c1027ab4fb8c.json');
      let res = await request.json();
      setReviewData(res.review);
      setReviewCount(res.aggregateRating.reviewCount);
      setReviewRating(res.aggregateRating.ratingValue);
    })();
  }, []);

  return (
    <section className="online-review">
      <div className="online-review__widget">
        <div className="online-review__header-wrapper">
          <h2 className="online-review__header">Online Reviews</h2>
          <div className="online-review__review-count">
            <AWSImage
              pngFile={'five-stars.png'}
              documentPrefix={'icons'}
              picClass={''}
              imageAlt={'five stars rating'}
              hasWebp
            />
            <div className="online-review__count-rating-wrapper">
              <p className="online-review__rating">{reviewRating} Rating</p>
              <p className="online-review__count">{reviewCount} Reviews</p>
            </div>
          </div>
        </div>
        <div className="online-review__review-container">
          {reviewData.map((review, index) => {
            return (
              <React.Fragment key={generateID()}>
                <div className="online-review__flex-wrapper">
                  <AWSImage
                    pngFile={'google_icon.png'}
                    documentPrefix={'icons'}
                    picClass={'online-review__google-icon'}
                    imageAlt={'google icon'}
                    hasWebp
                  />
                  <div className="online-review__single-review" key={index}>
                    <div className="online-review__review-header">
                      <div className="online-review__date-name">
                        <h5 className="online-review__date">{review.datePublished}</h5>
                        <h4 className="online-review__name">{review.author.name}</h4>
                      </div>
                      <AWSImage
                        pngFile={'five-stars.png'}
                        documentPrefix={'icons'}
                        picClass={''}
                        imageAlt={'five stars rating'}
                        hasWebp
                      />
                    </div>
                    <div className="online-review__review-content">
                      <p className="online-review__review">{review.description}</p>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default OnlineReview;
