import React from 'react';
import { generateID } from '../../Utils';
import { WorksheetsTextSegment } from '../../Models';

type WorksheetTextListProps = {
  segment: WorksheetsTextSegment;
};

const WorksheetTextList: React.FC<WorksheetTextListProps> = ({ segment }) => {
  return (
    <div className="single-worksheet__textBlock-list">
      {segment.type === 'list' &&
        Array.isArray(segment.content) &&
        segment.content.map((singleListItem) => {
          return <p key={generateID()}> - {singleListItem}</p>;
        })}
    </div>
  );
};

export default WorksheetTextList;
