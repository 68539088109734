// ! science programs 01
export const ScienceProgramsContent = [
  {
    number: '01',
    header: 'Helping students optimize their academic ability',
    programContent:
      'If your child is not getting the marks they want in science class. Whether it is biology, chemistry or physics, then you are in the right place. Science tutoring can help marks and foundational skills improve drastically. No matter what goals your child has, we have the resources and experience to ensure that they get there — and fast!',
    img: 'science1.jpg',
    alt: 'two student and a teacher looking at science model',
    picClass: 'science-programs__img',
    awsPrefix: 'homepage/science',
  },
];

// ! science subject cards
export const ScienceSubjectsContent = [
  {
    icon: 'chemIcon.png',
    alt: 'chemistry icon',
    awsPrefix: 'homepage/science',
    picClass: 'science-subjects__subject-card-icon',
    subjectHeading: 'Chemistry',
    subjectContent:
      'Chemistry is one that many students find challenging. Our chemistry tutors provide help with topics such as chemical properties, chemical bonds, chemical reactions, gases and atmospheric chemistry.',
  },
  {
    icon: 'bioIcon.png',
    alt: 'biology icon',
    awsPrefix: 'homepage/science',
    picClass: 'science-subjects__subject-card-icon',
    subjectHeading: 'Biology',
    subjectContent:
      'Students should expect to study human biology, evolution, genetics, animals, plants, microbiology and more. In order to succeed, memorisation and an in-depth understanding are integral.',
  },
  {
    icon: 'physicsIcon.png',
    alt: 'physics icon',
    awsPrefix: 'homepage/science',
    picClass: 'science-subjects__subject-card-icon',
    subjectHeading: 'Physics',
    subjectContent:
      'The study of physics is the study of the physical world around us. High school physics courses focus on topics such as kinematics, energy and momentum, magnetic fields and many more.',
  },
];

// ! science programs 02
export const ScienceProgramsContent02 = [
  {
    number: '02',
    header: 'Science Tutoring Subjects',
    img02: 'science2.jpg',
    img02Alt: 'young student holding science molecule model',
    img02Class: 'science-subjects__img02',
    img03: 'science3.jpg',
    img03Alt: 'butterfly science model',
    img03Class: 'science-subjects__img03',
    img04: 'science4.jpg',
    img04Alt: 'science student using ipad',
    img04Class: 'science-subjects__img04',
    awsPrefix: 'homepage/science',
  },
];

// ! careers
export const ScienceCareersContent = [
  {
    img: 'doctor.jpg',
    awsPrefix: 'homepage/science',
    picClass: 'careers__card-img',
    careerHeading: 'Doctor',
    careerContent:
      'The prized finish line for many high school science students. The path to becoming a doctor starts with great marks in science class.',
    imgAlt: 'tow doctors holding test tubes',
  },
  {
    img: 'engineer.jpg',
    awsPrefix: 'homepage/science',
    picClass: 'careers__card-img',
    careerHeading: 'Engineer',
    careerContent:
      'Engineering is the marriage of math and science. Some types of engineers specialize within different scientific topics such as chemistry and physics.',
    imgAlt: 'solar panels and factory smoke',
  },
  {
    img: 'marine-biologist.jpg',
    awsPrefix: 'homepage/science',
    picClass: 'careers__card-img',
    careerHeading: 'Marine Biologist',
    careerContent:
      'Marine biologists study a variety of organisms and ecosystems in the ocean and other saltwater environments.',
    imgAlt: 'dolphin in water',
  },
];
