import React from 'react';
import AWSImage from '../Partials/AWSImage';

type ProgramsProps = {
  content: {
    img: string;
    awsPrefix: string;
    alt: string;
    tag: string;
    headingOne: string;
    contentOne: string;
    headingTwo: string;
    contentTwo: string;
    className: string;
    tagClassName: string;
  }[];
  sectionHeader: {
    header: string;
  };
};

const Programs: React.FC<ProgramsProps> = ({ content, sectionHeader }) => {
  return (
    <section className="programs">
      <h3 className="programs__sub-heading">{sectionHeader.header}</h3>
      <div className="programs__wrapper">
        {content.map((item, index) => {
          return (
            <div className={item.className} key={index}>
              <AWSImage
                pngFile={item.img}
                documentPrefix={item.awsPrefix}
                picClass={item.className}
                imageAlt={item.alt}
                hasWebp
              />
              <div className="programs__content-wrapper">
                <p className={item.tagClassName}>{item.tag}</p>
                <h5 className="programs__heading">{item.headingOne}</h5>
                <p className="programs__content">{item.contentOne}</p>
                <h5 className="programs__heading">{item.headingTwo}</h5>
                <p className="programs__content">{item.contentTwo}</p>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Programs;
