import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Resources, UpdatedFilterOptions } from '../../Models';
import { useResourceFilters } from '../../Hooks/useResourceFilter';
import { useDisplayData } from '../../Hooks/useDisplayData';
import { URLGeneratorByFilter } from '../../Utils';
import Card from '../Card';

type ResourceRowProps = {
  identifying_filter: string;
  filter_value: string;
  current_filters?: UpdatedFilterOptions;
};

const ResourceRow: React.FC<ResourceRowProps> = ({ identifying_filter, filter_value, current_filters }) => {
  const { resourceData, UpdateFilterOptions, FetchRowDocuments } = useResourceFilters();
  const { setActiveCard, displayData } = useDisplayData();
  const [activeDocuments, setActiveDocuments] = useState<Resources[]>([]);
  const [title, setTitle] = useState<string | null>(null);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    let generatedURL = URLGeneratorByFilter({
      ...(current_filters ? current_filters : resourceData.filters),
      [identifying_filter.toLowerCase()]: filter_value,
    });
    let adjustedURLString = generatedURL.split('-').join(' ');
    let capitalizedString = adjustedURLString.split(' ').map((singleWord) => {
      return `${singleWord.charAt(0).toUpperCase()}${singleWord.length > 1 ? singleWord.slice(1) : ''}`;
    });
    setTitle(capitalizedString.join(' '));
  }, [identifying_filter, filter_value, resourceData.filters, resourceData.documents]);

  //todo: Update useEffect to find just three documents based on the filters selected.
  useEffect(() => {
    setActiveDocuments([]); //! set this to an empty array immediately when the filters change, if you comment this out you can see that some rows are rendered when they shouldn't be
    // Store FetchRowDocuments in a variable
    let RowValue = FetchRowDocuments({
      key: identifying_filter! as keyof UpdatedFilterOptions,
      value: filter_value,
      current_filters,
    });
    //! No longer updating empty rows. Just checks if the current filters return documents and if they do, setActiveDocuments with them
    if (RowValue.length > 0) {
      setActiveDocuments(RowValue);
    }
    //otherwise set Active Documents
  }, [resourceData.filters, resourceData.documents]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);
    });
  });

  const FIRST_ROW_CARDS = activeDocuments
    .slice(0, activeDocuments.length > 3 ? 3 : 2)
    .map((singleCardData: Resources, index: number) => {
      return (
        <div key={index} onClick={() => setActiveCard(singleCardData)} className="resource-row__single-card-wrapper">
          <Card
            link={singleCardData.link}
            src={singleCardData.src}
            webp={singleCardData.webp}
            alt={singleCardData.alt}
            type={singleCardData.type.toLowerCase()! as 'worksheets' | 'assessments'}
            title={singleCardData.title}
            subject={singleCardData.subject}
            grade={singleCardData.grade}
            onClick={() => {}}
            _id={singleCardData._id}
          ></Card>
        </div>
      );
    });
  activeDocuments.length <= 3 &&
    FIRST_ROW_CARDS.push(
      <Link
        to={`${URLGeneratorByFilter({
          ...resourceData.filters,
          [identifying_filter.toLowerCase()]: filter_value,
        })}`}
      >
        <Card
          alt="See More"
          type="worksheets"
          title="See More"
          subject={[]}
          grade={[]}
          onClick={() => {}}
          seeMore={true}
        ></Card>
      </Link>,
    );
  const SECOND_ROW_CARDS = activeDocuments
    .slice(3, activeDocuments.length === 6 ? activeDocuments.length - 1 : activeDocuments.length)
    .map((singleCardData: Resources, index: number) => {
      return (
        <div key={index} onClick={() => setActiveCard(singleCardData)} className="resource-row__single-card-wrapper">
          <Card
            link={singleCardData.link}
            src={singleCardData.src}
            webp={singleCardData.webp}
            alt={singleCardData.alt}
            type={singleCardData.type.toLowerCase()! as 'worksheets' | 'assessments'}
            title={singleCardData.title}
            subject={singleCardData.subject}
            grade={singleCardData.grade}
            onClick={() => {}}
            _id={singleCardData._id}
          ></Card>
        </div>
      );
    });
  activeDocuments.length > 3 &&
    SECOND_ROW_CARDS.push(
      <Link
        to={`${URLGeneratorByFilter({
          ...resourceData.filters,
          [identifying_filter.toLowerCase()]: filter_value,
        })}`}
      >
        <Card
          alt="See More"
          type="worksheets"
          title="See More"
          subject={[]}
          grade={[]}
          onClick={() => {}}
          seeMore={true}
        ></Card>
      </Link>,
    );

  if (activeDocuments.length === 0) {
    //! don't render anything if there are no documents for this row
    return null;
  } else {
    return (
      <section className="resource-row">
        <div
          className={
            displayData.showModal
              ? 'resource-row__row-heading-wrapper resource-row__row-heading-wrapper--overlay'
              : 'resource-row__row-heading-wrapper'
          }
        >
          {title !== null && (
            <h2 className="resource-row__title">
              {title.replace('Worksheets', '').replace('for', '').replace('For', '').replace('Assessments', '')}
            </h2>
          )}
        </div>
        <div className="resource-row__card-wrapper">{FIRST_ROW_CARDS}</div>
        {SECOND_ROW_CARDS.length > 0 && <div className="resource-row__card-wrapper">{SECOND_ROW_CARDS}</div>}
        <div
          className={'resource-row__see-more'}
          onClick={() => {
            UpdateFilterOptions({ entryKey: identifying_filter, entryValue: filter_value });
          }}
        ></div>
      </section>
    );
  }
};

export default ResourceRow;
