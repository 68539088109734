import React from 'react';

const WorksheetsForKidsText = () => {
  return (
    <section className="single-worksheet worksheet_text_block">
      <h3 className="single-worksheet__textBlock-heading">Benefits of Worksheets</h3>
      <p className="single-worksheet__textBlock-paragraph">
        Nothing is more effective than learning by doing. For example, if you want to teach a child how to write, begin
        by teaching them how to write their name. Similarly, if you want to teach a child how to read, begin by helping
        them read the alphabet correctly. However, as the child progresses along the path of learning, things can often
        get tricky.
      </p>
      <p className="single-worksheet__textBlock-paragraph">
        Picking one concept at a time and gradually shifting to the next one, is very important. Not following the
        correct step-by-step approach can make the child end up being confused. The pace at which you teach also
        matters. Going too fast or too slow can have a negative impact on learning.
      </p>
      <p className="single-worksheet__textBlock-paragraph">
        That’s why we’ve made an extensive collection of reading, writing, and basic math worksheets that proceed at the
        perfect pace and only one step at a time. They are colorful and super fun for the kids. The best part is, our
        worksheets are free, ready to print, and can be downloaded in one click.
      </p>
      <h2 className="single-worksheet__textBlock-heading">Math Worksheets for Kids</h2>
      <p className="single-worksheet__textBlock-paragraph">
        Learning does not have to be boring. With colorful worksheets that are super fun, even math can be made easy for
        kids. Our collection of basic math worksheets are crafted with this very thought in mind. Each exercise is
        uniquely designed to help the child understand numbers, be able to identify them, and understand what they
        represent. Concepts such as addition using the number line are also briefly covered.
      </p>

      <p className="single-worksheet__textBlock-paragraph">
        We have the perfect collection of math worksheets for elementary children. Browse from the long list of
        worksheets and print the ones you’re looking for, all for free!
      </p>
      <h2 className="single-worksheet__textBlock-heading">Reading Worksheets for Kids</h2>
      <p className="single-worksheet__textBlock-paragraph">
        Every child is unique and learns at his or her own pace. We understand this, which is why all our early-reading
        worksheets offer great flexibility. Our collection is vast with a varied explanation of each topic, giving you a
        lot to choose from. There are numerous ‘sight words’ worksheets that can help children learn new words as they
        read. Understanding the usage of each word also becomes easier with these worksheets.
      </p>

      <p className="single-worksheet__textBlock-paragraph">
        In addition, there are plenty of worksheets that focus on phonic sounds as well, helping children learn the
        pronunciation of words. Our printables can nurture fluency in reading from a very young age. The worksheets are
        ideal for pre-schoolers who are still new to reading. Go ahead and download them now!
      </p>
      <h2 className="single-worksheet__textBlock-heading">Writing Worksheets for Kids</h2>
      <p className="single-worksheet__textBlock-paragraph">
        Teaching a toddler how to begin writing is something that most parents and teachers struggle with. However, this
        process is greatly simplified by worksheets where the child has to trace the outline of the words. The more
        children write with a pencil, the faster they learn to write neatly. The fact that these colorful worksheets
        quickly grab a child’s attention, is an added advantage.
      </p>

      <p className="single-worksheet__textBlock-paragraph">
        If you’re looking for such writing worksheets for kids, you’ve reached the right place. Our worksheets are
        designed with utmost care and effort. They are error-free and of superior quality, yet you don’t have to pay for
        them. Print our amazing worksheets today, watch your child get better at reading, and eventually fall in love
        with it.
      </p>

      <h2 className="single-worksheet__textBlock-heading">Why Worksheets from No Fuss Tutors</h2>
      <p className="single-worksheet__textBlock-paragraph">
        The things that children learn in their formative years, shape who they become. Setting them on the right
        learning path is hence very important. However, in today’s world where parents are spending less time teaching
        their children, early learning is taking a hit. We are here with a vision to change that.
      </p>

      <p className="single-worksheet__textBlock-paragraph">
        By offering free of cost worksheets that can be downloaded and printed within a second, we help you save time.
        Also, the fact that we provide a vast collection in one place means that you get better access to worksheets for
        kids. This allows you to spend less time finding worksheets and more time solving them with children. With our
        engaging and truly effective worksheets, you can say goodbye to all the fuss, once and for all!
      </p>
    </section>
  );
};

export default WorksheetsForKidsText;
