import React from 'react';
import { PricingCardsInfo } from '../../Models'

const OurPricing = () => {
   return (
      <section className="our-pricing">
         <div className="our-pricing__left-wrapper">
            <h3 className="our-pricing__header">
               Our Pricing
            </h3>
            <h4 className="our-pricing__sub-header">
               Transparent pricing with transcendent quality
            </h4>
            <p className="our-pricing__info">
               We pride ourselves on providing affordable & quality tutoring. We provide the same quality tutor as you will find with other companies whom charge $50-80/hr for $30-40/hr.
            </p>
         </div>
         <div className="our-pricing__cards-wrapper">
            {
               PricingCardsInfo.map((item, index) => {
                  return (
                     <div className={
                        item.title === 'Elementary School'
                        ? 'our-pricing__card our-pricing__card--elementary'
                        : item.title === 'Middle School'
                        ? 'our-pricing__card our-pricing__card--middle'
                        : 'our-pricing__card our-pricing__card--high'
                     }
                     key={index} 
                     // style={{ borderLeft: `2px solid ${item.color}` }}
                     >
                        <div className="left-side-wrapper">
                           <h4 className="our-pricing__card-header">
                              {item.title}
                           </h4>
                           <p className="our-pricing__card-grade">
                              {item.grade}
                           </p>
                        </div>
                        <h5 className="our-pricing__card-cost">
                           {item.cost}
                           <span className="our-pricing__card-cost our-pricing__card-cost--per-hour"> /hour</span>
                        </h5>
                     </div>
                  )
               })
            }
         </div>
      </section>
   )
}

export default OurPricing
