import React, { useState, useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import SignInModal from './SignInModal';
import ShareIcons from './Partials/ShareIcons';
import DownloadButton from './Partials/DownloadButton';
import AWSImage from './Partials/AWSImage';
import { useDisplayData } from '../Hooks/useDisplayData';
import { useUserData } from '../Hooks/useUserData';
import { CurrentDomain, FormatGrades, SendEmail, SingleResourceLink, ValidateEmail } from '../Utils';
import { isDownloadable } from '../Utils/Downloadability';
import { ErrorProps, Resources } from '../Models';
import ErrorHandler from './Partials/ErrorHandler';
import GameSettings from './Games/GameSettings';

const Modal: React.FC<Resources> = ({
  type,
  title,
  src,
  webp,
  alt,
  shareUrl,
  facebookQuote,
  description,
  subject,
  grade,
  pdfDoc,
  link,
  _id,
}) => {
  const { displayData, hideModal, showGameModal, startBodyScroll } = useDisplayData();
  const { userData } = useUserData();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [displaySignIn, setDisplaySignIn] = useState(false);
  const [cookies] = useCookies(['email']);
  const [canDownload, setCanDownload] = useState(false);
  const emailRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<ErrorProps>({ type: 'error', isActive: false, message: '' });

  const resetError = () => {
    setError({ type: 'error', isActive: false, message: '' });
  };

  useEffect(() => {
    if (cookies.email) {
      setIsLoggedIn(true);
      setDisplaySignIn(false);
    }
  }, []);

  useEffect(() => {
    if (userData.subscription_active) {
      setCanDownload(true);
    } else {
      setCanDownload(isDownloadable(userData.assessments, userData.worksheets));
    }
  }, [userData.assessments, userData.worksheets]);

  if (displaySignIn || displayData.showSignInModal || displayData.showCreateUserModal) {
    return <SignInModal HideModal={hideModal} awsKey={pdfDoc} />;
  } else if (displayData.showGameModal) {
    return (
      <div className="games__modal">
        <div
          className="games__modal--close"
          onClick={() => {
            showGameModal(false);
            startBodyScroll();
          }}
        >
          x
        </div>

        <GameSettings />
      </div>
    );
  } else {
    return (
      <div className="modal hide-scrollbar">
        <div className="modal__type-close">
          {/* <span className="modal__doc-type">{type}</span> */}
          <span aria-label="close-window" onClick={hideModal}>
            <i className="fas fa-times"></i>
          </span>
        </div>

        <section className="modal__heading-wrapper">
          <h2 className="modal__title">{title}</h2>
          <ShareIcons
            shareUrl={`${CurrentDomain()}/worksheets/${_id}`}
            facebookQuote={facebookQuote}
            imagePreviewString={new URL(
              `/worksheets/${encodeURIComponent(src)}`,
              process.env.REACT_APP_AWS_BUCKET_URL,
            ).toJSON()}
          />
        </section>
        <section className="modal__content-container">
          <div className="modal__modal-image">
            <AWSImage imageAlt={alt} pngFile={src} picClass={''} documentPrefix="worksheets" />
            {title !==
              ('Letter A' ||
                'Letter B' ||
                'Letter C' ||
                'Letter D' ||
                'Letter E' ||
                'Letter F' ||
                'Letter I' ||
                'Letter T' ||
                'Common and Proper Nouns' ||
                'Possessive Nouns' ||
                'Prepositions') &&
              window.innerWidth > 1024 && (
                <AWSImage
                  imageAlt={'Document Preview Cover'}
                  pngFile={'worksheet-preview-cover.png'}
                  picClass={'preview-blocker'}
                  documentPrefix="worksheets"
                  forceLoad={true}
                />
              )}
          </div>
          <div className="modal__text-container">
            {/* <p className="modal__description hide-scrollbar">{description}</p>
            <div className="modal__categories">
              <span className="modal__categories-subject">
                <strong>Subject</strong>: {subject[0]}
              </span>
              <span className="modal__categories-grade">
                <strong>Grade</strong>: {FormatGrades(grade)}
              </span>
            </div> */}
            {!canDownload && <div className="modal__warning-text">You have one free download today.</div>}
            <div className="modal__download-btn-wrapper" data-cy="modal__div--downloadButton">
              {isLoggedIn ? (
                canDownload ? (
                  <DownloadButton awsKey={pdfDoc} docType={type} resource_id={_id} />
                ) : (
                  <Link to="/premium" target="_blank" rel="noopener noreferrer">
                    <button className="modal__download modal__download--link-to-premium">Download</button>
                  </Link>
                )
              ) : (
                <button
                  className="modal__download modal__download--free-download"
                  onClick={() => {
                    setDisplaySignIn(true);
                  }}
                >
                  Download
                </button>
              )}

              {error.isActive && <ErrorHandler type={error.type} message={error.message} handler={resetError} />}
              <input
                className="modal__download--input"
                type="email"
                defaultValue={userData.user_data.email}
                ref={emailRef}
              />

              <button
                className="modal__download modal__download--link-to-premium"
                onClick={async () => {
                  if (emailRef.current && ValidateEmail(emailRef.current?.value)) {
                    const response = await SendEmail(
                      `Click the link to be taken to ${title}: ${
                        process.env.REACT_APP_DOMAIN_NAME
                      }/worksheets/${SingleResourceLink(title)}`,
                      emailRef.current.value,
                      `${title} -- Worksheet from No Fuss`,
                    );
                    if (response.message === 'Success') {
                      setError({ type: 'success', isActive: true, message: 'Success! Please check your email.' });
                    } else {
                      setError({
                        type: 'error',
                        isActive: true,
                        message: 'There was an error. Please try again later.',
                      });
                    }
                  } else {
                    setError({ type: 'error', isActive: true, message: 'Please enter a valid email.' });
                  }
                }}
              >
                Email this to myself
              </button>
            </div>
            {userData.user_data.email &&
              userData.user_data.email !== '' &&
              `${process.env.REACT_APP_NFT_ADMINS}`.includes(userData.user_data.email) && (
                <Link to={`/edit/${_id}`} onClick={hideModal}>
                  <button className="modal__download modal__download--link-to-premium">Edit Document Attributes</button>
                </Link>
              )}
          </div>
          {/* </div> */}
        </section>
        <p className="modal__newsletter-notice">
          * By downloading you are signing up to our newsletter. You can unsubscribe anytime.
        </p>
      </div>
    );
  }
};

export default Modal;
