import React from 'react';
import Hero from '../../Components/Partials/Hero';
import SellingPoint from '../../Components/WritingPage/SellingPoint';
import TutoringProgram from '../../Components/WritingPage/TutoringProgram';
import Programs from '../../Components/WritingPage/Programs';
import GoogleReview from '../../Components/Partials/GoogleReview';
import Tutors from '../../Components/Partials/TutorCards/Tutors';
import MetaData from '../../Components/MetaData';
import ConsultationWidget from '../../Components/Partials/ConsultationWidget';
import ShareIcons from '../../Components/Blog/ShareIcons';
import SideContent from '../../Components/Partials/SideContent';
import {
  WritingHeaderContent,
  TutoringProgramCards,
  TutoringProgramCardsHeader,
  AdvancedTutoringProgramCards,
  AdvancedTutoringProgramCardsHeader,
  BeginnerProgramsInfo,
  BeginnerProgramsInfoHeader,
  AdvancedProgramsInfoHeader,
  AdvancedProgramsInfo,
  MathPageReview,
  StandardSubjectQuickLinks,
} from '../../Models';
import { generateID } from '../../Utils';

const Writing = () => {
  const RELATED_BLOGS: { pageName: string; pageLink: string }[] = [
    {
      pageLink: '/blog-posts/why-are-spelling-activities-important',
      pageName: 'Blog | Spelling Activities',
    },
    {
      pageLink: '/blog-posts/what-is-text-structure',
      pageName: 'Blog | Text Structure',
    },
    {
      pageLink: '/blog-posts/sight-words-done-right',
      pageName: 'Blog | Sight Words',
    },
    {
      pageLink: '/blog-posts/what-are-blends',
      pageName: 'Blog | What are Blends',
    },
  ];

  const RELATED_WORKSHEETS: { worksheetsName: string; worksheetsLink: string }[] = [
    {
      worksheetsLink: '/writing-worksheets',
      worksheetsName: 'Writing',
    },
    {
      worksheetsLink: '/spelling-worksheets',
      worksheetsName: 'Spelling',
    },
    {
      worksheetsLink: '/grammar-worksheets',
      worksheetsName: 'Grammar',
    },
  ];

  return (
    <>
      <MetaData
        pageTitle="No Fuss Tutors | Online Writing Tutors"
        postTitle="Online Writing Tutors"
        description="Your Destination For Top Online Writing Tutors. Our mission is to make quality writing tutors affordable for everyone!"
        image={'https://nft-development.netlify.app/assets/webp/logo.png'}
        url={'https://nofusstutors.com/onling-writing-tutor'}
        keywords={[
          'online writing tutoring canada',
          'online writing tutoring new york',
          'online writing tutoring florida',
        ]}
      />
      <div className="writing-page">
        <Hero content={WritingHeaderContent} />
        <SellingPoint />
        <TutoringProgram content={TutoringProgramCards} header={TutoringProgramCardsHeader} />
        <Programs content={BeginnerProgramsInfo} sectionHeader={BeginnerProgramsInfoHeader} />
        <TutoringProgram content={AdvancedTutoringProgramCards} header={AdvancedTutoringProgramCardsHeader} />
        <Programs content={AdvancedProgramsInfo} sectionHeader={AdvancedProgramsInfoHeader} />
        <GoogleReview reviewContent={MathPageReview} />
        <Tutors />
        <ConsultationWidget source="form" />
        {
          <React.Fragment key={generateID()}>
            <aside className={'blog-page__side-content--mobile'}>
              <div className="blog-page__side-links">
                {StandardSubjectQuickLinks && (
                  <SideContent
                    links={[...RELATED_BLOGS, ...StandardSubjectQuickLinks.pages]}
                    worksheetsLinks={[...RELATED_WORKSHEETS, ...StandardSubjectQuickLinks.worksheets]}
                  />
                )}
              </div>
              <div className="blog-page__share-icons-wrapper">
                <ShareIcons
                  url="https://nofusstutors.com/online-writing-tutor"
                  facebookQuote="No Fuss Tutoring has a dedicated network of tutors to help your kids improve their grades!"
                />
              </div>
            </aside>
          </React.Fragment>
        }
      </div>
    </>
  );
};

export default Writing;
