import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import { useUserData } from '../../Hooks/useUserData';
import { useDisplayData } from '../../Hooks/useDisplayData';

import Modal from '.././Modal';
import { Link } from 'react-router-dom';

const AITutor: React.FC = () => {
  const { userData } = useUserData();
  const { displayData, hideModal, startBodyScroll, stopBodyScroll, setLoginType, showModal, showGameModal } =
    useDisplayData();
  const [message, setMessage] = useState('');
  const [canSendMessage, setCanSendMessage] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isURLCopied, setIsURLCopied] = useState(false);

  const [chatHistory, setChatHistory] = useState<{ role: string; message: string }[]>([]);
  const socket = useRef<any>(null);
  const messagesRef = useRef<HTMLUListElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [feedbackMessage, setFeedbackMessage] = useState('');

  const showFeedbackMessage = (message: string) => {
    setFeedbackMessage(message);
    // Optionally, clear the message after a delay
    setTimeout(() => {
      setFeedbackMessage('');
    }, 5000); // Clears the feedback message after 5 seconds
  };

  const isValidMessage = (message: string) => {
    // Trim message and check for minimum length
    return message.trim().length > 1;
  };

  useEffect(() => {
    let serverURL =
      process.env.NODE_ENV === 'production'
        ? 'https://ai-writing-tutor-9749bab8f194.herokuapp.com/'
        : 'http://localhost:3010';
    socket.current = io(serverURL);
    socket.current.on('message', (message: string) => {
      setChatHistory((prevChatHistory) => [...prevChatHistory, { role: 'Popcorn', message }]);
    });

    // Cleanup when component unmounts
    return () => socket.current.disconnect();
  }, []);

  useEffect(() => {
    // Scroll to the bottom of the messages
    if (messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  }, [chatHistory]);

  useEffect(() => {
    // Initial assistant message
    if (chatHistory.length === 0) {
      setChatHistory([
        {
          role: 'AI Tutor',
          message: 'Hi, how can I help you today?',
        },
      ]);
    }
  }, [chatHistory]);
  // Initialize messageCount state to zero
  const [messageCount, setMessageCount] = useState<number>(0);

  useEffect(() => {
    // Define a function to update message count
    const updateMessageCount = () => {
      const savedCount = localStorage.getItem('messageCount');
      const savedUserEmail = localStorage.getItem('messageUserEmail');
      const today = new Date().toLocaleDateString();

      // Verify if the count belongs to the current user and it's for today
      if (localStorage.getItem('countDate') === today && savedUserEmail === userData.user_data.email) {
        const count = savedCount ? parseInt(savedCount, 10) : 0;
        setMessageCount(count); // Update the message count state
      }
    };

    // Call the function to update message count
    updateMessageCount();
  }, [userData]); // Depend on userData to ensure the effect runs when userData updates

  const sendMessage = () => {
    if (!userData._id || userData._id === '') {
      setLoginType({ login: true, signup: false }, true);
      showModal();
      return;
    }

    if (!isValidMessage(message) || !canSendMessage) {
      showFeedbackMessage('Please enter a valid message.');
      return;
    }

    // Define message limits
    const messageLimit = userData.subscription_active ? 50 : 10;

    // Check if user has reached their limit
    if (messageCount >= messageLimit) {
      showFeedbackMessage(`You have reached your daily message limit of ${messageLimit} messages.`);
      return;
    }

    setIsLoading(true);
    setChatHistory((prevChatHistory) => [...prevChatHistory, { role: 'user', message }]);
    socket.current.emit('sendMessage', message, (error: string) => {
      if (error) {
        showFeedbackMessage(error);
      } else {
        const newCount = messageCount + 1;
        setMessageCount(newCount);
        const today = new Date().toLocaleDateString();
        localStorage.setItem('messageCount', newCount.toString());
        localStorage.setItem('messageUserEmail', userData.user_data.email); // Save user's email to verify user
        localStorage.setItem('countDate', today); // Save the current date
      }
      setIsLoading(false);
    });

    setMessage('');
    setCanSendMessage(false);
    setTimeout(() => {
      setCanSendMessage(true);
    }, 1000); // Cooldown period before allowing the next message
  };

  useEffect(() => {
    // Function to be called when the page is closed or refreshed
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      handleEndChat(); // Call your function to send chat history

      // Optional: Custom message for leaving page (not supported in most modern browsers for beforeunload)
      // e.preventDefault();
      // e.returnValue = ''; // Chrome requires returnValue to be set
    };

    // Add event listener for beforeunload
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [chatHistory]); // Ensure this effect runs when chatHistory changes

  const handleEndChat = () => {
    const userEmail = userData && userData.user_data ? userData.user_data.email : 'Guest';

    const payload = {
      chatHistory,
      email: 'matt@nofusstutors.com',
      userEmail: userEmail, // Include the user's email in the payload
    };

    // Determine the server URL based on the environment
    const serverURL =
      process.env.NODE_ENV === 'production'
        ? 'https://ai-writing-tutor-9749bab8f194.herokuapp.com/api/send-chat-history'
        : 'http://localhost:3010/api/send-chat-history';

    // Send the chat history to the server
    fetch(serverURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => console.log(data.message))
      .catch((error) => console.error('Error sending chat history:', error));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  const handleShareClick = () => {
    const currentURL = window.location.href;
    navigator.clipboard
      .writeText(currentURL)
      .then(() => {
        setIsURLCopied(true);
        setTimeout(() => {
          setIsURLCopied(false);
        }, 3000); // Reset the state after 3 seconds
      })
      .catch((error) => {
        console.error('Failed to copy URL to clipboard:', error);
      });
  };

  return (
    <div className="bg-ai-tutor">
      <h1 className="h1-ai">
        <span className="title-image"></span>Hi, I'm Popcorn!
      </h1>
      <div className="chat-actions">
        <button
          className={isURLCopied ? 'share-button copied-2' : 'share-button'}
          onClick={handleShareClick}
          disabled={isURLCopied}
        >
          {isURLCopied ? 'Copied URL, thanks!' : 'Tell Your Friends About Me!'}
        </button>
      </div>

      <div>
        <div className="chat-ai">
          <ul id="messages" ref={messagesRef}>
            {chatHistory.map((msg, index) => (
              <li key={index} className={msg.role === 'user' ? 'user-message right-align' : 'assistant-message'}>
                <b>{msg.role === 'user' ? 'You' : 'Popcorn'}:</b> {msg.message}
              </li>
            ))}

            {isLoading && (
              <li className="assistant-message">
                <div className="loading-animation">
                  <div className="loading-dot"></div>
                  <div className="loading-dot"></div>
                  <div className="loading-dot"></div>
                </div>
              </li>
            )}
          </ul>

          <form id="message-form" onSubmit={(e) => e.preventDefault()}>
            {feedbackMessage && (
              <div className="feedback-message-chat">
                <p>{feedbackMessage}</p>
              </div>
            )}

            <div className="message-limit-info">
              {/* Check if user is logged in */}
              {userData._id ? (
                <div className="message-limit-info--row">
                  <div className="message-limit-info--text">
                    You have used {messageCount} out of {userData.subscription_active ? 50 : 10} messages today.
                  </div>
                  {/* Show upgrade link if user is not a subscriber */}
                  {!userData.subscription_active && (
                    <Link to="/premium">
                      <div className="message-limit-upgrade-link">Upgrade Now</div>
                    </Link>
                  )}
                </div>
              ) : (
                // User is not logged in
                <div className="message-limit-info--text">
                  Please{' '}
                  <span
                    className="message-limit-info--link"
                    onClick={() => {
                      setLoginType({ login: true, signup: false });
                      showModal();
                    }}
                  >
                    log in
                  </span>{' '}
                  or{' '}
                  <span
                    className="message-limit-info--link"
                    onClick={() => {
                      setLoginType({ login: false, signup: true });
                      showModal();
                    }}
                  >
                    create a free account
                  </span>{' '}
                  to send Popcorn a message.
                </div>
              )}
            </div>

            <textarea
              id="message-input"
              ref={textareaRef}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Ask about our tutoring OR ask a homework question!"
            />
            <button
              type="button"
              onClick={() => {
                if (!userData._id || userData._id === '') {
                  setLoginType({ login: true, signup: false }, false);
                  showModal();
                } else {
                  setLoginType({ login: false, signup: false }, false); // Explicitly setting redirectToGame to false

                  showGameModal(false);
                  sendMessage();
                }
              }}
            >
              Send
            </button>
          </form>
        </div>
      </div>
      {displayData.showModal && (
        <Modal
          type={displayData.activeCard.type}
          title={displayData.activeCard.title}
          src={displayData.activeCard.src}
          webp={displayData.activeCard.webp}
          alt={displayData.activeCard.alt}
          shareUrl={displayData.activeCard.shareUrl}
          facebookQuote={displayData.activeCard.facebookQuote}
          description={displayData.activeCard.description}
          subject={displayData.activeCard.subject}
          grade={displayData.activeCard.grade}
          pdfDoc={displayData.activeCard.pdfDoc}
          link={displayData.activeCard.link}
          _id={displayData.activeCard._id}
        />
      )}
    </div>
  );
};

export default AITutor;
