import React from 'react';
import Hero from '../../Components/Partials/Hero';
import SellingPoints from '../../Components/EnglishPage/SellingPoints';
import SellingPointsReverse from '../../Components/EnglishPage/SellingPointsReverse';
import EnglishSkills from '../../Components/EnglishPage/EnglishSkills';
import PricingCards from '../../Components/EnglishPage/PricingCards';
import GoogleReview from '../../Components/Partials/GoogleReview';
import Tutors from '../../Components/Partials/TutorCards/Tutors';
import ConsultationWidget from '../../Components/Partials/ConsultationWidget';
import MetaData from '../../Components/MetaData';
import ShareIcons from '../../Components/Blog/ShareIcons';
import SideContent from '../../Components/Partials/SideContent';
import { EnglishHeaderContent, EnglishSkillsContent, MathPageReview, StandardSubjectQuickLinks } from '../../Models';
import { generateID } from '../../Utils';

const English = () => {
  const RELATED_BLOGS: { pageName: string; pageLink: string }[] = [
    {
      pageLink: '/blog-posts/my-9-year-old-cant-read',
      pageName: 'Blog | Struggling to Read',
    },
    {
      pageLink: '/blog-posts/my-kid-is-afraid-to-read-in-class',
      pageName: 'Blog | Reading in Class',
    },
    {
      pageLink: '/blog-posts/why-are-spelling-activities-important',
      pageName: 'Blog | Spelling Activities',
    },
    {
      pageLink: '/blog-posts/what-are-blends',
      pageName: 'Blog | What are Blends',
    },
  ];

  const RELATED_WORKSHEETS: { worksheetsName: string; worksheetsLink: string }[] = [
    {
      worksheetsLink: '/reading-worksheets',
      worksheetsName: 'Reading',
    },
    {
      worksheetsLink: '/sight-words-worksheets',
      worksheetsName: 'Sight Words',
    },
    {
      worksheetsLink: '/alphabet-worksheets',
      worksheetsName: 'Alphabet',
    },
    {
      worksheetsLink: '/phonics-worksheets',
      worksheetsName: 'Phonics',
    },
    {
      worksheetsLink: '/writing-worksheets',
      worksheetsName: 'Writing',
    },
    {
      worksheetsLink: '/spelling-worksheets',
      worksheetsName: 'Spelling',
    },
    {
      worksheetsLink: '/grammar-worksheets',
      worksheetsName: 'Grammar',
    },
  ];

  return (
    <>
      <MetaData
        pageTitle="No Fuss Tutors | Online English Tutors"
        postTitle="Online English Tutors"
        description="Your Destination For Top Online English Tutors. Our mission is to make quality english tutors affordable for everyone!"
        image={'https://nft-development.netlify.app/assets/webp/logo.png'}
        url={'https://nofusstutors.com/online-english-tutors'}
        keywords={['online english tutor canada', 'online english tutor new york', 'online english tutor florida']}
      />
      <div className="english-page">
        <Hero content={EnglishHeaderContent} />
        <SellingPoints />
        <SellingPointsReverse />
        <EnglishSkills content={EnglishSkillsContent} />
        <PricingCards />
        <GoogleReview reviewContent={MathPageReview} />
        <div className="english__tutors">
          <Tutors />
        </div>
        <ConsultationWidget source="form" />
        {
          <React.Fragment key={generateID()}>
            <aside className={'blog-page__side-content--mobile'}>
              <div className="blog-page__side-links">
                {StandardSubjectQuickLinks && (
                  <SideContent
                    links={[...RELATED_BLOGS, ...StandardSubjectQuickLinks.pages]}
                    worksheetsLinks={[...RELATED_WORKSHEETS, ...StandardSubjectQuickLinks.worksheets]}
                  />
                )}
              </div>
              <div className="blog-page__share-icons-wrapper">
                <ShareIcons
                  url="https://nofusstutors.com/online-english-tutor"
                  facebookQuote="No Fuss Tutoring has a dedicated network of tutors to help your kids improve their grades!"
                />
              </div>
            </aside>
          </React.Fragment>
        }
      </div>
    </>
  );
};

export default English;
