import { Resources } from './Resources';
import { NFTSubjectList } from './SubjectTypes';

export const docTypes = [
  { label: 'Assessments', value: 'Assessments' },
  { label: 'Worksheets', value: 'Worksheets' },
];

export const grades = [
  // { label: 'Grade', value: '', name: 'grade' },
  { label: 'Preschool', value: 'Preschool' },
  // { label: 'Junior Kindergarten', value: 'Junior Kindergarten' },
  { label: 'Kindergarten', value: 'Kindergarten' },
  { label: 'Grade 1', value: 'Grade 1' },
  { label: 'Grade 2', value: 'Grade 2' },
  { label: 'Grade 3', value: 'Grade 3' },
  { label: 'Grade 4', value: 'Grade 4' },
  { label: 'Grade 5', value: 'Grade 5' },
  { label: 'Grade 6', value: 'Grade 6' },
  { label: 'Grade 7', value: 'Grade 7' },
  { label: 'Grade 8', value: 'Grade 8' },
  { label: 'Grade 9', value: 'Grade 9' },
  { label: 'Grade 10', value: 'Grade 10' },
  { label: 'Grade 11', value: 'Grade 11' },
  { label: 'Grade 12', value: 'Grade 12' },
  // { label: 'College/University', value: 'College/University' },
];

export const subjectListArray = Object.entries(NFTSubjectList).map((subject) => {
  return { label: subject[1], value: subject[0] };
});

export const subjectOptions = Object.entries(NFTSubjectList).map((subject) => {
  return subject[0];
});

export const DEFAULT_SELECT_OPTION = {
  label: 'Subject',
  value: '',
};

export type FilterOptions = {
  docType?: string;
  grade?: string;
  subject?: string;
};

export type UpdatedFilterOptions = {
  docType: string;
  grade?: string;
  subject?: string;
  subcategory?: string;
};

export type ResourceContextValues = {
  filters: UpdatedFilterOptions;
  documents: Resources[];
  pageTitle: string;
  hasFetched: boolean;
};

export type SubjectSortedResources = {
  math: Resources[];
  reading: Resources[];
  writing: Resources[];
  science: Resources[];
  art: Resources[];
};

export const subcategoryArray = [
  'Addition',
  'Multiplication',
  'Subtraction',
  'Division',
  'Sight Words',
  'Spelling',
  'Alphabet',
  'Phonics',
  'Counting',
  'Fractions',
  'Grammar',
  'Number Sense',
  'Word Problems',
  'Biology',
];

export const validTimes = [
  '9:00 am',
  '9:30 am',
  '10:00 am',
  '10:30 am',
  '11:00 am',
  '11:30 am',
  '12:00 pm',
  '12:30 pm',
  '1:00 pm',
  '1:30 pm',
  '2:00 pm',
  '2:30 pm',
  '3:00 pm',
  '3:30 pm',
  '4:00 pm',
  '4:30 pm',
  '5:00 pm',
  '5:30 pm',
  '6:00 pm',
  '6:30 pm',
  '7:00 pm',
  '7:30 pm',
  '8:00 pm',
  '8:30 pm',
  '9:00 pm',
];

export const validDates = [
  new Date(new Date().setDate(new Date().getDate() + 7)),
  new Date(new Date().setDate(new Date().getDate() + 8)),
  new Date(new Date().setDate(new Date().getDate() + 9)),
  new Date(new Date().setDate(new Date().getDate() + 10)),
  new Date(new Date().setDate(new Date().getDate() + 11)),
  new Date(new Date().setDate(new Date().getDate() + 12)),
  new Date(new Date().setDate(new Date().getDate() + 13)),
  new Date(new Date().setDate(new Date().getDate() + 14)),
  new Date(new Date().setDate(new Date().getDate() + 15)),
  new Date(new Date().setDate(new Date().getDate() + 16)),
  new Date(new Date().setDate(new Date().getDate() + 17)),
  new Date(new Date().setDate(new Date().getDate() + 18)),
  new Date(new Date().setDate(new Date().getDate() + 19)),
  new Date(new Date().setDate(new Date().getDate() + 20)),
  new Date(new Date().setDate(new Date().getDate() + 21)),
  new Date(new Date().setDate(new Date().getDate() + 22)),
  new Date(new Date().setDate(new Date().getDate() + 23)),
  new Date(new Date().setDate(new Date().getDate() + 24)),
  new Date(new Date().setDate(new Date().getDate() + 25)),
  new Date(new Date().setDate(new Date().getDate() + 26)),
  new Date(new Date().setDate(new Date().getDate() + 27)),
  new Date(new Date().setDate(new Date().getDate() + 28)),
  new Date(new Date().setDate(new Date().getDate() + 29)),
  new Date(new Date().setDate(new Date().getDate() + 30)),
  new Date(new Date().setDate(new Date().getDate() + 31)),
  new Date(new Date().setDate(new Date().getDate() + 32)),
  new Date(new Date().setDate(new Date().getDate() + 33)),
  new Date(new Date().setDate(new Date().getDate() + 34)),
  new Date(new Date().setDate(new Date().getDate() + 35)),
];

export const ProgramGrades = [
  'Pre-K',
  'KG',
  'Grade 1',
  'Grade 2',
  'Grade 3',
  'Grade 4',
  'Grade 5',
  'Grade 6',
  'Grade 7',
  'Grade 8',
  'Grade 9',
  'Grade 10',
  'Grade 11',
  'Grade 12',
];
