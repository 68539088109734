import React from 'react';
import { Link } from 'react-router-dom';
import ModernArrowLeft from '../../assets/modern-arrow-left.svg';

type BackToPageProps = {
  route: string;
  location: string;
};

const BackToPage: React.FC<BackToPageProps> = ({ route, location }) => {
  return (
    <div className="back-arrow">
      <Link to={`${route}`} className="back-to-page">
        <img src={ModernArrowLeft} alt="Back to previous page" loading="lazy" className="modern-arrow-left" />
        <span>Back to {location}</span>
      </Link>
    </div>
  );
};

export default BackToPage;
