// ! selling points
export const EnglishTutoringInfo = [
  {
    number: '01',
    header: 'English Tutoring',
    info:
      'Reading and Writing are essential skills for success in many careers and disciplines today. Students must begin to acquire a strong foundation early on to achieve success in adult life.',
    findOutMore: 'Find out more about our reading and writing tutoring below!',
    img: '01.png',
    class: 'english-page__selling-points--image',
    alt: 'tutor and student writing',
    imgClass: 'english-page__01-img',
    readingBtn: 'Reading Tutoring',
    writingBtn: 'Writing Tutoring',
    readingBtnClass: 'english-page__btn english-page__btn--reading',
    writingBtnClass: 'english-page__btn english-page__btn--writing',
  },

  {
    number: '02',
    header: 'Why get an English tutor?',
    info:
      'Reading and Writing are essential skills for success in many careers and disciplines today. Students must begin to acquire a strong foundation early on to achieve success in adult life.',
    findOutMore: 'Find out more about our reading and writing tutoring below!',
    img: '02.png',
    class: 'english-page__selling-points--image',
    alt: 'tutor and student writing',
    imgClass: 'english-page__02-img',
  },
];

// ! skills cards
export const EnglishSkillsContent = [
  'Improvement in all coursework, not just language arts',
  'Preparation for standardized testing (SAT and ACT)',
  'Enhancing college and university entrance',
  'Providing a path to more career opportunities',
  'Critical thinking and comprehension skills',
  'Become a better communicator throughout life',
];

export const ReadingEnglishSkills = [
  'Improvement in all coursework, not just language arts',
  'Preparation for standardized testing (SAT and ACT)',
  'Providing a path to more career opportunities',
  'Critical thinking and comprehension skills',
];

// ! pricing cards
export const EnglishPagePricingCardInfo = [
  {
    grade: 'JK - Grade 3',
    header: 'Elementary School',
    price: '$34 USD',
    summary: `Elementary school students may be familiar with reading and writing but many haven't yet mastered the fundamentals. Our elementary school English tutoring is customized to fulfill your child's exact needs.`,
    img: 'elementary-english.png',
    alt: 'young student learning alphabets',
    class: 'pricing-cards__card-img',
  },

  {
    grade: 'Grade 4 - 8',
    header: 'Middle School',
    price: '$34 USD',
    summary: `Middle school students are expected to have a solid foundation when it comes to their reading and writing skills. With English help, students gain the skills they need to help build a solid foundation for high school success.`,
    img: 'middleschool-english.png',
    alt: 'middle school student studying on computer',
    class: 'pricing-cards__card-img',
  },

  {
    grade: 'Grade 9 - 12',
    header: 'High School',
    price: '$40 USD',
    summary: `As students improve their High school English, they also learn to think actively, acquire planning abilities, practice study skills and develop confidence, skills which they can rely on for years to come.`,
    img: 'highschool-english.png',
    alt: 'high school student studying on computer',
    class: 'pricing-cards__card-img',
  },
];
