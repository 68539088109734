import React, { useState, useEffect, useRef, useContext } from 'react';
import { ColorPalette } from './ColorPalette';
import { SocketContext } from './../../Context/WebsocketContext';

type Point = {
  x: number;
  y: number;
};

type Drawing = {
  color: string;
  width: number;
  points: Point[];
};

type CanvasProps = {
  color: string;
  lineWidth: number;
  colors: string[];
  roomId: string;
  currentUserId: string | null;
  turnUserId: string | null;
};

export const Canvas: React.FC<CanvasProps> = ({
  color: initialColor,
  lineWidth,
  roomId,
  currentUserId,
  turnUserId,
}) => {
  const [isDrawing, setIsDrawing] = useState<boolean>(false);
  const [drawing, setDrawing] = useState<Drawing>({ color: initialColor, width: lineWidth, points: [] });
  const [activeColor, setActiveColor] = useState<string>(initialColor);
  const [originalLineWidth, setOriginalLineWidth] = useState<number>(lineWidth);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [drawingHistory, setDrawingHistory] = useState<Drawing[]>([]);
  const [currentDrawingIndex, setCurrentDrawingIndex] = useState<number>(-1);

  const socket = useContext(SocketContext);

  const drawOtherUsersPaths = (
    ctx: CanvasRenderingContext2D,
    userId: string,
    paths: Point[],
    color: string,
    lineWidth: number,
  ) => {
    if (paths && paths.length) {
      ctx.beginPath();
      ctx.moveTo(paths[0].x, paths[0].y);
      paths.forEach((path) => {
        ctx.lineTo(path.x, path.y);
      });
      ctx.strokeStyle = color;
      ctx.lineWidth = lineWidth;
      ctx.stroke();
    }
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    if (ctx && canvas?.width && canvas?.height) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      setDrawing({ ...drawing, points: [] });
      setDrawingHistory([]);
      setCurrentDrawingIndex(-1);
    }
  };

  const emitClearCanvas = () => {
    if (socket) {
      socket.emit('clear-canvas', roomId);
    }
  };

  useEffect(() => {
    if (socket) {
      socket.on(
        'drawing',
        ({ userId, paths, color, lineWidth }: { userId: string; paths: Point[]; color: string; lineWidth: number }) => {
          if (Array.isArray(paths)) {
            const ctx = canvasRef.current?.getContext('2d');
            if (ctx) {
              drawOtherUsersPaths(ctx, userId, paths, color, lineWidth);
            }
          }
        },
      );
      socket.on('clear-canvas', () => {
        clearCanvas();
      });
    }
  }, [socket]);

  const handleSelectColor = (color: string) => {
    setActiveColor(color);
    handleDrawing([], '', '', () => {}); // add missing arguments
    setDrawing((prevState) => ({ ...prevState, color }));
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
    if (currentUserId !== turnUserId) return;
    setIsDrawing(true);
    const point = getTouchCoords(event);
    if (point) {
      setDrawing({ ...drawing, points: [...drawing.points, point] });
      const newHistory = drawingHistory.slice(0, currentDrawingIndex + 1);
      setDrawingHistory([...newHistory, drawing]);
      setCurrentDrawingIndex(currentDrawingIndex + 1);
    }
  };

  const isMouseEvent = (
    event: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>,
  ): event is React.MouseEvent<HTMLCanvasElement> => {
    return (event as React.MouseEvent<HTMLCanvasElement>).clientX !== undefined;
  };

  const handleMouseLeave = () => {
    setIsDrawing(false);
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
    if (isDrawing) {
      const canvas = canvasRef.current;
      const ctx = canvas?.getContext('2d');
      const rect = canvas?.getBoundingClientRect();

      if (ctx && rect && canvas?.width && canvas?.height && rect?.width && rect?.height) {
        const scaleX = canvas.width / rect.width;
        const scaleY = canvas.height / rect.height;

        let x = 0,
          y = 0;

        if (event.nativeEvent instanceof MouseEvent) {
          const mouseEvent = event.nativeEvent as MouseEvent;
          x = (mouseEvent.clientX - rect.left) * (scaleX || 1);
          y = (mouseEvent.clientY - rect.top) * (scaleY || 1);
        } else if (event.nativeEvent instanceof TouchEvent) {
          const touchEvent = event.nativeEvent as TouchEvent;
          const touch = touchEvent.touches[0];
          x = (touch.clientX - rect.left) * (scaleX || 1);
          y = (touch.clientY - rect.top) * (scaleY || 1);
        }

        const point = { x, y };

        if (drawing.points.length > 0) {
          // check if drawing.points is not empty
          ctx.beginPath();
          ctx.moveTo(drawing.points[drawing.points.length - 1].x, drawing.points[drawing.points.length - 1].y);
          ctx.lineTo(point.x, point.y);
          ctx.strokeStyle = drawing.color;
          ctx.lineWidth = drawing.width;
          ctx.stroke();
          setDrawing({ ...drawing, points: [...drawing.points, point] });

          if (isDrawing && socket) {
            // Emit the drawing object to the server
            socket.emit('drawing', {
              roomId: roomId,
              paths: [drawing.points[drawing.points.length - 1], point],
              color: drawing.color,
              lineWidth: drawing.width,
            });
          }
        }
      }
    }
  };

  const handleMouseUp = () => {
    setIsDrawing(false);
  };

  const getTouchCoords = (
    event: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>,
  ): Point | undefined => {
    const canvas = canvasRef.current;
    const rect = canvas?.getBoundingClientRect();
    if (!canvas || !rect) return undefined;

    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;

    let x, y;

    if (isMouseEvent(event)) {
      x = (event.clientX - rect.left) * scaleX;
      y = (event.clientY - rect.top) * scaleY;
    } else {
      const touch = event.touches[0];
      x = (touch.clientX - rect.left) * scaleX;
      y = (touch.clientY - rect.top) * scaleY;
    }

    return { x, y };
  };

  const handleEraser = () => {
    setOriginalLineWidth(drawing.width);
    setDrawing({ ...drawing, color: '#ffffff', width: lineWidth });
  };

  const handleDrawing = (
    paths: Point[],
    senderId: string,
    currentUserId: string,
    setReceivedPaths: React.Dispatch<React.SetStateAction<Point[]>>,
  ) => {
    if (socket && senderId !== currentUserId && setReceivedPaths) {
      setReceivedPaths((prevPaths: Point[]) => [...prevPaths, ...paths]);
      socket.emit('drawing', { roomId, paths });
    }
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const container = containerRef.current;

    if (container && canvas) {
      canvas.width = container.offsetWidth;
      canvas.height = container.offsetHeight;
    }

    const ctx = canvas?.getContext('2d');
    if (ctx) {
      ctx.lineCap = 'round';
      ctx.lineJoin = 'round';
    }

    setOriginalLineWidth(lineWidth);
  }, [lineWidth]);

  return (
    <div>
      {currentUserId === turnUserId && (
        <>
          <ColorPalette
            colors={[
              '#FF4136',
              '#FF851B',
              '#FFDC00',
              '#2ECC40',
              '#0074D9',
              '#7FDBFF',
              '#B10DC9',
              '#F1FAEE',
              '#111111',
              '#AAAAAA',
              '#FF80CC',
              '#754600',
            ]}
            activeColor={activeColor}
            onSelectColor={handleSelectColor}
          />
        </>
      )}

      <div className="ArtOff__draw-canvas" ref={containerRef}>
        <canvas
          ref={canvasRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onTouchStart={handleMouseDown}
          onTouchMove={handleMouseMove}
          onTouchEnd={handleMouseUp}
          onMouseLeave={handleMouseLeave}
          style={{
            border: '1px solid #333',
            borderRadius: '5px',
            boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.5)',
          }}
        />
      </div>

      {currentUserId === turnUserId && (
        <>
          <button
            className="clear-canvas-button"
            onClick={() => {
              clearCanvas();
              emitClearCanvas();
            }}
          >
            Clear Canvas
          </button>

          <button className="eraser-button" onClick={handleEraser}>
            Eraser
          </button>
        </>
      )}
    </div>
  );
};
