import React, { useState } from 'react';
import { DisplayProps } from '../Models';

export interface DisplayContextProps {
  displayData: DisplayProps;
  setDisplayData: (displayData: any) => void;
}

const DisplayContext = React.createContext({} as DisplayContextProps);

const DisplayContextProvider = (props: any) => {
  const [displayData, setDisplayData] = useState<DisplayProps>({
    showModal: false,
    activeCard: {
      type: 'Worksheets',
      title: '',
      src: '',
      webp: '',
      alt: '',
      shareUrl: '',
      facebookQuote: '',
      description: '',
      subject: [],
      pdfDoc: '',
      grade: [],
      link: '',
      _id: '',
    },
    showSignInModal: false,
    showCreateUserModal: true,
    showFAQModal: false,
    showGameModal: false,
    FAQInfo: '',
    redirectReload: false,
    redirectToGame: false,
  });

  const providerValue: DisplayContextProps = {
    displayData,
    setDisplayData,
  };

  return <DisplayContext.Provider value={providerValue}>{props.children}</DisplayContext.Provider>;
};

export { DisplayContext, DisplayContextProvider };
