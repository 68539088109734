import React, { useEffect, useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import Banner from '../Components/Partials/Banner';
import BackToPage from '../Components/Partials/BackToPage';
import ErrorHandler from '../Components/Partials/ErrorHandler';
import { useUserData } from '../Hooks/useUserData';
import { useResourceFilters } from '../Hooks/useResourceFilter';
import { GetBaseURL, generatedRoute, generatedLocation } from '../Utils';
import { ErrorProps, GradeOptions, Resources, subjectOptions } from '../Models';
interface MatchParams {
  documentID: string;
}

interface ComponentProps extends RouteComponentProps<MatchParams> {}

const EditDocumentDetails: React.FC<ComponentProps> = ({ match }) => {
  const { userData } = useUserData();
  const { resourceData } = useResourceFilters();
  const [resource, setResource] = useState<Resources | null>();
  const [error, setError] = useState<ErrorProps>({
    isActive: false,
    message: '',
    type: 'error',
  });

  useEffect(() => {
    (async () => {
      let documentRequest = await fetch(
        `${GetBaseURL()}/api/resources/singleResource/${match.params.documentID}?key=${process.env.REACT_APP_API_KEY}`,
      );

      let response = await documentRequest.json();
      if (documentRequest.status === 200) {
        setResource(response.resource);
      } else {
        setError({ isActive: true, message: response.message, type: 'error' });
      }
    })();
  }, [match]);

  const submitUpdatedDocumentInfo = async (e: any) => {
    e.preventDefault();
    let isValidRequest = { grades: true, subjects: true };
    let grades = e.target.grade.value.trim();
    let subjects = e.target.subject.value.trim();
    let subcategories = e.target.subcategories.value.trim();
    let tags = e.target.tags.value.trim();
    if (grades !== '') {
      grades = grades.split(',');
      for (const index in grades) {
        grades[index] = grades[index].trim();
        grades[index] = grades[index][0].toUpperCase() + grades[index].slice(1).toLowerCase();
        if (grades[index].includes(' ')) {
          grades[index] =
            grades[index].slice(0, grades[index].indexOf(' ') + 1) +
            grades[index][grades[index].indexOf(' ') + 1].toUpperCase() +
            grades[index].slice(grades[index].indexOf(' ') + 2).toLowerCase();
        }
        if (!GradeOptions.includes(grades[index])) {
          isValidRequest.grades = false;
          break;
        }
      }
    }
    if (subjects !== '') {
      subjects = subjects.split(',');
      for (const index in subjects) {
        subjects[index] = subjects[index].trim();
        subjects[index] = subjects[index][0].toUpperCase() + subjects[index].slice(1).toLowerCase();
        if (subjects[index].includes(' ')) {
          subjects[index] =
            subjects[index].slice(0, subjects[index].indexOf(' ') + 1) +
            subjects[index][subjects[index].indexOf(' ') + 1].toUpperCase() +
            subjects[index].slice(subjects[index].indexOf(' ') + 2).toLowerCase();
        }
        if (!subjectOptions.includes(subjects[index])) {
          isValidRequest.subjects = false;
          break;
        }
      }
    }
    if (subcategories !== '') {
      subcategories = subcategories.split(',');
      for (const index in subcategories) {
        subcategories[index] = subcategories[index].trim();
        subcategories[index] = subcategories[index][0].toUpperCase() + subcategories[index].slice(1).toLowerCase();
        if (subcategories[index].includes(' ')) {
          subcategories[index] =
            subcategories[index].slice(0, subcategories[index].indexOf(' ') + 1) +
            subcategories[index][subcategories[index].indexOf(' ') + 1].toUpperCase() +
            subcategories[index].slice(subcategories[index].indexOf(' ') + 2).toLowerCase();
        }
      }
    }
    if (tags !== '') {
      tags = tags.split(',');
      for (const index in tags) {
        tags[index] = tags[index].trim();
      }
    }

    const updatedDocumentInfo = {
      document_id: resource!._id,
      type: e.target.docType.value,
      description: e.target.description.value.trim() === '' ? resource!.description : e.target.description.value.trim(),
      subject: subjects === '' ? resource!.subject : subjects,
      grade: grades === '' ? resource!.grade : grades,
      tags: tags === '' ? resource!.tags : tags,
      subcategories: subcategories === '' ? resource!.subcategories : subcategories,
      admin: userData.user_data.email,
      priority: e.target.priority.value && e.target.priority.value !== '' ? Number(e.target.priority.value) : null,
    };

    if (isValidRequest.grades && isValidRequest.subjects) {
      let updateRequest = await fetch(
        `${GetBaseURL()}/api/resources/updateSingleResource?key=${process.env.REACT_APP_API_KEY}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedDocumentInfo),
        },
      );
      let response = await updateRequest.json();
      if (updateRequest.status === 200) {
        setError({ isActive: true, message: response.message, type: 'success' });
        setResource(response.resource);
      } else {
        setError({ isActive: true, message: response.message, type: 'error' });
      }
    } else {
      setError({
        isActive: true,
        message: `The ${!(isValidRequest.grades || isValidRequest.subjects) ? 'fields' : 'field'} ${
          !(isValidRequest.grades || isValidRequest.subjects)
            ? "'Grades' and 'Subjects' are"
            : !isValidRequest.grades
            ? "'Grades' is"
            : "'Subjects' is"
        } invalid. Please double-check the inputs and try again.`,
        type: 'error',
      });
    }
  };

  const resetError = () => {
    setError({
      isActive: false,
      message: '',
      type: 'error',
    });
  };

  return userData.user_data.email &&
    userData.user_data.email !== '' &&
    `${process.env.REACT_APP_NFT_ADMINS}`.includes(userData.user_data.email) ? (
    <main>
      <Banner lineOne={'Edit Document Attributes'} main imageName="worksheets_banner" alt="No Fuss Tutors banner" />

      <BackToPage
        route={`/${generatedRoute({ ...resourceData.filters })}`}
        location={generatedLocation(generatedRoute({ ...resourceData.filters }))}
      />

      {error.isActive && <ErrorHandler message={error.message} type={error.type} handler={resetError}></ErrorHandler>}
      {resource && (
        <form className="account" onSubmit={(e) => submitUpdatedDocumentInfo(e)}>
          <div className="account__header-container">
            <h1 className="account__header">Hello, {userData.user_data.full_name}!</h1>
            <p className="account__sub-header">
              Welcome to the document details page. Here you can update the details of the selected document.
            </p>
            <p className="account__sub-header">
              Admin access only. If you are seeing this page and do not work for NFT, please contact the dev team.
            </p>
          </div>

          <input
            className="account__input"
            placeholder={
              resource.priority || resource.priority === 0 ? `${resource.priority}` : 'Priority in List (0 = top)'
            }
            type="number"
            id="priority"
            name="priority"
            aria-label="priority"
          />

          <div className="sign-in-modal__checkbox-wrapper">
            <h4>Document Type</h4>
            <div className="sign-in-modal__checkbox-options">
              <div className="sign-in-modal__radio-wrapper">
                <input
                  type="radio"
                  id="docType1"
                  name="docType"
                  value="Worksheets"
                  defaultChecked={resource.type === 'Worksheets' ? true : false}
                  onClick={() => {
                    setResource((prev: any) => ({
                      ...prev,
                      type: 'Worksheets',
                    }));
                  }}
                />
                <label htmlFor="docType1">Worksheet</label>
              </div>
              <div className="sign-in-modal__radio-wrapper">
                <input
                  type="radio"
                  id="docType2"
                  name="docType"
                  value="Assessments"
                  defaultChecked={resource.type === 'Worksheets' ? false : true}
                  onClick={() => {
                    setResource((prev: any) => ({
                      ...prev,
                      type: 'Assessments',
                    }));
                  }}
                />
                <label htmlFor="docType2">Assessment</label>
              </div>
            </div>
          </div>
          <label htmlFor="title">
            Document Title (If you would like to change this, message the dev team and we'll do it internally)
          </label>
          <strong id="title">{resource.title}</strong>
          <br></br>
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            name="description"
            placeholder={resource.description}
            defaultValue={resource.description}
            aria-label="description"
            className="account__input"
          />
          <h4>
            For the following inputs, type each value separated by commas (e.g. to add "Addition" and "Word Problems" as
            subcategories, type "addition, word problems" or even "aDDiTioN,WoRD prOBlEmS").
          </h4>
          <label htmlFor="grade">Grades</label>
          <input
            type="text"
            id="grade"
            name="grade"
            placeholder={resource.grade.join(', ')}
            aria-label="grade"
            className="account__input"
          />
          <label htmlFor="subject">Subjects</label>
          <input
            type="text"
            id="subject"
            name="subject"
            placeholder={resource.subject.join(', ')}
            aria-label="subject"
            className="account__input"
          />
          <label htmlFor="subcategories">Subcategories</label>
          <input
            type="text"
            id="subcategories"
            name="subcategories"
            placeholder={resource.subcategories?.join(', ')}
            aria-label="description"
            className="account__input"
          />
          <label htmlFor="tags">Tags (case sensitive)</label>
          <input
            type="text"
            id="tags"
            name="tags"
            placeholder={resource.tags?.join(', ')}
            aria-label="tags"
            className="account__input"
          />

          <button
            data-cy="account__button--update"
            type="submit"
            className="account__button account__button--update"
            onClick={() => window.scrollTo(0, 515)}
          >
            Update
          </button>
        </form>
      )}
    </main>
  ) : (
    <Redirect to="/worksheets-for-kids"></Redirect>
  );
};

export default EditDocumentDetails;
