export interface NavDropdownProps {
  title: string;
  options: Array<{
    label: string;
    link: string;
    nested?: boolean;
    children?: Array<{
      label: string;
      link: string;
      nested?: boolean;
    }>;
  }>;
  handler: () => void;
  onBlur?: () => void;
  toggleDropdown?: () => void;
  closeNav?: () => void;
  link?: string;
  id?: string;
  toggleState?: boolean;
  responsive?: boolean;
}



export const Subjects = [
  {
    label: 'Elementary',
    link: 'https://www.nofusstutors.com/online-elementary-tutor' ,
    children: [
      { label: 'Math', link: 'https://www.nofusstutors.com/online-math-tutor' },
      { label: 'Reading', link: 'https://www.nofusstutors.com/online-reading-tutor' },
      { label: 'English', link: 'https://www.nofusstutors.com/online-english-tutor' },
      { label: 'Writing', link: 'https://www.nofusstutors.com/online-writing-tutor' },
      { label: 'Science', link: 'https://www.nofusstutors.com/online-science-tutor' },
    ],
  },
  {
    label: 'HighSchool',
    link: 'https://www.nofusstutors.com/online-high-school-tutor' ,
    children: [
      { label: 'Algebra', link: 'https://www.nofusstutors.com/online-algebra-tutor' },
      { label: 'Geometry', link: 'https://www.nofusstutors.com/online-geometry-tutor' },
      { label: 'Calculus', link: 'https://www.nofusstutors.com/online-calculus-tutor' },
      { label: 'Biology', link: 'https://www.nofusstutors.com/online-biology-tutor' },
      { label: 'Chemistry', link: 'https://www.nofusstutors.com/online-chemistry-tutor' },
      { label: 'Physics', link: 'https://www.nofusstutors.com/online-physics-tutor' },
      { label: 'EnglishLiterature', link: 'https://www.nofusstutors.com/online-english-literature-tutor' },
      { label: 'History', link: 'https://www.nofusstutors.com/online-history-tutor' },
    ],
  },
  {
    label: 'Language',link: 'https://www.nofusstutors.com/online-language-tutor',
    children: [

      { label: 'Spanish', link: 'https://www.nofusstutors.com/online-spanish-tutor' },
      { label: 'French', link: 'https://www.nofusstutors.com/online-french-tutor' },
      { label: 'ESL', link: 'https://www.nofusstutors.com/online-esl-tutor' },

      { label: 'Mandarin', link: 'https://www.nofusstutors.com/online-mandarin-tutor' },
{ label: 'German', link: 'https://www.nofusstutors.com/online-german-tutor' },
{ label: 'Italian', link: 'https://www.nofusstutors.com/online-italian-tutor' },
{ label: 'Russian', link: 'https://www.nofusstutors.com/online-russian-tutor' },
{ label: 'Arabic', link: 'https://www.nofusstutors.com/online-arabic-tutor' },
{ label: 'Korean', link: 'https://www.nofusstutors.com/online-korean-tutor' },
{ label: 'Hindi', link: 'https://www.nofusstutors.com/online-hindi-tutor' },

    ],
  },
  {
    label: 'TestPrep',
     link: 'https://www.nofusstutors.com/online-test-prep-tutor' ,
    children: [
      { label: 'SAT', link: 'https://www.nofusstutors.com/online-sat-tutor' },
      { label: 'ACT', link: 'https://www.nofusstutors.com/online-act-tutor' },
      { label: 'PrivateSchool', link: 'https://www.nofusstutors.com/online-act-tutor' },

    ],
  },
];



export const PhoneNumbers = [
  { label: 'CA: (213) 337-7441', link: 'tel:+2133377441' },
  { label: 'TO: (647) 370-6323', link: 'tel:+6473706323' },
  { label: 'FLA: (305) 590-5573', link: 'tel:+3055905573' },
];

export const Locations = [
  {
    
    label: 'Canada',
      
    link: 'https://www.nofusstutors.com/online-tutor-canada',
    children: [
  {
    label: 'Ontario',
    link: 'https://www.nofusstutors.com/online-tutor-ontario',
    nested: true
  },
  {
    label: 'BritishColumbia',
    link: 'https://www.nofusstutors.com/online-tutor-british-columbia',
    nested: true
  },
  {
    label: 'Quebec',
    link: 'https://www.nofusstutors.com/online-tutor-quebec',
    nested: true
  },
  {
    label: 'NovaScotia',
    link: 'https://www.nofusstutors.com/online-tutor-nova-columbia',
    nested: true
  },
  { label: 'Alberta', link: 'https://www.nofusstutors.com/online-tutor-alberta', nested: true },
  { label: 'Manitoba', link: 'https://www.nofusstutors.com/online-tutor-manitoba',  nested: true },
  {
    label: 'Saskatchewan',
    link: 'https://www.nofusstutors.com/online-tutor-saskatchewan',
    nested: true
  },
    ]},
  {
    label: 'USA',
    link: 'https://www.nofusstutors.com/online-tutor-usa',
    children: [
      
  {
    label: 'California',
    link: 'https://www.nofusstutors.com/online-tutor-california',
    nested: true
  },
  {
    label: 'Florida',
    link: 'https://www.nofusstutors.com/online-tutor-florida',
    nested: true
  },
  {
    label: 'NY',
    link: 'https://www.nofusstutors.com/online-tutor-new-york',
    nested: true
  },
  {
    label: 'Texas',
    link: 'https://www.nofusstutors.com/online-tutor-florida',
    nested: true
  },
  { label: 'Pennsylvania', link: 'https://www.nofusstutors.com/online-tutor-pennsylvania' },
  { label: 'Illinois', link: 'https://www.nofusstutors.com/online-tutor-illinois' },
  { label: 'Ohio', link: 'https://www.nofusstutors.com/online-tutor-ohio' },
  { label: 'Georgia', link: 'https://www.nofusstutors.com/online-tutor-georgia' },
  { label: 'NorthCarolina', link: 'https://www.nofusstutors.com/online-tutor-north-carolina' },
  { label: 'Michigan', link: 'https://www.nofusstutors.com/online-tutor-michigan' },
  { label: 'NewJersey', link: 'https://www.nofusstutors.com/online-tutor-new-jersey' },

]},  {
  label: ' ',
  link: 'https://www.nofusstutors.com/online-tutor-usa',
  children: [
{ label: 'Virginia', link: 'https://www.nofusstutors.com/online-tutor-virginia' },
{ label: 'Washington', link: 'https://www.nofusstutors.com/online-tutor-washington' },
{ label: 'Arizona', link: 'https://www.nofusstutors.com/online-tutor-arizona' },
{ label: 'Massachusetts', link: 'https://www.nofusstutors.com/online-tutor-massachusetts' },
{ label: 'Tennessee', link: 'https://www.nofusstutors.com/online-tutor-tennessee' },
{ label: 'Indiana', link: 'https://www.nofusstutors.com/online-tutor-indiana' },
{ label: 'Missouri', link: 'https://www.nofusstutors.com/online-tutor-missouri' },
{ label: 'Maryland', link: 'https://www.nofusstutors.com/online-tutor-maryland' },
{ label: 'Wisconsin', link: 'https://www.nofusstutors.com/online-tutor-wisconsin' },
{ label: 'Colorado', link: 'https://www.nofusstutors.com/online-tutor-colorado' },
{ label: 'Minnesota', link: 'https://www.nofusstutors.com/online-tutor-minnesota' },



]}
]
