import React from 'react';
import { LanguagesOfferedContent } from '../../Models';
import AWSImage from '../Partials/AWSImage';

const LanguagesOffered = () => {
  const { img, alt, content } = LanguagesOfferedContent;

  return (
    <div className="languages-offered">
      <AWSImage
        picClass="languages-offered__img"
        pngFile={img}
        documentPrefix="homepage/languages"
        imageAlt={alt}
        hasWebp
      />
      <div className="languages-offered__language-wrapper">
        {content.map((item, index) => {
          return (
            <div className="languages-offered__language" key={index}>
              <h3 className="languages-offered__heading">{item.heading}</h3>
              <p className="languages-offered__summary">{item.summary}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LanguagesOffered;
