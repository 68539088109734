import { GetBaseURL } from './URLUtils';

export const NormalizePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
  const x = event.target.value.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/);

  if (event.target.value.length < 1) {
    return x;
  }

  if (event.target.value.length !== 10) {
    return x;
  }

  event.target.value = '(' + x![1] + ') ' + x![2] + '-' + x![3];
};

// ! format phone number for display in app
export const FormatPhoneNumber = (str: string) => {
  const cleaned = ('' + str).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
};

export const addNumberSuffix = (str: string) => {
  if (str === '0') return str;
  if (parseInt(str) >= 4 && parseInt(str) <= 20) return (str += 'th');

  if (str.charAt(str.length - 1) === '1') return (str += 'st');
  else if (str.charAt(str.length - 1) === '2') return (str += 'nd');
  else if (str.charAt(str.length - 1) === '3') return (str += 'rd');
  else return (str += 'th');
};

export const SubscribeEmailList = async (email: string) => {
  const body = {
    api_key: `${process.env.REACT_APP_KLAVIYO_API_KEY}`,
    profiles: [{ email }],
  };

  const request = await fetch(`${GetBaseURL()}/api/leads/subscribeToKlaviyo?key=${process.env.REACT_APP_API_KEY}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  return request.status === 200 ? true : false;
};
