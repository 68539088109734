import React, { useRef, useState, useEffect } from 'react';
import { decrypt, encrypt, GetBaseURL, NormalizePhone, NumbersOnly, SendEmail, ValidateEmail } from '../Utils';
import { ErrorProps } from '../Models';
import { useCookies } from 'react-cookie';
import { useUserData } from '../Hooks/useUserData';
import { useDisplayData } from '../Hooks/useDisplayData';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import NewEncryption from '../Utils/NewEncryption';

type SignInModalProps = {
  HideModal: () => void;
  awsKey?: string;
  download?: (documentName: string) => void;
  documentName?: string;
  source?: string;
};

const SignInModal: React.FC<SignInModalProps> = ({ awsKey, HideModal, download, documentName, source }) => {
  const nameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const aTag = useRef<HTMLAnchorElement>(null);
  const submitBtn = useRef<HTMLButtonElement>(null);
  const [cookies, setCookie] = useCookies(['email']);
  const [downloadUrl, setDownloadUrl] = useState<null | string>(null);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [createAccount, setCreateAccount] = useState(true);
  const [error, setError] = useState<ErrorProps>({
    isActive: false,
    message: '',
    type: 'error',
  });
  const { userData, setUser } = useUserData();
  const { displayData, setLoginType, startBodyScroll, stopBodyScroll, showGameModal } = useDisplayData();
  useEffect(() => {
    (async () => {
      const request = await fetch(`${GetBaseURL()}/api/aws/s3?key=${process.env.REACT_APP_API_KEY}`, {
        method: 'POST',
        body: JSON.stringify({ bucket: 'resourcehub', key: awsKey }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const blob = await request.blob();
      const pdfURL = window.URL.createObjectURL(blob);

      setDownloadUrl(pdfURL);
    })();
  }, [awsKey]);

  useEffect(() => {
    if (displayData.showCreateUserModal || displayData.showCreateUserModal === undefined) {
      setCreateAccount(true);
    } else {
      setCreateAccount(false);
    }
  }, [displayData.showCreateUserModal]);

  const googleRegister = async (response: any) => {
    if (response.profileObj) {
      const request = await fetch(`${GetBaseURL()}/api/users/addUser?key=${process.env.REACT_APP_API_KEY}`, {
        method: 'POST',
        body: JSON.stringify({
          email: response.profileObj.email,
          tutorInterest: 'Maybe Later',
          name: response.profileObj.name,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (request.status === 200) {
        const user = await request.json();
        const createReadingAccountRequest = await fetch(
          `${process.env.REACT_APP_STUDY_PORTAL_URL}/api/users/register?key=${process.env.REACT_APP_API_KEY}`,
          {
            method: 'POST',
            body: JSON.stringify({
              account_type: 'parent',
              username: response.profileObj.email,
              email: response.profileObj.email,
              name: response.profileObj.name,
              device: window.innerWidth <= 600 ? 'mobile' : window.innerWidth <= 1024 ? 'tablet' : 'desktop',
            }),
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );
        const createReadingAccountResponse = await createReadingAccountRequest.json();
        if (createReadingAccountRequest.status === 200) {
          setUser(user.user);
          setCookie('email', encrypt(response.profileObj.email), { path: '/' });
          DownloadPDF();
          HideModal();
          startBodyScroll();
          if (download && documentName) {
            download(documentName);
          }
          if (displayData.redirectToGame) {
            setLoginType({ login: true, signup: false }, false);
            showGameModal(false);
            stopBodyScroll();
          }
        } else {
          setError({
            isActive: true,
            message: 'There was an error creating your No Fuss Readers account.',
            type: 'error',
          });
        }
      } else if (request.status === 500) {
        googleLogin(response);
      } else {
        const response = await request.json();
        setError({
          isActive: true,
          message: response.errorMessage,
          type: 'error',
        });
      }
    } else {
      setError({ isActive: true, message: 'Google authorization error. Please try again.', type: 'error' });
    }
  };

  const facebookRegister = async (response: any) => {
    if (response.email) {
      const request = await fetch(`${GetBaseURL()}/api/users/addUser?key=${process.env.REACT_APP_API_KEY}`, {
        method: 'POST',
        body: JSON.stringify({
          email: response.email,
          tutorInterest: 'Maybe Later',
          name: response.name,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (request.status === 200) {
        const user = await request.json();
        const createReadingAccountRequest = await fetch(
          `${process.env.REACT_APP_STUDY_PORTAL_URL}/api/users/register?key=${process.env.REACT_APP_API_KEY}`,
          {
            method: 'POST',
            body: JSON.stringify({
              account_type: 'parent',
              username: response.email,
              email: response.email,
              name: response.name,
              device: window.innerWidth <= 600 ? 'mobile' : window.innerWidth <= 1024 ? 'tablet' : 'desktop',
            }),
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );
        const createReadingAccountResponse = await createReadingAccountRequest.json();
        if (createReadingAccountRequest.status === 200) {
          setUser(user.user);
          setCookie('email', encrypt(response.email), { path: '/' });
          DownloadPDF();
          HideModal();
          startBodyScroll();
          if (download && documentName) {
            download(documentName);
          }
          if (displayData.redirectToGame) {
            setLoginType({ login: true, signup: false }, false);
            showGameModal(false);
            stopBodyScroll();
          }
        } else {
          setError({
            isActive: true,
            message: 'There was an error creating your No Fuss Readers account.',
            type: 'error',
          });
        }
      } else if (request.status === 500) {
        facebookLogin(response);
      } else {
        const response = await request.json();
        setError({
          isActive: true,
          message: response.errorMessage,
          type: 'error',
        });
      }
    } else {
      setError({ isActive: true, message: 'Facebook authorization error. Please try again.', type: 'error' });
    }
  };

  const googleLogin = async (response: any) => {
    if (response.profileObj) {
      const request = await fetch(`${GetBaseURL()}/api/login/oauthLogin?key=${process.env.REACT_APP_API_KEY}`, {
        method: 'POST',
        body: JSON.stringify({
          email: response.profileObj.email,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (request.status === 200) {
        const foundUser = await request.json();
        // const NFRAccountRequest = await fetch(
        //   `${process.env.REACT_APP_STUDY_PORTAL_URL}/api/users/login?key=${process.env.REACT_APP_API_KEY}`,
        //   {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify({ username: response.profileObj.email }),
        //   },
        // );
        // const NFRAccountResponse = await NFRAccountRequest.json();

        setUser(foundUser);
        setCookie('email', encrypt(foundUser.user_data.email), { path: '/' });
        DownloadPDF();
        HideModal();
        startBodyScroll();
        if (download && documentName) {
          download(documentName);
        }
        if (displayData.redirectToGame) {
          setLoginType({ login: true, signup: false }, false);
          showGameModal(false);
          stopBodyScroll();
        }
      } else {
        googleRegister(response);
      }
    } else {
      setError({ isActive: true, message: 'Google authorization error. Please try again.', type: 'error' });
    }
  };

  const facebookLogin = async (response: any) => {
    if (response.email) {
      const request = await fetch(`${GetBaseURL()}/api/login/oauthLogin?key=${process.env.REACT_APP_API_KEY}`, {
        method: 'POST',
        body: JSON.stringify({
          email: response.email,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (request.status === 200) {
        const foundUser = await request.json();
        // const NFRAccountRequest = await fetch(
        //   `${process.env.REACT_APP_STUDY_PORTAL_URL}/api/users/login?key=${process.env.REACT_APP_API_KEY}`,
        //   {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify({ username: response.email }),
        //   },
        // );
        // const NFRAccountResponse = await NFRAccountRequest.json();

        setUser(foundUser);

        setCookie('email', encrypt(foundUser.user_data.email), { path: '/' });
        DownloadPDF();
        HideModal();
        startBodyScroll();
        if (download && documentName) {
          download(documentName);
        }
        if (displayData.redirectToGame) {
          setLoginType({ login: true, signup: false }, false);
          showGameModal(false);
          stopBodyScroll();
        }
      } else {
        facebookRegister(response);
      }
    } else {
      setError({ isActive: true, message: 'Facebook authorization error. Please try again.', type: 'error' });
    }
  };

  const handleLogin = async (e: any) => {
    e.preventDefault();
    if (emailRef.current?.value && ValidateEmail(emailRef.current?.value as string) && passwordRef.current?.value) {
      const request = await fetch(`${GetBaseURL()}/api/login/userLogin?key=${process.env.REACT_APP_API_KEY}`, {
        method: 'POST',
        body: JSON.stringify({ email: emailRef.current.value, password: encrypt(passwordRef.current!.value) }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (request.status === 200) {
        const foundUser = await request.json();
        // const NFRAccountRequest = await fetch(
        //   `${process.env.REACT_APP_STUDY_PORTAL_URL}/api/users/login?key=${process.env.REACT_APP_API_KEY}`,
        //   {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify({ username: emailRef.current.value, password: encrypt(passwordRef.current.value) }),
        //   },
        // );
        // const NFRAccountResponse = await NFRAccountRequest.json();

        setUser(foundUser);
        setCookie('email', encrypt(foundUser.user_data.email), { path: '/' });
        DownloadPDF();
        HideModal();
        startBodyScroll();
        if (download && documentName) {
          download(documentName);
        }
        if (displayData.redirectToGame) {
          setLoginType({ login: true, signup: false }, false);
          showGameModal(false);
          stopBodyScroll();
        }
      } else {
        const response = await request.json();
        setError({
          isActive: true,
          message: response.errorMessage,
          type: 'error',
        });
      }
    } else {
      setError({
        isActive: true,
        message: 'Please enter a valid email and password.',
        type: 'error',
      });
    }
  };

  const handleRegister = async (e: any) => {
    e.preventDefault();

    if (emailRef.current?.value && ValidateEmail(emailRef.current?.value as string)) {
      const request = await fetch(`${GetBaseURL()}/api/users/addUser?key=${process.env.REACT_APP_API_KEY}`, {
        method: 'POST',
        body: JSON.stringify({
          email: emailRef.current?.value,
          tutorInterest: e.target.tutorInterest.value !== '' ? e.target.tutorInterest.value : 'Maybe Later',
          userRole: e.target.userRole.value !== '' ? e.target.userRole.value : 'Incomplete',
          name: nameRef.current?.value,
          phone: phoneRef.current!.value !== '' ? NumbersOnly(phoneRef.current!.value) : '',
          password: encrypt(passwordRef.current!.value),
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const response = await request.json();
      if (request.status === 200) {
        const createReadingAccountRequest = await fetch(
          `${process.env.REACT_APP_STUDY_PORTAL_URL}/api/users/register?key=${process.env.REACT_APP_API_KEY}`,
          {
            method: 'POST',
            body: JSON.stringify({
              account_type: 'parent',
              username: emailRef.current.value,
              email: emailRef.current.value,
              password: NewEncryption.encrypt(passwordRef.current!.value),
              device: window.innerWidth <= 600 ? 'mobile' : window.innerWidth <= 1024 ? 'tablet' : 'desktop',
            }),
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );
        const createReadingAccountResponse = await createReadingAccountRequest.json();
        if (createReadingAccountRequest.status === 200) {
          setUser(response.user);
          setCookie('email', encrypt(emailRef.current.value), { path: '/' });
          DownloadPDF();
          HideModal();
          startBodyScroll();
          if (download && documentName) {
            download(documentName);
          }
          if (displayData.redirectToGame) {
            setLoginType({ login: true, signup: false }, false);
            showGameModal(false);
            stopBodyScroll();
          }
        } else {
          setError({
            isActive: true,
            message: 'There was an error creating your No Fuss Readers account.',
            type: 'error',
          });
        }
      } else {
        setError({
          isActive: true,
          message: response.errorMessage,
          type: 'error',
        });
      }
    } else {
      setError({
        isActive: true,
        message: 'Please enter a valid email.',
        type: 'error',
      });
    }
  };

  const resetError = () => {
    setError({
      isActive: false,
      message: '',
      type: 'error',
    });
  };

  const DownloadPDF = () => {
    if (error.message === '' && aTag.current && submitBtn.current) {
      submitBtn.current.setAttribute('disabled', 'disabled');
      aTag.current.click();
    }
  };

  const toggleForgotPassword = () => {
    setForgotPassword((prev) => !prev);
  };

  const toggleCreateAccount = () => {
    setCreateAccount((prev) => !prev);
  };

  const sendEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    if (emailRef.current && ValidateEmail(emailRef.current.value as string)) {
      const email = emailRef.current.value;
      const request = await fetch(`${GetBaseURL()}/api/users/fetchUser?key=${process.env.REACT_APP_API_KEY}`, {
        method: 'POST',
        body: JSON.stringify({ email: email }),
        headers: { 'Content-Type': 'application/json' },
      });
      const res = await request.json();
      if (request.status === 200) {
        const message = `Please visit the following page to reset your password: ${
          process.env.REACT_APP_DOMAIN_NAME
        }/reset?id=${encrypt(email)}`;
        const subject = 'Password Reset Request for No Fuss Tutors';
        await SendEmail(message, email, subject);
        setError({
          isActive: true,
          message: 'Email sent! Please check your email for instructions on how to reset your password',
          type: 'success',
        });
      } else {
        setError({ isActive: true, message: res.error, type: 'error' });
      }
    } else {
      setError({ isActive: true, message: 'Please enter a valid email', type: 'error' });
    }
  };

  if (forgotPassword === false && createAccount === false) {
    return (
      // ! removed sign-in-modal class
      <div className="modal modal--sign-in">
        <div className="sign-in-modal__type-close">
          <span
            data-cy="signInModal__span--closeLogin"
            aria-label="close-window"
            onClick={() => {
              HideModal();
              startBodyScroll();
            }}
          >
            <i className="fas fa-times"></i>
          </span>
        </div>
        <form onSubmit={(e: React.FormEvent) => handleLogin(e)} className="sign-in-modal__form">
          <h2 data-cy="signInModal__h2--loginHeading" className="sign-in-modal__header">
            Log In
          </h2>
          <div className="error" id="sign-in-error">
            <span
              data-cy="signInModal__span--loginError"
              className={error.type === 'error' ? 'error-span' : 'success-span'}
              onClick={resetError}
            >
              {error.message}
            </span>
          </div>
          <input
            data-cy="signInModal__input--email"
            ref={emailRef}
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            aria-label="email"
            className="sign-in-modal__input sign-in-modal__input--email"
          />
          <input
            data-cy="signInModal__input--password"
            ref={passwordRef}
            type="password"
            id="password"
            name="password"
            placeholder="Password"
            aria-label="password"
            className="sign-in-modal__input sign-in-modal__input--password"
          />
          <p
            data-cy="signInModal__p--forgotPassword"
            className="sign-in-modal__forgot-password"
            onClick={toggleForgotPassword}
          >
            Forgot Password?
          </p>

          <button
            data-cy="signInModal__button--signIn"
            ref={submitBtn}
            type="submit"
            className="sign-in-modal__sign-in-btn"
          >
            Sign In
          </button>
          <p className="sign-in-modal__create-account">
            Not a member yet?{' '}
            <span
              className="sign-in-modal__create-account-link"
              onClick={() => {
                toggleCreateAccount();
                setLoginType({ login: false, signup: true }, displayData.redirectToGame);
              }}
            >
              Create an account
            </span>
          </p>
        </form>
        <h2 className="sign-in-modal__separator-text">
          <span>OR</span>
        </h2>

        <div className="sign-in-modal__social-sign-in-wrapper">
          {/* <FacebookLogin
            appId={`${process.env.REACT_APP_FB_ID}`}
            autoLoad={false}
            fields="name,email,picture"
            callback={facebookLogin}
            cssClass="sign-in-modal__facebook-login"
            icon="fa-facebook"
          /> */}
          <GoogleLogin
            clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}.apps.googleusercontent.com`}
            render={(renderProps) => (
              <button
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                className="sign-in-modal__google-login"
              >
                <i className="fab fa-google"></i>
                Sign in with Google
              </button>
            )}
            buttonText="Login"
            onSuccess={googleLogin}
            onFailure={googleLogin}
            cookiePolicy={'single_host_origin'}
          />
        </div>
      </div>
    );
  } else if (forgotPassword === true && createAccount === false) {
    return (
      <div className="modal modal--forgot-password">
        <div className="sign-in-modal__type-close">
          <span data-cy="signInModal__span--closeForgotPassword" aria-label="close-window" onClick={HideModal}>
            <i className="fas fa-times"></i>
          </span>
        </div>
        <form onSubmit={(e: React.FormEvent) => sendEmail(e)} className="sign-in-modal__forgot-password-wrapper">
          <h2 className="sign-in-modal__forgot-password-header">Forgot Your Password?</h2>
          <h5 className="sign-in-modal__forgot-password-reset">
            Enter your email address to receive instructions on resetting your password.
          </h5>
          <input
            data-cy="signInModal__input--email"
            type="email"
            placeholder="Email"
            ref={emailRef}
            className="sign-in-modal__input sign-in-modal__input--forgot-password-email"
          />

          <button
            data-cy="signInModal__button--sendEmail"
            ref={submitBtn}
            type="submit"
            className="sign-in-modal__sign-in-btn"
          >
            Send Email
          </button>
          <p
            className="sign-in-modal__back-to-sign-in"
            onClick={() => {
              toggleForgotPassword();
              setLoginType({ login: true, signup: false });
            }}
          >
            Back to Sign In
          </p>
        </form>
        <div className="error" id="sign-in-error">
          <span
            data-cy="signInModal__span--forgotPasswordError"
            className={error.type === 'error' ? 'error-span' : 'success-span'}
            onClick={resetError}
          >
            {error.message}
          </span>
        </div>
      </div>
    );
  } else {
    return (
      <div className="modal modal--create-account">
        <div className="sign-in-modal__type-close">
          <span
            data-cy="signInModal__span--closeSignup"
            aria-label="close-window"
            onClick={() => {
              HideModal();
              startBodyScroll();
            }}
          >
            <i className="fas fa-times"></i>
          </span>
        </div>
        <form onSubmit={(e: React.FormEvent) => handleRegister(e)} className="sign-in-modal__create-account-form">
          <h2
            data-cy="signInModal__h2--signupHeading"
            className="sign-in-modal__header sign-in-modal__header--create-account"
          >
            Create a free account {source === 'blog' && '(this download will not count towards your daily limit)'}
          </h2>
          <div className="error" id="sign-in-error">
            <span
              data-cy="signInModal__span--signupError"
              className={error.type === 'error' ? 'error-span' : 'success-span'}
              onClick={resetError}
            >
              {error.message}
            </span>
          </div>
          <input
            data-cy="signInModal__input--name"
            ref={nameRef}
            type="text"
            id="name"
            name="name"
            placeholder="Name"
            aria-label="name"
            className="sign-in-modal__create-account-input"
          />
          <input
            data-cy="signInModal__input--email"
            ref={emailRef}
            type="text"
            id="email"
            name="email"
            placeholder="Email"
            aria-label="email"
            className="sign-in-modal__create-account-input"
          />
          <input
            data-cy="signInModal__input--phone"
            ref={phoneRef}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => NormalizePhone(event)}
            type="text"
            id="phone"
            name="phone"
            placeholder="Phone number (optional)"
            aria-label="Phone number"
            className="sign-in-modal__create-account-input"
          />
          <input
            data-cy="signInModal__input--password"
            ref={passwordRef}
            type="password"
            id="password"
            name="password"
            placeholder="Create Password (required)"
            aria-label="password"
            className="sign-in-modal__create-account-input"
          />
          <div className="sign-in-modal__checkbox-wrapper">
            <h4>Interested in Booking a Tutor?</h4>
            <div className="sign-in-modal__checkbox-options">
              <div className="sign-in-modal__radio-wrapper">
                <input type="radio" id="tutorInterest1" name="tutorInterest" value="Yes" />
                <label htmlFor="tutorInterest1">Yes</label>
              </div>
              <div className="sign-in-modal__radio-wrapper">
                <input type="radio" id="tutorInterest2" name="tutorInterest" value="No" />
                <label htmlFor="tutorInterest2">No</label>
              </div>
              <div className="sign-in-modal__radio-wrapper">
                <input type="radio" id="tutorInterest3" name="tutorInterest" value="Maybe Later" />
                <label htmlFor="tutorInterest3">Maybe</label>
              </div>
            </div>
          </div>

          <div className="sign-in-modal__checkbox-wrapper">
            <h4>I am a...</h4>
            <div className="sign-in-modal__checkbox-options">
              <div className="sign-in-modal__radio-wrapper">
                <input type="radio" id="signup_student" name="userRole" value="Student" />
                <label htmlFor="signup_student">Student</label>
              </div>
              <div className="sign-in-modal__radio-wrapper">
                <input type="radio" id="signup_parent" name="userRole" value="Parent" />
                <label htmlFor="signup_parent">Parent</label>
              </div>
              <div className="sign-in-modal__radio-wrapper">
                <input type="radio" id="signup_tutor" name="userRole" value="Tutor" />
                <label htmlFor="signup_tutor">Teacher</label>
              </div>
            </div>
          </div>

          <button
            data-cy="signInModal__button--signIn"
            ref={submitBtn}
            type="submit"
            className="sign-in-modal__sign-in-btn sign-in-modal__sign-in-btn--create-account"
          >
            Create Account
          </button>
          <div className="sign-in-modal__flex-wrapper">
            <span className="sign-in-modal__already-member">Already a Member?</span>
            <p
              className="sign-in-modal__login-link sign-in-modal__login-link--create-account"
              onClick={() => {
                setLoginType({ login: true, signup: false });
              }}
            >
              Log In
            </p>
          </div>
        </form>
        <div className="sign-in-modal__social-sign-in-wrapper">
          {/* <FacebookLogin
            appId={`${process.env.REACT_APP_FB_ID}`}
            autoLoad={false}
            fields="name,email,picture"
            callback={facebookRegister}
            cssClass="sign-in-modal__facebook-login"
            icon="fa-facebook"
          /> */}
          <GoogleLogin
            clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}.apps.googleusercontent.com`}
            render={(renderProps) => (
              <button
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                className="sign-in-modal__google-login"
              >
                <i className="fab fa-google"></i>
                Sign up with Google
              </button>
            )}
            buttonText="Login"
            onSuccess={googleRegister}
            onFailure={googleRegister}
            cookiePolicy={'single_host_origin'}
          />
        </div>
        <p className="modal__newsletter-notice">
          * By downloading you are signing up to our newsletter. You can unsubscribe anytime.
        </p>
      </div>
    );
  }
};

export default SignInModal;
