import React, { useEffect, useState, useRef } from 'react';
import Hero from '../../Components/Partials/Hero';
import MetaData from '../../Components/MetaData';
import { OntarioPageContent, OntarioPageHeaderContent, OntarioPageQuickLinks } from '../../Models';
import { generateID } from '../../Utils';
import ShareIcons from '../Blog/ShareIcons';
import SideContent from '../Partials/SideContent';
import ConsultationWidget from '../Partials/ConsultationWidget';
import TorontoLocation from '../../Components/LocationPages/OntarioCities/TorontoLocation';
import { Link } from 'react-router-dom';
import OnlineReview from '../Home/OnlineReview';

const OntarioLocation: React.FC = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth < 1200 ? true : false);
  const [isLinkVisibleFromTop, setIsLinkVisibleFromTop] = useState(true);
  const [isLinkVisibleFromBottom, setIsLinkVisibleFromBottom] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const scrollHeightRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    //! scroll to top of page
    window.scrollTo(0, 0);
  }, []);

  window.addEventListener('scroll', () => {
    let lastKnownScrollPosition = window.scrollY;

    if (scrollHeightRef.current && lastKnownScrollPosition < 615) {
      setIsLinkVisibleFromTop(true);
    } else {
      setIsLinkVisibleFromTop(false);
    }
    if (scrollHeightRef.current && lastKnownScrollPosition > scrollHeightRef.current.scrollHeight - 815) {
      setIsLinkVisibleFromBottom(true);
    } else {
      setIsLinkVisibleFromBottom(false);
    }
  });

  window.addEventListener('resize', () => {
    setWidth(window.innerWidth);
    if (isMobileScreen && window.innerWidth > 1199) {
      setIsMobileScreen(false);
    }
    if (!isMobileScreen && window.innerWidth < 1200) {
      setIsMobileScreen(true);
    }
  });

  return (
    <>
      <MetaData
        pageTitle="No Fuss Tutors | Online Tutors in Ontario"
        postTitle="Online Tutors in Ontario"
        description="Your Destination For Top Online Tutors in Ontario. Our mission is to make quality math tutors affordable for everyone in Ontario!"
        image={'https://nft-development.netlify.app/assets/webp/logo.png'}
        url={'https://nofusstutors.com/online-tutor-canada'}
        keywords={['online math tutor ontario', 'online reading tutor ontario', 'online tutor ontario']}
      />
      <div className="ontario-location-page" ref={scrollHeightRef}>
        <Hero content={OntarioPageHeaderContent} />
        <div className="ontario-location-page__content-wrapper">
          {!isMobileScreen && (
            <aside
              className={
                isLinkVisibleFromTop
                  ? 'blog-page__side-content blog-page__side-content--fix-top'
                  : isLinkVisibleFromBottom
                  ? 'blog-page__side-content blog-page__side-content--fix-bottom location__side-content'
                  : 'blog-page__side-content location__side-content'
              }
              style={
                isLinkVisibleFromBottom
                  ? width < 1700
                    ? { marginLeft: '-0.5rem', bottom: '1rem' }
                    : { bottom: '1rem' }
                  : width < 1700
                  ? { marginLeft: '-0.5rem' }
                  : undefined
              }
            >
              <div className="blog-page__side-links">
                {OntarioPageQuickLinks && (
                  <SideContent links={OntarioPageQuickLinks.pages} worksheetsLinks={OntarioPageQuickLinks.worksheets} />
                )}
              </div>
              <div className="blog-page__share-icons-wrapper">
                <ShareIcons
                  url="welcome.nofusstutors.com"
                  facebookQuote="I read this great article on the No Fuss Tutors blog!"
                />
              </div>
            </aside>
          )}

          <section className="location-cities-links">
            <div className="container">
              <h2 className="cities-title">Cities We Tutor in Ontario</h2>
              <div className="cities-grid">
                <Link to="/toronto-tutoring" className="city-link">
                  Toronto
                </Link>
                {/* Repeat for other cities as needed */}
                <Link to="/ottawa-tutoring" className="city-link">
                  Ottawa
                </Link>
                <Link to="/mississauga-tutoring" className="city-link">
                  Mississauga
                </Link>
                <Link to="/hamilton-tutoring" className="city-link">
                  Hamilton
                </Link>
                <Link to="/kitchener-tutoring" className="city-link">
                  Kitchener
                </Link>
                <Link to="/london-ontario-tutoring" className="city-link">
                  London
                </Link>
                <Link to="/windsor-tutoring" className="city-link">
                  Windsor
                </Link>
                {/* Toronto neighborhoods */}
                <Link to="/north-york-tutoring" className="city-link">
                  North York
                </Link>
                <Link to="/scarborough-tutoring" className="city-link">
                  Scarborough
                </Link>
                <Link to="/etobicoke-tutoring" className="city-link">
                  Etobicoke
                </Link>
                <Link to="/markham-tutoring" className="city-link">
                  Markham
                </Link>
                <Link to="/vaughan-tutoring" className="city-link">
                  Vaughan
                </Link>
                <Link to="/richmond-hill-tutoring" className="city-link">
                  Richmond Hill
                </Link>
                <Link to="/brampton-tutoring" className="city-link">
                  Brampton
                </Link>
              </div>
            </div>
          </section>

          <ConsultationWidget source="form" />

          <div className="location-online-review-widget">
            <OnlineReview />
          </div>

          {OntarioPageContent.map((item, index) => {
            return (
              <div className="ontario-location-page__flex-wrapper" key={index}>
                <div className="ontario-location-page__heading-content-wrapper">
                  <h2 className="ontario-location-page__heading">{item.heading ? item.heading : ''}</h2>
                  <p className="ontario-location-page__content-text">{item.content ? item.content : ''}</p>
                </div>
              </div>
            );
          })}
        </div>
        <ConsultationWidget source="form" />
        {isMobileScreen && (
          <React.Fragment key={generateID()}>
            <aside className={'blog-page__side-content--mobile'}>
              <div className="blog-page__side-links">
                {OntarioPageQuickLinks && (
                  <SideContent links={OntarioPageQuickLinks.pages} worksheetsLinks={OntarioPageQuickLinks.worksheets} />
                )}
              </div>
              <div className="blog-page__share-icons-wrapper">
                <ShareIcons
                  url="welcome.nofusstutors.com"
                  facebookQuote="I read this great article on the No Fuss Tutors blog!"
                />
              </div>
            </aside>
          </React.Fragment>
        )}
      </div>
    </>
  );
};

export default OntarioLocation;
