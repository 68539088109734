import React, { useState, useEffect, useRef } from 'react';
import ErrorHandler from './ErrorHandler';
import { GetBaseURL } from '../../Utils';
import { useUserData } from '../../Hooks/useUserData';

type DownloadPDFProps = {
  awsKey: string;
  docType: 'Worksheets' | 'Assessments';
  resource_id: string | undefined;
};

const DownloadButton: React.FC<DownloadPDFProps> = ({ awsKey, docType, resource_id }) => {
  const { addDocument, DownloadDocument } = useUserData();
  const [url, setUrl] = useState<null | string>(null);
  const [willDownload, setWillDOwnload] = useState(false);
  const [isDisabled, setIsDIsabled] = useState(false);
  const [hadError, setHadError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const downloadBtn = useRef<HTMLButtonElement>(null);

  //effect that will run on the change of willDownload. If it is true, we should manually click a ref for the link to download
  useEffect(() => {
    if (willDownload && downloadBtn.current) {
      downloadBtn.current.click();
    }
  }, [willDownload]);

  const clickDownload = () => {
    AddDocumentToUserHistory();
  };

  const AddDocumentToUserHistory = async () => {
    const request_body: {
      docType: string;
      documentData: { date_downloaded: Date; resource_id: string };
    } = {
      docType: docType.toLowerCase(),
      documentData: { date_downloaded: new Date(Date.now()), resource_id: resource_id! },
    };
    addDocument(request_body.docType, request_body.documentData);
  };

  const FetchDocument = async () => {
    setIsDIsabled(true);
    const getDocument = await fetch(`${GetBaseURL()}/api/aws/s3?key=${process.env.REACT_APP_API_KEY}`, {
      method: 'POST',
      body: JSON.stringify({ bucket: 'resourcehub', key: awsKey }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (getDocument.status === 200) {
      const blob = await getDocument.blob();
      const pdfURL = window.URL.createObjectURL(blob);

      const SuccessfulDownload =
        resource_id !== undefined && (await DownloadDocument({ document_id: resource_id, type: docType }));

      if (SuccessfulDownload) {
        setUrl(pdfURL);
        setWillDOwnload(true);
      } else {
        setHadError(true);
        setErrorMessage('Unable to add document to download history');
      }
    } else {
      let response = await getDocument.json();

      setHadError(true);
      setErrorMessage(response.message);
    }
    setIsDIsabled(false);
  };

  if (url === null || willDownload === false) {
    return (
      <div>
        {hadError && errorMessage !== null && (
          <ErrorHandler
            type="error"
            message={errorMessage}
            handler={() => {
              setErrorMessage(null);
              setHadError(false);
            }}
          />
        )}
        <button
          className="modal__download download"
          disabled={isDisabled}
          onClick={FetchDocument}
          data-cy="downloadButton__button--fetchDocument"
        >
          Download
        </button>
      </div>
    );
  } else {
    return (
      <a href={url} download={awsKey}>
        <button
          ref={downloadBtn}
          className="modal__download download"
          data-cy="downloadButton__button--confirmDownload"
          onClick={clickDownload}
        >
          Download
        </button>
      </a>
    );
  }
};

export default DownloadButton;
