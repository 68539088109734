export const regularArtOffWords: string[] = [
  'Apple',
  'Baby',
  'Banana',
  'Beach',
  'Bear',
  'Bird',
  'Birdhouse',
  'Boat',
  'Boot',
  'Bottle',
  'Box',

  'Butterfly',
  'Cake',
  'Camera',
  'Candle',
  'Car',
  'Carrot',
  'Castle',
  'Cat',
  'Cheese',
  'Chicken',
  'Chocolate',
  'Church',
  'Circle',
  'Cloud',
  'Coffee',
  'Cookie',
  'Couch',
  'Cow',
  'Crab',
  'Crayon',
  'Cup',
  'Diamond',
  'Dog',
  'Dolphin',
  'Door',

  'Duck',
  'Earth',
  'Egg',

  'Eye',
  'Feather',
  'Fire',
  'Fish',
  'Flag',
  'Flower',
  'Flowerpot',
  'Fork',

  'Fruit',
  'Guitar',
  'Hammer',
  'Hand',
  'Hat',
  'Heart',
  'House',

  'Ice',
  'Ice cream',
  'Island',
  'Jacket',
  'Jellyfish',
  'Kangaroo',
  'Key',

  'Lamp',
  'Leaf',
  'Lemon',

  'Lollipop',
  'Mailbox',
  'Maple Syrup',
  'Map',
  'Mermaid',
  'Monkey',
  'Moon',
  'Moonlight',
  'Mountain',
  'Mouse',
  'Mushroom',
  'Nail',
  'Necklace',
  'Nest',
  'Night',
  'Phone',
  'Plane',
  'Snake',
  'Starfish',
  'Longboard',
  'Cage',

  'TV',
  'Tree',
  'Watermelon',
  'Bee',
  'Bicycle',
  'Birdcage',
  'Bookshelf',
  'Elf',
  'Sheep',
  'Bowl',
  'Bridge',
  'Butter',
  'Tuna',
  'Cactus',
  'River',
  'Giant Squid',

  'Carriage',
  'Caterpillar',
  'Chair',
  'Chalk',
  'Clock',
  'Compass',
  'Crown',
  'Curtain',
  'Daisy',
  'Desk',
  'Diamond',
  'Dice',

  'Dragonfly',
  'Drum',
  'Eagle',
  'Earring',
  'Eiffel Tower',
  'Pearl',
  'Peach',

  'Envelope',
  'Fence',
  'Ferris wheel',
  'Film',
  'Fireplace',
  'Fisherman',
  'Flamingo',
  'Flip flops',
  'Flute',
  'Fountain',
  'Frog',
  'Garden',
  'Gate',
  'Gingerbread',
  'Giraffe',
  'Globe',
  'Lobster',
  'Gorilla',

  'Hammock',
  'Harmonica',
  'Hat',
  'Heart',
  'Helicopter',

  'Hippo',
  'Hot air balloon',
  'Hourglass',
  'Houseboat',
  'Hummingbird',
  'Ice cream truck',
  'Creek',
  'Igloo',
  'Iron',
  'Jet',
  'Jigsaw',
  'Anchor',
  'Barn',
  'Basket',
  'Beehive',
  'Broom',
  'Campfire',
  'Cannon',
  'Castle',
  'Cherry',
  'Clover',
  'Cricket',
  'Crown',
  'Dinosaur',
  'Dragon',
  'Drums',

  'Fishing',
  'Flashlight',
  'Football',
  'Garbage',

  'Giraffe',

  'Gumball',

  'Helmet',
  'Horse',
  'Hotdog',

  'Jelly',

  'Laptop',
  'Lighthouse',
  'Mountain Lion',
  'Mermaid',
  'Microphone',
  'Napkin',
  'Owl',
  'Palm',
  'Parrot',
  'Zoo',
  'Zookeeper',

  'Pizza',
  'Rainbow',
  'Rooster',
  'Sandcastle',
  'Seashell',
  'Sea Lion',
  'Shark',
  'Skateboard',
  'Spider',
  'Sunflower',
  'Teapot',
  'Turtle',
  'Unicorn',
  'Coral Reef',
  'Wagon',
  'Waterfall',
  'Wheelbarrow',
  'Zebra',
  'Airplane',
  'Alarm clock',
  'Backpack',
  'Basketball',
  'Bed',
  'Bicycle',
  'Binoculars',
  'Bowtie',

  'Cactus',
  'Calculator',
  'Camel',
  'Campfire',
  'Candle',
  'Carrot',
  'Cartoon',
  'Caterpillar',
  'Chalkboard',
  'Cheeseburger',
  'Chimpanzee',
  'Christmas tree',
  'Pudding',

  'Coffee cup',
  'Comb',
  'Compass',
  'Computer',
  'Cookie',
  'Corn',
  'Cowboy hat',
  'Manta Ray',

  'Cucumber',
  'Curtains',

  'Donut',
  'Drill',
  'Dump truck',
  'Eagle',
  'Ear',
  'Eggplant',
  'Eiffel Tower',
  'Elephant',
  'Eyeglasses',
  'Fairy',
  'Fire station',
  'Fire truck',
  'Fishing rod',

  'Flip flops',
  'Flowerpot',
  'Football helmet',
  'Mango',
  'Garbage can',
  'Great White Shark',
  'Gingerbread man',
  'Gloves',
  'Grapes',
  'Green beans',
  'Hairbrush',
  'Hammer',
  'Hat',
  'Helicopter',
  'Hippo',
  'Hot air balloon',
  'Hot chocolate',
  'Hourglass',

  'Ice cream cone',

  'Insect',
  'Jeans',
  'Jewelry',
  'Juice box',
  'Jump rope',

  'Keyboard',
  'Kite',
  'Koala',
  'Ladybug',
  'Lawn mower',

  'Lion',
  'Lipstick',
  'Lollipop',
  'Magnifying glass',
  'Mailbox',
  'Map',
  'Megaphone',

  'Mosquito',
  'Mushroom',
  'Necktie',
  'Notebook',
  'Orange',
  'Oven',
  'Panda',
  'Penguin',
  'Piano',
  'Pineapple',
  'Pine Tree',
  'Pine Cone',
  'Popcorn',
  'Pumpkin',
  'Rain',
  'Raincoat',
  'Ring',
  'Roller skates',
  'Sandwich',
  'Scissors',
  'Sea turtle',
  'Shoe',
  'Soccer ball',
  'Spider web',
  'Spool of thread',
  'Squid',
  'Squirrel',
  'Stethoscope',
  'Scorpion',
  'Strawberry',
  'Suitcase',
  'Sun',
  'Sunset',
  'Swiss Cheese',
  'Fog',
  'Parasite',
  'Virus',
  'Bacteria',
  'Cereal',
  'Junkyard',
  'Earthquake',
  'Skipping Rope',
  'Knitting',
  'Hula Hoop',
  'Skeleton',
  'Vampire',
  'Ghost',
  'Frankenstein',
  'Swing',
  'Teeter Totter',
  'Statue',
  'Time Machine',
  'Olympics',
  'Home Plate',
  'Ninja',
  'Angry Birds',
  'Sunglasses',
  'Swordfish',
  'Sunscreen',
  'Black Hole',
  'Tired',
  'Worried',
  'Paperweight',
  'Antenna',
  'Algae',
  'Slug',
  'Moss',
  'Pizza Crust',
  'Lawnmower',
  'Tooth Fairy',
  'Hypnosis',
  'Trick-or-Treat',
  'Halloween',
  'Christmas',
  'Werewolf',
  'Easter',
  'Easter Bunny',
  'Oysters',
  'Clam',
  'Scientist',
  'Surgery',

  'Surfboard',
  'Surfing',
  'Sushi',
  'Sweater',
  'Swimming pool',
  'Teddy bear',
  'Telephone',
  'Tennis racket',
  'Tent',
  'Tiger',
  'Tire',
  'Toaster',
  'Toilet',
  'Tow truck',
  'Trombone',
  'Truck',

  'Washing machine',
  'Water bottle',
  'Whale',
  'Blue Whale',

  'Windmill',
  'Acrobat',
  'Robot',
  'Alpaca',
  'Ballet',
  'Bandana',
  'Barbecue',
  'Biscuit',
  'Bonsai',
  'Bracelet',
  'Bulldozer',
  'Cannonball',
  'Canoe',
  'Chandelier',
  'Chessboard',
  'Chisel',
  'Clothesline',
  'Coconut',
  'Dagger',
  'Deodorant',
  'Drumstick',
  'Earmuffs',
  'Easel',
  'Electricity',
  'Elevator',
  'Vacation',

  'Frisbee',
  'Gear',
  'Giraffe',
  'Glitter',
  'Grandfather',
  'Grenade',
  'Groceries',
  'Rocket Ship',
  'Elon Musk',

  'India',
  'Canada',
  'China',
  'Jail',

  'Hedgehog',
  'Hologram',
  'Iceberg',
  'Javelin',
  'Jetpack',
  'Ketchup',
  'Koala',
  'Laundry',
  'Lemonade',
  'Accordion',
  'Acorn',
  'Adventure',
  'Afro',
  'Backpack',
  'Balloon',
  'Beekeeper',
  'Belt',
  'Blender',
  'Blizzard',
  'Boardgame',
  'Bookcase',
  'Bowling',
  'Brick',
  'Broccoli',
  'Broomstick',
  'Bubblegum',
  'Bulldog',
  'Bumper car',

  'Candlestick',
  'Cape',
  'Carousel',
  'Cartwheel',
  'Cello',
  'Chimney',
  'Cinnamon',
  'Clown',
  'Coaster',
  'Cocktail',
  'Computer',
  'Concert',
  'Cookie jar',
  'Corkscrew',
  'Cowboy',
  'Crayfish',
  'Crop',
  'Cruise',
  'Cupcake',
  'Cutlery',
  'Aeroplane',
  'Plankton',
  'Apricot',
  'Avocado',
  'Bagpipes',
  'Biscotti',
  'Blueberry',
  'Bonfire',
  'Bowtie',
  'Bubble wrap',
  'Buckle',
  'Burrito',
  'Cactus',
  'Camel',
  'Caramel',
  'Carriage',
  'Cauliflower',
  'Chestnut',
  'Chihuahua',
  'Coconut Water',
  'Cowbell',
  'Croissant',
  'Santa',
  'Cucumber',
  'Cup holder',
  'Dagger',
  'Dandelion',
  'Doormat',
  'Dragonfruit',
  'Dumbbell',
  'Dumpster',
  'Reindeer',
  'Earmuffs',
  'Eggplant',
  'Espresso',
  'Fajita',
  'Firefly',
  'Foghorn',
  'Footstool',
  'tsunami',

  'Apron',
  'Aquarium',
  'Armchair',
  'Artichoke',
  'Asparagus',
  'Baguette',
  'Balcony',
  'Bamboo',
  'Bandana',
  'Barracuda',
  'Basketball',
  'Beaver',
  'Bicycle',
  'Billiards',
  'Blimp',
  'Blueberry pie',
  'Bobcat',
  'Boulevard',
  'Bracelet',
  'Bubble bath',
  'Bubblegum',
  'Buffalo',
  'Bulldozer',
  'Cadillac',
  'Calendar',
  'Campground',
  'Canary',
  'Cantaloupe',
  'Caribou',
  'Carpet',
  'Watch Tower',
  'Catacomb',
  'Cauldron',
  'Superman',
  'Batman',
  'Spiderman',
  'Ironman',
  'Thor',
  'Hulk',
  'Captain',
  'America',
  'Wonder Woman',
  'Orca',

  'Flash',
  'Green',
  'Lantern',
  'Aquaman',
  'Black Panther',
  'Octopus',
  'Puppet',
  'Kermit',
  'Miss Piggy',
  'The Muppets',

  'Doctor Strange',

  'Groot',

  'Joker',
  'Harley',

  'Wolverine',
  'Magneto',

  'Storm',

  'Cyclops',

  'Professor',
  'Volcano',

  'Harry Potter',
  'Marlin',

  'Hermione Granger',
  'Voldemort',
  'Ron Weasley',
  'Darth Vader',
  'Luke Skywalker',
  'Walrus',
  'Yoda',
  'Chewbacca',
  'Mario',
  'Luigi',
  'Pikachu',
  'Sonic',
  'Kirby',
  'Donkey Kong',
  'Link',
  'Zelda',
  'Safari',
  'Samus',
  'Yoshi',
  'Pac-Man',
  'Ms. Pac-Man',
  'Albert Einstein',
  'Queen Elizabeth II',
  'Abraham Lincoln',
  'Mozart',
  'William Shakespeare',
  'Elvis Presley',
  'Michael Jackson',
  'Jaws',
  'Napoleon Bonaparte',
  'Naruto',
];

export const easyArtOffWords: string[] = [
  'Cat',
  'Dog',
  'Hat',
  'Car',
  'Sun',
  'Bus',
  'Box',
  'Cup',
  'Map',
  'Pen',
  'Ant',
  'Bug',
  'Cow',
  'Bat',
  'Toy',
  'Pie',
  'Fly',
  'Fan',
  'Key',
  'Leg',
  'Lip',
  'Eye',
  'Jam',
  'Jug',
  'Cry',
  'Boy',
  'Car',
  'Can',
  'Cab',
  'Axe',
  'Arm',
  'Ear',
  'Egg',
  'Gum',
  'Gas',
  'Gem',
  'Hen',
  'Hug',
  'Ham',
  'Hot',
  'Ice',
  'Jaw',
  'Jet',
  'Joy',
  'Jar',
  'Jog',
  'Kid',
  'Lab',
  'Lap',
  'Leg',
  'Lid',
  'Log',
  'Mud',
  'Man',
  'Mat',
  'Mom',
  'Net',
  'Nap',
  'Nut',
  'Owl',
  'Oil',
  'One',
  'Pad',
  'Pan',
  'Pen',
  'Pig',
  'Pin',
  'Pot',
  'Rat',
  'Rug',
  'Run',
  'Sad',
  'Saw',
  'Sea',
  'Sit',
  'Sky',
  'Sun',
  'Tap',
  'Toe',
  'Toy',
  'Two',
  'Van',
  'Wig',
  'Win',
  'Wet',
  'Web',
  'Zip',
  'Zoo',
  'Ball',
  'Bear',
  'Book',
  'Card',
  'Cat',
  'Dog',
  'Duck',
  'Ear',
  'Egg',
  'Fish',
  'Foot',
  'Frog',
  'Hand',
  'Hat',
  'Ice',
  'Leaf',
  'Lion',
  'Moon',
  'Mouth',
  'Neck',
  'Nose',
  'Pear',
  'Rain',
  'Ring',
  'Rose',
  'Shoe',
  'Star',
  'Tree',
  'Wave',
  'Wind',
  'Baby',
  'Blue',
  'Bone',
  'Bowl',
  'Cake',
  'Corn',

  'Crab',
  'Doll',
  'Door',
  'Drum',
  'Eyes',
  'Fire',
  'Flag',
  'Fork',
  'Gold',
  'Grin',
  'Hair',
  'Horn',
  'King',
  'Kite',
  'Lamp',
  'Lock',

  'Milk',

  'Park',
  'Pink',
  'Pool',
  'Rope',
  'Sock',
  'Song',
  'Spoon',
  'Spot',
  'Star',
  'Swan',
  'Taco',
  'Tail',
  'Tent',
  'Tie',
  'Time',

  'Wand',
  'Worm',

  'Zoo',
  'Boat',
  'Bomb',
  'Crab',

  'Duck',
  'Ear',
  'Fire',
  'Fist',
  'Flag',
  'Flower',
  'Frog',
  'Game',
  'Grapes',
  'Hair',
  'Horn',
  'Idea',
  'Kite',
  'Leaf',
  'Lips',
  'Lock',
  'Maze',
  'Moon',
  'Need',
  'Net',
  'Nose',

  'Pear',

  'Pick',
  'Pig',
  'Rice',
  'Ring',
  'Rock',
  'Rose',
  'Salt',
  'Shoe',
  'Snow',
  'Sock',
  'Star',
  'Stop',
  'Tail',
  'Tent',
  'Tick',
  'Tire',
  'Tree',
  'Vest',
  'Wall',
  'Wave',
  'Wind',
  'Apple',
  'Bread',
  'Chair',
  'Dress',

  'Flower',
  'Grape',
  'House',
  'Igloo',
  'Juice',
  'Kite',
  'Lemon',
  'Mouse',

  'Orange',
  'Pants',

  'Ruler',
  'Shirt',
  'Table',
];
