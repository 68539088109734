import { addNumberSuffix } from './FormUtils';

export const FormatGrades = (gradeArray: string[]) => {
  const withSuffixesAndValues = gradeArray.map((currentGrade) => {
    let grade = currentGrade.includes('Grade') ? addNumberSuffix(currentGrade.split(' ')[1]) : currentGrade;
    let value =
      grade === 'Preschool'
        ? -1
        : grade === 'Kindergarten'
        ? 0
        : !isNaN(Number(grade.slice(0, 2)))
        ? Number(grade.slice(0, 2))
        : Number(grade[0]);
    return { grade, value };
  });
  const sortedGrades = withSuffixesAndValues.sort((first, second) => first.value - second.value);
  return sortedGrades.length > 1
    ? `${sortedGrades[0].grade} - ${sortedGrades[sortedGrades.length - 1].grade}`
    : sortedGrades[0].grade;
};

export const FormatGradeWithEr = (grade?: number) => {
  return grade === -1 ? 'preschooler' : grade === 0 ? 'kindergartener' : `${addNumberSuffix(`${grade}`)} grader`;
};

export const GradeToNumberOrString = (grade: number | string) => {
  if (typeof grade === 'number') {
    switch (grade) {
      case -1:
        return 'Preschool';
      case 0:
        return 'Kindergarten';
      case 1:
        return 'One';
      case 2:
        return 'Two';
      case 3:
        return 'Three';
      case 4:
        return 'Four';
      case 5:
        return 'Five';
      case 6:
        return 'Six';
      case 7:
        return 'Seven';
      case 8:
        return 'Eight';
      case 9:
        return 'Nine';
      case 10:
        return 'Ten';
      case 11:
        return 'Eleven';
      case 12:
        return 'Twelve';
      default:
        return null;
    }
  } else if (typeof grade === 'string') {
    switch (grade.toLowerCase()) {
      case 'preschool':
        return -1;
      case 'kindergarten':
        return 0;
      case 'one':
        return 1;
      case 'two':
        return 2;
      case 'three':
        return 3;
      case 'four':
        return 4;
      case 'five':
        return 5;
      case 'six':
        return 6;
      case 'seven':
        return 7;
      case 'eight':
        return 8;
      case 'nine':
        return 9;
      case 'ten':
        return 10;
      case 'eleven':
        return 11;
      case 'twelve':
        return 12;
      default:
        return null;
    }
  } else {
    return null;
  }
};
