export enum NFTSubjectList {
  // English = 'English',
  Math = 'Math',
  Reading = 'Reading',
  Writing = 'Writing',
  Science = 'Science',
  Art = 'Art',
  'Test Prep' = 'Test Prep',
  // GeneralScience = 'Science',
  // History = 'History',
  // AdvancedFunctions = 'Advanced Functions',
  // Calculus = 'Calculus',
  // DataManagement = 'Data Management',
  // Biology = 'Biology',
  // Physics = 'Physics',
  // Chemistry = 'Chemistry',
  // SAT = 'SAT (College Admission Exam)',
  // AdmissionsTest = 'Admissions Test',
  // FrenchCore = 'French (Core) - Not Immersion',
  // FrenchImmersion = 'French Immersion (Fluent)',
  // Italian = 'Italian',
  // French = 'French',
  // Spanish = 'Spanish',
  // Economics = 'Economics',
  // Accounting = 'Accounting',
  // ComputerScience = 'Computer Science',
  // Geography = 'Geography',
  // Sociology = 'Sociology',
  // Philosophy = 'Philosophy',
  // Psychology = 'Psychology',
  // Anthropology = 'Anthropology',
}
