import { UpdatedFilterOptions } from '../Models';

export const MetadataKeywordsFromFilters = (filters: UpdatedFilterOptions) => {
  let keywords: string[] = [];

  let docType =
    filters.docType.toLowerCase() === 'assessments' || filters.docType.toLowerCase() === 'worksheets'
      ? filters.docType
      : 'Worksheets';

  filters.grade !== undefined &&
    filters.subject === undefined &&
    filters.subcategory === undefined &&
    keywords.push(`${filters.grade} ${docType}`, `Printable ${filters.grade} ${docType}`) &&
    filters.docType.toLowerCase() !== 'assessments' &&
    keywords.push(`${filters.grade} Printables`); //Just Grade is Valid

  filters.grade === undefined &&
    filters.subject !== undefined &&
    filters.subcategory === undefined &&
    keywords.push(`${filters.subject} ${docType}`, `Printable ${filters.subject} ${docType}`) &&
    filters.docType.toLowerCase() !== 'assessments' &&
    keywords.push(`${filters.subject} Printables`); //Just Subject is Valid

  filters.grade === undefined &&
    filters.subject === undefined &&
    filters.subcategory !== undefined &&
    keywords.push(`${filters.subcategory} ${docType}`, `Printable ${filters.subcategory} ${docType}`) &&
    filters.docType.toLowerCase() !== 'assessments' &&
    keywords.push(`${filters.subcategory} Printables`); //Just Subcategory is Valid

  filters.grade === undefined &&
    filters.subject !== undefined &&
    filters.subcategory !== undefined &&
    keywords.push(
      `${filters.subject} ${filters.subcategory} ${docType}`,
      `Printable ${filters.subject} ${filters.subcategory} ${docType}`,
    ) &&
    filters.docType.toLowerCase() !== 'assessments' &&
    keywords.push(`${filters.subject} ${filters.subcategory} Printables`); //Subject and Subcategory are Valid

  filters.grade !== undefined &&
    filters.subject !== undefined &&
    filters.subcategory === undefined &&
    keywords.push(
      `${filters.grade} ${filters.subject} ${docType}`,
      `Printable ${filters.grade} ${filters.subject} ${docType}`,
    ) &&
    filters.docType.toLowerCase() !== 'assessments' &&
    keywords.push(`${filters.grade} ${filters.subject} Printables`); //Grade and Subject are Valid

  filters.grade !== undefined &&
    filters.subject === undefined &&
    filters.subcategory !== undefined &&
    keywords.push(
      `${filters.grade} ${filters.subcategory} ${docType}`,
      `Printable ${filters.grade} ${filters.subcategory} ${docType}`,
    ) &&
    filters.docType.toLowerCase() !== 'assessments' &&
    keywords.push(`${filters.grade} ${filters.subcategory} Printables`); //Grade and Subcategory are Valid

  filters.grade !== undefined &&
    filters.subject !== undefined &&
    filters.subcategory !== undefined &&
    keywords.push(
      `${filters.grade} ${filters.subject} ${filters.subcategory} ${docType}`,
      `Printable ${filters.grade} ${filters.subject} ${filters.subcategory} ${docType}`,
    ) &&
    filters.docType.toLowerCase() !== 'assessments' &&
    keywords.push(`${filters.grade} ${filters.subject} ${filters.subcategory} Printables`); //Grade, Subject, and Subcategory are Valid

  filters.grade &&
    filters.grade.toLowerCase() === 'kindergarten' &&
    filters.docType.toLowerCase() === 'worksheets' &&
    filters.subcategory === undefined &&
    filters.subject === undefined &&
    keywords.push('Worksheets for Kindergarten'); //Kindergarten worksheets get "worksheets for kindergarten". Highly specified

  return keywords;
};

export const MetadataKeywordsFromTags = (tags: string[]) => {
  if (tags.length === 0) {
    return ['Worksheets for kids', 'Grade School Worksheets', 'Middle School Worksheets', 'High School Worksheets'];
  } else {
    return tags;
  }
};

export const MetadataDescriptionfromFilters = (filters: UpdatedFilterOptions) => {
  let doctype = filters.docType;
  let grade = filters.grade ? ` ${filters.grade}` : '';
  let age =
    filters.grade?.includes('9') ||
    filters.grade?.includes('10') ||
    filters.grade?.includes('11') ||
    filters.grade?.includes('12')
      ? ' for high school'
      : filters.grade?.includes('8') || filters.grade?.includes('7') || filters.grade?.includes('6')
      ? ' for middle school'
      : filters.grade === undefined
      ? ''
      : ' from a young age';
  const subject = filters.subject ? `${filters.subject} ` : '';

  return `With our ${subject}${doctype}${grade !== '' ? ' for' : ''}${grade}${
    grade !== '' ? ',' : ''
  } give your child a boost${age}, positioning them to be a top ${subject}student. Visit to download our${grade} ${subject}${doctype}!`;
};

export const MetadataTitlefromFilters = (filters: UpdatedFilterOptions) => {
  if (Object.keys(filters).length === 1) {
    return `${filters.docType} for Kids | Beautifully Designed Modern ${filters.docType}`;
  } else if (filters.subcategory !== undefined) {
    return `${filters.docType} for ${filters.subcategory} | Beautifully Designed Modern ${filters.docType}`;
  } else if (filters.subject !== undefined) {
    return `${filters.docType} for ${filters.subject} | Beautifully Designed Modern ${filters.docType}`;
  } else if (filters.grade !== undefined) {
    return `${filters.docType} for ${filters.grade} | Beautifully Designed Modern ${filters.docType}`;
  } else {
    return `${filters.docType} for Kids | Beautifully Designed Modern ${filters.docType}`;
  }
};
