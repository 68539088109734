import React from 'react';

interface SearchInputProps {
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
}

const SearchInput: React.FC<SearchInputProps> = ({ setSearchText }) => {
  return (
    <div className="search-input-container">
      <i
        style={{
          color: '#333',
          fontSize: '1.5rem',
        }}
        className="fa fa-search"
      ></i>
      <input
        className="search-input"
        type="text"
        placeholder="Search Worksheets"
        onChange={(e) => setSearchText(e.target.value)}
      />
    </div>
  );
};

export default SearchInput;
