// ! subject cards
import languageIconWebp from '../../assets/home/icons/icon_language.webp';
import languageIcon from '../../assets/home/icons/icon_language.png';
import mathIconWebp from '../../assets/home/icons/icon_math.webp';
import mathIcon from '../../assets/home/icons/icon_math.png';
import scienceIconWebp from '../../assets/home/icons/icon_science.webp';
import scienceIcon from '../../assets/home/icons/icon_science.png';
import englishIconWebp from '../../assets/home/icons/icon_reading.webp';
import englishIcon from '../../assets/home/icons/icon_english.png';
import moreIconWebp from '../../assets/home/icons/icon_more.webp';
import moreIcon from '../../assets/home/icons/icon_more.png';
import readingIconWebp from '../../assets/home/icons/ReadingIcon.webp';
import readingIcon from '../../assets/home/icons/ReadingIcon.png';

//! pricing cards
import elementaryIconWebp from '../../assets/home/icons/elementary.webp';
import elementaryIcon from '../../assets/home/icons/elementary.png';
import middleSchoolIconWebp from '../../assets/home/icons/middleschool.webp';
import middleSchoolIcon from '../../assets/home/icons/middleschool.png';
import highSchoolIconWebp from '../../assets/home/icons/highschool.webp';
import highSchoolIcon from '../../assets/home/icons/highschool.png';

//!Location Images
import bcImg from '../../assets/home/mins/bc.png';
import bcWebp from '../../assets/home/webp/bc.webp';
import ontarioImg from '../../assets/home/mins/Ontario.png';
import ontarioWebp from '../../assets/home/webp/Ontario.webp';
import californiaImg from '../../assets/home/mins/California.png';
import californiaWebp from '../../assets/home/webp/California.webp';
import floridaImg from '../../assets/home/mins/Florida.png';
import floridaWebp from '../../assets/home/webp/Florida.webp';
import newYorkImg from '../../assets/home/mins/New-york.png';
import newYorkWebp from '../../assets/home/webp/New-york.webp';

// ! your personalized learning journey
export const JourneyInfo = [
  {
    number: 1,
    header: 'Place a request',
    content: 'Tell us what you need help with and we will hand pick the online tutor that best fits with your child.',
  },
  {
    number: 2,
    header: 'Get the most qualified tutors',
    content:
      "We'll connect you with a tutor who is most qualified for your needs and has worked with kids just like yours.",
  },
  {
    number: 3,
    header: 'Watch your kids improve!',
    content:
      "We don't stop at finding you a perfect tutor, we closely monitor and manage lessons to ensure your child is making progress.",
  },
];

// ! advanced e-learning with lesson space
export const AdvancedLearningInfo = [
  'Expert notes & upload worksheets',
  'Smooth writing',
  'Infinite space & whiteboards',
  'Audio, video & collaborative whiteboard',
  'Easy to use for all ages',
];

// ! subject cards
export const SubjectCardInfo = [
  {
    subject: 'Writing',
    webpIcon: englishIconWebp,
    icon: englishIcon,
    link: '/online-writing-tutor',
  },
  {
    subject: 'Reading',
    webpIcon: readingIconWebp,
    icon: readingIcon,
    link: '/online-reading-tutor',
  },
  {
    subject: 'Math',
    webpIcon: mathIconWebp,
    icon: mathIcon,
    link: '/online-math-tutor',
  },
  {
    subject: 'Science',
    webpIcon: scienceIconWebp,
    icon: scienceIcon,
    link: '/online-science-tutor',
  },
  {
    subject: 'Language',
    webpIcon: languageIconWebp,
    icon: languageIcon,
    link: '/online-language-tutor',
  },
  {
    subject: 'More',
    webpIcon: moreIconWebp,
    icon: moreIcon,
    link: '/online-test-prep-tutor',
  },
];

// ! location cards
export const LocationCards = [
  {
    location: 'New York',
    className: 'locations__location-card locations__location-card--new-york locations__location-card--new-york-webp',
    link: 'online-tutor-new-york',
    src: newYorkImg,
    webp: newYorkWebp,
  },
  {
    location: 'Florida',
    className: 'locations__location-card locations__location-card--florida locations__location-card--florida-webp',
    link: 'online-tutor-florida',
    src: floridaImg,
    webp: floridaWebp,
  },
  {
    location: 'California',
    className:
      'locations__location-card locations__location-card--california locations__location-card--california-webp',
    link: 'online-tutor-california',
    src: californiaImg,
    webp: californiaWebp,
  },
  {
    location: 'Ontario',
    className: 'locations__location-card locations__location-card--ontario locations__location-card--ontario-webp',
    link: 'online-tutor-ontario',
    src: ontarioImg,
    webp: ontarioWebp,
  },
  {
    location: 'British Columbia',
    className: 'locations__location-card locations__location-card--bc locations__location-card--bc-webp',
    link: 'online-tutor-british-columbia',
    src: bcImg,
    webp: bcWebp,
  },
];

// ! pricing cards
export const PricingCardsInfo = [
  {
    grade: 'JK - Grade 3',
    title: 'Elementary School',
    cost: '$34 USD',
    benefits: [
      'Learn to read program',
      'Fundamentals of mathematics',
      'Fundamentals of writing',
      'Intro to Spanish & French',
    ],
    imgWebp: elementaryIconWebp,
    img: elementaryIcon,
    alt: 'abc graphic',
    pClassName: 'pricing__img',
  },

  {
    grade: 'Grade 4 - 8',
    title: 'Middle School',
    cost: '$34 USD',
    benefits: [
      'Grammar, vocabulary, syntax',
      'Accelerated mathematics',
      'Introduction to essay writing',
      'Advanced languages',
    ],
    imgWebp: middleSchoolIconWebp,
    img: middleSchoolIcon,
    alt: 'textbook graphic',
    pClassName: 'pricing__img',
  },

  {
    grade: 'Grade 9 - 12',
    title: 'High School',
    cost: '$40 USD',
    benefits: ['Advanced essay writing', 'Advanced mathematics', 'Chemistry, physics, biology', 'Fluency in language'],
    imgWebp: highSchoolIconWebp,
    img: highSchoolIcon,
    alt: 'graduation graphic',
    pClassName: 'pricing__img',
  },
];

export const StandardSubjectQuickLinks = {
  pages: [
    {
      pageName: 'English Tutor',
      pageLink: '/online-english-tutor',
    },
    {
      pageName: 'Reading Tutor',
      pageLink: '/online-reading-tutor',
    },
    {
      pageName: 'Writing Tutor',
      pageLink: '/online-writing-tutor',
    },
    {
      pageName: 'Math Tutor',
      pageLink: '/online-math-tutor',
    },
    {
      pageName: 'Science Tutor',
      pageLink: '/online-science-tutor',
    },
  ],
  worksheets: [
    {
      worksheetsName: 'All Worksheets',
      worksheetsLink: '/worksheets-for-kids',
    },
    {
      worksheetsName: 'Preschool',
      worksheetsLink: '/preschool-worksheets',
    },
    {
      worksheetsName: 'Kindergarten',
      worksheetsLink: '/worksheets-for-kindergarten',
    },
    {
      worksheetsName: 'Grade 1',
      worksheetsLink: '/grade-1-worksheets',
    },
    {
      worksheetsName: 'Grade 2',
      worksheetsLink: '/grade-2-worksheets',
    },
    {
      worksheetsName: 'Grade 3',
      worksheetsLink: '/grade-3-worksheets',
    },
    {
      worksheetsName: 'Grade 4',
      worksheetsLink: '/grade-4-worksheets',
    },
    {
      worksheetsName: 'Grade 5',
      worksheetsLink: '/grade-5-worksheets',
    },
  ],
};
