import React, { useState, useEffect } from 'react';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import ShareIcons from '../Components/Partials/ShareIcons';
import DownloadButton from '../Components/Partials/DownloadButton';
import SignInModal from '../Components/SignInModal';
import AWSImage from '../Components/Partials/AWSImage';
import MetaData from '../Components/MetaData';
import BackToPage from '../Components/Partials/BackToPage';
import WorksheetText from '../Components/WorksheetPartials/WorksheetText';
import { useUserData } from '../Hooks/useUserData';
import { useDisplayData } from '../Hooks/useDisplayData';
import { useResourceFilters } from '../Hooks/useResourceFilter';
import { Resources, UpdatedFilterOptions } from '../Models';
import {
  GetBaseURL,
  CurrentDomain,
  isDownloadable,
  generatedRoute,
  generatedLocation,
  FormatGrades,
  decodeURLString,
  SingleResourceLink,
} from '../Utils';

interface MatchParams {
  documentTitle: string;
}

interface ComponentProps extends RouteComponentProps<MatchParams> {}

const SingleWorksheet: React.FC<ComponentProps> = ({ match }) => {
  const { userData } = useUserData();
  const { displayData, hideModal, startBodyScroll, setLoginType } = useDisplayData();
  const { resourceData } = useResourceFilters();
  const [cannonical, setCannonical] = useState<null | string>(null);
  const [canDownload, setCanDownload] = useState(false);
  const [willRedirect, setWillRedirect] = useState(false);
  const [resource, setResource] = useState<Resources | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [displaySignIn, setDisplaySignIn] = useState(false);
  const [cookies, setCookie] = useCookies(['email']);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Check URL for case sensitivity. Add canonical link if necessary
  useEffect(() => {
    const capitalizedDocuments = [
      'Letter A',
      'Letter B',
      'Letter C',
      'Letter D',
      'Letter E',
      'Letter F',
      'Letter I',
      'Letter T',
      'Common and Proper Nouns',
      'Possessive Nouns',
      'Prepositions',
    ];

    const decodedURL = decodeURLString(match.params.documentTitle);

    const isCapitalizedTitle = capitalizedDocuments.find((singleTitle) => {
      return decodedURL.toLowerCase() === singleTitle.toLowerCase();
    });

    const willCapitalizeExisting = isCapitalizedTitle && decodedURL !== isCapitalizedTitle;

    if (isCapitalizedTitle && willCapitalizeExisting) {
      setCannonical(`${CurrentDomain()}/worksheets/${SingleResourceLink(isCapitalizedTitle)}`);
      window.location.replace(`${CurrentDomain()}/worksheets/${SingleResourceLink(isCapitalizedTitle)}`);
    } else if (!isCapitalizedTitle && decodedURL.toLowerCase() !== decodedURL) {
      setCannonical(`${CurrentDomain()}/worksheets/${SingleResourceLink(decodedURL.toLowerCase())}`);
      window.location.replace(`${CurrentDomain()}/worksheets/${SingleResourceLink(decodedURL.toLowerCase())}`);
    }
  }, []);

  useEffect(() => {
    if (cookies.email) {
      setIsLoggedIn(true);
      setDisplaySignIn(false);
    }
  }, []);

  useEffect(() => {
    if (resource !== null) {
      let filters: UpdatedFilterOptions = {
        docType: resource.type,
      };

      if (resource.grade && resource.grade.length > 0) {
        filters.grade = resource.grade[0];
      }

      if (resource.subcategories && resource.subcategories.length > 0) {
        filters.subcategory = resource.subcategories[0];
      }

      if (resource.subject && resource.subject.length > 0) {
        filters.subject = resource.subject[0];
      }
    }
  }, [resource]);

  useEffect(() => {
    if (cookies.email) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [cookies.email]);

  useEffect(() => {
    if (userData.subscription_active) {
      setCanDownload(true);
    } else {
      setCanDownload(isDownloadable(userData.assessments, userData.worksheets));
    }
  }, [userData.assessments, userData.worksheets]);

  useEffect(() => {
    (async () => {
      let documentRequest = await fetch(
        `${GetBaseURL()}/api/resources/fetchDocumentByTitleInsensitive/${match.params.documentTitle}?key=${
          process.env.REACT_APP_API_KEY
        }`,
      );

      if (documentRequest.status === 200) {
        let responseBody = await documentRequest.json();
        setResource(responseBody.resource);
      } else {
        setWillRedirect(true);
      }
    })();
  }, [match]);

  if (willRedirect) {
    return <Redirect to={'/notFound'} />;
  } else if (resource !== null) {
    return (
      <section className="single-worksheet__wrapper">
        <div
          className={displayData.showModal || displaySignIn ? 'overlay' : 'transparent'}
          onClick={() => {
            hideModal();
            startBodyScroll();
          }}
        ></div>
        <MetaData
          pageTitle={resource.title}
          postTitle={resource.title}
          description={resource.description}
          image="https://nft-development.netlify.app/assets/webp/logo.png"
          url={`${process.env.REACT_APP_DOMAIN_NAME}/worksheets/${match.params.documentTitle}`}
          keywords={[
            `${resource.title} ${resource.type}`,
            `${resource.title} ${resource.type.slice(0, resource.type.length - 1)}`,
          ]}
          canonical={cannonical ? cannonical : undefined}
        />

        <BackToPage
          route={`/${generatedRoute({ ...resourceData.filters })}`}
          location={generatedLocation(generatedRoute({ ...resourceData.filters }))}
        />

        <div className="single-worksheet">
          <div className="single-worksheet__heading-wrapper">
            <div className="single-worksheet__heading-type-wrapper">
              <h1 className="single-worksheet__heading">
                {`${resource.title} ${resource.type.slice(0, resource.type.length - 1)}`}
                {/* <span className="single-worksheet__type">{resource.type}</span> */}
              </h1>
            </div>
            <section className="single-worksheet__share-wrapper">
              <ShareIcons
                shareUrl={`${CurrentDomain()}/worksheets/${resource._id}`}
                facebookQuote={resource.facebookQuote}
                imagePreviewString={new URL(
                  `/worksheets/${encodeURIComponent(resource.src)}`,
                  process.env.REACT_APP_AWS_BUCKET_URL,
                ).toJSON()}
              />
            </section>
          </div>
          <section className="single-worksheet__worksheet-content">
            <div className="modal-image single-worksheet__worksheet-image">
              <AWSImage imageAlt={resource.alt} pngFile={resource.src} picClass={''} documentPrefix="worksheets" />
              {resource.title !==
                ('Letter A' ||
                  'Letter B' ||
                  'Letter C' ||
                  'Letter D' ||
                  'Letter E' ||
                  'Letter F' ||
                  'Letter I' ||
                  'Letter T' ||
                  'Common and Proper Nouns' ||
                  'Possessive Nouns' ||
                  'Prepositions') && (
                <AWSImage
                  imageAlt={'Document Preview Cover'}
                  pngFile={'worksheet-preview-cover.png'}
                  picClass={'preview-blocker'}
                  documentPrefix="worksheets"
                />
              )}
            </div>
            <div className="single-worksheet__worksheet-info">
              <p className="single-worksheet__worksheet-description">{resource.description}</p>
              <div className="single-worksheet__categories-download-container">
                <div className="single-worksheet__subject-grade-wrapper">
                  <span className="single-worksheet__subject">
                    <strong>Subject</strong>: {resource.subject[0]}
                  </span>
                  <span className="single-worksheet__grade">
                    <strong>Grade</strong>: {FormatGrades(resource.grade)}
                  </span>
                </div>
                <div className="single-worksheet__download-button">
                  {isLoggedIn ? (
                    canDownload ? (
                      <DownloadButton awsKey={resource.pdfDoc} docType={resource.type} resource_id={resource._id} />
                    ) : (
                      <Link to="/premium" target="_blank" rel="noopener noreferrer">
                        <button className="modal__download modal__download--download-document-button">Download</button>
                      </Link>
                    )
                  ) : (
                    <button
                      data-cy="signInModal__button--displaySignIn"
                      className=" modal__download single-worksheet__download-button"
                      onClick={() => {
                        setDisplaySignIn(true);
                        setLoginType({ login: false, signup: true });
                      }}
                    >
                      Download
                    </button>
                  )}
                </div>
              </div>
            </div>
          </section>
          <p className="single-worksheet__newsletter-info">
            * By downloading you are signing up to our newsletter. You can unsubscribe anytime.
          </p>
        </div>
        {displaySignIn && (
          <SignInModal
            HideModal={() => {
              setDisplaySignIn(false);
            }}
            awsKey={resource.pdfDoc}
          />
        )}
        {resource.uniqueTextBlock !== undefined && resource.uniqueTextBlock.length > 0 && (
          <WorksheetText textData={resource.uniqueTextBlock} />
        )}
        {displayData.showModal && <SignInModal HideModal={hideModal} />}
      </section>
    );
  } else {
    return (
      <>
        <div className="single-worksheet__loading-wrapper">
          <h1 className="single-worksheet__loading-text">Waiting for Document to Load</h1>
        </div>
      </>
    );
  }
};

export default SingleWorksheet;
