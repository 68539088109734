import React from 'react';
import { generateID } from '../../Utils';
import WorksheetTextHeading from './WorksheetTextHeading';
import WorksheetTextParagraph from './WorksheetTextParagraph';
import WorksheetTextLink from './WorksheetTextLink';
import WorksheetTextList from './WorksheetTextList';
import { WorksheetsText } from '../../Models';

type WorksheetTextProps = {
  textData: WorksheetsText;
};

const WorksheetText: React.FC<WorksheetTextProps> = ({ textData }) => {
  const TEXT_BLOCK = textData.map((singleSegment) => {
    return (
      <React.Fragment key={generateID()}>
        {singleSegment.type === 'header' ? (
          <WorksheetTextHeading segment={singleSegment} />
        ) : singleSegment.type === 'paragraph' ? (
          <WorksheetTextParagraph segment={singleSegment} />
        ) : singleSegment.type === 'linkInText' ? (
          <WorksheetTextLink segment={singleSegment} />
        ) : singleSegment.type === 'list' ? (
          <WorksheetTextList segment={singleSegment} />
        ) : null}{' '}
      </React.Fragment>
    );
  });

  return <section className="single-worksheet worksheet_text_block">{TEXT_BLOCK}</section>;
};

export default WorksheetText;
