import React from 'react';
import NavLink from './NavLink';
import { NavDropdownProps } from '../../Models';

const NavDropdown: React.FC<NavDropdownProps> = ({
  title,
  options,
  handler,
  onBlur,
  link,
  id,
  toggleState,
  responsive,
  toggleDropdown,
  closeNav,
}) => {
  const [items] = React.useState(options);

  return (
    <div
      className={responsive ? 'responsive-dropdown-container' : 'dropdown-container'}
      onClick={handler}
      onBlur={onBlur}
      id={id}
    >
      <div className={responsive ? 'responsive-nav-dropdown' : 'nav-dropdown'}>
        <a href={link}>{title}</a>
        <i className="fas fa-caret-down"></i>
      </div>
      <div
        className={
          responsive && toggleState
            ? 'responsive-dropdown-content active'
            : responsive
            ? 'responsive-dropdown-content'
            : 'dropdown-content'
        }
      >
        {items.map((item, index) => (
          <div
            key={item.label}
            className={`dropdown-group ${item.label.toLowerCase()}`} // Add a class based on the label
          >
            <NavLink
              href={item.link}
              title={item.label}
              text={item.label}
              nested={item.nested}
              responsive={responsive}
              toggleDropdown={toggleDropdown}
              closeNav={closeNav}
            />
            <div className="nested-links">
              {item.children &&
                item.children.map((child) => (
                  <NavLink
                    key={child.label}
                    href={child.link}
                    title={child.label}
                    text={child.label}
                    nested={true} // Assuming nested items are to be styled differently
                    responsive={responsive}
                    toggleDropdown={toggleDropdown}
                    closeNav={closeNav}
                  />
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NavDropdown;
