import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Banner from '../Components/Partials/Banner';
import ResourceComponent from '../Components/ResourceComponent';
import WorksheetsForKidsText from '../Components/Resources/WorksheetsForKidsText';
import WorksheetsForKindergartenText from '../Components/Resources/WorksheetsForKindergartenText';
import { useDisplayData } from '../Hooks/useDisplayData';
import { useResourceFilters } from '../Hooks/useResourceFilter';
import { PDFContextProvider } from '../Context/PDFContext';

interface MatchParams {
  filterURL: string;
}

interface ComponentProps extends RouteComponentProps<MatchParams> {}

const WorksheetHome: React.FC<ComponentProps> = (routerProps) => {
  const { displayData, hideModal, startBodyScroll, redirectReload } = useDisplayData();
  const { resourceData } = useResourceFilters();

  const [width, setWidth] = useState(window.innerWidth);
  window.addEventListener('resize', () => {
    setWidth(window.innerWidth);
  });

  useEffect(() => {
    if (displayData.redirectReload) {
      redirectReload(false);
      window.location.reload();
    }
  }, [displayData.redirectReload]);

  return (
    <div className="main-container">
      <div
        className={displayData.showModal ? 'main-overlay' : 'transparent'}
        onClick={() => {
          hideModal();
          startBodyScroll();
        }}
      ></div>
      {width < 768 ? (
        <Banner
          lineOne={resourceData.pageTitle}
          main
          imageName="worksheets_banner_mobile"
          alt="No Fuss Tutors banner"
        />
      ) : width < 1024 ? (
        <Banner
          lineOne={resourceData.pageTitle}
          main
          imageName="worksheets_banner_tablet"
          alt="No Fuss Tutors banner"
        />
      ) : width < 1395 ? (
        <Banner
          lineOne={resourceData.pageTitle}
          main
          imageName="worksheets_banner_desktop"
          alt="No Fuss Tutors banner"
        />
      ) : (
        <Banner lineOne={resourceData.pageTitle} main imageName="worksheets_banner" alt="No Fuss Tutors banner" />
      )}
      <PDFContextProvider>
        <ResourceComponent {...routerProps} />
      </PDFContextProvider>
      {routerProps.match.params.filterURL === 'worksheets-for-kids' && <WorksheetsForKidsText />}
      {routerProps.match.params.filterURL === 'worksheets-for-kindergarten' && <WorksheetsForKindergartenText />}
    </div>
  );
};

export default WorksheetHome;
