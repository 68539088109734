import React from 'react';
import AWSImage from '../Partials/AWSImage';

type ScienceProgramsProps = {
  content: {
    number: string;
    header: string;
    programContent?: string;
    img: any;
    alt: string;
    awsPrefix: string;
    picClass: string;
  }[];
};

const SciencePrograms: React.FC<ScienceProgramsProps> = ({ content }) => {
  return (
    <section className="science-programs">
      {content.map((item, index) => {
        return (
          <div className={`science-programs__${item.number}`} key={index}>
            <AWSImage
              pngFile={item.img}
              picClass={item.picClass}
              imageAlt={item.alt}
              documentPrefix={item.awsPrefix}
              hasWebp
            />
            <div className="science-programs__content">
              <p className="science-programs__number">{item.number}</p>
              <h4 className="science-programs__header">{item.header}</h4>
              <p className="science-programs__program-content">{item.programContent}</p>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default SciencePrograms;
