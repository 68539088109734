import { UpdatedFilterOptions } from '../Models';
import { URLGeneratorByFilter } from './URLUtils';

export const generatedRoute = (filters: UpdatedFilterOptions) => {
  return URLGeneratorByFilter(filters);
};

export const generatedLocation = (route: string) => {
  let adjustedURLString = route.split('-').join(' ');
  let capitalizedString = adjustedURLString.split(' ').map((singleWord) => {
    return `${singleWord.charAt(0).toUpperCase()}${singleWord.length > 1 ? singleWord.slice(1) : ''}`;
  });
  return capitalizedString.join(' ');
};
