import React from 'react';
import { DateOptions } from '../../Models';
import AWSImage from '../Partials/AWSImage';
import Picture from '../Partials/Picture';

type BannerProps = {
  lineOne: string;
  imageName?: string;
  image?: any;
  webp?: any;
  alt: string;
  lineTwo?: string;
  author?: string;
  date?: string;
  //! main is declared when blog title is one line only
  main?: boolean;
  awsPrefix?: string; //true when the image should be loaded from an AWS URL
};

const Banner: React.FC<BannerProps> = ({
  lineOne,
  lineTwo,
  author,
  date,
  main,
  imageName,
  image,
  webp,
  alt,
  awsPrefix,
}) => {
  return (
    <section className={main ? 'main' : 'blog-page__banner'}>
      {awsPrefix ? (
        <AWSImage pngFile={image} imageAlt={alt} documentPrefix={awsPrefix} picClass="blog-page__banner-img" hasWebp />
      ) : (
        <Picture webp={webp} src={image} alt={alt} picClass="blog-page__banner-img" />
      )}
      <div className={main ? 'main__content' : 'blog-page__banner-content'}>
        <h1 className={main ? 'main__heading' : 'blog-page__banner-heading'}>
          {lineOne}
          {lineTwo ? (
            <>
              <br />
              {lineTwo}
            </>
          ) : null}
        </h1>
        {author ? (
          <div className="author-date">
            <span className="author-date__author">{author}</span>
            {date ? (
              <>
                <i className="fas fa-circle author-date__dot"></i>
                <span className="author-date__date">{new Date(date).toLocaleString('en-US', DateOptions)}</span>
              </>
            ) : null}
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default Banner;
