import React from 'react';

const HeaderRefactor = () => {
  return (
    <header className="header">
      <section className="header__contact-review">

      </section>
    </header>
  )
}

export default HeaderRefactor
