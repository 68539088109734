import React from 'react';
import AWSImage from './AWSImage';

type TutoringProgramsProps = {
  content: {
    number: string;
    header: string;
    programInfo: string;
    benefits: string[];
    img: any;
    className: string;
    alt: string;
    imgClass: string;
    awsPrefix: string;
  }[];
  headerContent: {
    header: string;
  };
};

const TutoringPrograms: React.FC<TutoringProgramsProps> = ({ content, headerContent }) => {
  const { header } = headerContent;
  return (
    <section className="math-page__tutoring-programs">
      <h3 className="math-page__heading">{header}</h3>

      {content.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <div className="math-page__tutoring-programs-wrapper">
              <div className={item.className}>
                <div className="math-page__program-content">
                  <h4 className="math-page__program-header math-page__program-header--number">{item.number}</h4>
                  <h4 className="math-page__program-header">{item.header}</h4>
                  <p className="math-page__program-info">{item.programInfo}</p>

                  {item.benefits.map((item, index) => {
                    return (
                      <div className="math-page__program-benefits" key={index}>
                        <p className="math-page__program-benefits-content">
                          <i className="fa fa-check math-page__program-benefits-icon"></i>
                          {item}
                        </p>
                      </div>
                    );
                  })}
                </div>
                <AWSImage
                  imageAlt={item.alt}
                  pngFile={item.img}
                  documentPrefix={item.awsPrefix}
                  picClass={item.imgClass}
                  hasWebp
                />
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </section>
  );
};

export default TutoringPrograms;
