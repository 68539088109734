export const PremiumCards = [
  {
    price: '$9.99',
    originalPrice: '$15',
    subType: 'Monthly Price',
  },
  {
    price: '$6.99',
    originalPrice: '$10',
    subType: 'Annual Price',
  },
];
