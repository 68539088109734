import React from 'react';
import AWSImage from '../Partials/AWSImage';

const WhyTutoring = () => {
  return (
    <section className="why-tutoring">
      <div className="why-tutoring__left-container">
        <h2 className="why-tutoring__header">Why choose 1 on 1 tutoring over a test prep class?</h2>
        <AWSImage
          pngFile={'testPrep2.jpg'}
          documentPrefix={'homepage/test-prep'}
          picClass={'why-tutoring__img'}
          imageAlt={''}
          hasWebp
        />
      </div>
      <div className="why-tutoring__right-container">
        <div className="why-tutoring__point">
          <h4 className="why-tutoring__sub-header">The Problem</h4>
          <p className="why-tutoring__summary">
            Classes give the same lectures and questions to every student, making their teaching very ineffective. Some
            lessons will be too easy, while others will be too hard. Students waste time relearning content they already
            know and don’t spend enough time on the topics they actually need to work on.
          </p>
        </div>
        <div className="why-tutoring__point">
          <h4 className="why-tutoring__sub-header">The Solution</h4>
          <p className="why-tutoring__summary">
            Individualized teaching with custom lessons for you. Ideally with No Fuss Tutors! (But we’re biased)
          </p>
        </div>
      </div>
    </section>
  );
};

export default WhyTutoring;
