
   import Board from "./components/Board";
   import Keyboard from "./components/Keyboard";
   import { boardDefault, generateWordSet } from "./Words";
   import React, { useState, createContext, useEffect } from "react";
   import GameOver from "./components/GameOver";
   
   export const AppContext = createContext();

   const createEmptyBoard = () => {
    return new Array(6).fill(null).map(() => new Array(5).fill(""));
  };
  
   
   export function GuessTheWord() {
     const [board, setBoard] = useState(boardDefault);
     const [currAttempt, setCurrAttempt] = useState({ attempt: 0, letter: 0 });
     const [wordSet, setWordSet] = useState(new Set());
     const [correctWord, setCorrectWord] = useState("");
     const [wordNotFound, setWordNotFound] = useState(false);
     const [disabledLetters, setDisabledLetters] = useState([]);
     
     const [gameOver, setGameOver] = useState({
      gameOver: false,
      guessedWord: false,
    });

    const generateNewWord = async () => {
      const words = await generateWordSet();
      setWordSet(words.wordSet);
      setCorrectWord(words.todaysWord);
    };
    
  
    const handleGameOver = () => {
      setGameOver({ gameOver: false, guessedWord: false });
      setDisabledLetters([]);
      setCurrAttempt({ attempt: 0, letter: 0 });
      setBoard(createEmptyBoard());
      generateNewWord();
    };
    
    
   
     useEffect(() => {
       generateWordSet().then((words) => {
         setWordSet(words.wordSet);
         setCorrectWord(words.todaysWord);
         generateNewWord();
       });
     }, []);
   
     const onEnter = () => {
       if (currAttempt.letter !== 5) return;
   
       let currWord = "";
       for (let i = 0; i < 5; i++) {
         currWord  += board[currAttempt.attempt][i].toLowerCase();
       }
       if (wordSet.has(currWord.toLowerCase())) {
         setCurrAttempt({ attempt: currAttempt.attempt + 1, letter: 0 });
         setWordNotFound(false)
       } else {
           setWordNotFound(true)
           if (currAttempt.letter === 0) return;
           const newBoard = [...board];
           newBoard[currAttempt.attempt][currAttempt.letter - 1] = "";
           setBoard(newBoard);
           newBoard[currAttempt.attempt][currAttempt.letter - 2] = "";
           setBoard(newBoard);
         
           setCurrAttempt({ ...currAttempt, letter: currAttempt.letter - 2 });
       }

   
      
      
   
       if (currWord === correctWord) {
         setGameOver({ gameOver: true, guessedWord: true });
         setWordNotFound(false)
         return;
       }
       console.log(currAttempt);
       if (currAttempt.attempt === 5) {
         setGameOver({ gameOver: true, guessedWord: false });
         setWordNotFound(false)
         return;
       }
     };
   
     const onDelete = () => {
       if (currAttempt.letter === 0) return;
       const newBoard = [...board];
       newBoard[currAttempt.attempt][currAttempt.letter - 1] = "";
       setBoard(newBoard);
       setCurrAttempt({ ...currAttempt, letter: currAttempt.letter - 1 });
     };
   
     const onSelectLetter = (key) => {
      if (currAttempt.letter > 4 || !board) return;
      const newBoard = [...board];
      newBoard[currAttempt.attempt][currAttempt.letter] = key;
      setBoard(newBoard);
      setCurrAttempt({
        attempt: currAttempt.attempt,
        letter: currAttempt.letter + 1,
      });
    };
    
   
     const wordNotFoundToggler = () => {
       setWordNotFound(false)
     }
   
     return (
       <div className="guess" >
         <nav>
         <img
                  src="https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/GuessTheWordRow.png"
                  alt="Art Off"
                  className="games__guess-image-title"
                />
         </nav>
   
         {wordNotFound && (
    
            <div className="word-not-found" onClick={wordNotFoundToggler} >
            <div>Word Not Found. 
              </div>
              <br></br> <div>Keep in mind, we don't include plural 
              words or obscure words that most adults wouldn't know.</div>
              <button className="word-not-found__button">Got It</button>
              </div>
              
          )}
    
    <AppContext.Provider
  value={{
    board,
    setBoard,
    currAttempt,
    setCurrAttempt,
    correctWord,
    onSelectLetter,
    onDelete,
    onEnter,
    setDisabledLetters,
    disabledLetters,
    gameOver,
    handleGameOver,
  }}   >
            <div className="game">
              <Board />
              {gameOver.gameOver ? <GameOver /> : <Keyboard />}
            </div>
          </AppContext.Provider>
        </div>
      );
    }
    
    export default GuessTheWord

