import React from 'react';

type EnglishSkillsProps = {
   content: string[]
}

const EnglishSkills: React.FC<EnglishSkillsProps> = ({ content }) => {
   return (
      <section className="english-skills">
         <h2 className="english-skills__header">
            English skills are not limited to English classes
         </h2>
         <h5 className="english-skills__sub-header">
            Developing strong reading and writing skills has a number of benefits
         </h5>
         <div className="english-skills__skill-wrapper">
            {
               content.map((item, index) => {
                  return (
                     <div key={index} className="english-skills__skill-card">
                        <i className="fa fa-check english-skills__check-icon" aria-hidden="true"></i>
                        <p className="english-skills__skill">
                           {item}
                        </p>
                     </div>
                  )
               })
            }
         </div>
      </section>
   )
}

export default EnglishSkills
