// ! math page program info
export const MathTutoringProgramsInfo = [
  {
    number: '01',
    header: 'Standard Math Program',
    programInfo:
      'Our standard math program focuses on helping your child excel at their current grade level. We work a few weeks ahead of the actual material they will be covering in class in order to ensure that new material taught in class feels like review.',
    benefits: [
      'Practice questions and homework related to class topics.',
      'Communication with their teacher is essential.',
      'Double down on the class curriculum to ensure higher marks.',
    ],
    img: 'math1.jpg',
    awsPrefix: 'homepage/math',
    className: 'math-page__content',
    alt: 'child doing homework looking at camera',
    imgClass: 'math-page__01-img',
  },

  {
    number: '02',
    header: 'Advanced math program',
    programInfo:
      "Our advanced program focuses on material ahead of your child's current grade level. We work on challenging questions preparing your child for long-term success in the field of math.",
    benefits: [
      'Structured curriculum ahead of their current grade level.',
      'Best for parents who want their child to become a math whiz!',
    ],
    img: 'math2.jpg',
    awsPrefix: 'homepage/math',
    className: 'math-page__content-reverse',
    alt: 'student doing homework in front of camera',
    imgClass: 'math-page__02-img',
  },
];

// ! langue program info
export const LanguagesTutoringProgramsInfo = [
  {
    number: '01',
    header: 'Beginner Level',
    programInfo:
      'Beginner level language tutoring covers the basics. We want to make sure your base is strong and that you understand elementary grammar, verb usage and pronunciation.',
    benefits: [
      'Conjugation of verbs.',
      'Awareness of tense and basic sentence structure.',
      'Intro to pronunciation and translating basic vocabulary.',
    ],
    img: 'language2.jpg',
    awsPrefix: 'homepage/languages',
    className: 'math-page__content',
    alt: 'child doing homework on computer',
    imgClass: 'math-page__01-img',
  },

  {
    number: '02',
    header: 'Advanced level',
    programInfo:
      'Our advanced program aims to achieve fluency through focusing on writing, expanding vocabulary, oral comprehension and pronunciation.',
    benefits: [
      'Structured curriculum geared toward achieving fluency.',
      'Focus on pronunciation, writing & oral communication.',
      'For high level academic schools where students are immersed in the language.',
    ],
    img: 'language3.jpg',
    awsPrefix: 'homepage/languages',
    className: 'math-page__content-reverse',
    alt: 'student doing homework on notebook',
    imgClass: 'math-page__02-img',
  },
];

// ! program info headers
export const MathTutoringProgramsHeader = {
  header: 'Our Math Tutoring Programs:',
};

export const LanguagesTutoringProgramsHeader = {
  header: 'Our Languages Tutoring Programs:',
};
