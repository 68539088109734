// ! overview
export const TestOverviewContent = {
  headerInfo: {
    sectionHeading: 'SAT/ACT Scoring',
    sectionSummary:
      'Getting a perfect score on the SAT/ACT sounds like an inconceivable task. Less than 1% of people achieve it. If you are able to achieve this feat, or come close to it, your chances of being admitted to top universities skyrockets and can even make up for a weaker GPA.',
  },

  testInfo: [
    {
      testHeading: 'SAT Overview',
      testSectionHeader: 'There are two sections:',
      testSections: ['Evidence-Based Reading and Writing', 'Math (no-calculator and calculator-based portions)'],
      testSummary:
        'Each section is scored from 200 to 800. The total score is a sum of the section scores, and ranges from 400 to 1600. Because the highest score that anyone can get is a 1600, this is a perfect score.',
    },

    {
      testHeading: 'ACT Overview',
      testSectionHeader: 'The ACT contains multiple-choice tests in four sections:',
      testSections: ['English', 'Mathematics', 'Reading', 'Science'],
      testSummary: "ACT's writing test is optional and will not affect your composite score.",
    },
  ],

  images: {
    img: 'testPrep1.jpg',
    alt: 'three students taking test on pen and paper',
    picClass: 'test-overview__img',
    awsPrefix: 'homepage/test-prep',
  },
};

// ! perfect score steps
export const PerfectScoreSteps = [
  {
    stepHeader: 'We identify your baseline',
    stepSummary:
      'Everyone starts somewhere! What sets perfect scorers apart from the rest is learning how to overcome sections they are weaker in to achieve desired results.',
    stepClassName: 'perfect-score__step1',
  },

  {
    stepHeader: 'Highlight your weaknesses',
    stepSummary:
      'There’s always a root cause of weakness. We understand where the focus needs to be paid to early on and make targeted and impactful improvements to your skillset in those areas.',
    stepClassName: 'perfect-score__step2',
  },

  {
    stepHeader: 'Create a structured path forward',
    stepSummary: 'Create a study plan with your test date in mind with time set aside for review.',
    stepClassName: 'perfect-score__step3',
  },

  {
    stepHeader: 'Test and update your weaknesses as you improve',
    stepSummary: 'Good test prep starts with a targeted practice cycle:',
    step4List: [
      'Identify areas of weakness.',
      'Work to address them with the help of our tutors.',
      'Practice questions related to what you’ve learned.',
      'Repeat.',
    ],
    stepClassName: 'perfect-score__step4',
  },

  {
    stepHeader: 'Continue to strengthen weak points up until test day',
    stepSummary:
      'Throughout this study journey, your areas of weakness will naturally shift. Prior weaknesses will become strong points, so to challenge you and see improvements, we adapt our questions and lessons to your skill level. This is crucial to attaining the best score possible.',
    stepClassName: 'perfect-score__step5',
  },
];
