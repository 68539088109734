import React from 'react';
import { EnglishPagePricingCardInfo } from '../../Models';
import AWSImage from '../Partials/AWSImage';

const PricingCards = () => {
  return (
    <section className="pricing-cards">
      <h2 className="pricing-cards__header">
        We accelerate your child's learning from as young as age 3 through university
      </h2>
      <div className="pricing-cards__cards-container">
        {EnglishPagePricingCardInfo.map((item, index) => {
          return (
            <div key={index} className="pricing-cards__card-wrapper">
              <AWSImage
                pngFile={item.img}
                imageAlt={item.alt}
                documentPrefix="homepage/english"
                picClass={item.class}
                hasWebp
              />
              <div className="pricing-cards__card-content">
                <p
                  className={
                    item.header === 'Elementary School'
                      ? 'pricing-cards__card-tag pricing-cards__card-tag--elementary-school'
                      : item.header === 'Middle School'
                      ? 'pricing-cards__card-tag pricing-cards__card-tag--middle-school'
                      : 'pricing-cards__card-tag pricing-cards__card-tag--high-school'
                  }
                >
                  {item.grade}
                </p>
                <h4 className="pricing-cards__card-header">{item.header}</h4>
                <p className="pricing-cards__card-price">
                  {item.price}
                  <span className="pricing-cards__per-hour">/hour</span>
                </p>
                <p className="pricing-cards__description">{item.summary}</p>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default PricingCards;
