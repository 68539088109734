import React, { useEffect, useState } from 'react';
import AWSImage from '../AWSImage';

type ProgramInformationProps = {
  info: {
    name: string;
    minTimesPerWeek: number;
    subject: string;
    ageRange: string;
    priceLow: number;
    priceMid: number;
    priceHigh: number;
    cardText: string;
    bgColor: string;
  };
  onClick: (
    name: string,
    minTimesPerWeek: number,
    subject: string,
    ageRange: string,
    price: number,
    cardText: string,
    bgColor: string,
    lessonLength: string,
    priceLow: number,
    priceMid: number,
    priceHigh: number,
  ) => void;
};

const ProgramCard: React.FC<ProgramInformationProps> = ({ info, onClick }) => {
  const { name, minTimesPerWeek, subject, ageRange, priceLow, priceMid, priceHigh, cardText, bgColor } = info;
  const [lessonLength, setLessonLength] = useState('45 min');
  const [price, setPrice] = useState(priceMid);

  useEffect(() => {
    lessonLength === '30 min' ? setPrice(priceLow) : lessonLength === '1 hr' ? setPrice(priceHigh) : setPrice(priceMid);
  }, [lessonLength]);
  return (
    <div>
      <div
        className="programs__program-card"
        onClick={() =>
          onClick(
            name,
            minTimesPerWeek,
            subject,
            ageRange,
            price,
            cardText,
            bgColor,
            lessonLength,
            priceLow,
            priceMid,
            priceHigh,
          )
        }
      >
        <div
          style={{
            backgroundImage: bgColor,
            paddingTop: subject === 'Any' ? '10px' : '20px',
            lineHeight: subject === 'Any' ? '1.2' : '1.7',
          }}
          className="program-img"
        >
          {`${cardText}${subject === 'Any' ? ' Subject' : ''}`}
          <br></br>
          <span className="program-img--text">1-on-1</span>
        </div>

        <div className="programs__program-header-info-wrapper">
          <div className="programs__info-wrapper">
            <h4 className="programs__program-card-name">{name}</h4>
            <h5 className="programs__program-occupation">{`${minTimesPerWeek} x per week`}</h5>
            <p className="programs__education">
              <i className="fas fa-graduation-cap programs__education-bio-icon"></i>
              {`Ages ${ageRange}`}
            </p>
            {/* {subject === 'Any' && (
            <p className="programs__bio">
              <i className="fas fa-bolt programs__education-bio-icon"></i>
              Any subject
            </p>
          )} */}
          </div>
          <div className="programs__price-info-wrapper">
            <div className="programs__price-number-wrapper">
              <p className="programs__price-number">${Math.round(100 * price) / 100}</p>
            </div>
            <div className="programs__price-row-end">
              <p className="programs__price-payment-schedule">12 lessons per month</p>
            </div>

            <div className="programs__price-row-end">
              <p className="programs__price-payment-schedule"></p>
            </div>
          </div>
        </div>
        <div className="programs__each-lesson">
          Each 1-on-1 lesson comes to:{''}
          <span className="programs__price-each">${Math.round((100 * price) / 12) / 100}</span>
        </div>
      </div>
      <div className="programs__lesson-container">
        <div className="programs__lesson-heading">Lesson Length</div>
        <div className="programs__lesson-length-wrapper">
          <div
            className={lessonLength === '30 min' ? 'programs__lesson-length--selected' : 'programs__lesson-length'}
            onClick={() => setLessonLength('30 min')}
          >
            30 min
          </div>
          <div
            className={lessonLength === '45 min' ? 'programs__lesson-length--selected' : 'programs__lesson-length'}
            onClick={() => setLessonLength('45 min')}
          >
            45 min
          </div>
          <div
            className={lessonLength === '1 hr' ? 'programs__lesson-length--selected' : 'programs__lesson-length'}
            onClick={() => setLessonLength('1 hr')}
          >
            1 hr
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramCard;
