import React from 'react';

type PictureProps = {
  webp?: any;
  src: any;
  alt: string;
  picClass?: string;
};

const Picture: React.FC<PictureProps> = ({ webp, src, alt, picClass }) => {
  return (
    <div className={picClass}>
      <picture>
        <source srcSet={webp} type="image/webp" />
        <img src={src} alt={alt} />
      </picture>
    </div>
  );
};

export default Picture;
